import logo from './../../images/pont_portal_logo.png'
import { LR } from '../Common/Help/LR';

const HeaderLogin = () => {

  return (
    <>
      <div className='row d-flex justify-content-center'>
        <div className='col-6 mt-5 mb-2 d-flex justify-content-center'>
          <a href="/">
          <img src={logo} className='img-fluid' alt="Flex Alliance Operators Portals"></img>
          </a>
        </div>
        <div className='col-12 d-flex justify-content-center mb-3'>          
            <LR localResource='HOST_TITLE_HOSTPORTAL'/>            
        </div>
      </div>
    </>
  );
};

export default HeaderLogin;