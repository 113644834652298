import axios, { AxiosError, AxiosResponse } from "axios";
import { HookConfig } from "../../config/HookConfig";
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "react-query";
import { operatorDefaultHeaders } from "../../functions/api/api";
import { ContactGetByIdResponseDto, ContactSetByIdRequestDto } from "../../types/Member/ContactDetailDto";


type ContactGetByIdArgs = {
  params: {
    companyId: string,
    contactId: string
  },
  options?: UseQueryOptions<any, AxiosError>
}

export const useContactGetById = (args: ContactGetByIdArgs) => {
  const { companyId, contactId } = args.params;
  return useQuery<ContactGetByIdResponseDto, AxiosError>(['companies', companyId, contactId,], () =>
    axios.get(`${HookConfig.memberMgmtUrl}/companies/${companyId}/contacts/${contactId}`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then(
      (response) => {
        return response.data;
      }
    ), {
    ...args?.options
  });
};

type ContactSetByIdArgs = {
  params: {
    companyId: string,
    contactId: string
  }
}

export const useContactSetById = (args: ContactSetByIdArgs) => {
  const { companyId, contactId } = args.params;
  return useMutation<ContactGetByIdResponseDto, AxiosError, ContactSetByIdRequestDto>(
    (contactData) => axios.put(`${HookConfig.memberMgmtUrl}/companies/${companyId}/contacts/${contactId}`, contactData,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    )
  );
};