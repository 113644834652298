import { useEffect, useState } from "react";
import i18n from "../../i18n";
import { LR } from "../Common/Help/LR";
import { LabelAndHelp } from "../Common/Help/LabelAndHelp";
import { InventoryAllocationDto } from "../../types/Inventory/InventoryAllocationDto";
import { InventoryTypeSelectOption } from "../../types/Inventory/InventoryTypeDto";

import { InventoryVariantDto, InventoryVariantSelectOption } from "../../types/Inventory/InventoryVariantDto";
import { operatorDefaultHeaders } from "../../functions/api/api";
import Select, { SingleValue } from "react-select";
import axios from "axios";
import { HookConfig } from "../../config/HookConfig";
import ApiStatus from "../Common/Errors/ApiStatus";
import { PriceDto } from "../../types/Price/PriceDto";
import { PricesCollection } from "../Common/Prices/PricesCollection";
import { Paper, Switch } from "@mui/material";
import { useFetchInventoryTypes } from "../../hooks/Inventory/inventory";
import { pontAllowedActions } from "../../data/inventory/pontAllowedActions";
import { pontConstants } from "../../data/pontConstants";

type Args = {
    isEdit: boolean,
    inventoryAllocation: InventoryAllocationDto,
    countryIsoCode3: string,
    inventoryAllocationPrices?: PriceDto[],
    locationId: string
    inventoryName?: string
    submitted: (inventory: InventoryAllocationDto, prices?: PriceDto[]) => void;
};

const AllocationForm = ({ locationId, countryIsoCode3, inventoryAllocationPrices, inventoryName, inventoryAllocation, isEdit, submitted }: Args) => {
    const [inventoryAllocationState, setInventoryAllocationState] = useState({...inventoryAllocation});
    const [submitting, setSubmitting] = useState(false);
    const [inventoryTypes, setInventoryTypes] = useState<InventoryTypeSelectOption[]>([]);
    const [inventoryPrices, setInventoryPrices] = useState<PriceDto[]>([]);
    const [inventoryVariants, setInventoryVariants] = useState<InventoryVariantSelectOption[]>([]);
    const [selectedVariant, setSelectedVariant] = useState<InventoryVariantSelectOption>();
    const [selectedInventoryType, setSelectedInventoryType] = useState<InventoryTypeSelectOption>();
    const { data, status, isSuccess } = useFetchInventoryTypes();


    const getInitialAllowedActions = (): number[] => {
        // We will set default value to 1 if the value is not set
        const allowedActionFlags = inventoryAllocation?.allowedActionFlags || 1
        const localPontAllowedActions = pontAllowedActions
            .reduce<number[]>((acc, action) => {
                if ((allowedActionFlags & action.flag) !== 0) {
                    acc.push(action.flag);
                }
                return acc;
            }, []);
        return localPontAllowedActions.length > 0 ? localPontAllowedActions : [1];
    };

    const [allowedActions, setAllowedActions] = useState<number[]>(getInitialAllowedActions());

    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            setSubmitting(true);

            const inventoryAllocationNumber = allowedActions.reduce((acc, curr) => acc + curr, 0)

            const _inventoryAllocationState = {
                ...inventoryAllocationState,
                allowedActionFlags: inventoryAllocationNumber <= 1 ? 1 : inventoryAllocationNumber
            };

            if (inventoryPrices) {
                submitted(_inventoryAllocationState, inventoryPrices);
            } else {
                submitted(_inventoryAllocationState);
            }

        } catch (err: any) {
            setSubmitting(false)
            console.error(err);
        } finally {
            setSubmitting(false);
        }
    }

    useEffect(() => {
        if (data) {
            const selectOptions = data.map((inventoryType) => ({
                label: i18n.t(inventoryType.label),
                value: inventoryType.name,
                quantityVisible: inventoryType.quantityVisible,
                unitName: inventoryType.unitName
            }));

            setInventoryTypes(selectOptions);
            if (data.length > 0) {
                if (!inventoryAllocationState || !inventoryAllocationState.inventoryTypeName || inventoryAllocationState.inventoryTypeName === '') {
                    setInventoryAllocationState({ ...inventoryAllocationState, inventoryTypeName: selectOptions[0].value! })
                    setSelectedInventoryType(selectOptions[0]);
                } else {
                    var selectedValue = selectOptions.filter(x => x.value === inventoryAllocation.inventoryTypeName);
                    setSelectedInventoryType(selectedValue[0]);
                }
            }
        }
    }, [data]);

    useEffect(() => {
        if (inventoryAllocationPrices) {
            setInventoryPrices(inventoryAllocationPrices);
        }
    }, [inventoryAllocationPrices]);

    // useEffect(() => {
    //     // inventoryAllocation is lazy loaded, so we need to set the state here
    //     // Due to use of useQuery
    //     if (inventoryAllocation) {
    //         setInventoryAllocationState({...inventoryAllocation});
    //         setAllowedActions(getInitialAllowedActions())
    //     }
    // },[inventoryAllocation]);



    useEffect(() => {
        const fetchData = async () => {
            try {
                if (selectedInventoryType) {

                    const response = await axios.get(`${HookConfig.inventoryUrl}/inventory-variants/${locationId}/${selectedInventoryType.value}`,
                        {
                            withCredentials: true,
                            headers: operatorDefaultHeaders()
                        }
                    );
                    const variants: InventoryVariantDto[] = response.data;
                    const selectOptions = variants.filter(x => x.id !== pontConstants.emptyGuid).map((variant) => ({
                        label: variant.value,
                        value: variant.id
                    }));

                    setInventoryVariants(selectOptions);

                    if ((!inventoryAllocationState || !inventoryAllocationState.inventoryVariantId || inventoryAllocationState.inventoryVariantId === '') || (inventoryAllocationState.inventoryVariantId !== selectOptions[0].value && inventoryAllocation.inventoryTypeName !== selectedInventoryType.value)) {
                        setInventoryAllocationState({ ...inventoryAllocationState, inventoryVariantId: selectOptions[0].value! })
                        setSelectedVariant(selectOptions[0])
                    } else {
                        var selectedValue = selectOptions.filter(x => x.value === inventoryAllocation.inventoryVariantId);
                        setSelectedVariant(selectedValue[0]);
                    }
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [selectedInventoryType]);

    const handleAllowedActionChange = (value: number) => {
        setAllowedActions(prevAllowedActions => {
            if (prevAllowedActions.includes(value)) {
                return prevAllowedActions.filter(action => action !== value);
            } else {
                return [...prevAllowedActions, value];
            }
        });
    };


    const handleDropdownChange = (value: SingleValue<InventoryTypeSelectOption>) => {
        var selectedType = inventoryTypes.find(x => x.value === value?.value);
        setInventoryAllocationState({ ...inventoryAllocationState, inventoryTypeName: selectedType?.value! })
        setSelectedInventoryType(selectedType);
    };


    const handleDropdownChangeVariant = (value: SingleValue<{ value: string, label: string | undefined }>) => {
        const selectedVariant = inventoryVariants.find(x => x.value === value?.value);

        if (selectedVariant) {
            setSelectedVariant(selectedVariant);
            setInventoryAllocationState({ ...inventoryAllocationState, inventoryVariantId: selectedVariant?.value! })
        }
    };


    const handlePrices = (value: PriceDto) => {
        if (inventoryPrices) {
            var changedPrice = inventoryAllocationPrices!.find(x => x.unitName === value.unitName);

            if (!changedPrice)
                throw Error("Price not found!");

            changedPrice.currencyCode = value.currencyCode;
            changedPrice.price = value.price;
            changedPrice.fromDate = new Date();
            changedPrice.toDate = new Date('2099-01-01');

            setInventoryPrices((prevPrices) =>
                prevPrices.map((price) => {

                    if (price.unitName === changedPrice!.unitName) {
                        return changedPrice!;
                    }
                    return price;
                })
            );
        }
    };

    if (!selectedInventoryType || !inventoryTypes || !inventoryVariants || !inventoryAllocationState) return <ApiStatus status={"loading"} />;


    return (
        <form onSubmit={onFormSubmit}>
            <LabelAndHelp mandatory={false} fullscreen={true} message='INVENTORY_LABEL_TYPE' ></LabelAndHelp>
            <div className="row d-flex justify-content-center mb-2">
                <div className="justify-content-center">

                    <Select value={selectedInventoryType} options={inventoryTypes} className="form-control fa-form fa-dropdown-container" classNamePrefix="fa-dropdown"
                        onChange={(e) => handleDropdownChange(e)} placeholder={i18n.t('INVENTORY_LABEL_TYPE')}></Select>
                </div>
            </div>

            <LabelAndHelp mandatory={false} fullscreen={true} message='INVENTORY_LABEL_VARIANT' ></LabelAndHelp>
            <div className="row d-flex justify-content-center mb-2">
                <div className="justify-content-center">
                    <Select value={selectedVariant} options={inventoryVariants} className="form-control fa-form fa-dropdown-container" classNamePrefix="fa-dropdown"
                        onChange={(e) => handleDropdownChangeVariant(e)} placeholder={i18n.t('INVENTORY_LABEL_VARIANT')}></Select>
                </div>
            </div>

            {selectedInventoryType?.quantityVisible &&
                <>
                    <LabelAndHelp mandatory={false} fullscreen={true} message={selectedInventoryType?.unitName!} ></LabelAndHelp>
                    <div className="row d-flex justify-content-center mb-2">
                        <div className="justify-content-center">
                            <input
                                type="number"
                                min={0}
                                className="form-control fa-form"
                                placeholder={i18n.t("INVENTORY_LABEL_AREASQM").toString()}
                                value={inventoryAllocationState.quantity}
                                onChange={e => { setInventoryAllocationState({ ...inventoryAllocationState, quantity: parseInt(e.target.value) }); }} required></input>
                        </div>
                    </div>
                </>
            }

            <div>
                <label><small>Allowed action</small></label>
                <div className="d-flex flex-column gap-2">
                    {pontAllowedActions.filter(item => item.flag !== 1).map((action, index) => {
                        return (
                            <Paper key={index} className="d-flex align-items-center justify-content-between p-2 fa-paper-light shadow-none">
                                <label className="form-check-label">{i18n.t(action.label)}</label>
                                <Switch
                                    checked={allowedActions.includes(action.flag)}
                                    onChange={() => { handleAllowedActionChange(action.flag) }}
                                    size="small"
                                    color="secondary"
                                />
                            </Paper>
                        )
                    })}
                </div>
            </div>
            {
                inventoryAllocationPrices &&
                <div className="row d-flex justify-content-center mb-3">
                    <div className="justify-content-center">
                        <PricesCollection countryIsoCode3={countryIsoCode3} label={i18n.t('PRICES_TITLE_PRICES', { param0: inventoryName })} handleDirty={() => { }} callBack={handlePrices} items={inventoryAllocationPrices!}></PricesCollection>
                    </div>
                </div>
            }

            <div className="row d-flex justify-content-center mt-2">
                <div className="col-6 justify-content-center">
                    <button className="btn btn-green btn-primary btn-block" disabled={submitting} type="submit">
                        <LR localResource="COMMON_LABEL_SAVE"></LR>
                    </button>
                </div>
            </div>
        </form>
    );
}


export { AllocationForm }