const pontAllowedActions = [
  {
    flag: 1,
    label: "LABEL_ALLOWEDACTION_NONE",
  },
  {
    flag: 2,
    label: "LABEL_ALLOWEDACTION_ENQUIRE",
  },
  {
    flag: 4,
    label: "LABEL_ALLOWEDACTION_TOUR",
  },
  {
    flag: 8,
    label: "LABEL_ALLOWEDACTION_RESERVE",
  },
  {
    flag: 16,
    label: "LABEL_ALLOWEDACTION_BOOK",
  },
  {
    flag: 32,
    label: "LABEL_ALLOWEDACTION_RESELL",
  }
]
export {
  pontAllowedActions
}