import React from 'react';


interface OrderStatusProps {
  label: string;
  info?: string;
}

const OrderStatus: React.FC<OrderStatusProps> = ({ label, info  }) => {
  
  return (
    <>
      <div className='order-status-main-wrapper'>
            <div className='order-comment-left'>{label}</div>
            <div className='order-comment-right'>{info}</div>
      </div>
    </>
  );
};

export { OrderStatus };
