import { useEffect, useState } from "react";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { Box, CircularProgress } from "@mui/material";

type Args =
    {
        onLogout: () => void
    }

function Logout({ onLogout }: Args) {

    useEffect(() => {
        onLogout();
    }, [onLogout]);

    return (
        <div className='container'>
            <div className="row d-flex justify-content-center">
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress color="success" />
                </Box>
            </div>
        </div>
    );
}

export default Logout;
