import { useMutation, useQuery, useQueryClient } from "react-query";
import { SummaryItemDto } from "../../types/Common/SummaryItemDto";
import axios, { AxiosError, AxiosResponse } from "axios";
import { HookConfig } from "../../config/HookConfig";
import { useNavigate } from "react-router";
import Problem from "../../types/Problem";
import { operatorDefaultHeaders } from "../../functions/api/api";


const useFetchFeaturesByMembershipId = (membershipId: string) => {
    return useQuery<SummaryItemDto[], AxiosError>(["membershipFeatures", membershipId], () =>
        axios.get(`${HookConfig.inventoryUrl}/memberships/${membershipId}/features/summary`,
            {
                withCredentials: true,
                headers: operatorDefaultHeaders()
            }
        ).then((resp) => resp.data)
    );
}

const useFetchMembershipFeatureHierarchy = (membershipId: string,) => {
    return useQuery<any, AxiosError>(["membershipFeatures", membershipId], () =>
        axios.get(`${HookConfig.inventoryUrl}/memberships/${membershipId}/feature-heirarchies?group=membership`,
            {
                withCredentials: true,
                headers: operatorDefaultHeaders()
            }
        ).then((resp) => resp.data)
    );
}


const useManageMembershipFeatures = () => {
    const queryClient = useQueryClient();
    const nav = useNavigate();
    const manageFeatures = async ([id, groupName, items]: [string, string, any[]]): Promise<AxiosResponse<any, any>> => {

        const url = `${HookConfig.inventoryUrl}/memberships/${id}/features`;
        const body = { membershipId: id, group: groupName, features: items }

        const response = await axios["put"](
            url, body,
            {
                withCredentials: true,
                headers: operatorDefaultHeaders(),
            }
        );

        return response;
    };

    const mutation = useMutation<
        AxiosResponse<any, any>, AxiosError<Problem>, [string, string, any[]]>(manageFeatures, {
            onSuccess: (response, [id, groupName]) => {
                queryClient.invalidateQueries(["membershipFeatures", id, groupName]);
                nav(`/memberships/${id}/features`);
            },
        });

    return mutation;
};



export {
    useFetchFeaturesByMembershipId,
    useFetchMembershipFeatureHierarchy,
    useManageMembershipFeatures
}