const pontCurrencies = [
    { value: 'AED', label: 'United Arab Emirates Dirham - د.إ', countryCode: 'ARE' },
    { value: 'AFN', label: 'Afghan Afghani - ؋', countryCode: 'AFG' },
    { value: 'ALL', label: 'Albanian Lek - Lek', countryCode: 'ALB' },
    { value: 'AMD', label: 'Armenian Dram - ֏', countryCode: 'ARM' },
    { value: 'ANG', label: 'Netherlands Antillean Guilder - ƒ', countryCode: 'ANT' },
    { value: 'AOA', label: 'Angolan Kwanza - Kz', countryCode: 'AGO' },
    { value: 'ARS', label: 'Argentine Peso - $', countryCode: 'ARG' },
    { value: 'AUD', label: 'Australian Dollar - $', countryCode: 'AUS' },
    { value: 'AWG', label: 'Aruban Florin - ƒ', countryCode: 'ABW' },
    { value: 'AZN', label: 'Azerbaijani Manat - ₼', countryCode: 'AZE' },
    { value: 'BAM', label: 'Bosnia-Herzegovina Convertible Mark - KM', countryCode: 'BIH' },
    { value: 'BBD', label: 'Barbadian Dollar - $', countryCode: 'BRB' },
    { value: 'BDT', label: 'Bangladeshi Taka - ৳', countryCode: 'BGD' },
    { value: 'BGN', label: 'Bulgarian Lev - лв', countryCode: 'BGR' },
    { value: 'BHD', label: 'Bahraini Dinar - .د.ب', countryCode: 'BHR' },
    { value: 'BIF', label: 'Burundian Franc - Fr', countryCode: 'BDI' },
    { value: 'BMD', label: 'Bermudian Dollar - $', countryCode: 'BMU' },
    { value: 'BND', label: 'Brunei Dollar - $', countryCode: 'BRN' },
    { value: 'BOB', label: 'Bolivian Boliviano - Bs.', countryCode: 'BOL' },
    { value: 'BRL', label: 'Brazilian Real - R$', countryCode: 'BRA' },
    { value: 'BSD', label: 'Bahamian Dollar - $', countryCode: 'BHS' },
    { value: 'BTN', label: 'Bhutanese Ngultrum - Nu.', countryCode: 'BTN' },
    { value: 'BWP', label: 'Botswana Pula - P', countryCode: 'BWA' },
    { value: 'BYN', label: 'Belarusian Ruble - Br', countryCode: 'BLR' },
    { value: 'BZD', label: 'Belize Dollar - BZ$', countryCode: 'BLZ' },
    { value: 'CAD', label: 'Canadian Dollar - $', countryCode: 'CAN' },
    { value: 'CDF', label: 'Congolese Franc - Fr', countryCode: 'COD' },
    { value: 'CHF', label: 'Swiss Franc - CHF', countryCode: 'CHE' },
    { value: 'CLP', label: 'Chilean Peso - $', countryCode: 'CHL' },
    { value: 'CNY', label: 'Chinese Yuan - ¥', countryCode: 'CHN' },
    { value: 'COP', label: 'Colombian Peso - $', countryCode: 'COL' },
    { value: 'CRC', label: 'Costa Rican Colón - ₡', countryCode: 'CRI' },
    { value: 'CUP', label: 'Cuban Peso - ₱', countryCode: 'CUB' },
    { value: 'CVE', label: 'Cape Verdean Escudo - $', countryCode: 'CPV' },
    { value: 'CZK', label: 'Czech Koruna - Kč', countryCode: 'CZE' },
    { value: 'DJF', label: 'Djiboutian Franc - Fr', countryCode: 'DJI' },
    { value: 'DKK', label: 'Danish Krone - kr', countryCode: 'DNK' },
    { value: 'DOP', label: 'Dominican Peso - RD$', countryCode: 'DOM' },
    { value: 'DZD', label: 'Algerian Dinar - د.ج', countryCode: 'DZA' },
    { value: 'EGP', label: 'Egyptian Pound - £', countryCode: 'EGY' },
    { value: 'ERN', label: 'Eritrean Nakfa - Nfk', countryCode: 'ERI' },
    { value: 'ETB', label: 'Ethiopian Birr - Br', countryCode: 'ETH' },
    { value: 'EUR', label: 'Euro - €', countryCode: 'EU' },
    { value: 'FJD', label: 'Fijian Dollar - $', countryCode: 'FJI' },
    { value: 'FKP', label: 'Falkland Islands Pound - £', countryCode: 'FLK' },
    { value: 'FOK', label: 'Faroese Króna - kr', countryCode: 'FRO' },
    { value: 'GBP', label: 'British Pound Sterling - £', countryCode: 'GBR' },
    { value: 'GEL', label: 'Georgian Lari - ₾', countryCode: 'GEO' },
    { value: 'GGP', label: 'Guernsey Pound - £', countryCode: 'GGY' },
    { value: 'GHS', label: 'Ghanaian Cedi - ₵', countryCode: 'GHA' },
    { value: 'GIP', label: 'Gibraltar Pound - £', countryCode: 'GIB' },
    { value: 'GMD', label: 'Gambian Dalasi - D', countryCode: 'GMB' },
    { value: 'GNF', label: 'Guinean Franc - Fr', countryCode: 'GIN' },
    { value: 'GTQ', label: 'Guatemalan Quetzal - Q', countryCode: 'GTM' },
    { value: 'GYD', label: 'Guyanaese Dollar - $', countryCode: 'GUY' },
    { value: 'HKD', label: 'Hong Kong Dollar - $', countryCode: 'HKG' },
    { value: 'HNL', label: 'Honduran Lempira - L', countryCode: 'HND' },
    { value: 'HRK', label: 'Croatian Kuna - kn', countryCode: 'HRV' },
    { value: 'HTG', label: 'Haitian Gourde - G', countryCode: 'HTI' },
    { value: 'HUF', label: 'Hungarian Forint - Ft', countryCode: 'HUN' },
    { value: 'IDR', label: 'Indonesian Rupiah - Rp', countryCode: 'IDN' },
    { value: 'ILS', label: 'Israeli New Shekel - ₪', countryCode: 'ISR' },
    { value: 'IMP', label: 'Isle of Man Pound - £', countryCode: 'IMN' },
    { value: 'INR', label: 'Indian Rupee - ₹', countryCode: 'IND' },
    { value: 'IQD', label: 'Iraqi Dinar - ع.د', countryCode: 'IRQ' },
    { value: 'IRR', label: 'Iranian Rial - ﷼', countryCode: 'IRN' },
    { value: 'ISK', label: 'Icelandic Króna - kr', countryCode: 'ISL' },
    { value: 'JEP', label: 'Jersey Pound - £', countryCode: 'JEY' },
    { value: 'JMD', label: 'Jamaican Dollar - J$', countryCode: 'JAM' },
    { value: 'JOD', label: 'Jordanian Dinar - د.ا', countryCode: 'JOR' },
    { value: 'JPY', label: 'Japanese Yen - ¥', countryCode: 'JPN' },
    { value: 'KES', label: 'Kenyan Shilling - KSh', countryCode: 'KEN' },
    { value: 'KGS', label: 'Kyrgystani Som - с', countryCode: 'KGZ' },
    { value: 'KHR', label: 'Cambodian Riel - ៛', countryCode: 'KHM' },
    { value: 'KMF', label: 'Comorian Franc - Fr', countryCode: 'COM' },
    { value: 'KPW', label: 'North Korean Won - ₩', countryCode: 'PRK' },
    { value: 'KRW', label: 'South Korean Won - ₩', countryCode: 'KOR' },
    { value: 'KWD', label: 'Kuwaiti Dinar - د.ك', countryCode: 'KWT' },
    { value: 'KYD', label: 'Cayman Islands Dollar - $', countryCode: 'CYM' },
    { value: 'KZT', label: 'Kazakhstani Tenge - ₸', countryCode: 'KAZ' },
    { value: 'LAK', label: 'Laotian Kip - ₭', countryCode: 'LAO' },
    { value: 'LBP', label: 'Lebanese Pound - ل.ل', countryCode: 'LBN' },
    { value: 'LKR', label: 'Sri Lankan Rupee - Rs', countryCode: 'LKA' },
    { value: 'LRD', label: 'Liberian Dollar - $', countryCode: 'LBR' },
    { value: 'LSL', label: 'Lesotho Loti - L', countryCode: 'LSO' },
    { value: 'LYD', label: 'Libyan Dinar - ل.د', countryCode: 'LBY' },
    { value: 'MAD', label: 'Moroccan Dirham - د.م.', countryCode: 'MAR' },
    { value: 'MDL', label: 'Moldovan Leu - L', countryCode: 'MDA' },
    { value: 'MGA', label: 'Malagasy Ariary - Ar', countryCode: 'MDG' },
    { value: 'MKD', label: 'Macedonian Denar - ден', countryCode: 'MKD' },
    { value: 'MMK', label: 'Burmese Kyat - Ks', countryCode: 'MMR' },
    { value: 'MNT', label: 'Mongolian Tugrik - ₮', countryCode: 'MNG' },
    { value: 'MOP', label: 'Macanese Pataca - P', countryCode: 'MAC' },
    { value: 'MRU', label: 'Mauritanian Ouguiya - UM', countryCode: 'MRT' },
    { value: 'MUR', label: 'Mauritian Rupee - ₨', countryCode: 'MUS' },
    { value: 'MVR', label: 'Maldivian Rufiyaa - ރ', countryCode: 'MDV' },
    { value: 'MWK', label: 'Malawian Kwacha - MK', countryCode: 'MWI' },
    { value: 'MXN', label: 'Mexican Peso - $', countryCode: 'MEX' },
    { value: 'MYR', label: 'Malaysian Ringgit - RM', countryCode: 'MYS' },
    { value: 'MZN', label: 'Mozambican Metical - MT', countryCode: 'MOZ' },
    { value: 'NAD', label: 'Namibian Dollar - $', countryCode: 'NAM' },
    { value: 'NGN', label: 'Nigerian Naira - ₦', countryCode: 'NGA' },
    { value: 'NIO', label: 'Nicaraguan Córdoba - C$', countryCode: 'NIC' },
    { value: 'NOK', label: 'Norwegian Krone - kr', countryCode: 'NOR' },
    { value: 'NPR', label: 'Nepalese Rupee - रू', countryCode: 'NPL' },
    { value: 'NZD', label: 'New Zealand Dollar - $', countryCode: 'NZL' },
    { value: 'OMR', label: 'Omani Rial - ر.ع.', countryCode: 'OMN' },
    { value: 'PAB', label: 'Panamanian Balboa - B/.', countryCode: 'PAN' },
    { value: 'PEN', label: 'Peruvian Sol - S/', countryCode: 'PER' },
    { value: 'PGK', label: 'Papua New Guinean Kina - K', countryCode: 'PNG' },
    { value: 'PHP', label: 'Philippine Peso - ₱', countryCode: 'PHL' },
    { value: 'PKR', label: 'Pakistani Rupee - Rs', countryCode: 'PAK' },
    { value: 'PLN', label: 'Polish Złoty - zł', countryCode: 'POL' },
    { value: 'PYG', label: 'Paraguayan Guaraní - ₲', countryCode: 'PRY' },
    { value: 'QAR', label: 'Qatari Riyal - ر.ق', countryCode: 'QAT' },
    { value: 'RON', label: 'Romanian Leu - L', countryCode: 'ROU' },
    { value: 'RSD', label: 'Serbian Dinar - РСД', countryCode: 'SRB' },
    { value: 'RUB', label: 'Russian Ruble - ₽', countryCode: 'RUS' },
    { value: 'RWF', label: 'Rwandan Franc - Fr', countryCode: 'RWA' },
    { value: 'SAR', label: 'Saudi Riyal - ر.س', countryCode: 'SAU' },
    { value: 'SBD', label: 'Solomon Islands Dollar - $', countryCode: 'SLB' },
    { value: 'SCR', label: 'Seychellois Rupee - ₨', countryCode: 'SYC' },
    { value: 'SDG', label: 'Sudanese Pound - £', countryCode: 'SDN' },
    { value: 'SEK', label: 'Swedish Krona - kr', countryCode: 'SWE' },
    { value: 'SGD', label: 'Singapore Dollar - $', countryCode: 'SGP' },
    { value: 'SHP', label: 'Saint Helena Pound - £', countryCode: 'SHN' },
    { value: 'SLL', label: 'Sierra Leonean Leone - Le', countryCode: 'SLE' },
    { value: 'SOS', label: 'Somali Shilling - S', countryCode: 'SOM' },
    { value: 'SRD', label: 'Surinamese Dollar - $', countryCode: 'SUR' },
    { value: 'SSP', label: 'South Sudanese Pound - £', countryCode: 'SSD' },
    { value: 'STN', label: 'São Tomé & Príncipe Dobra - Db', countryCode: 'STP' },
    { value: 'SVC', label: 'Salvadoran Colón - ₡', countryCode: 'SLV' },
    { value: 'SYP', label: 'Syrian Pound - ل.س', countryCode: 'SYR' },
    { value: 'SZL', label: 'Swazi Lilangeni - L', countryCode: 'SWZ' },
    { value: 'THB', label: 'Thai Baht - ฿', countryCode: 'THA' },
    { value: 'TJS', label: 'Tajikistani Somoni - SM', countryCode: 'TJK' },
    { value: 'TMT', label: 'Turkmenistani Manat - T', countryCode: 'TKM' },
    { value: 'TND', label: 'Tunisian Dinar - د.ت', countryCode: 'TUN' },
    { value: 'TOP', label: 'Tongan Paʻanga - T$', countryCode: 'TON' },
    { value: 'TRY', label: 'Turkish Lira - ₺', countryCode: 'TUR' },
    { value: 'TTD', label: 'Trinidad & Tobago Dollar - TT$', countryCode: 'TTO' },
    { value: 'TVD', label: 'Tuvaluan Dollar - $', countryCode: 'TUV' },
    { value: 'TWD', label: 'New Taiwan Dollar - NT$', countryCode: 'TWN' },
    { value: 'TZS', label: 'Tanzanian Shilling - Sh', countryCode: 'TZA' },
    { value: 'UAH', label: 'Ukrainian Hryvnia - ₴', countryCode: 'UKR' },
    { value: 'UGX', label: 'Ugandan Shilling - USh', countryCode: 'UGA' },
    { value: 'USD', label: 'United States Dollar - $', countryCode: 'USA' },
    { value: 'UYU', label: 'Uruguayan Peso - $', countryCode: 'URY' },
    { value: 'UZS', label: 'Uzbekistani Som - лв', countryCode: 'UZB' },
    { value: 'VES', label: 'Venezuelan Bolívar - Bs.', countryCode: 'VEN' },
    { value: 'VND', label: 'Vietnamese Dong - ₫', countryCode: 'VNM' },
    { value: 'VUV', label: 'Vanuatu Vatu - VT', countryCode: 'VUT' },
    { value: 'WST', label: 'Samoan Tala - T', countryCode: 'WSM' },
    { value: 'XAF', label: 'Central African CFA Franc - Fr', countryCode: 'CAF' },
    { value: 'XCD', label: 'East Caribbean Dollar - $', countryCode: 'XCD' },
    { value: 'XDR', label: 'Special Drawing Rights - SDR', countryCode: 'XDR' },
    { value: 'XOF', label: 'West African CFA Franc - Fr', countryCode: 'XOF' },
    { value: 'XPF', label: 'CFP Franc - Fr', countryCode: 'XPF' },
    { value: 'YER', label: 'Yemeni Rial - ﷼', countryCode: 'YEM' },
    { value: 'ZAR', label: 'South African Rand - R', countryCode: 'ZAF' },
    { value: 'ZMW', label: 'Zambian Kwacha - ZK', countryCode: 'ZMB' },
    { value: 'ZWL', label: 'Zimbabwean Dollar - $', countryCode: 'ZWE' },
  ];
  
  export{pontCurrencies}