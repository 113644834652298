export const pontConstants = {
    emptyGuid: '00000000-0000-0000-0000-000000000000',
    disableQuerySettings: {
        enabled: false,
        retryOnMount: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
    },
    emailRegex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/
}