import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import i18n from "../../i18n";
import ApiStatus from '../Common/Errors/ApiStatus';
import MessageAlert from '../Common/Errors/MessageAlert';
import { SummaryItemCollection } from '../Common/SummaryItems/SummaryItemCollection';
import { SummaryItemDto } from '../../types/Common/SummaryItemDto';
import { useFetchLocationById } from '../../hooks/Location/location';
import { LocationResponseDto } from '../../types/Location/LocationResponseDto';
import { SectionHead } from '../Common/Help/SectionHead';
import { pontConstants } from '../../data/pontConstants';

type Args =
    {
        userLogged: ApplicationUser
    }



const LocationCommunications = ({ userLogged }: Args) => {
    const nav = useNavigate();
    const params = useParams();

    if (!params.id) throw Error("Location id needed");

    //#region Empty Entities
    const emptyLocationResponseDto: LocationResponseDto = {
        id: '',
        internalIdentifier: '',
        externalReference: '',
        operatorMemberId: pontConstants.emptyGuid,
        legalEntityMemberId: pontConstants.emptyGuid,
        name: '',
        countryIso3Code: '',
        summaries: [],
        shortDescription: '',
        longDescription: '',
        brandName:''
    };


    //#endregion

    const { data: location, status, isSuccess } = useFetchLocationById(params.id)
    const [useLocation, setUseLocation] = useState<LocationResponseDto>(emptyLocationResponseDto);





    useEffect(() => {
        if (isSuccess && location) {
            setUseLocation(location);
        }
    }, [isSuccess, location]);

    //#region Navigation
    const linkCallBackCollection = (item: SummaryItemDto) => {

        let url = '';
        switch (item.name) {
            case "PhoneSummary":
                url = `/locations/${params.id}/communications/phone`;
                break;
            case "EmailSummary":
                url = `/locations/${params.id}/communications/email`;
                break;
            case "SocialSummary":
                url = `/locations/${params.id}/communications/social`;
                break;
            case "WebSummary":
                url = `/locations/${params.id}/communications/web`;
                break;
            default:
                alert(`Item ${item.name} does not have a link yet`);
                break;
        }
        nav(url);
    }

    const linkCallBack = () => {
        let url = `/locations/${useLocation.id}/summary`;
        nav(url);

    }






    if (!isSuccess) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <ApiStatus status={status} />
                </div>
            </div>
        </>
    )

    if (!location) return <MessageAlert message="MEMBER_LABEL_MEMBERNOTFOUND" params={{ param0: params.id }}></MessageAlert>;

    return (
        <>

            <SectionHead linkCallBack={linkCallBack} ctaText={i18n.t("LOCATION_SUMMARY_LOCATIONMAINPAGE")!}
            name={`${location.name} (${location.externalReference})`}
            title={i18n.t('COMMON_SUMMARY_LOCATIONDETAILS')}
            description={i18n.t('COMMON_SUMMARY_LOCATIONMANAGEMENT')}/>





            <div className="mt-1 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <SummaryItemCollection linkCallBack={linkCallBackCollection} items={useLocation.summaries!}></SummaryItemCollection>
                </div>
            </div>
        </>
    );
};


export { LocationCommunications }