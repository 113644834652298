type Args = {
    message: string

};

const ErrorLabel = ({ message }: Args) => {
    return (
        <>

            {Boolean(message) &&
                <div className="row d-flex justify-content-center mb-2">
                    <div className="col-10 d-flex justify-content-center">
                        <div className="alert alert-danger" role="alert">
                            {message}</div>
                    </div>
                </div>
            }
        </>
    );
}

export {
    ErrorLabel
}