import { LR } from "../Common/Help/LR";

type Args = {
    type: string
    value: string
};

function RegistrationCompleted({ type, value }: Args) {

    return (
        <>
            <div>
                <div className="row d-flex justify-content-center mb-2">
                    <div className="col-10 d-flex justify-content-center">
                        <h4>
                            {type === "REGISTER" && <LR localResource="COMMON_LABEL_LOGIN_CONGRATS" params={{ param: value }} />}
                            {type === "RESET" && <LR localResource="COMMON_LABEL_LOGIN_CONGRATS" params={{ param: value }} />}
                        </h4>
                    </div>
                </div>
                <div className="row d-flex justify-content-center mb-2">
                    <div className="col-10 d-flex justify-content-center">
                        <h5><LR localResource="COMMON_LABEL_LOGIN_PROCEED"></LR></h5>
                    </div>
                </div>
                <div className="row d-flex justify-content-center mb-2">
                    <div className="col-10 d-flex justify-content-center">
                        <a className="btn btn-green btn-primary btn-block" rel="noreferrer" href="/bff/login">
                            <LR localResource="LOGIN_BUTTON_LOGIN"></LR>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )

}

export { RegistrationCompleted }