import { Card, IconButton, Tooltip } from "@mui/material";
import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useFetchMembers } from "../../hooks/Member/member";
import ApiStatus from "../Common/Errors/ApiStatus";
import MessageAlert from "../Common/Errors/MessageAlert";
import { pontCountries } from "../../data/en/pontCountries";
import { CircleFlag } from "react-circle-flags";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { useNavigate } from "react-router";
import { GetRowClassName } from "../../functions/utils/helper";
import { SectionHead } from "../Common/Help/SectionHead";

type Args =
  {
    userLogged: ApplicationUser
  }

const Operators = ({ userLogged }: Args) => {
  const nav = useNavigate();
  const { data: operators, status, isSuccess } = useFetchMembers(512, userLogged.operator!.id, 3);

  const homeCallBack = () => {
    nav(`/admin`);
  }


  if (!isSuccess) return (
    <>
      <div className="mt-5 row d-flex justify-content-center">
        <div className="col-md-7 col-11 justify-content-center">
          <ApiStatus status={status} />
        </div>
      </div>
    </>
  )

  if (!operators) return <MessageAlert message="MEMBER_LABEL_MEMBERNOTFOUND" params={{ param0: '' }}></MessageAlert>;

  return (
    <>
      <SectionHead linkCallBack={homeCallBack}
        ctaText={i18n.t('COMMON_LABEL_ADMIN')!}
        name={`${userLogged.operator!.fullName} (${userLogged.operator!.externalReference})`}
        title={i18n.t("COMMON_SUMMARY_LEGALENTITIES")}
        description={i18n.t("COMMON_SUMMARY_LEGALENTITYMANAGEMENT")} />


      <div className="mt-3 row d-flex justify-content-center">
        <div className="col-md-7 col-11 justify-content-center">
          <Card variant="outlined">
            <DataGrid
              rows={operators}
              columns={[
                {
                  field: 'countryIso3Code',
                  headerName: i18n.t('COMMON_LABEL_COUNTRYSHORT')!,
                  flex: 0.2,
                  align: "center",
                  renderCell: (params) => {
                    const iso2Code = pontCountries.find((i) => i.Iso3Code === params.value)?.Iso2Code.toLowerCase();
                    return <CircleFlag countryCode={iso2Code!} height="20" />
                  },
                },
                { field: 'externalReference', headerName: i18n.t('COMMON_LABEL_EXTERNALREFERENCE')!, flex: 0.5 },
                { field: 'fullName', headerName: i18n.t('MEMBER_LABEL_COMPANYNAME')!, flex: 1 },

                {
                  field: 'id', headerName: '', flex: 0.1, align: "center",
                  renderCell: (params) => {

                    return <Tooltip title={i18n.t('COMMON_TOOLTIP_DETAILS')}>
                      <IconButton className="fa-info" onClick={() => nav(`${params.value}/summary`)}>
                        <EditRoundedIcon sx={{ color: '#00be95' }} />
                      </IconButton>
                    </Tooltip>
                  },
                }
              ]}

              slots={{
                toolbar: GridToolbar
              }}
              classes={{
                toolbarContainer: 'toolbarContainer'
              }}

              getRowClassName={GetRowClassName}

              checkboxSelection={false}
              disableColumnSelector={true}
              disableDensitySelector={true}
            />
          </Card>
        </div>
      </div>
    </>
  );
};


export { Operators }