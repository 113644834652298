// src/hooks/Invoice/useInvoiceHooks.ts
import { useQuery, useMutation, useQueryClient, UseQueryOptions } from 'react-query';
import { InvoiceDetailDto, InvoiceDto, InvoiceLineDetailDto, InvoiceLineTaxLineDto, InvoicePartyDetailDto } from '../../types/Invoice/InvoiceDto';  // Corrected imports
import axios, { AxiosError } from 'axios';
import { HookConfig } from '../../config/HookConfig';
import { operatorDefaultHeaders } from '../../functions/api/api';

const invoices = require('../../data/invoice/invoices.json') as InvoiceDto[];

export const useFetchInvoices = () => {
  return useQuery<InvoiceDto[]>('invoices', async () => {
    return invoices;
  });
};

export const useFetchInvoiceById = (id: number) => {
  return useQuery<InvoiceDto>(['invoice', id], async () => {
    const invoice = invoices.find(invoice => invoice.id === id);
    if (!invoice) throw new Error('Invoice not found');
    return invoice;
  });
};

export const useCreateInvoice = () => {
  const queryClient = useQueryClient();
  return useMutation<InvoiceDto, Error, InvoiceDto>(
    (newInvoice) => {
      invoices.push(newInvoice);
      return Promise.resolve(newInvoice);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('invoices');
      }
    }
  );
};

export const useUpdateInvoice = (id: number) => {
  const queryClient = useQueryClient();
  return useMutation<InvoiceDto, Error, InvoiceDto>(
    (updatedInvoice) => {
      const index = invoices.findIndex(invoice => invoice.id === id);
      if (index !== -1) {
        invoices[index] = updatedInvoice;
      }
      return Promise.resolve(updatedInvoice);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['invoice', id]);
      }
    }
  );
};


export const useFetchInvoiceDetailById = ({
  invoiceId,
  fullDetails = false,
  options
}: {
  invoiceId: string,
  fullDetails?: boolean,
  options?: UseQueryOptions<InvoiceDetailDto, AxiosError>
}) => {
  return useQuery<InvoiceDetailDto, AxiosError>(["invoice-detail", invoiceId], () =>
    axios.get(
      `${HookConfig.orderUrl}/invoices/${invoiceId}`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders(),
        params: {
          fullDetails: fullDetails
        }
      })
      .then(
        (response) => {
          return response.data;
        }
      ), {
    ...options
  });
};

export const useFetchInvoicePartyById = ({
  invoiceId,
  partyId,
  options
}: {
  invoiceId: string,
  partyId?: string,
  options?: UseQueryOptions<InvoicePartyDetailDto, AxiosError>
}) => {
  return useQuery<InvoicePartyDetailDto, AxiosError>(["invoice-parties-detail", invoiceId, partyId], () =>
    axios.get(
      `${HookConfig.orderUrl}/invoices/${invoiceId}/invoice-parties/${partyId}`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders(),
      })
      .then(
        (response) => {
          return response.data;
        }
      ), {
    ...options
  });
}

export const useFetchInvoiceSummaryLineById = ({
  invoiceId,
  invoiceLineId,
  options
}: {
  invoiceId: string,
  invoiceLineId?: string,
  options?: UseQueryOptions<InvoiceLineDetailDto, AxiosError>
}) => {
  return useQuery<InvoiceLineDetailDto, AxiosError>(["invoice-summary-detail", invoiceId, invoiceLineId], () =>
    axios.get(
      `${HookConfig.orderUrl}/invoices/${invoiceId}/invoice-lines/${invoiceLineId}`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders(),
      })
      .then(
        (response) => {
          return response.data;
        }
      ), {
    ...options
  });
}

export const useFetchInvoiceSummaryLineTaxLinesById = ({
  invoiceId,
  invoiceLineId,
  options
}: {
  invoiceId: string,
  invoiceLineId?: string,
  options?: UseQueryOptions<InvoiceLineTaxLineDto[], AxiosError>
}) => {
  return useQuery<InvoiceLineTaxLineDto[], AxiosError>(["invoice-summary-tax-line-detail", invoiceId, invoiceLineId], () =>
    axios.get(
      `${HookConfig.orderUrl}/invoices/${invoiceId}/invoice-lines/${invoiceLineId}/taxes`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders(),
      })
      .then(
        (response) => {
          return response.data;
        }
      ), {
    ...options
  });
}