import {  Collapse, IconButton, Paper,  Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import "../../Common/SummaryItems/SummaryItem.css"
import { LR } from "../../Common/Help/LR";
import Switch from '@mui/material/Switch';
import { ProductTypeResponseDto } from "../../../types/Product/ProductTypeResponseDto";

type Args = {
  items: ProductTypeResponseDto[],
  mutationCallBack : (items: boolean[]) => void,
  initialToggles: boolean[]
};

const ProductType = ({ items, mutationCallBack, initialToggles }: Args) => {
  
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const [typesItems, setTypesItems] = useState<ProductTypeResponseDto[]>(items);
  const [toggleStates, setToggleStates] = useState<boolean[]>(!!initialToggles ? initialToggles : items.map((x) => (x.name !== '')));

  useEffect(() => {
    if(items) {
      setTypesItems(items);
    }
  }, [items]);

  useEffect(() => {
    if(initialToggles) {
      setToggleStates(initialToggles);
    }
  }, [initialToggles])

  const handleToggleChange = (index: number) => {
    const updatedStates = toggleStates;
    updatedStates[index] = !updatedStates[index];
    setToggleStates(updatedStates);
    mutationCallBack(updatedStates);
  };

  return (
    <>
      <Paper elevation={3} className="fa-paper-light">
        <div className="d-flex align-items-center">
          <div className="fa-name flex-grow-1">
            <Typography variant="h6" className="linkbutton">
              <LR localResource="PRODUCT_LABEL_RULEPRODUCTTYPE" /> ({typesItems.length})
            </Typography>
          </div>
          <div className="fa-icons">
            <IconButton className="fa-arrow" onClick={() => setIsCollapseOpen(!isCollapseOpen)}>
              {!isCollapseOpen && <ArrowForwardIosRoundedIcon />}
              {isCollapseOpen && <ArrowForwardIosRoundedIcon className='fa-arrow-down' />}
            </IconButton>
          </div>
        </div>
      </Paper>

      <Collapse in={isCollapseOpen}>
        <div>
          <div className="row d-flex justify-content-center">
            <div className="justify-content-center">
              {typesItems.map((item, index) => (
                <Paper key={index} elevation={24} className="fa-paper-grid">
                  <div className="d-flex align-items-center">
                    <div className="fa-name flex-grow-1">
                      {item.name}
                    </div>

                    <div className="col-1 d-flex justify-content-end" style={{ flex: 1 }}>
                      <Switch
                        checked={toggleStates[index]}
                        onChange={() => handleToggleChange(index)}
                        color="primary"
                      />
                    </div>
                  </div>
                </Paper>
              ))}

            </div>
          </div>
        </div>
      </Collapse>
    </>
  );
}

export {
  ProductType
}