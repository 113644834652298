import React from 'react';
import {  useNavigate } from 'react-router-dom';

import { LocationLine } from '../common/LocationLine';
import { StatusSection } from '../common/StatusSection';
import { OrderLine } from '../common/OrderLine';
import { CustomerInfo } from '../common/CustomerInfo';
import { OrderComments } from '../common/OrderComments';
import { PoNumber } from '../common/PoNumber';
import { Invite } from '../common/Invite';
import { Invoice } from '../common/Invoice';

import './ViewOrder.css';
import { ApplicationUser } from '../../../types/Authentication/ApplicationUser';
import { SectionHead } from '../../Common/Help/SectionHead';
import i18n from '../../../i18n';

type OrderSummaryProps = {
  userLogged: ApplicationUser;
};
const ViewOrder = ({ userLogged }: OrderSummaryProps) => {
    const navigate = useNavigate();
  
    const orderDetails = {
      orderId: "123",
      status: "Confirmed",
      location: "22 Bishops Gate, London",
      orderLines: [
        {
          orderId: 123,
          orderType: "Day Pass",
          orderDate: "Jul 24, 2024 08:00 - 18:00",
          price: 45.00,
          room: "101A", // Example room number
          fromDate: "Jul 24, 2024 08:00",
          toDate: "Jul 24, 2024 18:00",
          quantity: 1,
          listPrice: 45.00,
          discountAmount: 5.00, // Example discount
          discountReason: "Early bird discount", // Example reason for the discount
          totalPrice: 40.00, // Adjusted total price after discount
          options: {
            saveChanges: () => console.log('Saving changes...'),
            delete: () => console.log('Deleting...')
          }
        },
      ],
      comments: "Additional chairs needed"
    };
  
    const goBack = () => {
      navigate(-1);
    };
  
    return (
      <>
        <SectionHead
          linkCallBack={goBack}
          ctaText={i18n.t('Go Back') || 'Go Back'}
          name={`Order ID: ${orderDetails.orderId}`}
          title={i18n.t('Order') || 'Order'}
          description={i18n.t('Detailed view of the selected order') || 'Detailed view of the selected order'}
        />
        <div className='order-view-container'>
          <div className='view-inner-container'>
            <StatusSection label="Status"
              value={orderDetails.status}
              Options="Options"
              ConfirmOrder="Confirm Order"
              CancelOrder="Cancel Order"
            />
            <LocationLine 
              location='Location'
              address={orderDetails.location}
            />
            {orderDetails.orderLines.map(line => (
              <OrderLine
                key={line.orderId}
                orderId={line.orderId}
                orderType={line.orderType}
                orderDate={line.orderDate}
                price={line.price}
                room={line.room}
                fromDate={line.fromDate}
                toDate={line.toDate}
                quantity={line.quantity}
                listPrice={line.listPrice}
                discountAmount={line.discountAmount}
                discountReason={line.discountReason}
                totalPrice={line.totalPrice}
                options={line.options}
              />
            ))}
            <CustomerInfo label='Customer' company='Simon Dowdell - IKI Force'/>
            <OrderComments label='Comments' info='-' comment='test'/>
            <PoNumber label='PO Number' info='-' comment='test'/>
            <Invite/>
            <Invoice/>
          </div>
        </div>
      </>
    );
  };
  
  export { ViewOrder };
  