import { LR } from "../Common/Help/LR";

type Args = {
    link: string
    name: string
    image: string;
};


const ExternalProvider = ({ link, name, image }: Args) => {

    return (
        <div className="row d-flex justify-content-center mb-2">
            <div className="col-10 d-flex justify-content-center">
                <a className="btn btn-transparent btn-primary btn-block" href={link}>
                    <img src={image} className="img-fluid externalProvider" alt={name} width="30"></img>
                    <LR localResource="COMMON_LABEL_CONTINUEEXTERNALPROVIDER" params={{provider:name}}/>                        
                </a>
            </div>
        </div>
    );
}

export default ExternalProvider;
