import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import { isNil, isNull, isNumber } from "lodash";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { pontUnits } from "../../data/en/pontUnits";
import { pontNearByTypes } from "../../data/locationConfigurations";
import yup from "../../functions/utils/yup";
import { useFetchAmenityDetailsById, usePostAmenity, useUpdateAmenity } from "../../hooks/Location/amenities";
import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { CustomSelect } from "../Common/formComponents/CustomSelect";
import { Input } from "../Common/formComponents/Input";
import { SectionHead } from "../Common/Help/SectionHead";
import { CustomButton } from "../Common/UI/CustomButton";
import { CustomSnackbar } from "../Common/UI/CustomSnackbar";
import { useFetchLocationById } from "../../hooks/Location/location";

type Args = {
    userLogged: ApplicationUser
}


export const NearByAmenityForm = ({ userLogged }: Args) => {
    const { id, amenityId } = useParams()
    const nav = useNavigate()
    const [snackbarContent, setSnackbarContent] = useState<any>()

    const isEditMode = !!(id && amenityId);

    const formattedType = pontNearByTypes.map(i => ({
        ...i,
        label: i18n.t(i.label).toString()
    }))

    const formattedUnit = pontUnits
        .filter(unit => unit.unitGroupName === "Length")
        .map(i => ({
            label: i.unitName,
            value: i.unitName
        }))

    const schema = yup.object().shape({
        nearByTypeName: yup.object().isEmptyObject(i18n.t('VALIDATION_TYPE_REQUIRED')!).shape({
            label: yup.string(),
            value: yup.string(),
        }),
        name: yup.string().required(i18n.t('VALIDATION_NAME_REQUIRED').toString()),
        description: yup.string().nullable(),
        url: yup.string().url().nullable(),
        displayOrder: yup
            .number()
            .emptyNumberToNull()
            .min(1, i18n.t('VALIDATION_DISPLAY_ORDER_MIN').toString())
            .max(1000, i18n.t('VALIDATION_DISPLAY_ORDER_MAX').toString())
            .nullable(),
        latitude: yup.number()
            .emptyNumberToNull()
            .min(-90, i18n.t('VALIDATION_LATITUDE_MIN').toString())
            .max(90, i18n.t('VALIDATION_LATITUDE_MAX').toString())
            .nullable()
            .test('latitude-required-with-longitude', i18n.t('VALIDATION_LATITUDE_REQUIRED_DEPEND')!, function (value) {
                const { longitude } = this.parent;
                return isNil(longitude) || isNumber(value);
            }),
        longitude: yup.number()
            .emptyNumberToNull()
            .min(-180, i18n.t('VALIDATION_LONGITUDE_MIN').toString())
            .max(180, i18n.t('VALIDATION_LONGITUDE_MAX').toString())
            .nullable()
            .test('longitude-required-with-latitude', i18n.t('VALIDATION_LONGITUDE_REQUIRED_DEPEND')!, function (value) {
                const { latitude } = this.parent;
                return isNil(latitude) || isNumber(value);
            }),
        distance: yup
            .number()
            .emptyNumberToNull()
            .nullable()
            .min(0, i18n.t('VALIDATION_DISTANCE_MIN').toString())
            .notRequired(),
        unitName: yup.object().shape({
            label: yup.string(),
            value: yup.string(),
        }).nullable().when(['distance'], {
            is: (distance: any) => !isNil(distance) && isNumber(distance) && distance > 0,
            then: schema => schema.isEmptyObject(i18n.t('VALIDATION_UNIT_NAME_REQUIRED')!),
            otherwise: schema => schema.nullable(),
        }),
    });

    type FormType = yup.InferType<typeof schema>

    const { control, reset, handleSubmit, watch, setValue } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            description: "",
            displayOrder: 1,
            distance: undefined,
            latitude: undefined,
            longitude: undefined,
            name: "",
            nearByTypeName: undefined,
            unitName: undefined,
            url: ""
        }
    })

    const { data: location } = useFetchLocationById(id)

    const { data: previousValue } = useFetchAmenityDetailsById({
        params: {
            locationId: id,
            amenityId: amenityId
        },
        options: {
            enabled: isEditMode,
            onSuccess: (data) => {
                reset({
                    description: data.description,
                    displayOrder: data.displayOrder,
                    distance: data.distance,
                    latitude: data.latitude,
                    longitude: data.longitude,
                    name: data.name,
                    url: data.url,
                    nearByTypeName: formattedType.find(i => i.value === data.nearByTypeName),
                    unitName: formattedUnit.find(i => i.value === data.unitName),
                })
            }
        }
    })

    const { mutate: postAmenity, isLoading: postingAmenity } = usePostAmenity({
        params: {
            locationId: id,
        },
    })
    const { mutate: updateAmenity, isLoading: updatingAmenity } = useUpdateAmenity({
        params: {
            locationId: id,
            amenityId: amenityId
        },
    })

    const distance = watch('distance')

    const submit = (data: FormType) => {
        if (isEditMode) {
            if (!previousValue || !id || !amenityId) {
                return
            }
            updateAmenity({
                locationId: id,
                nearByTypeName: data.nearByTypeName.value!,
                name: data.name,
                description: data.description,
                url: data.url,
                displayOrder: data.displayOrder,
                distance: data.distance,
                latitude: data.latitude,
                longitude: data.longitude,
                unitName: data?.unitName?.value,
                id: amenityId,
                nearByGroupLabel: previousValue.nearByGroupLabel,
                nearByTypeLabel: previousValue.nearByTypeLabel
            }, {
                onError(error) {
                    setSnackbarContent(error)
                },
                onSuccess: () => {
                    handleBackLink()
                }
            })
        } else {
            postAmenity({
                locationId: id!,
                nearByTypeName: data.nearByTypeName.value!,
                name: data.name,
                description: data.description,
                url: data.url,
                displayOrder: data.displayOrder,
                distance: data.distance,
                latitude: data.latitude,
                longitude: data.longitude,
                unitName: data?.unitName?.value,
            }, {
                onError(error) {
                    setSnackbarContent(error)
                },
                onSuccess: () => {
                    handleBackLink()
                }
            })
        }
    }

    const handleBackLink = () => {
        nav(`/locations/${id}/near-by-amenities`)
    }

    const locationName = () => {
        if (!location) return ''
        return `${location?.name} (${location?.externalReference})`
    }


    const isInvalidDistance = isNull(distance) || distance?.toString() === ""

    useEffect(() => {
        if (isInvalidDistance) {
            setValue("unitName", null)
        }
    }, [distance])

    return (
        <>
            <SectionHead linkCallBack={handleBackLink}
                ctaText={i18n.t('TITLE_NEARBY_AMENITIES').toString()!}
                name={locationName()}
                title={i18n.t(isEditMode ? 'TITLE_NEARBY_AMENITIES_EDIT' : 'TITLE_NEARBY_AMENITIES_ADD')}
                description={i18n.t('COMMON_SUMMARY_LOCATIONMANAGEMENT')} />
            <CustomSnackbar snackbarContent={snackbarContent} setSnackbarContent={setSnackbarContent} severity="error" />
            <div className="row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <form onSubmit={handleSubmit(submit)} noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="nearByTypeName"
                                    render={({ field, fieldState }) => {
                                        return (
                                            <CustomSelect
                                                labelProps={{ message: 'AMENITY_LABEL_TYPE', mandatory: true }}
                                                options={formattedType}
                                                placeholder={i18n.t('AMENITY_LABEL_TYPE')}
                                                value={field.value}
                                                onChange={(e) => {
                                                    field.onChange(e)
                                                }}
                                                validationError={fieldState.error}
                                            />
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="name"
                                    render={({ field, fieldState }) => (
                                        <Input
                                            labelProps={{
                                                message: i18n.t("COMMON_LABEL_NAME").toString(),
                                                mandatory: true
                                            }}
                                            placeholder={i18n.t("COMMON_LABEL_NAME").toString()}
                                            field={field}
                                            validationError={fieldState.error}
                                        ></Input>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="description"
                                    render={({ field, fieldState }) => (
                                        <Input
                                            labelProps={{
                                                message: i18n.t("COMMON_SUMMARY_DESCRIPTIONS").toString(),
                                            }}
                                            placeholder={i18n.t("COMMON_SUMMARY_DESCRIPTIONS").toString()}
                                            field={field}
                                            validationError={fieldState.error}
                                        ></Input>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="url"
                                    render={({ field, fieldState }) => (
                                        <Input
                                            labelProps={{
                                                message: i18n.t("COMMON_LABEL_URL").toString(),
                                            }}
                                            placeholder={i18n.t("COMMON_PLACEHOLDER_URL").toString()}
                                            field={field}
                                            validationError={fieldState.error}
                                        ></Input>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="displayOrder"
                                    render={({ field, fieldState }) => (
                                        <Input
                                            labelProps={{
                                                message: i18n.t("AMENITY_LABEL_DISPLAYORDER").toString(),
                                            }}
                                            placeholder={i18n.t("AMENITY_LABEL_DISPLAYORDER").toString()}
                                            field={field}
                                            validationError={fieldState.error}
                                            type="number"
                                            inputProps={{
                                                min: 1,
                                                max: 1000,
                                                step: 1
                                            }}
                                            value={field.value ?? ""}
                                        ></Input>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    name='latitude'
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <Input
                                            labelProps={{
                                                message: i18n.t("COMMON_LABEL_LATITUDE").toString(),
                                                mandatory: false
                                            }}
                                            placeholder={i18n.t("COMMON_LABEL_LATITUDE").toString()}
                                            type="number"
                                            inputProps={{
                                                min: -90,
                                                max: 90,
                                                step: 0.0000001
                                            }}
                                            field={field}
                                            value={field.value ?? ""}
                                            validationError={fieldState.error}
                                        />)}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    name='longitude'
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <Input
                                            labelProps={{
                                                message: i18n.t("COMMON_LABEL_LONGITUDE").toString(),
                                                mandatory: false
                                            }}
                                            placeholder={i18n.t("COMMON_LABEL_LONGITUDE").toString()}
                                            type="number"
                                            inputProps={{
                                                min: -180,
                                                max: 180,
                                                step: 0.0000001
                                            }}
                                            field={field}
                                            value={field.value ?? ""}
                                            validationError={fieldState.error}
                                        />)}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    name='distance'
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <Input
                                            labelProps={{
                                                message: i18n.t("AMENITY_LABEL_DISTANCE").toString(),
                                                mandatory: false
                                            }}
                                            placeholder={i18n.t("AMENITY_LABEL_DISTANCE").toString()}
                                            type="number"
                                            inputProps={{
                                                min: 0,
                                                step: 0.01
                                            }}
                                            field={field}
                                            value={field.value ?? ""}
                                            validationError={fieldState.error}
                                        />)}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    name='unitName'
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <CustomSelect
                                            isDisabled={isInvalidDistance}
                                            labelProps={{ message: 'AMENITY_LABEL_UNIT' }}
                                            options={formattedUnit}
                                            placeholder={i18n.t('AMENITY_LABEL_UNIT')}
                                            value={field.value}
                                            onChange={(e) => {
                                                field.onChange(e)
                                            }}
                                            validationError={fieldState.error}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} mx={'auto'} mt={3}>
                                <CustomButton variant="contained" fullWidth type="submit" loading={postingAmenity || updatingAmenity}>
                                    {i18n.t('COMMON_LABEL_SAVE')}
                                </CustomButton>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </div>
        </>
    );
};