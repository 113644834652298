import React from 'react';
import { IconDisplayer } from '../Help/IconDisplayer';  

interface EditorToolbarProps {
    onViewSourceClick: () => void;
}

const EditorToolbar: React.FC<EditorToolbarProps> = ({ onViewSourceClick }) => {
    return (
        <div id="toolbar">
            <button className="ql-bold" title="Bold">
                <IconDisplayer type="FORMAT_BOLD" titleAccess="Bold" />
            </button>
            <button className="ql-italic" title="Italic">
                <IconDisplayer type="FORMAT_ITALIC" titleAccess="Italic" />
            </button>
            <button className="ql-underline" title="Underline">
                <IconDisplayer type="FORMAT_UNDERLINED" titleAccess="Underline" />
            </button>
            <button className="ql-link" title="Insert Link">
                <IconDisplayer type="INSERT_LINK" titleAccess="Insert Link" />
            </button>
            <button className="ql-list" value="ordered" title="Ordered List">
                <IconDisplayer type="LIST_ORDERED" titleAccess="Ordered List" />
            </button>
            <button className="ql-list" value="bullet" title="Bullet List">
                <IconDisplayer type="LIST_BULLETED" titleAccess="Bullet List" />
            </button>
            <button className="ql-view-source" onClick={onViewSourceClick} title="View Source">
                <IconDisplayer type="VIEW_SOURCE" titleAccess="View Source" />
            </button>
        </div>
    );
};

export default EditorToolbar;
