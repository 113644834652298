import { DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import { LR } from './Help/LR';
import { CustomButton } from './UI/CustomButton';
import { CustomDialog } from './UI/CustomDialog';

type Args = {
  showConfirmation: boolean,
  handleConfirm: () => void,
  handleCancel: () => void,
  customTitle?: string,
  customDescription?: string,
  customConfirmLabel?: string,
  buttonVariant?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning"
}

const ConfirmationChangesDialog = ({ showConfirmation, handleConfirm, handleCancel, customTitle, customDescription, customConfirmLabel, buttonVariant }: Args) => {
  return (
    <CustomDialog open={showConfirmation} onClose={handleCancel}>
      <DialogTitle><LR localResource={customTitle ?? 'COMMON_TITLE_UNSAVEDCHANGES'}></LR></DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1" color="textSecondary">
            <LR localResource={customDescription ?? 'COMMON_LABEL_UNSAVEDCHANGES'}></LR>
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={handleCancel} variant='contained' color='info'>
          <LR localResource='COMMON_BUTTON_CANCEL' />
        </CustomButton>
        <CustomButton className='btn-danger' color={buttonVariant ?? 'error'} autoFocus onClick={handleConfirm} variant='contained'>
          <LR localResource={customConfirmLabel ?? 'COMMON_BUTTON_LEAVE'} />
        </CustomButton>
      </DialogActions>
    </CustomDialog>
  );
};

export { ConfirmationChangesDialog };

