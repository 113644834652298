import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../../types/Authentication/ApplicationUser";
import { LR } from "../../Common/Help/LR";
import i18n from "../../../i18n";
import ApiStatus from '../../Common/Errors/ApiStatus';
import MessageAlert, { AlertStyle } from '../../Common/Errors/MessageAlert';
import { useDirtyState } from '../../../hooks/Common/dirty';
import { ConfirmationChangesDialog } from '../../Common/ConfirmationChangesDialog';
import { SaveSnackBar } from '../../Common/SaveSnackBar';
import { LabelAndHelp } from '../../Common/Help/LabelAndHelp';
import { useFetchBusinessRulesRule, useFetchProductRuleById, useFetchRuleProductTypes, useManageProductRule, useManageProductsRulesBusinessRules } from '../../../hooks/Product/product';
import { ProductRuleResponseDto } from '../../../types/Product/ProductRuleResponseDto';
import { Typography } from '@mui/material';
import { RuleProductType } from './RuleProductType';
import { BusinessRules } from './BusinessRules';
import { OperationResultDto } from '../../../types/Common/OperationResultDto';
import { BusinessRuleResponseDto } from '../../../types/Product/BusinessRuleResponseDto';
import { SectionHead } from '../../Common/Help/SectionHead';

type Args =
    {
        userLogged: ApplicationUser
    }



const ProductRuleDetails = ({ userLogged }: Args) => {
    const nav = useNavigate();
    const params = useParams();

    if (!params.id) throw Error("Product Rule id needed");

    //#region  CALLBACKS
    const mutationCallBack = (operationResult: OperationResultDto) => {

        if (operationResult.isSuccess) {

            setStateRulesBook(prevItems => prevItems!.map(item =>
                item.isChanged ? { ...item, isChanged: false } : item
            ));
            setStateRulesSearch(prevItems => prevItems!.map(item =>
                item.isChanged ? { ...item, isChanged: false } : item
            ));
            setStateRulesCancel(prevItems => prevItems!.map(item =>
                item.isChanged ? { ...item, isChanged: false } : item
            ));
            setStateRulesBilling(prevItems => prevItems!.map(item =>
                item.isChanged ? { ...item, isChanged: false } : item
            ));
            setStateRulesNoticePeriod(prevItems => prevItems!.map(item =>
                item.isChanged ? { ...item, isChanged: false } : item
            ));

            setSnackbarOpen(true);
        } else {
            setOperationInError(true);
            setError(operationResult.errorMessage);
        }
    }

    const businessRulesMutationCallback = (item: BusinessRuleResponseDto, responseId: string, operationResult: OperationResultDto) => {

        if (operationResult.isSuccess) {
            switch (item.businessRuleTypeName) {
                case "Book":
                    const updatedItemsBook = stateRulesBook!.map(obj =>
                        obj.productRuleId === item.productRuleId ? { ...obj, id: responseId } : obj
                    );
                    setStateRulesBook(updatedItemsBook);
                    break;
                case "Search":
                    const updatedItemsSearch = stateRulesSearch!.map(obj =>
                        obj.productRuleId === item.productRuleId ? { ...obj, id: responseId } : obj
                    );
                    setStateRulesBook(updatedItemsSearch);
                    break;
                case "Cancel":
                    const updatedItemsCancel = stateRulesCancel!.map(obj =>
                        obj.productRuleId === item.productRuleId ? { ...obj, id: responseId } : obj
                    );
                    setStateRulesBook(updatedItemsCancel);
                    break;
                case "Billing":
                    const updatedItemsBilling = stateRulesBilling!.map(obj =>
                        obj.productRuleId === item.productRuleId ? { ...obj, id: responseId } : obj
                    );
                    setStateRulesBook(updatedItemsBilling);
                    break;
                case "NoticePeriod":
                    const updatedItemsNoticePeriod = stateRulesNoticePeriod!.map(obj =>
                        obj.productRuleId === item.productRuleId ? { ...obj, id: responseId } : obj
                    );
                    setStateRulesBook(updatedItemsNoticePeriod);
                    break;
            }


        } else {
            setOperationInError(true);
            setError(operationResult.errorMessage);
        }
    };

    //#endregion

    const { data: productRule, status, isSuccess } = useFetchProductRuleById(params.id)
    const { data: productTypes, status: statusProductType, isSuccess: isSuccessProductType } = useFetchRuleProductTypes(params.id)
    const { data: rulesBook, status: statusBookRules, isSuccess: isSuccessBookRules } = useFetchBusinessRulesRule(params.id, 'Book')
    const { data: rulesSearch, status: statusSearchRules, isSuccess: isSuccessSearchRules } = useFetchBusinessRulesRule(params.id, 'Search')
    const { data: rulesCancel, status: statusCancelRules, isSuccess: isSuccessCancelRules } = useFetchBusinessRulesRule(params.id, 'Cancel')
    const { data: rulesBilling, status: statusBillingRules, isSuccess: isSuccessBillingRules } = useFetchBusinessRulesRule(params.id, 'Billing')
    const { data: rulesNoticePeriod, status: statusNoticePeriodRules, isSuccess: isSuccessNoticePeriodRules } = useFetchBusinessRulesRule(params.id, 'NoticePeriod')
    const [useProductRule, setUseProductRule] = useState<ProductRuleResponseDto>();
    const [isDirty, setDirty, unsetDirty] = useDirtyState();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [targetUrl, setTargetUrl] = useState('');
    const updateMutation = useManageProductRule(mutationCallBack);
    const rulesUpdateMutation = useManageProductsRulesBusinessRules(businessRulesMutationCallback);
    const [stateRulesBook, setStateRulesBook] = useState<BusinessRuleResponseDto[]>();
    const [stateRulesSearch, setStateRulesSearch] = useState<BusinessRuleResponseDto[]>();
    const [stateRulesCancel, setStateRulesCancel] = useState<BusinessRuleResponseDto[]>();
    const [stateRulesBilling, setStateRulesBilling] = useState<BusinessRuleResponseDto[]>();
    const [stateRulesNoticePeriod, setStateRulesNoticePeriod] = useState<BusinessRuleResponseDto[]>();
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [error, setError] = useState('');
    const [isOperationInError, setOperationInError] = useState(false);

    //#region snackbar

    const handleSnackClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };
    //#endregion snackbar

    //#region UseEffect and set State
    useEffect(() => {
        if (productRule) {
            setUseProductRule(productRule);
        }
    }, [productRule]);

    useEffect(() => {
        if (rulesSearch) {
            setStateRulesSearch(rulesSearch);
        }
    }, [rulesSearch]);

    useEffect(() => {
        if (rulesBook) {
            setStateRulesBook(rulesBook);
        }
    }, [rulesBook]);

    useEffect(() => {
        if (rulesCancel) {
            setStateRulesCancel(rulesCancel);
        }
    }, [rulesCancel]);

    useEffect(() => {
        if (rulesBilling) {
            setStateRulesBilling(rulesCancel);
        }
    }, [rulesBilling]);

    useEffect(() => {
        if (rulesNoticePeriod) {
            setStateRulesNoticePeriod(rulesCancel);
        }
    }, [rulesNoticePeriod]);

    //#endregion


    //#region Dirty
    const handleDirty = () => {
        setDirty();
    };

    const dialogConfirm = () => {
        setShowConfirmation(false);
        unsetDirty();
        nav(targetUrl);
    }

    const linkCallBack = () => {

        let url = '/products/configuration/productrules/'

        if (isDirty) {
            setTargetUrl(url);
            setShowConfirmation(true);
        }
        else {
            nav(url);
        }
    };


    const dialogCancel = () => {
        setShowConfirmation(false);
    }

    //#endregion

    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            setShowConfirmation(false);
            unsetDirty();
            if (stateRulesBook) {
                processRulesMutations(stateRulesBook!);
            }
            if (stateRulesSearch) {
                processRulesMutations(stateRulesSearch!);
            }
            if (stateRulesCancel) {
                processRulesMutations(stateRulesCancel!);
            }
            if (stateRulesBilling) {
                processRulesMutations(stateRulesBilling!);
            }
            if (stateRulesNoticePeriod) {
                processRulesMutations(stateRulesNoticePeriod!);
            }
            updateMutation.mutate(useProductRule!)
        } catch (err: any) {
            console.error(err);
        }
    }


    const processRulesMutations = (items: BusinessRuleResponseDto[]) => {
        const itemsToMutate = items.filter(item => item.isChanged);
        itemsToMutate.forEach(item => {
            rulesUpdateMutation.mutate(item);
        });


    };



    const rulesProductsTypesCallBack = (result: OperationResultDto) => {
        if (result.isSuccess) {
            setSnackbarOpen(true);
        } else {
            setOperationInError(true);
            setError(result.errorMessage);
        }
    }


    const rulesChangedItemsCallBack = (items: BusinessRuleResponseDto[], type: string) => {

        switch (type) {
            case "Book":
                setStateRulesBook(items.filter(x => x.isChanged === true));
                break;
            case "Search":
                setStateRulesSearch(items.filter(x => x.isChanged === true));
                break;
            case "Cancel":
                setStateRulesCancel(items.filter(x => x.isChanged === true));
                break;
            case "Billing":
                setStateRulesBilling(items.filter(x => x.isChanged === true));
                break;
            case "NoticePeriod":
                setStateRulesNoticePeriod(items.filter(x => x.isChanged === true));
                break;
        }
    }



    if (!isSuccess && !isSuccessProductType) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <ApiStatus status={status} />
                </div>
            </div>
        </>
    )

    if (!useProductRule || !productTypes) return <MessageAlert message="MEMBER_LABEL_MEMBERNOTFOUND" params={{ param0: params.id }}></MessageAlert>;

    return (
        <form onSubmit={onFormSubmit}>
            <SaveSnackBar onClose={handleSnackClose} isOpen={snackbarOpen}></SaveSnackBar>
            <ConfirmationChangesDialog handleConfirm={dialogConfirm} handleCancel={dialogCancel} showConfirmation={showConfirmation}></ConfirmationChangesDialog>
           
            <SectionHead linkCallBack={linkCallBack} ctaText={`${i18n.t("PRODUCT_LABEL_PRODUCTRULES")}`} name={`${useProductRule!.name} (${useProductRule.externalReference})`} title={i18n.t('PRODUCT_LABEL_PRODUCTRULEDETAIL')} description={i18n.t('COMMON_SUMMARY_PRODUCTMANAGEMENT')}/>
           
           
           
            <LabelAndHelp mandatory={false} message='PRODUCT_LABEL_PRODUCTRULENAME'></LabelAndHelp>
            <div className="row d-flex justify-content-center mb-3">
                <div className="col-md-7 col-11 justify-content-center">

                    <input
                        type="text"
                        className="form-control fa-form"
                        placeholder={i18n.t("PRODUCT_LABEL_PRODUCTRULENAME").toString()}
                        value={useProductRule!.name}
                        onChange={e => {
                            setUseProductRule({ ...useProductRule, name: e.target.value });
                            handleDirty();
                        }
                        } ></input>
                </div>
            </div>

            <LabelAndHelp mandatory={false} message='PRODUCT_LABEL_PRODUCTRULEDESCRIPTION'></LabelAndHelp>

            <div className="row d-flex justify-content-center mb-3">
                <div className="col-md-7 col-11 justify-content-center">
                    <input
                        type="text"
                        className="form-control fa-form"
                        placeholder={i18n.t("PRODUCT_LABEL_PRODUCTRULEDESCRIPTION").toString()}
                        value={useProductRule!.description}
                        onChange={e => {
                            setUseProductRule({ ...useProductRule, description: e.target.value });
                            handleDirty();
                        }} ></input>
                </div>
            </div>

            <div className="row d-flex justify-content-center mt-3 mb-3">
                <div className="col-md-7 col-11 justify-content-center">
                    <Typography variant={'h5'}><LR localResource='PRODUCT_TITLE_PRODUCTTYPES'></LR> </Typography>
                </div>
            </div>
            <div className="row d-flex justify-content-center mt-2">
                <div className="col-md-7 col-11 justify-content-center" >
                    <RuleProductType mutationCallBack={rulesProductsTypesCallBack} items={productTypes}></RuleProductType>
                </div>
            </div>
            <div className="row d-flex justify-content-center mt-3 mb-3">
                <div className="col-md-7 col-11 justify-content-center">
                    <Typography variant={'h5'}><LR localResource='PRODUCT_TITLE_RULES'></LR> </Typography>
                </div>
            </div>
            {
                rulesSearch && <div className="row d-flex justify-content-center mt-2">
                    <div className="col-md-7 col-11 justify-content-center" >
                        <BusinessRules itemsChangedCallBack={rulesChangedItemsCallBack} mutationSwitchCallBack={mutationCallBack} handleDirty={handleDirty} type='Search' items={rulesSearch}></BusinessRules>
                    </div>
                </div>
            }
            {
                rulesBook && <div className="row d-flex justify-content-center mt-2">
                    <div className="col-md-7 col-11 justify-content-center" >
                        <BusinessRules itemsChangedCallBack={rulesChangedItemsCallBack} mutationSwitchCallBack={mutationCallBack} handleDirty={handleDirty} type='Book' items={rulesBook}></BusinessRules>
                    </div>
                </div>
            }
            {
                rulesBilling && <div className="row d-flex justify-content-center mt-2">
                    <div className="col-md-7 col-11 justify-content-center" >
                        <BusinessRules itemsChangedCallBack={rulesChangedItemsCallBack} mutationSwitchCallBack={mutationCallBack} handleDirty={handleDirty} type='Billing' items={rulesBilling}></BusinessRules>
                    </div>
                </div>
            }
            {
                rulesNoticePeriod && <div className="row d-flex justify-content-center mt-2">
                    <div className="col-md-7 col-11 justify-content-center" >
                        <BusinessRules itemsChangedCallBack={rulesChangedItemsCallBack} mutationSwitchCallBack={mutationCallBack} handleDirty={handleDirty} type='NoticePeriod' items={rulesNoticePeriod}></BusinessRules>
                    </div>
                </div>
            }
            {
                rulesCancel && <div className="row d-flex justify-content-center mt-2">
                    <div className="col-md-7 col-11 justify-content-center" >
                        <BusinessRules itemsChangedCallBack={rulesChangedItemsCallBack} mutationSwitchCallBack={mutationCallBack} handleDirty={handleDirty} type='Cancel' items={rulesCancel}></BusinessRules>
                    </div>
                </div>
            }

            <div className="row d-flex justify-content-center mt-3">
                <div className="col-md-2 col-6 justify-content-center">
                    <button type="submit" className="btn btn-green btn-primary btn-block"
                        disabled={
                            !useProductRule!.name ||
                            !useProductRule!.description ||
                            !isDirty
                        }>
                        <LR localResource="COMMON_LABEL_SAVE"></LR>
                    </button>
                </div>
            </div>

            {(isOperationInError) &&
                <div className="row d-flex justify-content-center">
                    <div className="col-md-4 col-11 justify-content-center">
                        <MessageAlert message={error} variant="danger" style={AlertStyle.Tiny} additionalClassName="fa-alert-tiny"></MessageAlert>
                    </div>
                </div>
            }



        </form>
    );
};


export { ProductRuleDetails }