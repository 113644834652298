import { IconButton, Input, InputAdornment, Tooltip } from "@mui/material";
import i18n from "../../i18n";
import { MemberResponseDto } from "../../types/Member/MemberResponseDto";
import { useContext, useEffect, useState } from "react";
import { DialogOperatorSelector } from "./DialogOperatorSelector";
import { useFetchMembers } from "../../hooks/Member/member";
import ApiStatus from "../Common/Errors/ApiStatus";
import MessageAlert from "../Common/Errors/MessageAlert";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { useQueryClient } from "react-query";
import { GlobalStateContext } from "../Common/GlobalStateProvider";
import { useNavigate } from "react-router-dom";
import { ApplicationConfig } from "../../config/ApplicationConfig";
import { getDefaultLocationByOperator } from "../../hooks/Authentication/userinfoV2";

type Args = {
  userInfo: ApplicationUser
};

const OperatorContext = ({ userInfo }: Args) => {
  const nav = useNavigate();
  const { data: operators, status, isSuccess } = useFetchMembers(4, "", 2);
  const [selectedRow, setSelectedRow] = useState<MemberResponseDto | null>(null);
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [operatorName, setOperatorName] = useState('');
  const globalState = useContext(GlobalStateContext);
  if (!globalState) {
    throw new Error('Used GlobalStateContext outside of GlobalStateProvider');
  }

  const { globalLoggedUser, setGlobalLoggedUser } = globalState;


  useEffect(() => {
    if (operators) {
      const cacheddOperator: MemberResponseDto = JSON.parse(localStorage.getItem(userInfo.id + "_currentOperator")!) as MemberResponseDto;
      if (cacheddOperator) {
        setOperatorName(cacheddOperator.fullName!)
      } else {
        const selectedOperator = operators[0];
        localStorage.setItem(userInfo.id + "_currentOperator", JSON.stringify(selectedOperator));
      }
    }

  }, [operators]);


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  const handleValueSelection = async  (operator: MemberResponseDto) => {

    localStorage.setItem(userInfo.id + ApplicationConfig.currentOperatorStorageKey, JSON.stringify(operator));
    const defaultLocation = await getDefaultLocationByOperator(operator);

    let updatedUserInfo = userInfo;
    updatedUserInfo.operator = operator;

    if(!defaultLocation)
    {
      updatedUserInfo.doesNeedSetup = true;
    }else
    {
      updatedUserInfo.location=  defaultLocation;
      localStorage.setItem(userInfo.id + ApplicationConfig.currentLocationStorageKey, JSON.stringify(defaultLocation));
    }

    setGlobalLoggedUser(updatedUserInfo);
    localStorage.setItem(ApplicationConfig.currentUserStorageKey, JSON.stringify(updatedUserInfo));
    queryClient.invalidateQueries("locations");
    setOperatorName(operator.fullName)
    handleClose();
    nav('/')
  };


  if (!isSuccess) return (
    <>
      <div className="mt-5 row d-flex justify-content-center">
        <div className="col-md-7 col-11 justify-content-center">
          <ApiStatus status={status} />
        </div>
      </div>
    </>
  )

  if (!operators) return <MessageAlert message="MEMBER_LABEL_MEMBERSNOTFOUND"></MessageAlert>;

  return (
    <div className="operatorContext center-vertically">
      <DialogOperatorSelector type="Operator" operators={operators} open={open} handleCancel={handleClose} handleConfirm={handleValueSelection}></DialogOperatorSelector>
      <div className="operatorContext-names">
        <h3>{userInfo.location?.name}</h3>
        <h6>{operatorName}</h6>
      </div>
      {operators.length > 1 &&
        <IconButton aria-label="share" onClick={handleOpen}>
          <Tooltip title={i18n.t('MEMBER_TITLE_SWAPOPERATOR')}>
            <SwapHorizIcon></SwapHorizIcon>
          </Tooltip>
        </IconButton>
      }
    </div>
  );
};


export { OperatorContext }