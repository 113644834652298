export const pontRelationshipTypes = [
  {
    flag: 32,
    label: "LABEL_RELATIONSHIPTYPE_BILLINGCONTACT",
    value: "BillingContact"
  },
  {
    flag: 1,
    label: "LABEL_RELATIONSHIPTYPE_DECISIONMAKER",
    value: "DecisionMaker"
  },
  {
    flag: 8,
    label: "LABEL_RELATIONSHIPTYPE_ENDUSER",
    value: "EndUser"
  },
  {
    flag: 4,
    label: "LABEL_RELATIONSHIPTYPE_GATEKEEPER",
    value: "GateKeeper"
  },
  {
    flag: 2,
    label: "LABEL_RELATIONSHIPTYPE_INFLUENCER",
    value: "Influencer"
  },
  {
    flag: 16,
    label: "LABEL_RELATIONSHIPTYPE_POINTOFCONTACT",
    value: "PointOfContact"
  },
]