import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { Grid, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { pontConstants } from "../../data/pontConstants";
import { useDeleteDomain, useManageMemberDomains } from "../../hooks/Member/domains";
import i18n from "../../i18n";
import { DomainDto } from "../../types/Common/DomainDto";
import { LR } from "../Common/Help/LR";
import { CustomButton } from "../Common/UI/CustomButton";
import { Input } from "../Common/formComponents/Input";
import { CustomSnackbar } from '../Common/UI/CustomSnackbar';

type Args = {
  entityKey: string,
  entityName: string,
  items: DomainDto[],
  handleDirty: () => void
  afterSave: () => void
};

const DomainCollection = ({ entityKey, entityName, items, handleDirty, afterSave }: Args) => {

  const isFormValid = items.every((item) => item.value.trim() !== '');
  const [itemsState, setItemsState] = useState<DomainDto[]>(items);
  const [isFormValidState, setIsFormValidState] = useState<boolean>(isFormValid);
  const deleteDomain = useDeleteDomain();
  const { mutate } = useManageMemberDomains();
  const [isSaving, setIsSaving] = useState(false)
  const [snackbarContent, setSnackbarContent] = useState<any>()

  const setItemValue = (item: DomainDto, value: string) => {
    let updatedItem = { ...item, value: value };
    updatedItem = validateForm(updatedItem);
    const updatedItemsState = itemsState.map((i) => (i === item ? updatedItem : i));
    setIsFormValidState(updatedItemsState.every((item) => item.value.trim() !== '' && !item.errorMessage));
    setItemsState(updatedItemsState);
  };

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setIsSaving(true)
    e.preventDefault();
    itemsState.forEach(item => {
      if (item.isDeleted) {
        executeDelete(item);
      }
    });

    mutate([entityKey, itemsState.filter((i) => !i.isDeleted && i.value !== '')], {
      onSuccess: () => {
        setIsSaving(false)
        afterSave()
      },
      onError(error) {
        setIsSaving(false)
        setSnackbarContent(error.response?.data)
      },
    })
  }

  const executeDelete = async (item: DomainDto) => {
    const deletionResult = await deleteDomain(item) as { success: boolean; error?: any };;
    if (deletionResult.success) {
      const updatedItemsState = itemsState.filter((i) => i.id !== item.id);
      setItemsState([...updatedItemsState]);
      console.log('Deletion successful');
    } else {
      setIsSaving(false)
      console.log('Error deleting item:', deletionResult.error!);
    }
  };

  const deleteItem = async (item: DomainDto) => {
    if (item.id !== pontConstants.emptyGuid) {
      handleDirty();
      const updatedItem = { ...item, isDeleted: true };
      const updatedItemsState = itemsState.map((i) => (i.id === item.id ? updatedItem : i));
      setItemsState([...updatedItemsState]);
      setIsFormValidState(true); // we need to be able to save
    } else {
      const updatedItemsState = itemsState.filter((i) => i.id !== item.id);
      setItemsState([...updatedItemsState]);
    }
  };

  const validateForm = (item: DomainDto): DomainDto => {
    let domain = item.value.replace(/^(https?|http):\/\/(www\.)?/i, '');
    const pattern = /^[a-zA-Z0-9-]{3,}(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;
    if (!pattern.test(domain)) {
      item.errorMessage = 'COMMON_ERROR_DOMAINFORMATTING';
    } else {
      item.errorMessage = ''
    }
    return item;
  };

  const addNewItem = () => {
    const hasBlankDomain = itemsState.some((item) => item.value.trim() === '');
    if (!hasBlankDomain) {
      const newItem: DomainDto = { id: pontConstants.emptyGuid, entityKey: entityKey, entityName: entityName, value: '', errorMessage: '', isDeleted: false };
      const updatedItemsState = [...itemsState, newItem];
      setItemsState(updatedItemsState);
      setIsFormValidState(false); // Disable form until the new item is filled
    }
  };

  return (
    <>
      <CustomSnackbar
        snackbarContent={snackbarContent}
        setSnackbarContent={setSnackbarContent}
        severity='error'
      ></CustomSnackbar>
      <form onSubmit={onFormSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomButton
              color='secondary'
              onClick={addNewItem}
              sx={{
                textTransform: "none",
              }}>
              <AddCircleIcon /> &nbsp; <LR localResource="COMMON_TITLE_DOMAIN"></LR>
            </CustomButton>
          </Grid>
          {itemsState.map((item, index) => (
            <Grid item xs={12} key={index}>
              <Input
                type="text"
                value={item.value}
                className={`${item.isDeleted ? 'strikethrough' : ''}`}
                onChange={(e) => { setItemValue(item, e.target.value); handleDirty() }}
                disabled={item.isDeleted}
                placeholder={i18n.t(`COMMON_PLACEHOLDER_DOMAIN`).toString()}
                validationError={item?.errorMessage ? { message: i18n.t(item?.errorMessage).toString() } : undefined}
                rightIcon={<Tooltip title={i18n.t('COMMON_TOOLTIP_DELETE', { param0: item.value })}>
                  <button
                    className="btn btn-link fa-info"
                    onClick={() => deleteItem(item)} type="button">
                    <DeleteRoundedIcon color="error" />
                  </button>
                </Tooltip>}
              />
            </Grid>
          ))}
          {
            itemsState.length > 0 && (
              <Grid item xs={12} md={4} mx={'auto'} mt={3}>
                <CustomButton
                  type="submit"
                  disabled={!isFormValidState}
                  color="secondary"
                  variant="contained"
                  fullWidth
                  loading={isSaving}
                >
                  <LR localResource="COMMON_LABEL_SAVE" />
                </CustomButton>
              </Grid>
            )
          }
        </Grid>
      </form>
    </>
  );
}

export { DomainCollection };

