import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

type Args = {
    status: "idle" | "success" | "error" | "loading";
};

const ApiStatus = ({ status }: Args) => {
    switch (status) {
        case "error":
            return <div>Error communicating with the data backend</div>;
        case "idle":
            return <div>
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress color="error" />
                    <h6>The call seems idle</h6>
                </Box>
            </div>;
        case "loading":
        case "success":
            return <div>
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress color="success" />
                </Box>
            </div>;
        default:
            throw Error(`Unknown API state: ${status}`);
    }
};

export default ApiStatus;
