import { useParams } from "react-router";
import { useDirtyStateV2 } from "../../hooks/Common/dirtyV2";
import { useFetchInventoryById } from "../../hooks/Inventory/inventory";
import { useFetchLocationById } from "../../hooks/Location/location";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import ApiStatus from "../Common/Errors/ApiStatus";
import { InventoryForm } from "./InventoryForm";
import { ConfirmationChangesDialog } from "../Common/ConfirmationChangesDialog";

type Args = {
    userLogged: ApplicationUser
}

const InventoryEdit = ({ userLogged }: Args) => {
    const { id, inventoryId } = useParams();
    const { setDirty, showConfirmation, cancelDirtyAction, confirmDirtyAction, handleDirtyAction, unsetDirty } = useDirtyStateV2();

    if (!id) throw Error("Location id needed");
    if (!inventoryId) throw Error("Inventory id needed");

    const { data: location, status: locationStatus, isSuccess: locationIsSuccess } = useFetchLocationById(id!)
    const { data, status, isSuccess } = useFetchInventoryById(inventoryId!);
    console.log(data)

    if ((!isSuccess || !locationIsSuccess) && (status !== 'success') && (locationStatus !== 'success')) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <ApiStatus status={locationStatus} />
                </div>
            </div>
        </>
    )

    if (!location || !data) return <ApiStatus status={status} />;

    return (
        <>
            <InventoryForm
                inventory={data}
                isEdit={true}
                setDirty={setDirty}
                location={location}
                handleDirtyAction={handleDirtyAction}
                unsetDirty={unsetDirty}
            />
            <ConfirmationChangesDialog
                handleConfirm={confirmDirtyAction}
                handleCancel={cancelDirtyAction}
                showConfirmation={showConfirmation}
            />
        </>
    );
};


export { InventoryEdit };