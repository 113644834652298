import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import ApiStatus from "../Common/Errors/ApiStatus";
import MessageAlert from "../Common/Errors/MessageAlert";
import { useNavigate } from "react-router";
import { SectionHead } from "../Common/Help/SectionHead";
import { useParams } from "react-router-dom";
import { LabelAndHelp } from "../Common/Help/LabelAndHelp";
import { useCallback, useEffect, useState } from "react";
import { LR } from "../Common/Help/LR";
import { SaveSnackBar } from "../Common/SaveSnackBar";
import { useDirtyState } from "../../hooks/Common/dirty";
import { ConfirmationChangesDialog } from '../Common/ConfirmationChangesDialog';
import { useFetchTicketPriorityById, useGetPriorityNameValidity, useUpdateTicketPriority } from "../../hooks/Ticket/ticketPriority";
import { TicketPriorityDto } from "../../types/Ticket/TicketPriorityDto";
import { Tooltip } from "@mui/material";
import { debounce } from "lodash";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import InfoIcon from '@mui/icons-material/Info';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';

type Args =
  {
    userLogged: ApplicationUser
  }

const TicketPriorityEdit = ({ userLogged }: Args) => {
  const nav = useNavigate();
  const params = useParams();
  if (!params.id) {
    throw Error("TicketPriority id needed");
  }

  const { data: ticketPriority, status, isSuccess } = useFetchTicketPriorityById(params.id!);
  const [ticketPriorityState, setTicketPriorityState] = useState<TicketPriorityDto>();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    if (isSuccess && ticketPriority) {
      setTicketPriorityState(ticketPriority);
    }
  }, [isSuccess, ticketPriority]);

  const [isDirty, setDirty, unsetDirty] = useDirtyState();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [targetUrl, setTargetUrl] = useState('');
  const [debouncedName, setDebouncedName] = useState<string>('');
  const { data: isNameValid, loading } = useGetPriorityNameValidity(debouncedName, debouncedName !== ticketPriority?.name);

  const handleNameChange = useCallback(
    debounce((term) => {
      setDebouncedName(term);
    }, 500),
    []
  );

  useEffect(() => {
    if (ticketPriorityState && ticketPriorityState.name) {
      handleNameChange(ticketPriorityState.name);
    }
  }, [ticketPriorityState?.name]);

  const updatedCallback = (ticketPriorityId: string) => {
    setSnackbarOpen(true);
  }

  const homeCallBack = () => {
    const url = `/ticket-priorities`;
    if (isDirty) {
      setShowConfirmation(true);
      setTargetUrl(url);
    } else {
      nav(url);
    }
  };

  const dialogConfirm = () => {
    setShowConfirmation(false);
    unsetDirty();
    nav(targetUrl);
  };

  const dialogCancel = () => {
    setShowConfirmation(false);
  };


  const enableSave = () => {
    return isDirty && isNameValid && !loading && ticketPriorityState && ticketPriorityState.name !== '' && ticketPriorityState.label !== '';
  }

  const handleSnackClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const { mutate } = useUpdateTicketPriority(updatedCallback);

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();

      if (isDirty) {
        mutate(ticketPriorityState as TicketPriorityDto);
        unsetDirty();
      }

    } catch (err: any) {
      console.error(err);
    }
  }

  if (!isSuccess || !ticketPriorityState) return (
    <>
      <div className="mt-5 row d-flex justify-content-center">
        <div className="col-md-5 col-11 justify-content-center">
          <ApiStatus status={status} />
        </div>
      </div>
    </>
  )

  if (!ticketPriority) return <MessageAlert message="TICKET_LABEL_TICKETPRIORITYNOTFOUND" params={{ param0: '' }}></MessageAlert>;

  return (
    <>
      <SectionHead linkCallBack={homeCallBack}
        ctaText={i18n.t('MENU_LABEL_TICKETPRIORITIES')!}
        name={`${ticketPriorityState?.name}`}
        title={i18n.t("MENU_LABEL_TICKETPRIORITIES")}
        description={i18n.t("COMMON_SUMMARY_TICKETMANAGEMENT")} />

      <ConfirmationChangesDialog
        handleConfirm={dialogConfirm}
        handleCancel={dialogCancel}
        showConfirmation={showConfirmation}
      />


      <form onSubmit={onFormSubmit} >

        <div className="row d-flex justify-content-center">
          <div className="col-md-5 col-11 justify-content-center">
            <LabelAndHelp mandatory={false} fullscreen={true} message='TICKET_LABEL_NAME' ></LabelAndHelp>
            <div className="row d-flex justify-content-center mb-2">
              <div className="col-md-11 col-6 justify-content-center">
                <input
                  type="text"
                  className="form-control fa-form"
                  placeholder={i18n.t("TICKET_LABEL_NAME").toString()}
                  value={ticketPriorityState.name}
                  onChange={e => { setTicketPriorityState({ ...ticketPriorityState, name: e.target.value }); setDirty(); }}></input>
              </div>
              <div className="col-md-1 col-6 d-flex align-items-center justify-content-center">
                {loading && (
                  <Tooltip title={i18n.t('COMMON_TOOLTIP_NOTVALIDATED')}>
                    <InfoIcon />
                  </Tooltip>
                )}
                {!isNameValid && !loading && (
                  <Tooltip title={i18n.t('COMMON_TOOLTIP_NOTVALID')}>
                    <ErrorRoundedIcon color="error" className='fa-paper-cta-rejected' />
                  </Tooltip>
                )}
                {isNameValid && !loading && (
                  <Tooltip title={i18n.t('COMMON_TOOLTIP_VALID')}>
                    <CheckCircleRoundedIcon className="fa-paper-cta-good" />
                  </Tooltip>
                )}
              </div>
            </div>

            <LabelAndHelp mandatory={false} fullscreen={true} message='TICKET_LABEL_LABEL' ></LabelAndHelp>
            <div className="row d-flex justify-content-center mb-2">
              <div className="justify-content-center">
                <input
                  type="text"
                  className="form-control fa-form"
                  placeholder={i18n.t("TICKET_LABEL_LABEL").toString()}
                  value={ticketPriorityState.label}
                  onChange={e => { setTicketPriorityState({ ...ticketPriorityState, label: e.target.value }); setDirty(); }}></input>
              </div>
            </div>

            <LabelAndHelp mandatory={false} fullscreen={true} message='TICKET_LABEL_INTERNALIDENTIFIER' ></LabelAndHelp>
            <div className="row d-flex justify-content-center mb-2 disabled-content">
              <div className="justify-content-center">
                <input
                  type="text"
                  className="form-control fa-form"
                  placeholder={i18n.t("TICKET_LABEL_INTERNALIDENTIFIER").toString()}
                  value={ticketPriorityState.internalIdentifier}
                  onChange={e => { setTicketPriorityState({ ...ticketPriorityState, internalIdentifier: e.target.value }); setDirty(); }}></input>
              </div>
            </div>


            <div className="row d-flex justify-content-center mt-3">
              <div className="col-md-4 col-6 justify-content-center">
                <Tooltip title={!enableSave() ? i18n.t('TICKET_REQUIRED_TOOLTIP') : ''}>
                  <span>
                    <button type="submit" disabled={!enableSave()} style={!enableSave() ? { pointerEvents: 'none' } : {}} className="btn btn-green btn-primary btn-block">
                      <LR localResource="COMMON_LABEL_SAVE"></LR>
                    </button>
                  </span>
                </Tooltip>
              </div>
            </div>
            <SaveSnackBar onClose={handleSnackClose} isOpen={snackbarOpen} />
          </div>
        </div>

      </form>
    </>
  );
};

export { TicketPriorityEdit }

