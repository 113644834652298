import React, { useState } from 'react';
import { DateRangePickerComponent, RangeEventArgs } from '@syncfusion/ej2-react-calendars';
import './DateNavigation.css';
import i18n from '../../../i18n';
import { format, format as dateFnsFormat } from 'date-fns';
import { getLocalDateFormat } from '../../../functions/utils/helper';

interface DateNavigationProps {
  onDateSelect: (fromDate: Date, toDate: Date) => void;
  fromDate: Date;
  toDate: Date;
}

const DateNavigation: React.FC<DateNavigationProps> = ({ onDateSelect, fromDate, toDate }) => {
  const [selectedDates, setSelectedDates] = useState<Date[]>([fromDate, toDate]);

  const handleDateChange = (args: RangeEventArgs) => {
    if (args.startDate && args.endDate) {    
      setSelectedDates([args.startDate, args.endDate]);

      onDateSelect(args.startDate, args.endDate);
    }
  };

  const getLocaleDateFormat = (): string => {
   
    const formatted = getLocalDateFormat();
    return formatted;
  };

  return (
    <div className="date-navigation-container">
        <DateRangePickerComponent
          format={getLocaleDateFormat()}
          value={selectedDates}
          change={handleDateChange} 
          showClearButton={false}
          placeholder="Select a date range"
          cssClass="custom-datepicker-dropdown"
          locale={i18n.language}
          
        />
    </div>
  );
};

export default DateNavigation;
