import React, { useState } from 'react';
import { IconDisplayer } from '../../Common/Help/IconDisplayer';

interface StatusSectionProps {
  label: string;
  value: string;
  Options: string;
  ConfirmOrder: string;
  CancelOrder: string;
}

const StatusSection: React.FC<StatusSectionProps> = ({ label, value, Options, ConfirmOrder, CancelOrder }) => {
  const [showMore, setShowMore] = useState(false);
  
  const toggleShowMore = () => {
    setShowMore(prev => !prev);
    console.log("Show more:", !showMore); // Check the state toggle in the console
  };

  return (
    <>
      <div className='status-section-main-wrapper'>
        <div className='status-section-main'>
          <div className='status-section-left'>{label}</div>
          <div className='status-section-group-right'>
            <div className='status-section-right'>{value}</div>
            <div className='status-section-arrow' onClick={toggleShowMore}>
              <IconDisplayer type={showMore ? 'COMMON_LABEL_UP_ARROW' : 'COMMON_LABEL_DOWN_ARROW'} />
            </div>
          </div>
        </div>
        <div className={`status-section-additional ${showMore ? 'show' : ''}`}>
          <div className='status-section-additional-inner'>
            <div className='invoice-additional-children'>
            <div className='aditional-inner-options'>{Options}</div>
              <div className='aditional-inner-confirm'>{ConfirmOrder}</div>
              <div className='aditional-inner-cancel'>{CancelOrder}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { StatusSection };
