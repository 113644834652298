import React, { forwardRef } from 'react';
import { Button, ButtonProps } from '@mui/material';
import { ButtonLoader } from './Loader';

// Extend MUI ButtonProps to include a `loading` prop
interface CustomButtonProps extends ButtonProps {
  loading?: boolean;
}

// Use forwardRef to ensure compatibility with refs
export const CustomButton = forwardRef<HTMLButtonElement, CustomButtonProps>(
  ({ loading = false, children, color = 'primary', disabled, ...props }, ref) => (
    <Button
      ref={ref}
      color={color}
      disabled={loading || disabled}
      {...props}
    >
      {loading ? <ButtonLoader color={color} /> : children}
    </Button>
  )
);

// Provide a display name for debugging purposes
CustomButton.displayName = 'CustomButton';
