import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { SummaryIcon, SummaryItem } from "../Common/SummaryItems/SummaryItem";
import ApiStatus from "../Common/Errors/ApiStatus";
import i18n from "../../i18n";
import { useFetchLocationById } from "../../hooks/Location/location";
import { useFetchDigitalAssetsRequirements } from "../../hooks/Common/fileUpload";
import { getSummaryLabel } from "../../hooks/Common/labels";
import { TitleWithIcon } from "../Common/Help/TitleWithIcon";
import { FileuploadContainer } from "../Common/Upload/FileUploadContainer";
import { useFetchMemberById } from "../../hooks/Member/member";
import { SectionHead } from "../Common/Help/SectionHead";


type Args =
    {
        userLogged: ApplicationUser
    }

const HostDigitalAssets = ({ userLogged }: Args) => {

    const nav = useNavigate();
    const queryStringParams = useParams();

    if (!queryStringParams.id) throw Error("Location id needed");
    if (!queryStringParams.groupName) throw Error("Digital Asset Type id needed");

    const titleLabel = getSummaryLabel(queryStringParams.groupName!);

    const linkCallBack = () => {
        nav(`/operators/${queryStringParams.id}/summary/`);
    }

    const { data: operator, status: operatorStatus, isSuccess: operatorIsSuccess } = useFetchMemberById(queryStringParams.id!)
    const { data, status, isSuccess } = useFetchDigitalAssetsRequirements('member', queryStringParams.id!, queryStringParams.groupName!);

    if ((!isSuccess || !operatorStatus) && (status !== 'success') && (operatorStatus !== 'success')) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <ApiStatus status={operatorStatus} />
                </div>
            </div>
        </>
    )

    if (!operator || !data) return <ApiStatus status={status} />;

    return (
        <>


            <SectionHead linkCallBack={linkCallBack} ctaText={i18n.t('MEMBER_SUMMARY_OPERATORMAINPAGE')!}
                name={`${operator.fullName} (${operator.externalReference})`}
                title={i18n.t(titleLabel)}
                description={i18n.t('COMMON_SUMMARY_OPERATORMANAGEMENT')} />



            <FileuploadContainer items={data} groupname={queryStringParams.groupName!}></FileuploadContainer>
        </>
    );
};


export { HostDigitalAssets }