import { Button, Card, CardContent, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ".././Common/SummaryItems/SummaryItem.css"
import { LR } from "../Common/Help/LR";
import MessageAlert, { AlertStyle } from "../Common/Errors/MessageAlert";
import { OperationResultDto } from "../../types/Common/OperationResultDto";
import { ProductBenefitSummaryDto } from "../../types/Product/ProductBenefitSummaryDto";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import i18n from "../../i18n";
import { useNavigate, useParams } from "react-router-dom";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { useDeleteBenefit } from "../../hooks/Product/benefits";

type Args = {
  items: ProductBenefitSummaryDto[],
  mutationCallBack: (operationResult: OperationResultDto) => void
};

const ProductBenefits = ({ items, mutationCallBack }: Args) => {
  const params = useParams();
  const nav = useNavigate();

  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const deleteBenefit = useDeleteBenefit();

  const [benefitItems, setBenefitItems] = useState<ProductBenefitSummaryDto[]>(items);
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
  const [deleteName, setDeleteName] = useState<string>('');
  const [deleteBenefitId, setDeleteBenefitId] = useState<string>('');
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (items) {
      setBenefitItems(items);
    }
  }, [items]);

  const handleDelete = (name: string, benefitId: string) => {
    setError('');
    setDeleteName(name);
    setDeleteBenefitId(benefitId);
    setDeleteConfirmation(true);
  }

  const confirmDeleteItem = async (benefitId: string) => {
    const resultDelete = await deleteBenefit(params.id!, benefitId);
    if (resultDelete.success) {
      setBenefitItems(benefitItems.filter(x => x.id !== benefitId));
    } else {
      setError("INVENTORY_ERROR_ALLOCATIONDELETION");
    }
    setDeleteConfirmation(false);
  }

  return (
    <>

      <Paper elevation={3} className="fa-paper-light">
        <div className="d-flex align-items-center">
          <div className="fa-name flex-grow-1">

            <Typography variant="h6" className="linkbutton">
              <LR localResource="PRODUCT_LABEL_PRODUCTBENEFITS" /> ({benefitItems.length})
            </Typography>
          </div>
          <div className="fa-icons">
            <IconButton className="fa-arrow" onClick={() => setIsCollapseOpen(!isCollapseOpen)}>
              {!isCollapseOpen && <ArrowForwardIosRoundedIcon />}
              {isCollapseOpen && <ArrowForwardIosRoundedIcon className='fa-arrow-down' />}
            </IconButton>
          </div>
        </div>
      </Paper>

      <Collapse in={isCollapseOpen}>
        <div>
          <div className="row d-flex justify-content-center">
            <div className="justify-content-center">
              {benefitItems.map((item, index) => (
                <Paper key={item.id} elevation={24} className="fa-paper-grid">
                  <div className="d-flex align-items-center">
                    <div className="fa-name flex-grow-1">
                      {item.summary}
                    </div>

                    <div className="col-1 d-flex justify-content-end" style={{ flex: 1 }}>
                      <Tooltip title={i18n.t('COMMON_TOOLTIP_DETAILS')}>
                        <IconButton className="fa-info" onClick={() => nav(`/products/${params.id}/benefits/${item.id}`)}>
                          <EditRoundedIcon sx={{ color: '#00be95' }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={i18n.t('COMMON_TOOLTIP_DELETE', { param0: item.summary })}>
                        <IconButton onClick={(e) => handleDelete(item.summary, item.id!.toString())} >
                          <DeleteRoundedIcon color={'error'} />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </Paper>
              ))}

            </div>
          </div>
          {
            error && <div className="row d-flex justify-content-center">
              <div className="justify-content-center">
                <MessageAlert message={error} variant="danger" style={AlertStyle.Tiny} additionalClassName="fa-alert-tiny"></MessageAlert>
              </div>
            </div>
          }


        </div>
      </Collapse>

      <Dialog open={deleteConfirmation} onClose={() => setDeleteConfirmation(false)}>
        <Card>
          <CardContent>
            <DialogTitle><LR localResource='COMMON_CONTACT_DELETECONFIRMATIONTITLE' /></DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Typography variant="body1" color="textSecondary">
                  <LR localResource='COMMON_BUTTON_DELETETEXT' params={{ name: deleteName }} />
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button className='btn-green' onClick={() => setDeleteConfirmation(false)}>
                <LR localResource='COMMON_BUTTON_CANCEL' />
              </Button>
              <Button className='btn-danger' type="button" onClick={() => { confirmDeleteItem(deleteBenefitId); }} color="error" autoFocus>
                <LR localResource='COMMON_BUTTON_DELETE' />
              </Button>
            </DialogActions>
          </CardContent>
        </Card>
      </Dialog>

    </>
  );
}

export { ProductBenefits }