import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDirtyState } from "../../hooks/Common/dirty";
import { useFetchMemberFiscalDetails } from "../../hooks/Member/fiscaldetails";
import { getLabelsByOperatorType, useFetchMemberById, } from "../../hooks/Member/member";
import i18n from "../../i18n";
import { useSource } from "../../providers/SourceProvider";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { ConfirmationChangesDialog } from "../Common/ConfirmationChangesDialog";
import ApiStatus from "../Common/Errors/ApiStatus";
import { SectionHead } from "../Common/Help/SectionHead";
import { FiscalDetailsCollection } from "./FiscalDetailsCollection";

type Args = {
    userLogged: ApplicationUser
}

const HostFiscalDetails = ({ userLogged }: Args) => {
    const { source } = useSource()

    const nav = useNavigate();
    const { id, country } = useParams();
    const [isDirty, setDirty, unsetDirty] = useDirtyState();
    const [showConfirmation, setShowConfirmation] = useState(false);

    if (!id) throw Error("Operator id needed");
    if (!country) throw Error("Country id needed");

    let url = `/${source}/${id}/summary`;

    const goBack = () => {
        nav(url);
    }

    const linkCallBack = () => {
        if (isDirty) {
            setShowConfirmation(true);
        } else {
            nav(url);
        }
    }

    const handleDirty = () => {
        setDirty();
    };

    const dialogConfirm = () => {
        setShowConfirmation(false);
        unsetDirty();
        goBack()
    }

    const dialogCancel = () => {
        setShowConfirmation(false);
    }

    const { data: operator, status: operatorStatus, isSuccess: operatorIsSuccess } = useFetchMemberById(id!)
    const { data, status, isSuccess } = useFetchMemberFiscalDetails(id!, country!);

    if ((!isSuccess || !operatorIsSuccess) && (status !== 'success') && (operatorStatus !== 'success')) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <ApiStatus status={status} />
                </div>
            </div>
        </>
    )

    if (!data || !operator) return <ApiStatus status={status} />;

    const labels = getLabelsByOperatorType(operator);

    return (
        <>
            <ConfirmationChangesDialog
                handleConfirm={dialogConfirm}
                handleCancel={dialogCancel}
                showConfirmation={showConfirmation}
            />

            <SectionHead
                linkCallBack={linkCallBack}
                ctaText={i18n.t(labels!.cta)!}
                name={`${operator.fullName} (${operator.externalReference})`}
                title={i18n.t('COMMON_SUMMARY_FISCAL-DETAILS')}
                description={i18n.t(labels!.label)}
            />
            <div className="row d-flex justify-content-center mt-1 mb-3">
                <div className="col-md-7 col-11 d-flex align-items-center">
                    <FiscalDetailsCollection
                        handleDirty={handleDirty}
                        items={data}
                        entityName="Member"
                        entityKey={id!}
                        afterSave={goBack}
                    />
                </div>
            </div>

        </>
    );
};


export { HostFiscalDetails };

