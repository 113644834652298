import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { colors } from '../../../main/Theme';
import { Label, LabelType } from './Label';
import { ValidationError } from './ValidationError';


interface CustomProps extends DatePickerProps<any> {
  labelProps?: LabelType;
  validationError?: {
    message?: string;
  }
}

export const CustomDatePicker = (props: CustomProps) => {
  const { validationError, labelProps, ...rest } = props;

  return (
    <>
      {labelProps && <Label {...labelProps} error={!!validationError} />}
      <DatePicker
        slotProps={{
          textField: {
            sx: (theme) => {
              return {
                width: '100%',
                '& .MuiInputBase-root': {
                  backgroundColor: colors.grey[100],
                },
                '& fieldset, & .MuiInputBase-root': {
                  borderColor: 'transparent !important',
                  borderRadius: '0 !important',
                },
                '& .MuiInputBase-input': {
                  position: 'relative',
                  backgroundColor: colors.grey[100],
                  padding: '.375rem .75rem',
                  fontSize: '1rem',
                  lineHeight: '2',
                  display: 'block',
                  height: 'auto',
                  width: '100%',
                  border: '2px solid',
                  borderColor: 'transparent',
                  boxShadow: 'none',
                  transition: theme.transitions.create([
                    'border-color',
                    'background-color',
                    'box-shadow',
                  ]),
                  '&:focus': {
                    borderColor: colors.grey[200],
                  },
                },
              }
            }
          },
        }}
        {...rest}
      />
      {validationError && <ValidationError message={validationError?.message} />}
    </>
  );
}
