const customerHeaderConstants = {
    accountReference: 'Account Reference',
    type: 'Type',
    company: 'Company',
    firstName: 'First Name',
    lastName: 'Last Name',
    relationship: 'Relationship',
    orders: 'Orders',
    spend: 'Spend'
}

export { customerHeaderConstants }