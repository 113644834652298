import axios from "axios";
import { HookConfig } from "../../config/HookConfig";
import { operatorDefaultHeaders } from "../../functions/api/api";
import { OrderSearchV2ResponseDto } from "../../types/Order/OrderSearchV2ResponseDto";

const getOrderData = async (params: {
  locationId: string;
  searchRequirement: string;
  pageNumber: number;
  pageSize: number;
  fromDate: string;
  toDate: string;
}): Promise<OrderSearchV2ResponseDto | null> => {
  try {
    const orderData = await axios.post(
      `${HookConfig.orderUrl}/search/orders`,
      params,
      {
        headers: operatorDefaultHeaders(),
        withCredentials: true,
      }
    );

    return orderData.data;
  } catch (error: any) {
    console.error(`Error fetching orders: ${error}`);
    return null;
  }
};

export { getOrderData };
