import { LR } from "../../Common/Help/LR";

const ResetLinks = () => {

    return (
        <div className="row d-flex justify-content-center mb-2 mt-3">
            <div className="col-10 d-flex justify-content-center">
                <a className="login-register" href="/account/login/">
                    <LR localResource="LOGIN_BUTTON_LOGIN" />
                </a>  <div className="vr" />
                <a className="login-register" href="/account/register/">
                    <LR localResource="LOGIN_BUTTON_REGISTER" />
                </a>
            </div>
        </div>
    );
}

export { ResetLinks }