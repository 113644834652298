import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Customers.css';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { CustomerSearchItemResponseDto } from '../../types/Customer/CustomerSearchItemResponseDto';
import { ApplicationUser } from '../../types/Authentication/ApplicationUser';
import { customerHeaderConstants } from '../../data/customerHeaderConstants';
import DataRow from '../Common/DataRow/DataRow';
import { IconDisplayer } from '../Common/Help/IconDisplayer';


type CustomerTableProps = {
    headers: string[];
    customers: CustomerSearchItemResponseDto[];
    onActionClick: (customer: CustomerSearchItemResponseDto) => void;
    userLogged: ApplicationUser;
    compact?: boolean;
};

const CustomerTable: React.FC<CustomerTableProps> = ({ customers, onActionClick, userLogged, headers, compact }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [openCustomerId, setOpenCustomerId] = useState<string | null>(null);
    const [isMobileView, setIsMobileView] = useState<boolean>(window.innerWidth <= 768);

    const toggleDetails = (customerId: string) => {
        setOpenCustomerId(openCustomerId === customerId ? null : customerId);
    };

    const handleResize = () => {
        setIsMobileView(window.innerWidth <= 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const isInHeaders = (key: string) => {
        return headers.find(x => x === key);
    }

    return (
        <div>
            {customers.map((customer, index) => {
                const isExpanded = openCustomerId === customer.memberId;

                const fulldata = [];
                isInHeaders(customerHeaderConstants.accountReference) && fulldata.push({ content: customer.memberExternalReference || `ACC-${customer.memberId}` });
                isInHeaders(customerHeaderConstants.type) && fulldata.push({ content: t(`MEMBER_LABEL_TYPE_${customer.memberTypeName?.toUpperCase() || "UNKNOWN"}`) });
                isInHeaders(customerHeaderConstants.company) && fulldata.push({ content: customer.companyName || "" });
                isInHeaders(customerHeaderConstants.firstName) && fulldata.push({ content: customer.firstName || "" });
                isInHeaders(customerHeaderConstants.lastName) && fulldata.push({ content: customer.lastName || "" });
                isInHeaders(customerHeaderConstants.relationship) && fulldata.push({ content: customer.relationshipTypes || "" });
                isInHeaders(customerHeaderConstants.orders) && fulldata.push({ content: customer.orderCount ?? "" });
                isInHeaders(customerHeaderConstants.spend) && fulldata.push({ content: customer.currencySymbol && customer.orderTotalAmountExTax ? `${customer.currencySymbol}${customer.orderTotalAmountExTax.toFixed(2)}` : '' });

                const dataToShow = isMobileView && !isExpanded
                ? [
                    { content: customer.firstName },
                    { content: customer.lastName || '' },
                ]
                : fulldata;

                return (
                    <DataRow
                        key={index}
                        compact={compact}
                        data={dataToShow}
                        actionIcon={
                            isMobileView ? (
                                <>
                                    <div
                                        onClick={() => toggleDetails(customer.memberId)}
                                        style={{
                                            color: isExpanded ? '#00be95' : 'inherit',
                                            cursor: 'pointer',
                                            transition: 'color 0.3s ease',
                                            marginRight: '10px',

                                        }}
                                    >
                                        <IconDisplayer
                                            type={isExpanded ? 'COMMON_LABEL_UP_ARROW' : 'COMMON_LABEL_DOWN_ARROW'}
                                        />
                                    </div>
                                    {isExpanded && (
                                        <div
                                            onClick={() => onActionClick(customer)}
                                            style={{
                                                cursor: 'pointer',
                                                marginTop: '15px'
                                            }}
                                        >
                                            <IconDisplayer type={'COMMON_LABEL_EDITPEN'} />
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div
                                    onClick={() => onActionClick(customer)}
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                >
                                    <ArrowForwardIosRoundedIcon className='fa-arrow-small' />
                                </div>
                            )
                        }
                        isExpanded={isExpanded}
                        // onActionClick={() => !isMobileView && onActionClick(customer)}
                    />
                );
            })}
        </div>
    );
};

export default CustomerTable;
