import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../../types/Authentication/ApplicationUser";
import { LR } from "../../Common/Help/LR";
import i18n from "../../../i18n";
import ApiStatus from '../../Common/Errors/ApiStatus';
import MessageAlert, { AlertStyle } from '../../Common/Errors/MessageAlert';
import { useDirtyState } from '../../../hooks/Common/dirty';
import { ConfirmationChangesDialog } from '../../Common/ConfirmationChangesDialog';
import { SaveSnackBar } from '../../Common/SaveSnackBar';
import { LabelAndHelp } from '../../Common/Help/LabelAndHelp';
import { useFetchProductPortfolioById, useFetchProductPortfolioProducts, useManageProductPortfolio, useManageProductPortfolioPrices } from '../../../hooks/Product/product';
import { Typography } from '@mui/material';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { ProductPortfolioResponseDto } from '../../../types/Product/ProductPortfolioResponseDto';
import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { ProductPortfolioProductInstance } from './ProductPortfolioProductInstance';
import { OperationResultDto } from '../../../types/Common/OperationResultDto';
import { ProductPortfolioProductInstanceDto } from '../../../types/Product/ProductPortfolioProductInstanceDto';
import { PriceDto } from '../../../types/Price/PriceDto';
import { SectionHead } from '../../Common/Help/SectionHead';

type Args =
    {
        userLogged: ApplicationUser
    }



const ProductPortfolioDetails = ({ userLogged }: Args) => {
    const nav = useNavigate();
    const params = useParams();

    if (!params.id) throw Error("Product Catalogue id needed");


    //#region  CALLBACKS
    const mutationCallBack = (operationResult: OperationResultDto) => {

        if (operationResult.isSuccess) {
            setSnackbarOpen(true);
        } else {
            setOperationInError(true);
            setError(operationResult.errorMessage);
        }
    }

    const pricesCallBack = (operationResult: OperationResultDto) => {

        if (!operationResult.isSuccess) {
            setOperationInError(true);
            setError(operationResult.errorMessage);
        }
    }



    const success = () => {
        setSnackbarOpen(true);
    }

    const { data: productPortfolio, status, isSuccess } = useFetchProductPortfolioById(params.id)
    const { data: products, status: statusProducts, isSuccess: isSuccessProducts } = useFetchProductPortfolioProducts(params.id)
    const [useProductPortfolio, setUseProductPortfolio] = useState<ProductPortfolioResponseDto>();
    const [dateRange, setDateRange] = useState<DateRange<Dayjs>>();
    const updateMutation = useManageProductPortfolio(mutationCallBack);
    const [isDirty, setDirty, unsetDirty] = useDirtyState();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [useProducts, setUseProducts] = useState<ProductPortfolioProductInstanceDto[]>();
    const [targetUrl, setTargetUrl] = useState('');    
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [error, setError] = useState('');
    const [isOperationInError, setOperationInError] = useState(false);
    const [prices, setPrices] = useState<PriceDto[]>([]);
    const pricesMutation = useManageProductPortfolioPrices();

    //#region snackbar

    const handleSnackClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };
    //#endregion snackbar


    useEffect(() => {
        if (productPortfolio) {

            setDateRange([dayjs(productPortfolio.fromDate), dayjs(productPortfolio.toDate),])
            setUseProductPortfolio(productPortfolio);
        }
    }, [productPortfolio]);


    useEffect(() => {
        if (products) {
            setUseProducts(products);
        }
    }, [products]);


    //#region Dirty
    const handleDirty = () => {
        setDirty();
    };

    const dialogConfirm = () => {
        setShowConfirmation(false);
        unsetDirty();
        nav(targetUrl);
    }

    const linkCallBack = () => {
        nav('/products/configuration/pricingportfolio/')
    };


    const dialogCancel = () => {
        setShowConfirmation(false);
    }

    //#endregion

    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            setShowConfirmation(false);
            unsetDirty();
            pricesMutation.mutate([prices, pricesCallBack]);
            updateMutation.mutate(useProductPortfolio!);

        } catch (err: any) {
            console.error(err);
        }
    }

 


    const handlePrices = (value: PriceDto) => {
        handleDirty();
        setPrices((prevPrices) => {
            const index = prevPrices.findIndex((price) => price.entityKey === value.entityKey && price.unitName === value.unitName);
            if (index !== -1) {              
              const newPrices = [...prevPrices];
              newPrices[index] = value;
              return newPrices;
            } else {              
              return [...prevPrices, value];
            }
          });
    };


    if (!isSuccess && !isSuccessProducts) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <ApiStatus status={status} />
                </div>
            </div>
        </>
    )

    if (!useProductPortfolio || !useProducts) return <MessageAlert message="MEMBER_LABEL_MEMBERNOTFOUND" params={{ param0: params.id }}></MessageAlert>;

    return (
        <form onSubmit={onFormSubmit}>
            <SaveSnackBar onClose={handleSnackClose} isOpen={snackbarOpen}></SaveSnackBar>
            <ConfirmationChangesDialog handleConfirm={dialogConfirm} handleCancel={dialogCancel} showConfirmation={showConfirmation}></ConfirmationChangesDialog>
            
            
            <SectionHead linkCallBack={linkCallBack} ctaText={i18n.t("PRODUCT_LABEL_PRICINGPORTFOLIO")!} name={`${useProductPortfolio!.name} (${useProductPortfolio.externalReference})`} title={i18n.t('PRODUCT_LABEL_PRICINGCATALOGUEDETAIL')} description={i18n.t('COMMON_SUMMARY_PRODUCTMANAGEMENT')}/>
            
           
            <LabelAndHelp mandatory={false} message='PRODUCT_LABEL_PRODUCTPORTFOLIONAME'></LabelAndHelp>
            <div className="row d-flex justify-content-center mb-3">
                <div className="col-md-7 col-11 justify-content-center">
                    <input
                        type="text"
                        className="form-control fa-form"
                        placeholder={i18n.t("PRODUCT_LABEL_PRODUCTPORTFOLIONAME").toString()}
                        value={useProductPortfolio!.name}
                        onChange={e => {setUseProductPortfolio({ ...useProductPortfolio, name: e.target.value });handleDirty()}} ></input>  
                </div>
            </div>

            <LabelAndHelp mandatory={false} message='PRODUCT_LABEL_PRODUCTPORTFOLIODESCRIPTION'></LabelAndHelp>

            <div className="row d-flex justify-content-center mb-3">
                <div className="col-md-7 col-11 justify-content-center">
                <input
                        type="text"
                        className="form-control fa-form"
                        placeholder={i18n.t("PRODUCT_LABEL_PRODUCTPORTFOLIODESCRIPTION").toString()}
                        value={useProductPortfolio!.description}
                        onChange={e => {setUseProductPortfolio({ ...useProductPortfolio, description: e.target.value }); handleDirty()}} ></input>
                </div>
            </div>


            <div className="row d-flex justify-content-center mt-4 mb-3">
                    <div className=" col-md-7 col-11 justify-content-center">
                        <DateRangePicker       
                            className="fa-datepicker"                    
                            value={dateRange}
                            onChange={(newValue) => {setDateRange(newValue); handleDirty()}}
                        />
                    </div>
                </div>


            <div className="row d-flex justify-content-center mt-3 mb-3">
                <div className="col-md-7 col-11 justify-content-center">
                    <Typography variant={'h5'}><LR localResource='COMMON_SUMMARY_PRODUCTS'></LR> </Typography>
                </div>
            </div>
            <div className="row d-flex justify-content-center mt-2">
                <div className="col-md-7 col-11 justify-content-center" >
                    <ProductPortfolioProductInstance mutationSwitchCallBack={mutationCallBack} pricingCallBack={handlePrices} handleDirty={handleDirty} currencyCode={useProductPortfolio.currencyCode} items={useProducts!}></ProductPortfolioProductInstance>
                </div>
            </div>

            <div className="row d-flex justify-content-center mt-3">
                <div className="col-md-2 col-6 justify-content-center">
                    <button type="submit" className="btn btn-green btn-primary btn-block"
                        disabled={
                            !useProductPortfolio!.name ||
                            !useProductPortfolio!.description ||
                            !isDirty
                        }>
                        <LR localResource="COMMON_LABEL_SAVE"></LR>
                    </button>
                </div>
            </div>

            {(isOperationInError) &&
                <div className="row d-flex justify-content-center">
                    <div className="col-md-4 col-11 justify-content-center">
                        <MessageAlert message={error} variant="danger" style={AlertStyle.Tiny} additionalClassName="fa-alert-tiny"></MessageAlert>
                    </div>
                </div>
            }


        </form>
    );
};


export { ProductPortfolioDetails }