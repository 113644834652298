import { Box, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { formatCurrency, readableDate } from '../../functions/utils/helper';
import { useFetchInvoiceDetailById } from '../../hooks/Invoice/useInvoiceHooks';
import { ApplicationUser } from '../../types/Authentication/ApplicationUser';
import { SectionHead } from '../Common/Help/SectionHead';

import { InvoiceInvoicePartyDetail } from './InvoiceCustom/InvoiceInvoicePartyDetail';
import { InvoiceSummaryLine } from './InvoiceCustom/InvoiceSummaryLine';
import i18n from '../../i18n';
import { CondensedAccordion, CondensedAccordionSummary } from '../Common/UI/CondensedAccordion';
import { CustomSnackbar } from '../Common/UI/CustomSnackbar';
import { useState } from 'react';

const InvoiceDetail = ({ userLogged }: { userLogged: ApplicationUser }) => {
  const { invoiceid } = useParams()
  const [snackbarContent, setSnackbarContent] = useState<any>()

  if (!invoiceid) throw Error("Invoice ID is needed")

  const { data: invoiceDetail, status, isSuccess } = useFetchInvoiceDetailById({
    invoiceId: invoiceid,
    fullDetails: false,
    options: {
      enabled: !!invoiceid,
      onError: (err) => {
        setSnackbarContent(err.response?.data)
      }
    }
  })


  const { date, dueDate, invoiceStatusName, totalPriceExTax, totalPriceIncTax, totalTax, summaryInvoiceLines, number, createDateUtc, invoiceTypeName, currencySymbol } = invoiceDetail || {}

  return (
    <>
      <SectionHead
        linkCallBack={() => { }}
        ctaText={i18n.t('INVOICE_LABEL_DETAIL').toString()}
        name={
          i18n.t('INVOICE_LABEL_TITLE_LINE', {
            id: number,
            date: readableDate(createDateUtc, 'MMM dd, yyyy HH:mm')
          }).toString()
        }
        title={invoiceTypeName ?? ""}
        description={i18n.t('INVOICE_LABEL_DETAILDESCRIPTION').toString()}
      />
      <div className="row d-flex justify-content-center">
        <div className="col-md-7 col-11 justify-content-center">
          <CustomSnackbar snackbarContent={snackbarContent} setSnackbarContent={setSnackbarContent}></CustomSnackbar>
          {
            invoiceDetail?.summaryInvoiceParties?.map((party) => (
              <InvoiceInvoicePartyDetail key={party.id} data={party} invoiceId={invoiceid} currencySymbol={currencySymbol} />
            ))
          }
          <CondensedAccordion expanded={false}>
            <CondensedAccordionSummary
              sx={{ pl: 2 }}
              expandIcon={<></>}
            >
              <Grid container>
                <Grid item xs={3}>
                  {i18n.t('INVOICE_LABEL_DATE')}
                </Grid>
                <Grid item xs={9} sx={{ pl: 1.4 }}>
                  {readableDate(date, 'MMM dd, yyyy HH:mm')}
                </Grid>
              </Grid>
            </CondensedAccordionSummary>
          </CondensedAccordion>
          <CondensedAccordion expanded={false}>
            <CondensedAccordionSummary
              sx={{ pl: 2 }}
              expandIcon={<></>}
            >
              <Grid container>
                <Grid item xs={3}>
                  {i18n.t('INVOICE_LABEL_DUE_DATE')}
                </Grid>
                <Grid item xs={9} sx={{ pl: 1.4 }}>
                  {readableDate(dueDate, 'MMM dd, yyyy HH:mm')}
                </Grid>
              </Grid>
            </CondensedAccordionSummary>
          </CondensedAccordion>
          <CondensedAccordion expanded={false}>
            <CondensedAccordionSummary
              sx={{ pl: 2 }}
              expandIcon={<></>}
            >
              <Grid container>
                <Grid item xs={3}>
                  {i18n.t('INVOICE_LABEL_STATUS')}
                </Grid>
                <Grid item xs={9} sx={{ pl: 1.4 }}>
                  {invoiceStatusName}
                </Grid>
              </Grid>
            </CondensedAccordionSummary>
          </CondensedAccordion>
          <CondensedAccordion expanded={false}>
            <CondensedAccordionSummary
              sx={{ pl: 2 }}
              expandIcon={<></>}
            >
              <Grid container>
                <Grid item xs={3}>
                  {i18n.t('INVOICE_LABEL_TOTAL_AMOUNT_EX_TAX')}
                </Grid>
                <Grid item xs={4} sx={{ textAlign: 'right', pl: 1.5, pr:3 }}>
                  {formatCurrency(totalPriceExTax, currencySymbol)}
                </Grid>
              </Grid>
            </CondensedAccordionSummary>
          </CondensedAccordion>
          <CondensedAccordion expanded={false}>
            <CondensedAccordionSummary
              sx={{ pl: 2 }}
              expandIcon={<></>}
            >
              <Grid container>
                <Grid item xs={3}>
                  {i18n.t('INVOICE_LABEL_TOTAL_TAX')}
                </Grid>
                <Grid item xs={4} sx={{ textAlign: 'right', pl: 1.5, pr:3 }}>
                  {formatCurrency(totalTax, currencySymbol)}
                </Grid>
              </Grid>
            </CondensedAccordionSummary>
          </CondensedAccordion>
          <CondensedAccordion expanded={false}>
            <CondensedAccordionSummary
              sx={{ pl: 2 }}
              expandIcon={<></>}
            >
              <Grid container>
                <Grid item xs={3}>
                  {i18n.t('INVOICE_LABEL_TOTAL_AMOUNT_INC_TAX')}
                </Grid>
                <Grid item xs={4} sx={{ textAlign: 'right', pl: 1.5, pr:3 }}>
                  {formatCurrency(totalPriceIncTax, currencySymbol)}
                </Grid>
              </Grid>
            </CondensedAccordionSummary>
          </CondensedAccordion>
          {
            summaryInvoiceLines?.map((line, index) => {
              return <InvoiceSummaryLine key={index} data={line} invoiceId={invoiceid} lineNumber={index} currencySymbol={currencySymbol} />
            })
          }
        </div>
      </div>
    </>
  );
};

export { InvoiceDetail };

