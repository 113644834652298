import { useNavigate, useParams } from "react-router-dom";
import { useDirtyState } from "../../hooks/Common/dirty";
import { useFetchMemberDomains } from "../../hooks/Member/domains";
import { useFetchMemberById, } from "../../hooks/Member/member";
import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import ApiStatus from "../Common/Errors/ApiStatus";
import { SectionHead } from "../Common/Help/SectionHead";
import { DomainCollection } from "./DomainCollection";
import { CommonSummaryHeaderProps } from "../../types/Common/CommonSummaryHeaderProps";

type SourceType = 'companies' | 'operators'
type Args = {
    userLogged: ApplicationUser,
    source: SourceType
}

const HostDomains = ({ userLogged, source }: Args) => {

    const nav = useNavigate();
    const queryStringParams = useParams();
    const [isDirty, setDirty, unsetDirty] = useDirtyState();

    if (!queryStringParams.id) throw Error(`${source.toUpperCase()} id needed`);

    const handleDirty = () => {
        setDirty();
    };


    const { data: memberData, status: memberStatus, isSuccess: memberIsSuccess } = useFetchMemberById(queryStringParams.id!)
    const { data: domainList, status: domainListStatus, isSuccess: domainListSuccess, refetch: refetchDomainList } = useFetchMemberDomains(queryStringParams.id!);

    const afterSave = () => {
        refetchDomainList()
        nav(`/${source}/${queryStringParams.id}/summary`);
    }

    if ((!domainListSuccess || !memberIsSuccess) && (domainListStatus !== 'success') && (memberStatus !== 'success')) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <ApiStatus status={domainListStatus} />
                </div>
            </div>
        </>
    )



    if (!domainList || !memberData) return <ApiStatus status={domainListStatus} />;

    let headerProps: Record<SourceType, CommonSummaryHeaderProps> = {
        operators: {
            ctaText: i18n.t('COMMON_SUMMARY_OPERATORCONTACT')!,
            title: i18n.t('COMMON_SUMMARY_DOMAINS')!,
            description: i18n.t('COMMON_SUMMARY_OPERATORMANAGEMENT')!,
            name: `${memberData.fullName} (${memberData.externalReference})`,
        },
        companies: {
            ctaText: i18n.t('COMMON_SUMMARY_COMPANYSUMMARY')!,
            title: i18n.t('COMMON_SUMMARY_DOMAINS')!,
            description: i18n.t('COMMON_SUMMARY_COMPANYMANAGEMENT')!,
            name: `${memberData.fullName} (${memberData.externalReference})`,
        }
    }
    let headerData = headerProps[source]

    return (
        <>
            <SectionHead
                linkCallBack={()=> nav(`/${source}/${queryStringParams.id}/summary`)}
                ctaText={headerData.ctaText}
                name={headerData.ctaText!}
                title={headerData.title!}
                description={headerData.description!}
            />

            <div className="row d-flex justify-content-center mt-1 mb-3">
                <div className="col-md-7 col-11">
                    <DomainCollection
                        handleDirty={handleDirty}
                        items={domainList}
                        entityName="Member"
                        entityKey={queryStringParams.id!}
                        afterSave={afterSave}
                    />
                </div>
            </div>
        </>
    );
};


export { HostDomains };

