import axios, { AxiosError } from "axios";
import { HookConfig } from "../../config/HookConfig";
import { operatorDefaultHeaders } from "../../functions/api/api";
import { useQuery } from "react-query";
import { ApplicationGetByMemberResponseDto } from "../../types/ApplicationConfiguration/ApplicationGetByMemberResponseDto";
import { useEffect, useState } from "react";

const useFetchApplications = () => {
    return useQuery<ApplicationGetByMemberResponseDto[], AxiosError>(["applications-configurations"], () => {
        let url = `${HookConfig.memberMgmtUrl}/applications`;

        return axios.get(url, {
            withCredentials: true,
            headers: operatorDefaultHeaders()
        })
            .then((resp) => resp.data);
    });
}

const useFetchApplicationSummaryById = (applicationId: string) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!applicationId) return;

        const fetchData = async () => {
            setLoading(true);
            try {
                let url = `${HookConfig.memberMgmtUrl}/applications/${applicationId}/summary`;
                const response = await axios.get(url, {
                    withCredentials: true,
                    headers: operatorDefaultHeaders()
                });
                setData(response.data);
            } catch (err) {
                console.error('Error fetching data', err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [applicationId]);

    return { data, loading };
};

export {
    useFetchApplications,
    useFetchApplicationSummaryById
}