import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { SummaryItemCollection } from "../Common/SummaryItems/SummaryItemCollection";
import ApiStatus from "../Common/Errors/ApiStatus";
import { SummaryItemDto } from "../../types/Common/SummaryItemDto";
import i18n from "../../i18n";
import { useFetchLocationById} from "../../hooks/Location/location";
import { useFetchFeaturesByLocationId } from "../../hooks/Location/locationFeatures";
import { SectionHead } from "../Common/Help/SectionHead";

type Args =
    {
        userLogged: ApplicationUser
    }

const LocationFeatures = ({ userLogged }: Args) => {

    const nav = useNavigate();

    const queryStringParams = useParams();
    if (!queryStringParams.id) throw Error("Location id needed");



    const linkCallBackCollection = (item: SummaryItemDto) => {
        switch (item.name) {
            default:
                nav(`/locations/${queryStringParams.id}/features/${item.uri.substring(item.uri.indexOf('group=') + 6)}`);
                break;
        }
    }

    const linkCallBack = () => {
        nav(`/locations/${queryStringParams.id}/summary`);
    }


    const { data: location, status: locationStatus, isSuccess: locationIsSuccess } = useFetchLocationById(queryStringParams.id!)
    const { data, status, isSuccess } = useFetchFeaturesByLocationId(queryStringParams.id!);

    if ((!isSuccess || !locationIsSuccess) && (status !== 'success') && (locationStatus !== 'success')) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <ApiStatus status={status} />
                </div>
            </div>
        </>
    )

    if (!data || !location) return <ApiStatus status={status} />;

    return (
        <>

            <SectionHead linkCallBack={linkCallBack} ctaText={i18n.t("LOCATION_SUMMARY_LOCATIONMAINPAGE")!}
                name={`${location.name} (${location.externalReference})`}
                title={i18n.t('COMMON_SUMMARY_FEATURES')}
                description={i18n.t('COMMON_SUMMARY_LOCATIONMANAGEMENT')} />

            <div className="row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    {data &&
                        <SummaryItemCollection linkCallBack={linkCallBackCollection} items={data}></SummaryItemCollection>
                    }
                </div>
            </div>

        </>
    );
};


export { LocationFeatures }