import React from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import './NewOrder.css';
import { ApplicationUser } from '../../../types/Authentication/ApplicationUser';
import { SectionHead } from '../../Common/Help/SectionHead';
import i18n from '../../../i18n';

type NewOrderProps = {
    userLogged: ApplicationUser;
    onClick?: () => void;  
  };  

const NewOrder = ({userLogged}: NewOrderProps) => {
    const params = useParams<{ id?: string }>();
    const navigate = useNavigate();
  
    
    const goBack = () => {
        navigate(-1); 
      };
  
    return (

    <>
     <SectionHead 
        linkCallBack={goBack}
        ctaText={i18n.t('COMMON_SUMMARY_BOOKINGS')!}
        name={`Create New Order}`}
        title={i18n.t('Create New Order') || 'Create New Order'}
        description={i18n.t('Detailed view of the selected order') || 'Detailed view of the selected order'}
      />
    <div className='new-order-container'>
        <div className='new-order-inner-container'>
            <h1>Create New Order</h1>
        </div>
    </div>
    </>
  )
}

export { NewOrder }