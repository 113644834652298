import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { Collapse, IconButton, List, Paper, Tooltip } from '@mui/material';
import "./cards.css"
import { useFetchMembers } from '../../hooks/Member/member';
import ApiStatus from '../Common/Errors/ApiStatus';
import MessageAlert from '../Common/Errors/MessageAlert';
import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import i18n from '../../i18n';
import HelpIcon from '@mui/icons-material/Help';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { LR } from '../Common/Help/LR';
import { ApplicationUser } from '../../types/Authentication/ApplicationUser';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

type Args = {
  setHelp: (value: string) => void,
  userLogged: ApplicationUser
}

const LegalEntity = ({ setHelp, userLogged }: Args) => {
  const nav = useNavigate();
  const { data: members, status, isSuccess } = useFetchMembers(512, userLogged.operator!.id, 3);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isListVisible, setIsListVisible] = useState(false);

  const handleToggleList = () => {
    setIsListVisible(!isListVisible);
  };
  const [memberIds, setMemberIds] = useState<string[]>([]);

  useEffect(() => {
    if (isSuccess && members) {
      const ids = members.map((item) => item.id);
      setMemberIds(ids);
    }
  }, [isSuccess, members]);


  const editOperator = (operatorId: string) => {
    nav(`/legal-entities/${operatorId}/summary`);
  }

  if (!isSuccess) return (
    <>
      <div className="mt-5 row d-flex justify-content-center">
        <div className="col-md-7 col-11 justify-content-center">
          <ApiStatus status={status} />
        </div>
      </div>
    </>
  )



  if (!members) return <MessageAlert message="MEMBER_LABEL_MEMBERSNOTFOUND"></MessageAlert>;

  return (
    <Box sx={{ minWidth: 300, maxWidth: 300, minHeight: 400, maxHeight: 400 }}>
      <Card>
        <React.Fragment>
          {/* Header with Title and Arrow */}
          <CardContent className='DashBoardCard' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography className='cardTitle' gutterBottom>
              <LR localResource='COMMON_LABEL_LEGALENTITIES'></LR>
            </Typography>
            <IconButton className="fa-arrow" onClick={handleToggleList}>
              {!isListVisible && <ArrowForwardIosRoundedIcon />}
              {isListVisible && <ArrowForwardIosRoundedIcon className='fa-arrow-down' />}
            </IconButton>
          </CardContent>

          <div className="list-container" >
            {/* Initial Display */}
            {!isListVisible && (
              <div className='cardCounter legalEntityCardCounter'>
                <Typography variant="h1">{members.length}</Typography>
              </div>
            )}

            {/* List Container */}
            <Collapse in={isListVisible}>
              <List component="nav" aria-label="main mailbox folders">
                {members.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Paper key={index} elevation={3} className="fa-paper-card">
                        <div className="d-flex align-items-center">
                          <div className="fa-name fa-name-card flex-grow-1">
                            {item.fullName}
                          </div>
                          <div className="fa-icons">
                            <IconButton onClick={(event) => editOperator(item.id)}>
                              <ArrowForwardIosRoundedIcon className='fa-arrow-small' />
                            </IconButton>
                          </div>
                        </div>
                      </Paper>
                    </React.Fragment>
                  );
                })}
              </List>
            </Collapse>
          </div>

          {/* Card Actions */}
          <CardActions className='DashBoardCard'
            disableSpacing
            sx={{
              alignSelf: "stretch",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-start",
              p: 0,
            }}>
            <IconButton aria-label="share" className='btn plusButton mr-2' onClick={()=> nav("/legal-entities/add")}>
              <Tooltip title={i18n.t('COMMON_TITLE_NEWLEGALENTITY')}>
                <AddCircleRoundedIcon sx={{ color: '#00be95' }} />
              </Tooltip>
            </IconButton>
            <IconButton aria-label="share" onClick={()=> nav("/legal-entities")}>
              <Tooltip title={i18n.t('MEMBER_TITLE_SEARCHLEGALENTITIES')}>
                <SearchRoundedIcon />
              </Tooltip>
            </IconButton>
            <IconButton aria-label="share" onClick={(e) => setHelp('MEMBER_MESSAGE_NEWLEGALENTITY')}>
              <Tooltip title={i18n.t('COMMON_LABEL_HELP')}>
                <HelpIcon />
              </Tooltip>
            </IconButton>
          </CardActions>
        </React.Fragment>
      </Card>
    </Box>


  );

}

export { LegalEntity }
