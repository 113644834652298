import { IconButton, Tooltip } from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import i18n from "../../../i18n";
import { useState } from "react";
import { HelpDialog } from "./HelpDialog";
import { LR } from "./LR";

type Args = {
    message: string,
    helpMessage?: string | null,
    mandatory: boolean
    fullscreen?: boolean


};

const LabelAndHelp = ({ message, helpMessage, mandatory, fullscreen }: Args) => {

    const [helpOpen, setHelpOpen] = useState(false);
    const [helpReference, setHelpReference] = useState('');

    if (fullscreen === undefined)
        fullscreen = false;

    const handleOpen = () => {
        setHelpOpen(true);
    };

    const handleClose = () => {
        setHelpOpen(false);
    };

    const setHelp = (value: string) => {
        setHelpReference(value);
        handleOpen();
    }

    return (
        <>
            <HelpDialog contentReference={helpReference} showHelp={helpOpen} handleClose={handleClose} handleConfirm={handleOpen} ></HelpDialog>
            <div className="row d-flex justify-content-center" style={{ display: 'flex', alignItems: 'center' }}>
                <div className={`${(!fullscreen ? 'col-md-7 col-11': '')} justify-content-center`}>
                    <small className="form-text text-muted ml-2">
                        <LR localResource={message}></LR>
                        {mandatory && <>*</>}
                        {
                            helpMessage &&
                            <IconButton aria-label="share" onClick={(e) => setHelp(helpMessage)}>
                                <Tooltip title={i18n.t('COMMON_LABEL_HELP')}>
                                    <HelpIcon  style={{ fontSize: '0.9rem' }} />
                                </Tooltip>
                            </IconButton>
                        }

                    </small>
                </div>
            </div>

        </>
    );
}

export {
    LabelAndHelp
}