import axios, { AxiosError } from "axios";
import { useMutation, useQuery, UseQueryOptions } from "react-query";
import { HookConfig } from "../../config/HookConfig";
import { operatorDefaultHeaders } from "../../functions/api/api";

import { IndividualGetByIdResponseDto,IndividualSetByIdRequestDto } from "../../types/Member/IndividualDto";

type IndividualGetByIdArgs = {
  params: {
    individualId: string,
  },
  options?: UseQueryOptions<any, AxiosError>
}
export const useIndividualGetById = (args: IndividualGetByIdArgs) => {
  const { individualId } = args.params;
  return useQuery<IndividualGetByIdResponseDto, AxiosError>(['individuals', individualId], () =>
    axios.get(`${HookConfig.memberMgmtUrl}/individuals/${individualId}`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then(
      (response) => {
        return response.data;
      }
    ), {
    ...args?.options
  });
};

type IndividualSetByIdArgs = {
  params: {
    individualId: string,
  }
}

export const useIndividualSetById = (args: IndividualSetByIdArgs) => {
  const { individualId } = args.params;
  return useMutation<IndividualGetByIdResponseDto, AxiosError, IndividualSetByIdRequestDto>(
    (contactData) => axios.put(`${HookConfig.memberMgmtUrl}/individuals/${individualId}`, contactData,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    )
  );
};