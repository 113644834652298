import { Button, IconButton, Paper,  Typography, InputBase } from "@mui/material";
import { useState, useEffect } from "react";
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ".././Common/SummaryItems/SummaryItem.css"
import { InventoryDto } from "../../types/Inventory/InventoryDto";
import { useNavigate } from "react-router";
import SearchIcon from '@mui/icons-material/Search';
import i18n from "../../i18n";

type Args = {
  items: InventoryDto[]  
};

const InventoryItemCollection = ({ items }: Args) => {
  const nav = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredItems, setFilteredItems] = useState<InventoryDto[]>(items);
  
  useEffect(() => {
    if (searchTerm.trim() === '') {
      setFilteredItems(items);
    } else {
      setFilteredItems(items.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase())));
    }
  }, [items, searchTerm]);

  const handleArrowIconClick = (id: string) => {
    nav(`${id}`);
  };

  return (
    <>
       <Paper style={{ display: 'flex', alignItems: 'center', margin: '15px 0', padding: '5px 10px', border: '1px solid #ccc' }}>
        <InputBase
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder={i18n.t('INVENTORY_LABEL_SEARCH').toString()!}
          style={{ flexGrow: 1 }}
        />
        <SearchIcon color="action" />
      </Paper>
      
      {filteredItems.map((item, index) => (
        <Paper key={index} elevation={3} className="fa-paper ">
          <div className="d-flex align-items-center">
            <div className="fa-name flex-grow-1">              
              <Typography variant="h6">
                <Button type="button" onClick={() => handleArrowIconClick(item.id!)} className="linkbutton" key={item.id}>
                    {item.name} - <small>{i18n.t(item.availabilitySummary!)}</small>
                </Button>
              </Typography>
            </div>
            <div className="fa-icons">
              <IconButton onClick={() => handleArrowIconClick(item.id!)} className="fa-arrow">
                <ArrowForwardIosRoundedIcon />
              </IconButton>
            </div>
          </div>
        </Paper>
      ))}
    </>
  );
}

export {
    InventoryItemCollection
}
