import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Box, Checkbox, Grid, Switch } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { CompanyCreateRequestDto } from "../../types/Customer/CustomerDto";
import { SectionHead } from "../Common/Help/SectionHead";
import { CustomButton } from "../Common/UI/CustomButton";
import CustomCountryDropDown from "../Common/formComponents/CustomCountryDropDown";
import { Input } from "../Common/formComponents/Input";
import { colors } from "../../main/Theme";
import { Label } from "../Common/formComponents/Label";
import { useCreateCompany } from "../../hooks/Customer/useCustomer";
import { CustomSnackbar } from "../Common/UI/CustomSnackbar";




type Args = {
  userLogged: ApplicationUser
}

const CompanyForm = ({ userLogged }: Args) => {
  const { mutate: createCompany, isLoading } = useCreateCompany();

  const [snackbarContent, setSnackbarContent] = useState<any>()

  const schema = yup
    .object({
      fullName: yup
        .string()
        .required(i18n.t('VALIDATION_COMPANYNAME_REQUIRED').toString())
        .min(2, i18n.t('VALIDATION_COMPANYNAME_MIN_LENGTH').toString()),
      countryIso3Code: yup.string().required(i18n.t('VALIDATION_COUNTRY_REQUIRED').toString()),
      allowDuplicates: yup.boolean().required(),
      languageCode: yup.string().required()
    })

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<CompanyCreateRequestDto>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      fullName: '',
      countryIso3Code: '',
      allowDuplicates: false,
      languageCode: i18n.language,
    }
  })

  const nav = useNavigate();

  const homeCallBack = () => {
    nav(-1);
  }
  const onSubmit: SubmitHandler<CompanyCreateRequestDto> = (data) => {
    createCompany(data, {
      onSuccess: (response) => {
        nav(`/companies/${response.id}/summary`)
      },
      onError: (error) => {
        if (error.status === 409) {
          setSnackbarContent(error?.response?.data)
        }
      }
    });
  }

  useEffect(() => {
    reset({
      languageCode: i18n.language,
      allowDuplicates: false,
    })
  }, [i18n.language])


  return (
    <>
      <SectionHead linkCallBack={homeCallBack}
        ctaText={i18n.t('COMMON_SUMMARY_CUSTOMERS')!}
        name={i18n.t('LABEL_ADDCUSTOMER_COMPANY_PAGE_DESCRIPTION')}
        title={i18n.t('LABEL_ADDCUSTOMER_COMPANY_PAGE_TITLE')}
        description={i18n.t('COMMON_SUMMARY_CUSTOMERMANAGEMENT')} />

      <CustomSnackbar snackbarContent={snackbarContent} setSnackbarContent={setSnackbarContent} ></CustomSnackbar>

      <div className="row d-flex justify-content-center">
        <div className="col-md-7 col-11 justify-content-center">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="fullName"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder={i18n.t("LABEL_ADDCUSTOMER_COMPANYNAME").toString()}
                      labelProps={{ message: 'LABEL_ADDCUSTOMER_COMPANYNAME', mandatory: true }}
                      validationError={errors.fullName}
                      field={field}
                    ></Input>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="countryIso3Code"
                  control={control}
                  render={({ field }) => (
                    <CustomCountryDropDown
                      valueChanged={field.onChange}
                      inputCountry={field.value}
                      labelProps={{
                        message: 'COMMON_LABEL_COUNTRY',
                        mandatory: true
                      }}
                      validationError={errors.countryIso3Code}
                    />
                  )}
                />
              </Grid>

              <Controller
                name="languageCode"
                control={control}
                render={({ field }) => (
                  <input type="hidden" {...field} />
                )}
              ></Controller>

              <Grid item xs={12} md={4} mx={'auto'} mt={3}>
                <CustomButton variant="contained" fullWidth type="submit" loading={isLoading}>
                  {i18n.t('COMMON_LABEL_SAVE')}
                </CustomButton>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </>
  );
}

export { CompanyForm };
