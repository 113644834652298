import axios from "axios";
import { FlexApiResponse } from "../../types/FlexApiResponse";
import { operatorDefaultHeaders } from "../../functions/api/api";

const UseSendConfirmationCode = async function (id: string , callback:any) {

    const emptyFlexApiResponse: FlexApiResponse =
    {
        success : false,
        error: "",
    }

    axios.post(`/identity-management/management/generateOTP/`+ id, id,  {
        headers: operatorDefaultHeaders() 
       
    }).then(response => {        
        emptyFlexApiResponse.success = true
        callback(emptyFlexApiResponse);
    }).catch(err => {        
        emptyFlexApiResponse.success = false;
        emptyFlexApiResponse.error = (err.response.data.message ? err.response.data.message : err.response.data);
        callback(emptyFlexApiResponse);
    })
};

export{
    UseSendConfirmationCode
}