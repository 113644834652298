import React, { useState } from 'react'
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { LocationsCard } from '../Admin/LocationsCard';
import { LegalEntity } from '../Admin/LegalEntityCard';
import { ProductsCard } from '../Admin/ProductsCard';
import { OperatorsCard } from '../Admin/OperatorsCard';
import { HelpDialog } from '../Common/Help/HelpDialog';

type Args =
  {
    userLogged: ApplicationUser
  }


const Admin = ({ userLogged }: Args) => {
  const [helpOpen, setHelpOpen] = useState(false);
  const [helpReference, setHelpReference] = useState('');

  const handleOpen = () => {
    setHelpOpen(true);
  };

  const handleClose = () => {
    setHelpOpen(false);
  };

  const setHelp = (value: string) => {
    setHelpReference(value);
    handleOpen();
  }

  return (
    <>
      <HelpDialog contentReference={helpReference} showHelp={helpOpen} handleClose={handleClose} handleConfirm={handleOpen} ></HelpDialog>
      <div className="row d-flex justify-content-center">
        <div className="col-md-10 col-11 justify-content-center" style={{ marginTop: '5rem' }}>
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-6 col-sm-6 d-flex justify-content-center mb-3">
              <LocationsCard userLogged={userLogged} setHelp={setHelp} />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 d-flex justify-content-center mb-3">
              <LegalEntity userLogged={userLogged} setHelp={setHelp} />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 d-flex justify-content-center mb-3">
              <ProductsCard userLogged={userLogged} setHelp={setHelp} />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 d-flex justify-content-center mb-3">
              <OperatorsCard userLogged={userLogged} setHelp={setHelp} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { Admin };