import React, { useState } from 'react';
import { IconDisplayer } from '../../Common/Help/IconDisplayer';


// interface InviteProps {
//     inviteeId: number;
//     inviteeNumber: string;  // "Invitee 1"
//     status: string;         // "new"
//     name: string;           // "Simon Dowdell"
//     email: string;          // "simon@pont.work"
//     actions: {
//       onAdd: () => void;    // Function to handle adding an invitee
//       onCancel: () => void; // Function to handle cancellation of an invite
//     };
//   }

const Invite = () => {
  const [showMore, setShowMore] = useState(false);
  const toggleShowMore = () => setShowMore(!showMore);

  return (
    <>
      <div className='order-section-main-wrapper'>
        <div className='order-section-main'>
          <div className='order-section-left'>Invitee 1</div>
          <div className='order-section-group-right'>
            <div className='order-section-right'>new</div>
            <div className='order-section-arrow' onClick={toggleShowMore}>
              <IconDisplayer type={'COMMON_LABEL_DOWN_ARROW'} />
            </div>
          </div>
        </div>
        {showMore && (
          <div className={`order-section-additional ${showMore ? 'show' : ''}`}>
            <div className='order-section-additional-inner'>
              <div className='order-section-additional-children'>
                <div className='order-aditional-inner-options'>Room</div>
                <div className='order-aditional-inner-data'>test</div>
              </div>
              <div className='order-section-additional-children'>
                <div className='order-aditional-inner-options'>From Date</div>
                <div className='order-aditional-inner-data'>test</div>
              </div>
              <div className='order-section-additional-children'>
                <div className='children-options options'>Options</div>
                <div className='children-save save'>Add</div>
                <div className='children-delete delete'>Cancel</div>
            </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export { Invite };
