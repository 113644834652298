import React, { useState } from 'react'
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { HelpDialog } from '../Common/Help/HelpDialog';
import { EventsCard } from './Cards/EventsCard';
import { MembershipsCard } from './Cards/MembershipsCard';
import { SpacesCard } from './Cards/SpacesCard';


type Args =
  {
    userLogged: ApplicationUser
  }


const Inventory = ({ userLogged }: Args) => {
  const [helpOpen, setHelpOpen] = useState(false);
  const [helpReference, setHelpReference] = useState('');

  const handleOpen = () => {
    setHelpOpen(true);
  };

  const handleClose = () => {
    setHelpOpen(false);
  };

  const setHelp = (value: string) => {
    setHelpReference(value);
    handleOpen();
  }

  return (
    <>
      <HelpDialog contentReference={helpReference} showHelp={helpOpen} handleClose={handleClose} handleConfirm={handleOpen} ></HelpDialog>
      <div className="row d-flex justify-content-center mt-4">
        <div className="col-md-10 col-11 justify-content-center" style={{ margin: '5rem' }}>
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-6 col-sm-6 d-flex justify-content-center mb-3">
              <SpacesCard userLogged={userLogged} setHelp={setHelp} />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 d-flex justify-content-center mb-3">
              <MembershipsCard userLogged={userLogged} setHelp={setHelp} />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 d-flex justify-content-center mb-3">
              <EventsCard userLogged={userLogged} setHelp={setHelp} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { Inventory };