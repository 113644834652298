import React, { useContext, useEffect, useRef, useState } from 'react';
import { AddressDto } from '../../../types/Common/AddressDto';
import i18n from '../../../i18n';
import EditLocationAltRoundedIcon from '@mui/icons-material/EditLocationAltRounded';
import { IconButton, Tooltip } from '@mui/material';
import { GlobalStateContext } from '.././GlobalStateProvider';
import { LabelAndHelp } from '.././Help/LabelAndHelp';
import { LR } from '../Help/LR';


declare global {
  interface Window {
    clickToAddress: any;
  }
}

type Args = {
  originalAddress: AddressDto,
  country: string,
  token: string,
  callback: (address: AddressDto) => void
  handleDirty: () => void
}

const AddressForm = ({ originalAddress, country, token, callback, handleDirty }: Args) => {

    const addressPassed: AddressDto = {
        line1: '',
        countryIso3Code: '',
        languageCode: i18n.language,
        addressTypeName: 'Business',
        entityKey: '',
        entityName: 'Member',
        city: '',
        area: '',
        latitude:0,
        longitude:0
  };


  const globalState = useContext(GlobalStateContext);
  if (!globalState) {
    throw new Error('Used GlobalStateContext outside of GlobalStateProvider');
  }

  const { globalFetchifytExecuted, setGlobalFetchifytExecuted } = globalState;
  const [useShowFields, setuseShowFields] = useState<Boolean>(false);
  const [useAddressState, setuseAddressState] = useState<AddressDto>(addressPassed);
  const accessToken = token;
  const [scriptLoaded, setScriptLoaded] = useState(false);


  const clickToAddressRef = useRef(null);
  useEffect(() => {
    const scriptId = 'ccScript';

    if (window && document) {
      if (!scriptLoaded && !globalFetchifytExecuted?.clickToAddressInstance) {
        const script = document.createElement('script')
        const body = document.getElementsByTagName('body')[0]
        script.src = '/scripts/cc_c2a.min.js'
        script.id = scriptId;
        body.appendChild(script)
        script.onload = () => {
          //setScriptExecuted(true);
          setScriptLoaded(true);
        };
      }
    }
  }, [globalFetchifytExecuted, scriptLoaded]);

  const initiateClickToAddress = () => {

    if (window.clickToAddress && globalFetchifytExecuted?.clickToAddressInstance) {
      if (clickToAddressRef.current) {
        clickToAddressRef.current = null;
      }

      const searchElement = document.getElementById("search");
      searchElement!.setAttribute("cc_applied", "false");
      globalFetchifytExecuted.clickToAddressInstance.attach({
        search: 'search'
      },
        {
          onResultSelected: function (c2a: any, elements: any, address: any) {
            setuseAddressState({
              ...useAddressState,
              line1: address.line_1,
              line2: address.line_2,
              line3: address.line_3,
              zip: address.postal_code,
              city: address.locality,
              latitude: address.extra.geolocation.latitude,
              longitude: address.extra.geolocation.longitude,
              countryIso3Code: address.country.iso_3166_1_alpha_3,
              area: address.province_name
            }
            );
          }
        });
    }
    };

    // Abstract changing of lat/lon
    const Coordinates = {
        Latitude: "",
        Longitude: ""
    };

    const [longitude, setLongitude] = useState(getLongitude());
    const [latitude, setLatitude] = useState(getLatitude());

    function getLatitude() {
        return Coordinates.Latitude
    }

    function handleLatitudeChange(event: React.ChangeEvent<HTMLInputElement>) {
        setLatitude(event.target.value)
        var lat = parseFloat(event.target.value);
        setuseAddressState({ ...useAddressState, latitude: lat })
        handleDirty();
    }

    function getLongitude() {
        return Coordinates.Longitude
    }

    function handleLongitudeChange(event: React.ChangeEvent<HTMLInputElement>) {
        setLongitude(event.target.value)
        var lon = parseFloat(event.target.value);
        setuseAddressState({ ...useAddressState, longitude: lon })
        handleDirty();
    }


  useEffect(() => {
    if (scriptLoaded) {
      const cc = new window.clickToAddress({
        accessToken: token,
        domMode: 'id',
        defaultCountry: (originalAddress) ? originalAddress.countryIso3Code : country,
        countrySelector: true,
        getIpLocation: false,
        style: {
          ambient: 'dark',
        },
      })

        const lat = (originalAddress && originalAddress.latitude) ? originalAddress.latitude?.toString() : "";
        setLatitude(lat);

        const lon = (originalAddress && originalAddress.longitude) ? originalAddress.longitude?.toString() : "";
    setLongitude(lon);
    setGlobalFetchifytExecuted({ clickToAddressInstance: cc });
    setScriptLoaded(false);


    }

  }, [accessToken, originalAddress, country, token, setGlobalFetchifytExecuted, globalFetchifytExecuted, scriptLoaded, setScriptLoaded]);


  useEffect(() => {
    if (useAddressState) {
      if (!useAddressState.countryIso3Code) {
        useAddressState.countryIso3Code = country;
      }

      callback(useAddressState);
      if (useAddressState.line1 && !useShowFields) {
        setuseShowFields(true);
      }
    }

  }, [useAddressState, useShowFields]);





  useEffect(() => {
    if (globalFetchifytExecuted?.clickToAddressInstance && !useShowFields) {
      initiateClickToAddress();
    }
  }, [globalFetchifytExecuted]);



  useEffect(() => {
    if (originalAddress) {
      setuseAddressState(originalAddress);
      setuseShowFields(true);
    }

  }, [originalAddress, useShowFields]);

  return (
    <>
      {!useShowFields &&
        <div className="row d-flex justify-content-center mb-2">
          <div className="col-11 col-md-11 align-items-center">
            <div className="row d-flex justify-content-center">
              <div className="col-12 flex-column flex-md-row align-items-md-center">
            
                <input
                  type="text"
                  id="search"
                  className="form-control fa-form"
                  onChange={(e) => handleDirty()}
                  
                />
              </div>
              
            </div>
          </div>
          <div className="col-1 col-md-1 align-items-center">
            <IconButton
              className="fa-address"
              onClick={() => { setuseShowFields(true); handleDirty(); }}>
                <Tooltip title={i18n.t('COMMON_LABEL_ADDRESSAUTOSEARCH')}>
              <EditLocationAltRoundedIcon />
              </Tooltip>
            </IconButton>
          </div>
        </div>
      }
      {useShowFields &&
        <div>


        
<LabelAndHelp mandatory={true} fullscreen={true} message='COMMON_LABEL_ADDRESSLINE_1' ></LabelAndHelp>

          <div className="row d-flex justify-content-center  mb-2">
            <div className="justify-content-center">
            
              <input
                type="text"
                id="addr_line_1"
                placeholder={i18n.t('COMMON_LABEL_ADDRESSLINE_1')!}
                value={useAddressState.line1}
                className="form-control fa-form"
                onChange={(e) => { setuseAddressState({ ...useAddressState, line1: e.target.value }); handleDirty() }}
              ></input>
            </div>
          </div>

          <LabelAndHelp mandatory={false} fullscreen={true} message='COMMON_LABEL_ADDRESSLINE_2' ></LabelAndHelp>
          <div className="row d-flex justify-content-center  mb-2">
            <div className="justify-content-center">
           
              <input
                type="text"
                id="addr_line_2"
                placeholder={i18n.t('COMMON_LABEL_ADDRESSLINE_2')!}
                value={useAddressState.line2!}
                className="form-control fa-form"
                onChange={(e) => { setuseAddressState({ ...useAddressState, line2: e.target.value }); handleDirty(); }}
              ></input>
            </div>
          </div>

          <LabelAndHelp mandatory={false} fullscreen={true} message='COMMON_LABEL_ADDRESSLINE_3' ></LabelAndHelp>
          <div className="row d-flex justify-content-center  mb-2">
            <div className="justify-content-center">
            
              <input
                type="text"
                id="addr_line_3"
                placeholder={i18n.t('COMMON_LABEL_ADDRESSLINE_3')!}
                value={useAddressState.line3!}
                className="form-control fa-form"
                onChange={(e) => { setuseAddressState({ ...useAddressState, line3: e.target.value }); handleDirty(); }}
              ></input>
            </div>
          </div>

        
          <LabelAndHelp mandatory={true} fullscreen={true} message='COMMON_LABEL_POSTALCODE' ></LabelAndHelp>
          <div className="row d-flex justify-content-center  mb-2">
            <div className="justify-content-center">
           
              <input
                type="text"
                id="zipcode"
                placeholder={i18n.t('COMMON_LABEL_POSTALCODE')!}
                value={useAddressState.zip!}
                className="form-control fa-form"
                onChange={(e) => { setuseAddressState({ ...useAddressState, zip: e.target.value });; handleDirty() }}
              ></input>
            </div>
          </div>


          <LabelAndHelp mandatory={true} fullscreen={true} message='COMMON_LABEL_CITY' ></LabelAndHelp>
          <div className="row d-flex justify-content-center  mb-2">
            <div className="justify-content-center">
           
              <input
                type="text"
                id="town"
                value={useAddressState.city}
                placeholder={i18n.t('COMMON_LABEL_CITY')!}
                className="form-control fa-form"
                onChange={(e) => { setuseAddressState({ ...useAddressState, city: e.target.value });; handleDirty() }}
              ></input>
            </div>
          </div>

          <LabelAndHelp mandatory={false} fullscreen={true} message='COMMON_LABEL_AREA' ></LabelAndHelp>
          <div className="row d-flex justify-content-center  mb-2">
            <div className="justify-content-center">
             
              <input
                type="text"
                id="county"
                value={useAddressState.area}
                placeholder={i18n.t('COMMON_LABEL_AREA')!}
                className="form-control fa-form"
                onChange={(e) => { setuseAddressState({ ...useAddressState, area: e.target.value });; handleDirty() }}
              ></input>
            </div>
          </div>
          
          <div className="row d-flex justify-content-center  mb-2">
            <div className="col-md-6">
            <small className={`form-text text-muted ml-2`}>
                <LR localResource='COMMON_LABEL_LATITUDE'></LR>
              </small>
              <input
                type="number"
                id="latitude"
                min={-90}
                max={90}
                step={0.0000001}
                value={latitude}
                placeholder={i18n.t('COMMON_LABEL_LATITUDE')!}
                className="form-control fa-form"
                onChange={(e) => {
                    handleLatitudeChange(e);
                }}
              />
            </div>
            <div className="col-md-6">
            <small className={`form-text text-muted ml-2`}>
                <LR localResource='COMMON_LABEL_LONGITUDE'></LR>
              </small>
              <input
                type="number"
                id="longitude"
                min={-180}
                max={180}
                step={0.0000001}
                value={longitude}
                placeholder={i18n.t('COMMON_LABEL_LONGITUDE')!}
                className="form-control fa-form"
                onChange={(e) => {
                    handleLongitudeChange(e); 
                }}
              />
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default AddressForm;
