import React from 'react';
import { Navigate} from "react-router-dom";
import { ApplicationUser } from '../../types/Authentication/ApplicationUser';

type Args = {
  userLogged: ApplicationUser
};



const Home = ({userLogged} : Args) => {   

 

  return (
    <>
      <div className="row d-flex justify-content-center">
        <div className="col-5 d-flex justify-content-center">
          <h3>loading...</h3>
        </div>
      </div>     

      {
        userLogged.isLogged && <Navigate to="/dashboard"></Navigate>
      }
    </>

  );

}

export { Home };
