import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ApiStatus from '../../Common/Errors/ApiStatus';
import MessageAlert from '../../Common/Errors/MessageAlert';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import { useFetchLocations } from '../../../hooks/Location/location';
import { useFetchInventoryByLocationId } from '../../../hooks/Inventory/inventory';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import HelpIcon from '@mui/icons-material/Help';
import { LR } from '../../Common/Help/LR';
import { ApplicationUser } from '../../../types/Authentication/ApplicationUser';
import { Collapse, IconButton, List, Paper, Tooltip } from '@mui/material';
import i18n from '../../../i18n';

type Args = {
  userLogged: ApplicationUser;
  setHelp: (value: string) => void;
};

const SpacesCard = ({ userLogged, setHelp }: Args) => {
  const nav = useNavigate();
  const [isListVisible, setIsListVisible] = useState(false);

  const handleToggleList = () => {
    setIsListVisible(!isListVisible);
  };

  const { data: inventoryData, status: inventoryStatus, isSuccess: inventorySuccess } = useFetchInventoryByLocationId(
    userLogged.location?.id! ,
    userLogged.operator?.id!
  );

  const editInventory = (inventoryId: string | undefined) => {
    if (inventoryId && userLogged.location?.id!) {
      nav(`/locations/${userLogged.location?.id!}/inventories/${inventoryId}`);
    }
  };

  if (!inventorySuccess) return (
    <div className="mt-5 row d-flex justify-content-center">
      <div className="col-md-7 col-11 justify-content-center">
        <ApiStatus status={inventoryStatus} />
      </div>
    </div>
  );

  // if (!inventoryData || inventoryData.length === 0) return <MessageAlert message="MEMBER_LABEL_MEMBERSNOTFOUND"></MessageAlert>;

  return (
    <Box sx={{ minWidth: 300, maxWidth: 300, minHeight: 400, maxHeight: 400 }}>
      <Card>
        <React.Fragment>
          <CardContent className='DashBoardCard' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography className='cardTitle' gutterBottom>
              <LR localResource='COMMON_LABEL_SPACES'></LR>
            </Typography>
            <IconButton className="fa-arrow" onClick={handleToggleList}>
              {!isListVisible && <ArrowForwardIosRoundedIcon />}
              {isListVisible && <ArrowForwardIosRoundedIcon className='fa-arrow-down' />}
            </IconButton>
          </CardContent>

          <div className="list-container">
            {!isListVisible && (
              <div className='cardCounter locationCardCounter'>
                <Typography variant="h1">{inventoryData.length}</Typography>
              </div>
            )}

            <Collapse in={isListVisible}>
              {inventorySuccess && inventoryData && inventoryData.map((inventoryItem, index) => (
                <Paper key={index} elevation={3} className="fa-paper-card">
                  <div className="d-flex align-items-center">
                    <div className="fa-name flex-grow-1 fa-name-card">
                      {inventoryItem.name || `Inventory ${index + 1}`}
                    </div>
                    <div className="fa-icons">
                      <IconButton onClick={() => editInventory(inventoryItem.id)}>
                        <ArrowForwardIosRoundedIcon className='fa-arrow-small' />
                      </IconButton>
                    </div>
                  </div>
                </Paper>
              ))}
            </Collapse>
          </div>
          {/* Card Actions */}
          <CardActions className='DashBoardCard'
            disableSpacing
            sx={{
              alignSelf: "stretch",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-start",
              p: 0,
            }}>
            <IconButton aria-label="add" className='btn plusButton mr-2' onClick={() => nav(`/locations/${userLogged.location?.id}/inventories/add`)}>
              <Tooltip title={i18n.t('INVENTORY_TITLE_NEWINVENTORY')}>
                <AddCircleRoundedIcon sx={{ color: '#00be95' }} />
              </Tooltip>
            </IconButton>
            <IconButton aria-label="search" onClick={() => nav(`/locations/${userLogged.location?.id}/inventories`)}>
              <Tooltip title={i18n.t('INVENTORY_TITLE_SEARCHINVENTORIES')}>
                <SearchRoundedIcon />
              </Tooltip>
            </IconButton>
            <IconButton aria-label="help" onClick={(e) => setHelp('INVENTORY_MESSAGE_NEWINVENTORY')}>
              <Tooltip title={i18n.t('COMMON_LABEL_HELP')}>
                <HelpIcon />
              </Tooltip>
            </IconButton>
          </CardActions>
        </React.Fragment>
      </Card>
    </Box>
  );
};

export { SpacesCard };
