import { useState } from "react";
import Header from "../Navigation/HeaderLogin";
import './Login.css'
import { UserPasswordReset } from "../../types/Login/UserPasswordReset";
import { UserPasswordResetResponse } from "../../types/Login/UserPasswordResetResponse";
import { UseRequestPasswordReset, UseResetPassword } from "../../hooks/Login/reset";
import Otp from "../Common/Login/Otp";
import { ErrorLabel } from "../Common/Errors/ErrorLabel";
import i18n from "../../i18n";
import { LR } from "../Common/Help/LR";
import { RegistrationCompleted } from "./RegistrationCompleted";
import {isMobile} from 'react-device-detect';
import { ResetLinks } from "./subsections/ResetLinks";


function ResetPassword() {

    const emptyUserPasswordReset: UserPasswordReset =
    {
        id: "",
        confirmationCode: "",
        confirmPassword: "",
        password: "",
        passwordToken: "",
        error: ""
    }

    const emptyUserPasswordResetResponse: UserPasswordResetResponse =
    {
        id: "",
        email: "",
        isPasswordChanged: false,
        error: ""
    }

    const [submitting, setSubmitting] = useState(false);
    const [email, setEmail] = useState('');    
    const [userPasswordResetState, setuserPasswordResetState] = useState<UserPasswordReset>({ ...emptyUserPasswordReset });
    const [userPasswordResetResponseState, setuserPasswordResetResponseState] = useState<UserPasswordResetResponse>({ ...emptyUserPasswordResetResponse });

    const OTPCodeChange = (value: string) => {        
        setuserPasswordResetState({ ...userPasswordResetState,  confirmationCode : value });
    }

    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            setSubmitting(true);
            setuserPasswordResetState({ ...userPasswordResetState, error: "" });

            if (userPasswordResetState.confirmationCode) {
                UseResetPassword(userPasswordResetState, function (response: UserPasswordResetResponse) {
                    if (!response.isPasswordChanged) {
                        setuserPasswordResetState({
                            ...userPasswordResetState,
                            error: response.error
                        });

                    } else {
                        setuserPasswordResetResponseState({
                            ...userPasswordResetResponseState,
                            id: response.id,
                            isPasswordChanged: response.isPasswordChanged,
                            email: response.email
                        });
                    }

                    setSubmitting(false);
                });
            } else {

                UseRequestPasswordReset(email, function (response: UserPasswordReset) {
                    setuserPasswordResetState({
                        ...userPasswordResetState,
                        id: response.id,
                        error: response.error,
                        passwordToken: response.passwordToken
                    });

                    setSubmitting(false);
                });

            }
        } catch (err: any) {
            console.error(err)
        } 
    }

    return (
        <div className='container'>
        <div className="row d-flex justify-content-center">                        
            <div className={isMobile ? "col-12 justify-content-center logincontainer" : "col-4 justify-content-center center-block border border-white logincontainer"}>
            <Header />
            {!Boolean(userPasswordResetResponseState.isPasswordChanged) &&
                <form onSubmit={onFormSubmit}>
                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-10 d-flex justify-content-center">
                            <input
                                type="email"
                                className="form-control fa-form"
                                placeholder={i18n.t("COMMON_LABEL_EMAIL").toString()}
                                value={email}
                                onChange={e => setEmail(e.currentTarget.value)} required
                            ></input>
                        </div>
                    </div>

                    {Boolean(userPasswordResetState.id) &&
                        <div className="row d-flex justify-content-center mb-2">
                            <div className="col-10 d-flex justify-content-center">
                                <input
                                    type="password"
                                    className="form-control fa-form"
                                    placeholder={i18n.t("SECURITY_LABEL_PASSWORD").toString()}
                                    value={userPasswordResetState.password}
                                    onChange={(e) =>
                                        setuserPasswordResetState({ ...userPasswordResetState, password: e.target.value })
                                    }
                                    required
                                ></input>
                            </div>
                        </div>
                    }
                    {Boolean(userPasswordResetState.id) &&
                        <div className="row d-flex justify-content-center mb-2">
                            <div className="col-10 d-flex justify-content-center">
                                <input
                                    type="password"
                                    className="form-control fa-form"
                                    placeholder={i18n.t("SECURITY_LABEL_CONFIRMPASSWORD").toString()}
                                    value={userPasswordResetState.confirmPassword}
                                    onChange={(e) =>
                                        setuserPasswordResetState({ ...userPasswordResetState, confirmPassword: e.target.value })
                                    }
                                    required
                                ></input>
                            </div>
                        </div>
                    }

                    {Boolean(userPasswordResetState.id) &&
                        <Otp code={userPasswordResetState.confirmationCode} id={userPasswordResetState.id} valueChanged={OTPCodeChange} ></Otp>
                    }

                    <ErrorLabel message={userPasswordResetState.error} />

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-10 d-flex justify-content-center">
                            <button className="btn btn-green btn-primary btn-block" disabled={submitting} type="submit">
                                {Boolean(userPasswordResetState.id) && <LR localResource="SECURITY_BUTTON_CHANGEPASSWORD"/>}
                                {!Boolean(userPasswordResetState.id) && <LR localResource="SECURITY_BUTTON_RESETPASSWORD"/>}
                            </button>
                        </div>
                    </div>
                </form>
            }
            {
                Boolean(userPasswordResetResponseState.isPasswordChanged) && 
                <RegistrationCompleted value={userPasswordResetResponseState.email} type="RESET"/>
            }
            <ResetLinks></ResetLinks>
        </div>
        </div>
        </div>
    );
}

export default ResetPassword;
