import { createContext, ReactNode, useContext } from "react";
import { SourceEnum } from "../types/Common/CommonSummaryHeaderProps";
import { Outlet } from "react-router-dom";

interface SourceContextType {
  source: SourceEnum;
}

// Create the Context with a default value (optional)
const SourceContext = createContext<SourceContextType | undefined>(undefined);


export const SourceProvider = ({  source }: {  source: SourceEnum }) => {
  return (
    <SourceContext.Provider value={{ source }}>
      <Outlet />
    </SourceContext.Provider>
  );
};

// Custom hook for consuming the context
export const useSource = (): SourceContextType => {
  const context = useContext(SourceContext);
  if (!context) {
    throw new Error('useSource must be used within a SourceProvider');
  }
  return context;
};