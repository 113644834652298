import React from 'react';
import './Invoice.css';

interface InvoiceLocationProps {
  location: string;
  address: string;
}

const InvoiceLocation: React.FC<InvoiceLocationProps> = ({ location, address }) => {
  return (
    <div className='invoice-line-main'>
      <div className='invoice-left'>{location}</div>
      <div className='invoice-right'>{address}</div>
    </div>
  );
};

export { InvoiceLocation };
