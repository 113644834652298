import axios, { AxiosError } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { HookConfig } from "../../config/HookConfig";
import { ActivityDto } from "../../types/Activity/ActivityDto";
import { operatorDefaultHeaders } from "../../functions/api/api";
import { ActivitySearchResponseDto } from "../../types/Activity/ActivitySearchResponseDto";
import { SearchActivityRequestDto } from "../../types/Activity/SearchActivityRequestDto";
import { useEffect, useState } from "react";


const activities = require('../../data/activity/activities.json') as ActivityDto[];

const useSearchActivities = (locationId: string, startDate: string, endDate: string, searchQuery: string, pageSize: number, pageNumber: number) => {
  const [data, setData] = useState<ActivitySearchResponseDto>();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<'loading' | 'success' | 'error' | 'idle'>('loading');

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setStatus('loading');

      const searchParams: SearchActivityRequestDto = {
        searchQuery: searchQuery,
        pageNumber: pageNumber,
        pageSize: pageSize,
        locationId: locationId,
        searchDateStart: new Date(startDate),
        searchDateEnd: new Date(endDate), 
      };

      try {
        axios.get(`${HookConfig.operatorMgmtUrl}/activities`,
          {
            params: searchParams,
            withCredentials: true,
            headers: operatorDefaultHeaders()
          })
          .then((resp) => {
            setStatus('success');
            setData(resp.data)
          });

      } catch (err) {
        console.error('Error fetching data', err);
        setStatus('error');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [searchQuery, locationId, startDate, endDate, pageSize, pageNumber]);

  return { data, status, loading };
};

const useFetchActivityById = (id: string) => {
  return useQuery<ActivityDto, AxiosError>(["activity", id], async () => {
    const activity = activities.find(activity => activity.entityKey === id);
    if (!activity) throw new Error('Activity not found');
    return activity;
  });
}

const useCreateActivity = (callback: (activityId: string) => void) => {
  const queryClient = useQueryClient();
  return useMutation<{ data: ActivityDto }, AxiosError, ActivityDto>(
    (newActivity) => {
      activities.push(newActivity);
      return Promise.resolve({ data: newActivity });
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(["activities-summary"]);
        callback(response.data.entityKey);
      }
    }
  );
}

const useUpdateActivity = (activityId: string, callback: () => void) => {
  const queryClient = useQueryClient();
  return useMutation<{ data: ActivityDto }, AxiosError, ActivityDto>(
    (updatedActivity) => {
      const index = activities.findIndex(activity => activity.entityKey === activityId);
      if (index !== -1) {
        activities[index] = updatedActivity;
      }
      return Promise.resolve({ data: updatedActivity });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["activities-summary", activityId]);
        callback();
      }
    }
  );
}

export {
  useFetchActivityById,
  useCreateActivity,
  useUpdateActivity,
  useSearchActivities
};
