import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import ApiStatus from "../Common/Errors/ApiStatus";
import i18n from "../../i18n";
import { useFetchDigitalAssetsRequirements } from "../../hooks/Common/fileUpload";
import { getSummaryLabel } from "../../hooks/Common/labels";
import { FileuploadContainer } from "../Common/Upload/FileUploadContainer";
import { SectionHead } from "../Common/Help/SectionHead";
import { useFetchEventById } from "../../hooks/Events/event";

type Args =
    {
        userLogged: ApplicationUser
    }

const EventDigitalAssets = ({ userLogged }: Args) => {

    const nav = useNavigate();
    const queryStringParams = useParams();
    if (!queryStringParams.id) throw Error("Event id needed");
    if (!queryStringParams.groupName) throw Error("Digital Asset Type id needed");

    const titleLabel = getSummaryLabel(queryStringParams.groupName!);

    const linkCallBack = () => {
        nav(`/events/${queryStringParams.id}/summary`);
    }

    const { data: event, status: eventStatus, isSuccess: eventIsSuccess } = useFetchEventById(queryStringParams.id!)
    const { data, status, isSuccess } = useFetchDigitalAssetsRequirements('event', queryStringParams.id!, queryStringParams.groupName!);

    if ((!isSuccess || !eventIsSuccess) && (status !== 'success') && (eventStatus !== 'success')) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <ApiStatus status={eventStatus} />
                </div>
            </div>
        </>
    )

    if (!event || !data) return <ApiStatus status={status} />;

    return (
        <>
            <SectionHead linkCallBack={linkCallBack} ctaText={i18n.t("EVENT_SUMMARY_EVENTMAINPAGE")!}
                name={`${event.name} (${event.externalReference})`}
                title={i18n.t(titleLabel!)}
                description={i18n.t('COMMON_SUMMARY_EVENTMANAGEMENT')} />

            <FileuploadContainer items={data} groupname={queryStringParams.groupName!}></FileuploadContainer>
        </>
    );
};


export { EventDigitalAssets }