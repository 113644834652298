import { useNavigate } from "react-router-dom";
import { useFetchOrderSummaryConfig } from "../../hooks/Order/orderConfiguration";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { SummaryItemDto } from "../../types/Common/SummaryItemDto";
import { SummaryItemCollection } from "../Common/SummaryItems/SummaryItemCollection";
import { SectionHead } from "../Common/Help/SectionHead";
import i18n from "../../i18n";

type Args =
    {
        userLogged: ApplicationUser
    }

const OrderManagementSystem = ({ userLogged }: Args) => {
    const { data: summaries } = useFetchOrderSummaryConfig();
    const nav = useNavigate()
    const linkCallBackCollection = (item: SummaryItemDto) => {
        const linkCollection = {
            "MerchantAccountSummary": "/system/payment-methods",
            "ConnectedAccountSummary": "/connected-accounts",
            "PspEnrolmentRuleSummary": "/psp-enrolment-rules",
            "PayoutAgreementSummary": "/payout-agreements"
        }
        if (item.name in linkCollection) {
            nav(linkCollection[item.name as keyof typeof linkCollection])
        } else {
            alert(`Item ${item.name} does not have a link yet`);
        }
    }

    return (
        <>
            <SectionHead linkCallBack={() => nav('/')}
                ctaText={i18n.t('COMMON_SUMMARY_DASHBOARD')!}
                name={''}
                title={i18n.t('COMMON_SUMMARY_SYSTEM')}
                description={i18n.t('COMMON_SUMMARY_SYSTEMMANAGEMENT')} />
            <div className="row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    {
                        (summaries && summaries?.length > 0) ?
                            <SummaryItemCollection linkCallBack={linkCallBackCollection} hideIcon items={summaries}></SummaryItemCollection> : <></>
                    }

                </div>
            </div>
        </>
    );
};


export { OrderManagementSystem };

