import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDirtyState } from "../../hooks/Common/dirty";
import { useFetchDigitalAssetsRequirements } from "../../hooks/Common/fileUpload";
import { useFetchMembershipById } from "../../hooks/Memberships/membership";
import i18n from "../../i18n";
import { useSource } from "../../providers/SourceProvider";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { SourceEnum } from "../../types/Common/CommonSummaryHeaderProps";
import { ConfirmationChangesDialog } from "../Common/ConfirmationChangesDialog";
import ApiStatus from "../Common/Errors/ApiStatus";
import { SectionHead } from "../Common/Help/SectionHead";
import { FileuploadContainer } from "../Common/Upload/FileUploadContainer";

type Args = {
  userLogged: ApplicationUser,
  idToUse: string
}

export const DigitalAssetsHandler = (args: Args) => {
  const { idToUse } = args
  const nav = useNavigate();
  const { source } = useSource()

  const { groupName, ...rest } = useParams();
  const { id, inventoryId } = rest
  const memberId = rest[idToUse]

  if (!memberId) throw Error(`${idToUse} id needed`);
  if (!groupName) throw Error("Digital Asset Type id needed");

  const [isDirty, setDirty, unsetDirty] = useDirtyState();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [targetUrl, setTargetUrl] = useState('');


  const backLinkCollection: Partial<Record<SourceEnum, string>> = {
    [SourceEnum.locations]: `/${source}/${id}/inventories/${inventoryId}`,
  }

  const ctaCollection: Partial<Record<SourceEnum, string>> = {
    [SourceEnum.locations]: i18n.t("INVENTORY_SUMMARY").toString(),
  }

  const descriptionCollection: Partial<Record<SourceEnum, string>> = {
    [SourceEnum.locations]: i18n.t("COMMON_SUMMARY_LOCATIONMANAGEMENT").toString(),
  }

  const titleCollection: Partial<Record<SourceEnum, string>> = {
    [SourceEnum.locations]: i18n.t(`INVENTORY_${groupName.toUpperCase()}`).toString(),
  }

  const linkCallBack = () => {
    let url = backLinkCollection[source];
    if (!url) {
      alert(`Back link not found for ${source}`)
      return
    }

    if (isDirty) {
      setShowConfirmation(true);
      setTargetUrl(url);
    } else {
      nav(url);
    }
  };


  const { data: membership, status: membershipStatus, isSuccess: membershipIsSuccess } = useFetchMembershipById(memberId!)
  const { data, status, isSuccess } = useFetchDigitalAssetsRequirements('membership', memberId!, groupName!);

  if ((!isSuccess || !membershipIsSuccess) && (status !== 'success') && (membershipStatus !== 'success')) return (
    <div className="mt-5 row d-flex justify-content-center">
      <div className="col-md-7 col-11 justify-content-center">
        <ApiStatus status={membershipStatus} />
      </div>
    </div>
  )

  if (!membership || !data) return <ApiStatus status={status} />;

  return (
    <>
      <SectionHead
        linkCallBack={linkCallBack}
        ctaText={ctaCollection[source]!}
        name={`${membership.name} (${membership.externalReference})`}
        title={titleCollection[source]!}
        description={descriptionCollection[source]!} />

      <ConfirmationChangesDialog
        handleConfirm={() => {
          setShowConfirmation(false);
          unsetDirty();
          nav(targetUrl);
        }}
        handleCancel={() => setShowConfirmation(false)}
        showConfirmation={showConfirmation}
      />

      <FileuploadContainer
        items={data}
        groupname={groupName!}
        setDirty={setDirty}
        unsetDirty={unsetDirty}
      />
    </>
  );
}