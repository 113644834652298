import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import './Calendar.css';
import { CalendarFeed } from './CalendarFeed';

type Args = {
  userLogged: ApplicationUser;
};

const Calendar = ({ userLogged }: Args) => {
 
  return (
    <div className="main-calendar-container">
      <div className='col-md-10 col-11 justify-content-center'>
      <CalendarFeed userLogged={userLogged} />
      </div>
    </div>
  );
};

export { Calendar };
