import React from 'react';

type HeaderDisplayProps = {
    headers: string[];
};

const HeaderDisplay: React.FC<HeaderDisplayProps> = ({ headers }) => {
    return (
        <div className="customer-header">
            {headers.map((header, index) => (
                <div key={index} className={`customer-header-item header-${index}`}>
                    {header}
                </div>
            ))}
        </div>
    );
};

export default HeaderDisplay;
