import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { getLabelsByOperatorType, useFetchContactsByMemberId, useFetchMemberById } from "../../hooks/Member/member";
import ApiStatus from "../Common/Errors/ApiStatus";
import i18n from "../../i18n";
import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { LR } from "../Common/Help/LR";
import { useState } from "react";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { SectionHead } from "../Common/Help/SectionHead";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { pontCountries } from "../../data/en/pontCountries";
import { CircleFlag } from "react-circle-flags";
import { GetRowClassName } from "../../functions/utils/helper";
import { CustomButton } from "../Common/UI/CustomButton";

type Args =
    {
        userLogged: ApplicationUser,
        type?: 'company'
    }

const Contacts = ({ userLogged, type }: Args) => {
    const nav = useNavigate();
    const queryStringParams = useParams();
    const _type = type ? 'companies' : 'operators'
    const _title = type ? i18n.t('COMMON_SUMMARY_CONTACT-MANAGEMENT') : i18n.t('COMMON_SUMMARY_CONTACTS')
    const _addNewTitle = type ? 'MEMBER_TITLE_NEWCOMPANYCONTACT' : 'MEMBER_TITLE_NEWCONTACT'
    if (!queryStringParams.id) throw Error(`${_type} id needed`);

    const linkCallBack = () => {
        nav(`/${_type}/${queryStringParams.id}/summary`);
    }

    const addNewItem = () => {
        nav(`/${_type}/${queryStringParams.id}/contacts/add`);
    };


    const { data: operator, status: operatorStatus, isSuccess: operatorIsSuccess } = useFetchMemberById(queryStringParams.id!)
    const { data, status, isSuccess } = useFetchContactsByMemberId(queryStringParams.id!);
    const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
    const [deleteName, setDeleteName] = useState<string>('');
    const [deleteId, setDeleteId] = useState<string>('');


    const handleDelete = (name: string, id: string) => {
        setDeleteName(name);
        setDeleteId(id);
        setDeleteConfirmation(true);
    }

    const confirmDeleteContact = (id: string) => {
        setDeleteConfirmation(false);
    }

    if ((!isSuccess || !operatorIsSuccess) && (status !== 'success') && (operatorStatus !== 'success')) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <ApiStatus status={status} />
                </div>
            </div>
        </>
    )

    if (!data || !operator) return <ApiStatus status={status} />;

    const labels = getLabelsByOperatorType(operator);
    return (
        <>

            <SectionHead linkCallBack={linkCallBack} ctaText={i18n.t(labels!.cta)!}
                name={`${operator.fullName} (${operator.externalReference})`}
                title={_title}
                description={i18n.t(labels!.label)} />


            <div className="mt-1 mb-3 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <Tooltip title={i18n.t(_addNewTitle)}>
                        <CustomButton onClick={addNewItem} sx={{ textTransform: 'none' }} color="secondary">
                            <AddCircleRoundedIcon />
                            <Box component={"span"} sx={{ ml: 1 }}>
                                <LR localResource={_addNewTitle} />
                            </Box>
                        </CustomButton>
                    </Tooltip>
                </div>
            </div>

            <div className="row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <Card variant="outlined">
                        <DataGrid
                            rows={data}
                            columns={[
                                {
                                    field: 'countryIso3Code',
                                    headerName: i18n.t('COMMON_LABEL_COUNTRYSHORT')!,
                                    flex: 0.2,
                                    align: "center",
                                    renderCell: (params) => {
                                        const iso2Code = pontCountries.find((i) => i.Iso3Code === params.value)?.Iso2Code.toLowerCase();
                                        return <CircleFlag countryCode={iso2Code!} height="20" />
                                    },
                                },
                                { field: 'externalReference', headerName: i18n.t('COMMON_LABEL_EXTERNALREFERENCE')!, flex: 0.5 },
                                { field: 'fullName', headerName: i18n.t('LOCATION_LABEL_NAMESHORT')!, flex: 1 },

                                {
                                    field: 'id', headerName: '', flex: 0.1, align: "center",
                                    renderCell: (params) => {

                                        return <Tooltip title={i18n.t('COMMON_TOOLTIP_DETAILS')}>
                                            <IconButton className="fa-info" onClick={() => nav(`${params.value}`)}>
                                                <EditRoundedIcon sx={{ color: '#00be95' }} />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                },
                                {
                                    field: 'contactDetails', headerName: '', flex: 0.1, align: "center",
                                    renderCell: (params) => {
                                        const item = params.row;

                                        return <Tooltip title={i18n.t('COMMON_TOOLTIP_DELETE', { param0: item.fullName })}>
                                            <IconButton disabled={item.id === userLogged.memberId} onClick={(e) => handleDelete(item.fullName, item.id)}>
                                                <DeleteRoundedIcon color={(item.id === userLogged.memberId) ? 'disabled' : 'error'} />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                }


                            ]}
                            slots={{
                                toolbar: GridToolbar
                            }}
                            classes={{
                                toolbarContainer: 'toolbarContainer'
                            }}
                            getRowClassName={GetRowClassName}
                            checkboxSelection={false}
                            disableColumnSelector={true}
                            disableDensitySelector={true}
                        />
                    </Card>
                </div>
            </div>




            <Dialog open={deleteConfirmation} onClose={() => setDeleteConfirmation(false)}>
                <Card>
                    <CardContent>
                        <DialogTitle><LR localResource='COMMON_CONTACT_DELETECONFIRMATIONTITLE' /></DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <Typography variant="body1" color="textSecondary">
                                    <LR localResource='COMMON_BUTTON_DELETETEXT' params={{ name: deleteName }} />
                                </Typography>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button className='btn-green' onClick={() => setDeleteConfirmation(false)}>
                                <LR localResource='COMMON_BUTTON_CANCEL' />
                            </Button>
                            <Button className='btn-danger' onClick={() => { confirmDeleteContact(deleteId); }} color="error" autoFocus>
                                <LR localResource='COMMON_BUTTON_DELETE' />
                            </Button>
                        </DialogActions>
                    </CardContent>
                </Card>
            </Dialog>
        </>
    );
};


export { Contacts }