import { Button, IconButton, Paper, Typography, } from "@mui/material";
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { useNavigate } from "react-router";
import { ConfigurationDto } from "../../../types/Common/ConfigurationDto";
import { LR } from "../Help/LR";

type Args = {
  items: ConfigurationDto[]
  showGroup: boolean
};

const ConfigurationCollection = ({ items, showGroup }: Args) => {
  const nav = useNavigate();

  const handleArrowIconClick = (configurationName: string) => {
    nav(`${configurationName.toLocaleLowerCase()}`);
  };

  let lastGroupName = '';

  return (
    <>
      {items.map((item, index) => {
        const isNewGroup = item.groupName !== lastGroupName;
        lastGroupName = item.groupName;

        return (
          <div key={index}>
            {isNewGroup && showGroup && <Typography variant="h5" className="group-title"><LR localResource={item.groupName}></LR></Typography>}

            <Paper elevation={3} className="fa-paper">
              <div className="d-flex align-items-center">
                <div className="fa-name flex-grow-1">
                  <Typography variant="h6">
                    <Button type="button" onClick={() => handleArrowIconClick(item.url!)} className="linkbutton">
                      <LR localResource={item.name}></LR>
                    </Button>
                  </Typography>
                </div>
                <div className="fa-icons">
                  <IconButton onClick={() => handleArrowIconClick(item.url!)} className="fa-arrow">
                    <ArrowForwardIosRoundedIcon />
                  </IconButton>
                </div>
              </div>
            </Paper>
          </div>
        );
      })}
    </>
  );

}





export {
  ConfigurationCollection
}
