import { useFetchTicketDigitalAssetRequirements } from "../../hooks/Ticket/ticket";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import ApiStatus from "../Common/Errors/ApiStatus";
import FileUploader from "../Common/Upload/FileUploader";

type Args =
    {
        userLogged: ApplicationUser,
        ticketId: string
    }

const TicketAttachments = ({ ticketId }: Args) => {
    const { data: digitalAssetRequirements, loading } = useFetchTicketDigitalAssetRequirements(ticketId, 'files');

    const handleFilesCount = (key: string, value: number) => {

    };

    if (!digitalAssetRequirements || digitalAssetRequirements.length === 0 || loading) return <ApiStatus status={'loading'} />;

    return (
        <>
            <FileUploader minimalUpload={false} setFilesCount={handleFilesCount} defaultOpen={true} groupName={'files'} key={'COMMON_SUMMARY_FILES'} item={digitalAssetRequirements[0]} version="1" ></FileUploader>
        </>
    );
};


export { TicketAttachments }