const isImageFile = (fileName: string): boolean => {
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp']; // Add more image extensions if needed
  
    const fileExtension = fileName.substring(fileName.lastIndexOf('.')).toLowerCase();
  
    return imageExtensions.some((extension) => fileExtension.endsWith(extension));
  };


  export {
    isImageFile
  }