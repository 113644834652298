import { UnitDto } from "../../types/Common/UnitDto";

const pontUnits: UnitDto[] =
    [
        {
            "id": 5,
            "unitGroupName": "Area",
            "unitName": "SqMeter",
            "displayOrder": 1
        },
        {
            "id": 6,
            "unitGroupName": "Area",
            "unitName": "SqFeet",
            "displayOrder": 2
        },
        {
            "id": 31,
            "unitGroupName": "BookingTimespan",
            "unitName": "Minute",
            "displayOrder": 1
        },
        {
            "id": 32,
            "unitGroupName": "BookingTimespan",
            "unitName": "Hour",
            "displayOrder": 2
        },
        {
            "id": 33,
            "unitGroupName": "BookingTimespan",
            "unitName": "HalfDay",
            "displayOrder": 3
        },
        {
            "id": 34,
            "unitGroupName": "BookingTimespan",
            "unitName": "Day",
            "displayOrder": 4
        },
        {
            "id": 35,
            "unitGroupName": "BookingTimespan",
            "unitName": "Week",
            "displayOrder": 5
        },
        {
            "id": 36,
            "unitGroupName": "BookingTimespan",
            "unitName": "Month",
            "displayOrder": 6
        },
        {
            "id": 37,
            "unitGroupName": "BookingTimespan",
            "unitName": "Month3",
            "displayOrder": 7
        },
        {
            "id": 38,
            "unitGroupName": "BookingTimespan",
            "unitName": "Month6",
            "displayOrder": 8
        },
        {
            "id": 39,
            "unitGroupName": "BookingTimespan",
            "unitName": "Month12",
            "displayOrder": 9
        },
        {
            "id": 40,
            "unitGroupName": "BookingTimespan",
            "unitName": "Month18",
            "displayOrder": 10
        },
        {
            "id": 41,
            "unitGroupName": "BookingTimespan",
            "unitName": "Month24",
            "displayOrder": 11
        },
        {
            "id": 64,
            "unitGroupName": "BookingTimespan",
            "unitName": "DeskPerDay",
            "displayOrder": 12
        },
        {
            "id": 65,
            "unitGroupName": "BookingTimespan",
            "unitName": "DeskPerMonth",
            "displayOrder": 13
        },
        {
            "id": 66,
            "unitGroupName": "BookingTimespan",
            "unitName": "SqMeterPerMonth",
            "displayOrder": 14
        },
        {
            "id": 67,
            "unitGroupName": "BookingTimespan",
            "unitName": "SqFeetPerMonth",
            "displayOrder": 15
        },
        {
            "id": 76,
            "unitGroupName": "DayTimespan",
            "unitName": "Day",
            "displayOrder": 1
        },
        {
            "id": 59,
            "unitGroupName": "Event",
            "unitName": "Ticket",
            "displayOrder": 1
        },
        {
            "id": 57,
            "unitGroupName": "Imperial",
            "unitName": "Feet",
            "displayOrder": 1
        },
        {
            "id": 58,
            "unitGroupName": "Imperial",
            "unitName": "SqFeet",
            "displayOrder": 2
        },
        {
            "id": 2,
            "unitGroupName": "Item",
            "unitName": "Item",
            "displayOrder": 1
        },
        {
            "id": 7,
            "unitGroupName": "Length",
            "unitName": "Mile",
            "displayOrder": 5
        },
        {
            "id": 8,
            "unitGroupName": "Length",
            "unitName": "Meter",
            "displayOrder": 2
        },
        {
            "id": 9,
            "unitGroupName": "Length",
            "unitName": "Kilometer",
            "displayOrder": 3
        },
        {
            "id": 10,
            "unitGroupName": "Length",
            "unitName": "Centimeter",
            "displayOrder": 1
        },
        {
            "id": 11,
            "unitGroupName": "Length",
            "unitName": "Feet",
            "displayOrder": 4
        },
        {
            "id": 42,
            "unitGroupName": "LongTermTimespan",
            "unitName": "Month",
            "displayOrder": 1
        },
        {
            "id": 43,
            "unitGroupName": "LongTermTimespan",
            "unitName": "Month3",
            "displayOrder": 2
        },
        {
            "id": 44,
            "unitGroupName": "LongTermTimespan",
            "unitName": "Month6",
            "displayOrder": 3
        },
        {
            "id": 45,
            "unitGroupName": "LongTermTimespan",
            "unitName": "Month12",
            "displayOrder": 4
        },
        {
            "id": 46,
            "unitGroupName": "LongTermTimespan",
            "unitName": "Month18",
            "displayOrder": 5
        },
        {
            "id": 47,
            "unitGroupName": "LongTermTimespan",
            "unitName": "Month24",
            "displayOrder": 6
        },
        {
            "id": 60,
            "unitGroupName": "LongTermTimespan",
            "unitName": "DeskPerDay",
            "displayOrder": 12
        },
        {
            "id": 61,
            "unitGroupName": "LongTermTimespan",
            "unitName": "DeskPerMonth",
            "displayOrder": 13
        },
        {
            "id": 62,
            "unitGroupName": "LongTermTimespan",
            "unitName": "SqMeterPerMonth",
            "displayOrder": 14
        },
        {
            "id": 63,
            "unitGroupName": "LongTermTimespan",
            "unitName": "SqFeetPerMonth",
            "displayOrder": 15
        },
        {
            "id": 68,
            "unitGroupName": "MembershipTimespan",
            "unitName": "Day",
            "displayOrder": 4
        },
        {
            "id": 69,
            "unitGroupName": "MembershipTimespan",
            "unitName": "Week",
            "displayOrder": 5
        },
        {
            "id": 70,
            "unitGroupName": "MembershipTimespan",
            "unitName": "Month",
            "displayOrder": 6
        },
        {
            "id": 71,
            "unitGroupName": "MembershipTimespan",
            "unitName": "Month3",
            "displayOrder": 7
        },
        {
            "id": 72,
            "unitGroupName": "MembershipTimespan",
            "unitName": "Month6",
            "displayOrder": 8
        },
        {
            "id": 73,
            "unitGroupName": "MembershipTimespan",
            "unitName": "Month12",
            "displayOrder": 9
        },
        {
            "id": 74,
            "unitGroupName": "MembershipTimespan",
            "unitName": "Month18",
            "displayOrder": 10
        },
        {
            "id": 75,
            "unitGroupName": "MembershipTimespan",
            "unitName": "Month24",
            "displayOrder": 11
        },
        {
            "id": 53,
            "unitGroupName": "Metric",
            "unitName": "SqMeter",
            "displayOrder": 4
        },
        {
            "id": 54,
            "unitGroupName": "Metric",
            "unitName": "Centimeter",
            "displayOrder": 1
        },
        {
            "id": 55,
            "unitGroupName": "Metric",
            "unitName": "Meter",
            "displayOrder": 2
        },
        {
            "id": 56,
            "unitGroupName": "Metric",
            "unitName": "Kilometer",
            "displayOrder": 3
        },
        {
            "id": 1,
            "unitGroupName": "Money",
            "unitName": "Money",
            "displayOrder": 1
        },
        {
            "id": 3,
            "unitGroupName": "Page",
            "unitName": "Page",
            "displayOrder": 1
        },
        {
            "id": 4,
            "unitGroupName": "Person",
            "unitName": "Person",
            "displayOrder": 1
        },
        {
            "id": 80,
            "unitGroupName": "SecurityDeposit",
            "unitName": "Week",
            "displayOrder": 1
        },
        {
            "id": 81,
            "unitGroupName": "SecurityDeposit",
            "unitName": "Month",
            "displayOrder": 2
        },
        {
            "id": 82,
            "unitGroupName": "SecurityDeposit",
            "unitName": "Month3",
            "displayOrder": 3
        },
        {
            "id": 83,
            "unitGroupName": "SecurityDeposit",
            "unitName": "Month6",
            "displayOrder": 4
        },
        {
            "id": 84,
            "unitGroupName": "SecurityDeposit",
            "unitName": "Month12",
            "displayOrder": 5
        },
        {
            "id": 85,
            "unitGroupName": "SecurityDeposit",
            "unitName": "Money",
            "displayOrder": 6
        },
        {
            "id": 48,
            "unitGroupName": "ShortTermTimespan",
            "unitName": "Minute",
            "displayOrder": 1
        },
        {
            "id": 49,
            "unitGroupName": "ShortTermTimespan",
            "unitName": "Hour",
            "displayOrder": 2
        },
        {
            "id": 50,
            "unitGroupName": "ShortTermTimespan",
            "unitName": "HalfDay",
            "displayOrder": 3
        },
        {
            "id": 51,
            "unitGroupName": "ShortTermTimespan",
            "unitName": "Day",
            "displayOrder": 4
        },
        {
            "id": 52,
            "unitGroupName": "ShortTermTimespan",
            "unitName": "Week",
            "displayOrder": 5
        },
        {
            "id": 79,
            "unitGroupName": "Space",
            "unitName": "Space",
            "displayOrder": 1
        },
        {
            "id": 77,
            "unitGroupName": "SubscriptionTimespan",
            "unitName": "Month",
            "displayOrder": 1
        },
        {
            "id": 78,
            "unitGroupName": "SubscriptionTimespan",
            "unitName": "Month12",
            "displayOrder": 2
        },
        {
            "id": 12,
            "unitGroupName": "Time",
            "unitName": "Second",
            "displayOrder": 1
        },
        {
            "id": 13,
            "unitGroupName": "Time",
            "unitName": "Minute",
            "displayOrder": 2
        },
        {
            "id": 14,
            "unitGroupName": "Time",
            "unitName": "Hour",
            "displayOrder": 3
        },
        {
            "id": 15,
            "unitGroupName": "Time",
            "unitName": "Day",
            "displayOrder": 4
        },
        {
            "id": 16,
            "unitGroupName": "Time",
            "unitName": "Week",
            "displayOrder": 5
        },
        {
            "id": 17,
            "unitGroupName": "Time",
            "unitName": "Year",
            "displayOrder": 6
        },
        {
            "id": 18,
            "unitGroupName": "TimeSpan",
            "unitName": "Second",
            "displayOrder": 1
        },
        {
            "id": 19,
            "unitGroupName": "TimeSpan",
            "unitName": "Minute",
            "displayOrder": 2
        },
        {
            "id": 20,
            "unitGroupName": "TimeSpan",
            "unitName": "Hour",
            "displayOrder": 3
        },
        {
            "id": 21,
            "unitGroupName": "TimeSpan",
            "unitName": "HalfDay",
            "displayOrder": 4
        },
        {
            "id": 22,
            "unitGroupName": "TimeSpan",
            "unitName": "Day",
            "displayOrder": 5
        },
        {
            "id": 23,
            "unitGroupName": "TimeSpan",
            "unitName": "Week",
            "displayOrder": 6
        },
        {
            "id": 24,
            "unitGroupName": "TimeSpan",
            "unitName": "Month",
            "displayOrder": 7
        },
        {
            "id": 25,
            "unitGroupName": "TimeSpan",
            "unitName": "Month3",
            "displayOrder": 8
        },
        {
            "id": 26,
            "unitGroupName": "TimeSpan",
            "unitName": "Month6",
            "displayOrder": 9
        },
        {
            "id": 27,
            "unitGroupName": "TimeSpan",
            "unitName": "Month12",
            "displayOrder": 10
        },
        {
            "id": 28,
            "unitGroupName": "TimeSpan",
            "unitName": "Month18",
            "displayOrder": 11
        },
        {
            "id": 29,
            "unitGroupName": "TimeSpan",
            "unitName": "Month24",
            "displayOrder": 12
        },
        {
            "id": 30,
            "unitGroupName": "TimeSpan",
            "unitName": "Year",
            "displayOrder": 13
        },
        {
            "id": 86,
            "unitGroupName": "WeekMonthTimespan",
            "unitName": "Day",
            "displayOrder": 1
        },
        {
            "id": 87,
            "unitGroupName": "WeekMonthTimespan",
            "unitName": "Week",
            "displayOrder": 2
        },
        {
            "id": 88,
            "unitGroupName": "WeekMonthTimespan",
            "unitName": "Month",
            "displayOrder": 3
        },
        {
            "id": 89,
            "unitGroupName": "WeekMonthTimespan",
            "unitName": "Month3",
            "displayOrder": 4
        },
        {
            "id": 90,
            "unitGroupName": "WeekMonthTimespan",
            "unitName": "Month6",
            "displayOrder": 5
        },
        {
            "id": 91,
            "unitGroupName": "WeekMonthTimespan",
            "unitName": "Month12",
            "displayOrder": 6
        },
        {
            "id": 92,
            "unitGroupName": "WeekMonthTimespan",
            "unitName": "Month18",
            "displayOrder": 7
        },
        {
            "id": 93,
            "unitGroupName": "WeekMonthTimespan",
            "unitName": "Month24",
            "displayOrder": 8
        }
    ]

export {
    pontUnits
}