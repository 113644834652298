import { useCallback, useState } from "react";
import OTPInput from "react-otp-input";
import { FlexApiResponse } from "../../../types/FlexApiResponse";
import { UseSendConfirmationCode } from "../../../hooks/Common/otp";
import { LR } from "../Help/LR";
import MessageAlert from "../Errors/MessageAlert";
import { ErrorLabel } from "../Errors/ErrorLabel";


type Args = {
    id: string
    code: string;
    valueChanged: (value: string) => void;
};

const Otp = ({ id, code, valueChanged }: Args) => {

    const emptyFlexApiResponse: FlexApiResponse =
    {
        success: false,
        error: ""
    }

    const mychange = (value: string) => {
        valueChanged(value);
    }

    const [confirmationCode, setConfirmationCode] = useState(emptyFlexApiResponse);

    const sendConfirmationCode = useCallback(async () => {

        UseSendConfirmationCode(id, function (response: FlexApiResponse) {
            try {
                if (response.error) {
                    setConfirmationCode({
                        ...confirmationCode,
                        error: response.error,
                        success: false
                    });
                } else {
                    setConfirmationCode({
                        ...confirmationCode,
                        success: true
                    });
                }

            }
            catch (err: any) {
                console.error(err)
            }

        });
    }, [confirmationCode, id]);

    return (
        <>
            <div>
                <div className="row d-flex justify-content-center mb-2">
                    <div className="col-10 d-flex justify-content-center">
                       <h5> <LR localResource="SECURITY_TITLE_VERIFICATIONCODE"></LR></h5>
                    </div>
                    <div className="col-10 d-flex justify-content-center small mb-3">                        
                        <LR localResource="SECURITY_LABEL_VERIFICATIONCODE"></LR>                        
                    </div>
                    <div className="col-8 d-flex justify-content-center">

                        <OTPInput
                            value={code}
                            onChange={(e) => mychange(e)}
                            numInputs={5}                                                                                                                
                            renderSeparator={<span>&nbsp;-&nbsp;</span>}
                            inputStyle="otp-input"
                            renderInput={(props) => <input {...props} />}
                        />
                    </div>

                </div>
                <div className="row d-flex justify-content-center mb-2">
                    <div className="col-10 d-flex justify-content-center">

                        <button type="button"
                            className="btn btn-link newcodeLink btn-block"
                            onClick={sendConfirmationCode}>
                           <LR localResource="SECURITY_BUTTON_REQUESTNEWCODE" />
                        </button>

                    </div>
                </div>

                {Boolean(confirmationCode.success) &&
                   <MessageAlert message="SECURITY_LABELNEWCODESENT" timeout={2000} variant="success"></MessageAlert>
                }

                <ErrorLabel message={confirmationCode.error} />
            </div>
        </>
    );
};

export default Otp;