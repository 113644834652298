import React from 'react';


interface OrderCommentsProps {
  label: string;
  info: string;
  comment: string;
}

const OrderComments: React.FC<OrderCommentsProps> = ({ label, info, comment }) => {
  
  return (
    <>
      <div className='order-comment-main-wrapper'>
        <div className='order-comment-main'>
            <div className='order-comment-left'>{label}</div>
            <div className='order-comment-mid'>{info}</div>
            <div className='order-comment-right'>{comment}</div>
        </div>
      </div>
    </>
  );
};

export { OrderComments };
