import React, { useState, useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import ActivityTable from './ActivityTable';
import './ActivityTable.css';
import { ApplicationUser } from '../../types/Authentication/ApplicationUser';
import { useSearchActivities } from '../../hooks/Activity/activity';
import ApiStatus from '../Common/Errors/ApiStatus';
import MessageAlert from '../Common/Errors/MessageAlert';
import { ActivityDto } from '../../types/Activity/ActivityDto';
import { useNavigate } from 'react-router-dom';

type ActivityFeedProps = {
  userLogged: ApplicationUser;
  searchQuery: string;
  fromDate: string;
  toDate: string;
};

const ActivityFeed = ({ userLogged, searchQuery, fromDate, toDate }: ActivityFeedProps) => {
  const [page, setPage] = useState(1);
  const itemsPerPage = 5;

  const { data: activities, status, loading } = useSearchActivities(userLogged.location?.id!, fromDate, toDate, searchQuery, itemsPerPage, page);

  useEffect(() => {
    // Reset to the first page when search query or date range changes
    setPage(1);
  }, [searchQuery, fromDate, toDate]);

  const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  const nav = useNavigate();

  const navigateActivity = (activity: ActivityDto) => {
    let url = '';
    switch(activity.entityName)
    {
      case 'Location':
        url = `/locations/${activity.entityKey}/summary`;
        break;
      case 'Ticket':
        url = `/tickets/${activity.entityKey}`;
        break;
      case 'Order':
        url = `/orders/${activity.entityKey}/view-order`;
        break;
      case 'Membership':
        url = `/memberships/${activity.entityKey}/summary`;
        break;
      case 'Event':
        url = `/events/${activity.entityKey}/summary`;
        break;
      case 'Product':
        url = `/products/${activity.entityKey}`;
        break;
    }
    nav(url);
  }

  const paginatedActivities = activities ? activities.records : [];

  if (status !== 'success') {
    return (
      <div className="mt-5 row d-flex justify-content-center">
        <div className="col-md-7 col-11 justify-content-center">
          <ApiStatus status={status} />
        </div>
      </div>
    );
  }

  if (!activities || activities.records?.length === 0) {
    return <MessageAlert message="No activities found." />;
  }

  return (
    <div className="activity-feed">
      <ActivityTable
        activities={paginatedActivities!}
        onActionClick={(activity) => navigateActivity(activity)}
        detailsLabels={[
          { label: 'Name', key: 'entityName' },
          { label: 'Activity Type', key: 'activityTypeName' },
          { label: 'Urgency', key: 'urgencyTypeName' },
          { label: 'Active Date', key: 'activeDate' },
          { label: 'Expiry Date', key: 'expiryDate' },
          { label: 'Description', key: 'description' },
        ]}
      />
      <div className="pagination-bar">
        <Pagination
          count={Math.ceil((activities.totalRecords || 0) / itemsPerPage)}
          page={page}
          onChange={handleChangePage}
          color="primary"
        />
      </div>
    </div>
  );
};

export default ActivityFeed;
