const pontLanguages = [
  { code: 'af', name: 'Afrikaans' },
  { code: 'am', name: 'Amharic' },
  { code: 'ar', name: 'Arabic' },
  { code: 'as', name: 'Assamese' },
  { code: 'az', name: 'Azerbaijani' },
  { code: 'ba', name: 'Bashkir' },
  { code: 'bg', name: 'Bulgarian' },
  { code: 'bn', name: 'Bengali' },
  { code: 'bo', name: 'Tibetan' },
  { code: 'bs', name: 'Bosnian' },
  { code: 'ca', name: 'Catalan;Valencian' },
  { code: 'cs', name: 'Czech' },
  { code: 'cy', name: 'Welsh' },
  { code: 'da', name: 'Danish' },
  { code: 'de', name: 'German' },
  { code: 'de-AT', name: 'German(Austria)' },
  { code: 'de-CH', name: 'German(Switzerland)' },
  { code: 'de-DE', name: 'German(Germany)' },
  { code: 'el', name: 'Greek' },
  { code: 'en', name: 'English' },
  { code: 'en-GB', name: 'English(UnitedKingdomofGreatBritainandNorthernIreland)' },
  { code: 'en-NZ', name: 'English(NewZealand)' },
  { code: 'en-US', name: 'English(UnitedStatesofAmerica)' },
  { code: 'en-AU', name: 'English(Australia)' },
  { code: 'es', name: 'Spanish;Castilian' },
  { code: 'et', name: 'Estonian' },
  { code: 'fa', name: 'Persian' },
  { code: 'fi', name: 'Finnish' },
  { code: 'fr', name: 'French' },
  { code: 'ga', name: 'Irish' },
  { code: 'gl', name: 'Galician' },
  { code: 'he', name: 'Hebrew' },
  { code: 'hi', name: 'Hindi' },
  { code: 'hr', name: 'Croatian' },
  { code: 'hy', name: 'Armenian' },
  { code: 'id', name: 'Indonesian' },
  { code: 'is', name: 'Icelandic' },
  { code: 'it', name: 'Italian' },
  { code: 'ja', name: 'Japanese' },
  { code: 'ka', name: 'Georgian' },
  { code: 'kk', name: 'Kazakh' },
  { code: 'km', name: 'CentralKhmer' },
  { code: 'ko', name: 'Korean' },
  { code: 'ku', name: 'Kurdish' },
  { code: 'nb', name: 'Norwegian' },
  { code: 'nl', name: 'Dutch;Flemish' },
  { code: 'pl', name: 'Polish' },
  { code: 'pt', name: 'Portuguese' },
  { code: 'ro', name: 'Romanian;Moldavian;Moldovan' },
  { code: 'ru', name: 'Russian' },
  { code: 'sk', name: 'Slovak' },
  { code: 'sl', name: 'Slovenian' },
  { code: 'sm', name: 'Samoan' },
  { code: 'so', name: 'Somali' },
  { code: 'sq', name: 'Albanian' },
  { code: 'sv', name: 'Swedish' },
  { code: 'sw', name: 'Swahili' },
  { code: 'ta', name: 'Tamil' },
  { code: 'tr', name: 'Turkish' },
  { code: 'uk', name: 'Ukrainian' },
  { code: 'zu', name: 'Zulu' },
  ];


const getFormatterLanguageOptions = () => {
  return pontLanguages.map((item) => ({
    label: item.name,
    value: item.code,
    name: item.name,
  }));
}

export { pontLanguages,getFormatterLanguageOptions };