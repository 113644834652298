import { useParams } from "react-router";
import { useDirtyStateV2 } from "../../hooks/Common/dirtyV2";
import { useFetchLocationById } from "../../hooks/Location/location";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { InventoryDto } from "../../types/Inventory/InventoryDto";
import { ConfirmationChangesDialog } from "../Common/ConfirmationChangesDialog";
import ApiStatus from "../Common/Errors/ApiStatus";
import { InventoryForm } from "./InventoryForm";

type Args =
    {
        userLogged: ApplicationUser
    }

const InventoryAdd = ({ userLogged }: Args) => {
    const queryStringParams = useParams();
    const { setDirty, showConfirmation, handleDirtyAction, cancelDirtyAction, confirmDirtyAction, unsetDirty } = useDirtyStateV2();

    if (!queryStringParams.id) throw Error("Location id needed");

    const { data: location, status: locationStatus, isSuccess: locationIsSuccess } = useFetchLocationById(queryStringParams.id!)

    const inventory: InventoryDto = {
        name: '',
        externalReference: '',
        inventoryGroupName: 'Space',
        areaInSqm: 0,
        isDisabled: false,
        locationId: queryStringParams.id!,
        description: '',
        shortDescription: '',
        internalIdentifier: ''
    };

    if ((!locationIsSuccess)) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <ApiStatus status={locationStatus} />
                </div>
            </div>
        </>
    )

    if (!location) return <ApiStatus status={locationStatus} />;

    return (
        <>
            <InventoryForm
                inventory={inventory}
                isEdit={false}
                setDirty={setDirty}
                location={location}
                handleDirtyAction={handleDirtyAction}
                unsetDirty={unsetDirty}
            ></InventoryForm>
            <ConfirmationChangesDialog
                handleConfirm={confirmDirtyAction}
                handleCancel={cancelDirtyAction}
                showConfirmation={showConfirmation}
            />
        </>
    );
};


export { InventoryAdd };

