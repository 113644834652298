import { Button, Collapse, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { LR } from '../Help/LR';
import i18n from '../../../i18n';
import { DigitalAssetDto } from '../../../types/Common/DigitalAssetDto';
import { useState } from 'react';
import FileUploader from './FileUploader';
import { IconDisplayer } from '../Help/IconDisplayer';
import { DictionaryStringNumber } from '../../../types/Common/Dictionaries';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import React from 'react';


type Args = {
    items: DigitalAssetDto[],
    groupname: string
    setDirty?: () => void,
    unsetDirty?: () => void;
};

const FileuploadContainer = ({ items, groupname, setDirty, unsetDirty }: Args) => {
    const [expandedItems, setExpandedItems] = useState<string[]>([]);
    const [myDictionary, setMyDictionary] = useState<DictionaryStringNumber>({});

    const handleExpandClick = (itemName: string) => {
        setExpandedItems((prevExpandedItems) => {
            if (prevExpandedItems.includes(itemName)) {
                return prevExpandedItems.filter((id) => id !== itemName);
            } else {
                return [...prevExpandedItems, itemName];
            }
        });
    };

    // const handleFilesCount = (key: string, value: number) => {

    //     setMyDictionary((prevDictionary) => {
    //         // Check if the key already exists in the dictionary
    //         if (prevDictionary.hasOwnProperty(key)) {
    //             // Update the existing record
    //             return {
    //                 ...prevDictionary,
    //                 [key]: value,
    //             };
    //         } else {
    //             // Insert a new record
    //             return {
    //                 ...prevDictionary,
    //                 [key]: value,
    //             };
    //         }
    //     });

    // const handleFilesCount = (key: string, value: number) => {
    //     setMyDictionary((prevDictionary) => {
    //         const updatedDictionary = {
    //             ...prevDictionary,
    //             [key]: value,
    //         };

    //         if (handleDirty) {
    //             handleDirty();
    //         }

    //         return updatedDictionary;
    //     });

    const handleFilesCount = (key: string, value: number) => {
        setMyDictionary((prevDictionary) => {
            // Check if the key already exists in the dictionary
            if (prevDictionary.hasOwnProperty(key)) {
                // Update the existing record
                const updatedDictionary = {
                    ...prevDictionary,
                    [key]: value,
                };

                if (setDirty && prevDictionary[key] !== value) {
                    // setDirty();
                }
                return updatedDictionary;
            } else {
                // Insert a new record
                const updatedDictionary = {
                    ...prevDictionary,
                    [key]: value,
                };

                if (setDirty && prevDictionary[key] !== value) {
                    // setDirty();
                }
                return updatedDictionary;
            }
        });

    };

    return (
        <>
            {items.map((item: DigitalAssetDto, index: number) => (
                <React.Fragment key={item.label}>
                    <div className="row d-flex justify-content-center ">
                        <div className="col-md-7 col-11 justify-content-center ">
                            <Paper elevation={3} className="fa-paper ">
                                <div className="d-flex align-items-center">
                                    <div className="fa-name flex-grow-1">
                                        <IconDisplayer type={item.label}></IconDisplayer>
                                        <Typography variant="h6" >
                                            <Button type="button" className="linkbutton">
                                                <LR localResource={item.label}></LR> ({

                                                    (myDictionary[item.label] === -1 ? 0 : myDictionary[item.label])
                                                })
                                            </Button>
                                        </Typography>
                                    </div>
                                    <div className="fa-paper-cta-description d-flex align-items-center">

                                        {(item.isMandatory && myDictionary[item.label] <= 0) && (

                                            <Typography variant="body2" style={{ fontSize: '12px' }}>
                                                <LR localResource="COMMON_SUMMARY_ACTIONREQUIRED"></LR>
                                            </Typography>
                                        )}

                                        {((myDictionary[item.label] > 0)) && (
                                            <Tooltip title={i18n.t('COMMON_TOOLTIP_GREEN')}>
                                                <CheckCircleRoundedIcon className="fa-paper-cta-good" />
                                            </Tooltip>
                                        )}

                                        {((myDictionary[item.label] === -1)) && (
                                            <Tooltip title={i18n.t('COMMON_LABEL_FILEREJECTED')}>
                                                <ErrorRoundedIcon color="error" className='fa-paper-cta-rejected' />
                                            </Tooltip>
                                        )}




                                    </div>
                                    <div className="fa-icons">
                                        <IconButton className="fa-arrow" onClick={() => handleExpandClick(item.label)}>
                                            {!expandedItems.includes(item.label) && <ArrowForwardIosRoundedIcon />}
                                            {expandedItems.includes(item.label) && <ArrowForwardIosRoundedIcon className='fa-arrow-down' />}
                                        </IconButton>
                                    </div>
                                </div>
                            </Paper>
                        </div>
                    </div>

                    <Collapse in={expandedItems.includes(item.label)}>
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-7 col-11 justify-content-center ">
                                <FileUploader
                                    setFilesCount={handleFilesCount}
                                    defaultOpen={item.isMandatory && myDictionary[item.label] === 0}
                                    groupName={groupname}
                                    key={item.label}
                                    item={item}
                                    version="1"
                                    setDirty={setDirty}
                                    unsetDirty={unsetDirty}
                                ></FileUploader>
                            </div>
                        </div>
                    </Collapse>
                </React.Fragment>
            ))}
        </>
    )
};

export { FileuploadContainer };
