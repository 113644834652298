import React from 'react';

interface CustomerInfoProps {
  label: string;
  company: string;
  
}
const CustomerInfo: React.FC<CustomerInfoProps> = ({ label, company }) => {

  return (
    <div className='invoice-line-main'>
      <div className='status-section-left'>{label}</div>
      <div className='status-section-right'>{company}</div>
    </div>
  );
};

export { CustomerInfo };
