import { Grid } from '@mui/material';
import { Controller } from 'react-hook-form';
import i18n from '../../../i18n';
import { Input } from '../formComponents/Input';


type AddressFormProps = {
  control: any,
  formKey?: string,
};

const AddressFormV2 = ({ control, formKey }: AddressFormProps) => {
  const getName = (name: string) => {
    if (formKey) {
      return `${formKey}.${name}`
    }
    return name
  }
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            name={getName('line1')}
            control={control}
            render={({ field, fieldState }) => (
              <Input
                labelProps={{
                  message: i18n.t("COMMON_LABEL_ADDRESSLINE_1").toString(),
                  mandatory: true
                }}
                placeholder={i18n.t("COMMON_LABEL_ADDRESSLINE_1").toString()}
                id="addr_line_1"
                field={field as any}
                validationError={fieldState.error}
              ></Input>)}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={getName('line2')}
            control={control}
            render={({ field, fieldState }) => (
              <Input
                labelProps={{
                  message: i18n.t("COMMON_LABEL_ADDRESSLINE_2").toString(),
                }}
                placeholder={i18n.t("COMMON_LABEL_ADDRESSLINE_2").toString()}
                id="addr_line_2"
                field={field as any}
                validationError={fieldState.error}
              />)}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={getName('line3')}
            control={control}
            render={({ field, fieldState }) => (
              <Input
                labelProps={{
                  message: i18n.t("COMMON_LABEL_ADDRESSLINE_3").toString(),
                }}
                placeholder={i18n.t("COMMON_LABEL_ADDRESSLINE_3").toString()}
                id="addr_line_3"
                field={field as any}
                validationError={fieldState.error}
              />)}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={getName('city')}
            control={control}
            render={({ field, fieldState }) => (
              <Input
                labelProps={{
                  message: i18n.t("COMMON_LABEL_CITY").toString(),
                  mandatory: true
                }}
                placeholder={i18n.t("COMMON_LABEL_CITY").toString()}
                id="town"
                field={field as any}
                validationError={fieldState.error}
              />)}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={getName('area')}
            control={control}
            render={({ field, fieldState }) => (
              <Input
                labelProps={{
                  message: i18n.t("COMMON_LABEL_AREA").toString(),
                  mandatory: false
                }}
                placeholder={i18n.t("COMMON_LABEL_AREA").toString()}
                id="county"
                field={field as any}
                validationError={fieldState.error}
              />)}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={getName('zip')}
            control={control}
            render={({ field, fieldState }) => (
              <Input
                labelProps={{
                  message: i18n.t("COMMON_LABEL_POSTALCODE").toString(),
                }}
                placeholder={i18n.t("COMMON_LABEL_POSTALCODE").toString()}
                id="zipcode"
                field={field as any}
                validationError={fieldState.error}
              />)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={getName('latitude')}
            control={control}
            render={({ field, fieldState }) => (
              <Input
                labelProps={{
                  message: i18n.t("COMMON_LABEL_LATITUDE").toString(),
                  mandatory: false
                }}
                placeholder={i18n.t("COMMON_LABEL_LATITUDE").toString()}
                type="number"
                id="latitude"
                inputProps={{
                  min: -90,
                  max: 90,
                  step: 0.0000001
                }}
                field={field as any}
                validationError={fieldState.error}
              />)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={getName('longitude')}
            control={control}
            render={({ field, fieldState }) => (
              <Input
                labelProps={{
                  message: i18n.t("COMMON_LABEL_LONGITUDE").toString(),
                  mandatory: false
                }}
                placeholder={i18n.t("COMMON_LABEL_LONGITUDE").toString()}
                type="number"
                id="longitude"
                inputProps={{
                  min: -180,
                  max: 180,
                  step: 0.0000001
                }}
                field={field as any}
                validationError={fieldState.error}
              />)}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={getName('whatThreeWords')}
            control={control}
            render={({ field, fieldState }) => (
              <Input
                labelProps={{
                  message: i18n.t("COMMON_LABEL_WHAT_THREE_WORDS").toString(),
                  mandatory: false
                }}
                placeholder={i18n.t("COMMON_LABEL_WHAT_THREE_WORDS").toString()}
                id="whatThreeWords"
                field={field as any}
                validationError={fieldState.error}
              />)}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AddressFormV2;
