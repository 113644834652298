import { useNavigate, useParams } from "react-router-dom";
import { useFetchMemberById } from "../../../hooks/Member/member";
import i18n from "../../../i18n";
import { ApplicationUser } from "../../../types/Authentication/ApplicationUser";
import ApiStatus from "../../Common/Errors/ApiStatus";
import { SectionHead } from "../../Common/Help/SectionHead";
import { BulkUpload } from "./BulkUpload";

type Args = {
    userLogged: ApplicationUser
}

const GlobalLocationConfigurationDetails = ({ userLogged }: Args) => {

    const nav = useNavigate();
    const { configurationName } = useParams();

    if (!configurationName) throw Error("configurationName id needed");

    const label = `LOCATION_LABEL_${configurationName.toUpperCase()}`

    const callBackUrl = configurationName === 'memberupload' ? '/admin' : `/locations/configuration/`;
    const sectionHeadDescription = configurationName === 'memberupload' ? 'COMMON_SUMMARY_OPERATORMANAGEMENT' : 'COMMON_SUMMARY_LOCATIONMANAGEMENT';
    const callToActionText = configurationName === 'memberupload' ? 'COMMON_LABEL_ADMIN' : 'LOCATION_SUMMARY_CONFIGURATION';

    const linkCallBack = () => {
        nav(callBackUrl);
    }

    const { data: operator, status: operatorStatus, isSuccess: operatorIsSuccess } = useFetchMemberById(userLogged.operator?.id!)

    if ((!operatorIsSuccess)) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <ApiStatus status={operatorStatus} />
                </div>
            </div>
        </>
    )

    if (!operator) return <ApiStatus status={operatorStatus} />;

    const uploadTypes: Record<string, string> = {
        locationsupload: 'location',
        inventoryupload: 'inventory',
        memberupload: 'member',
        orderupload: 'order',
        productupload :'product'
    }

    return (
        <>

            <SectionHead
                linkCallBack={linkCallBack}
                ctaText={i18n.t(callToActionText)!}
                name=''
                title={i18n.t(label)}
                description={i18n.t(sectionHeadDescription)}
            />
            {
                uploadTypes[configurationName] &&
                <BulkUpload type={uploadTypes[configurationName]} userLogged={userLogged} />
            }
        </>
    );
};


export { GlobalLocationConfigurationDetails };
