import axios from "axios";

import { UserPasswordReset } from "../../types/Login/UserPasswordReset";
import { UserPasswordResetResponse } from "../../types/Login/UserPasswordResetResponse";
import i18n from "../../i18n";
import { ErrorInterpolationParser } from "../../functions/error-management/errorParse";
import { operatorDefaultHeaders } from "../../functions/api/api";

const UseRequestPasswordReset = async function (email: string, callback: any) {

    const emptyUserPasswordReset: UserPasswordReset =
    {
        id: "",
        confirmationCode: "",
        confirmPassword: "",
        password: "",
        passwordToken: "",
        error: ""
    }

    axios.post(`/identity-management/management/forgotPassword`, email, {
        headers: operatorDefaultHeaders() 
    }).then(response => {

        emptyUserPasswordReset.id = response.data.id;
        emptyUserPasswordReset.passwordToken = response.data.passwordToken;
        callback(emptyUserPasswordReset);
    }).catch(err => {
        emptyUserPasswordReset.error = 
        i18n.t(err.response.data.error, 
            ErrorInterpolationParser(err.response.data.parameters)
        ).toString();
        callback(emptyUserPasswordReset);
    })
};

const UseRequestPasswordResetById = async function (id: string, callback: any) {

    const emptyUserPasswordReset: UserPasswordReset =
    {
        id: "",
        confirmationCode: "",
        confirmPassword: "",
        password: "",
        passwordToken: "",
        error: ""
    }

    axios.get(`/identity-management/management/forgotPassword/${id}`, {
        headers: operatorDefaultHeaders() 
    }).then(response => {

        emptyUserPasswordReset.id = response.data.id;
        emptyUserPasswordReset.passwordToken = response.data.passwordToken;
        callback(emptyUserPasswordReset);
    }).catch(err => {
        emptyUserPasswordReset.error = 
        i18n.t(err.response.data.error, 
            ErrorInterpolationParser(err.response.data.parameters)
        ).toString();
        callback(emptyUserPasswordReset);
    })
};


const UseResetPassword = async function (userPasswordReset: UserPasswordReset, callback:any) {
    const emptyUserPasswordResetResponse: UserPasswordResetResponse =
    {
        id: "",
        isPasswordChanged : false,
        email : "",
        error : ""
    }


    axios.post(`/identity-management/management/changePassword/`+ userPasswordReset.id, userPasswordReset, {
        headers: operatorDefaultHeaders() 
    }).then(response => {        
        emptyUserPasswordResetResponse.isPasswordChanged = true;
        emptyUserPasswordResetResponse.email = response.data.email; 
        emptyUserPasswordResetResponse.id = response.data.id;
        
        callback(emptyUserPasswordResetResponse);
    }).catch(err => {        
        emptyUserPasswordResetResponse.isPasswordChanged = false;
        emptyUserPasswordResetResponse.error = i18n.t(err.response.data.error, 
            ErrorInterpolationParser(err.response.data.parameters)
        ).toString();
        callback(emptyUserPasswordResetResponse);
    })
};



export {
    UseRequestPasswordReset,
    UseRequestPasswordResetById,
    UseResetPassword
}