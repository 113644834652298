import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { Box, Tooltip } from "@mui/material";
import { useState } from 'react';
import { useNavigate } from "react-router";
import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { CustomerSearchItemResponseDto } from '../../types/Customer/CustomerSearchItemResponseDto';
import { LR } from "../Common/Help/LR";
import { SearchInput } from '../Common/SearchInput';
import { CustomButton } from '../Common/UI/CustomButton';
import CustomerFeed from './CustomerFeed';

type Args = {
  userLogged: ApplicationUser;
  headers?: string[];
  handleArrowClick?: (customer: CustomerSearchItemResponseDto) => void;
  compact?: boolean;
};

const Customers = ({ userLogged, headers, handleArrowClick, compact }: Args) => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (query: string) => {
    setSearchQuery(query);
  };

  const handleAddNewCustomer = () => {
    navigate('/customers/add');
  }

  return (
    <div className='mainpage-container-layout'>
      <div className='col-md-10 col-11 justify-content-center'>
        <div className='customers-button'>
          <Tooltip title={i18n.t('ACTIVITY_LABEL_NEWCUSTOMER')}>
            <CustomButton onClick={handleAddNewCustomer} color='secondary' sx={{ textTransform: 'none', mb: 2 }}>
              <AddCircleRoundedIcon />
              <Box sx={{ ml: 1 }}>
                <LR localResource="ACTIVITY_LABEL_NEWCUSTOMER" />
              </Box>
            </CustomButton>
          </Tooltip>
        </div>
        <SearchInput onSearchChange={handleSearchChange} searchQuery={searchQuery} placeholder={'SEARCH_BY_CUSTOMER'} />
        <CustomerFeed
          userLogged={userLogged}
          searchQuery={searchQuery}
          headers={headers}
          handleArrowClick={handleArrowClick}
          compact={compact}
        />
      </div>
    </div>
  );
};

export { Customers };

