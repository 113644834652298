import { useEffect, useState } from "react";
import i18n from "../../i18n";
import { LR } from "../Common/Help/LR";
import { LabelAndHelp } from "../Common/Help/LabelAndHelp";
import Select, { SingleValue } from "react-select";
import ApiStatus from "../Common/Errors/ApiStatus";
import { PriceDto } from "../../types/Price/PriceDto";
import {  InventoryUnavailabilityTypeSelectOption } from "../../types/Inventory/InventoryUnavailabilityTypeDto";
import { InventoryUnavailabilityDto } from "../../types/Inventory/InventoryUnavailabilityDto";
import { useFetchInventoryUnavailabilityTypes } from "../../hooks/Inventory/inventory";
import { DateRange} from '@mui/x-date-pickers-pro';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs, { Dayjs } from "dayjs";
import { AddDaysToDate, DateShim } from "../../functions/utils/helper";

type Args = {
    isEdit: boolean,
    inventoryUnavailability: InventoryUnavailabilityDto,
    locationId: string
    submitted: (inventory: InventoryUnavailabilityDto, prices?: PriceDto[]) => void;
};

const UnavailabilityForm = ({ locationId, inventoryUnavailability, isEdit, submitted }: Args) => {

    const [inventoryUnavailabilityState, setInventoryUnavailabilityState] = useState({ ...inventoryUnavailability });
    const [submitting, setSubmitting] = useState(false);
    const [unavailabilityTypes, setUnavailabilityTypes] = useState<InventoryUnavailabilityTypeSelectOption[]>([]);
    const [selectedUnavailabilityTypes, setSelectedUnavailabilityType] = useState<InventoryUnavailabilityTypeSelectOption>();
    const { data, status, isSuccess } = useFetchInventoryUnavailabilityTypes();
    const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([dayjs(inventoryUnavailability.fromDate), dayjs(inventoryUnavailability.toDate),]);


    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            setSubmitting(true);

            inventoryUnavailabilityState.fromDate = dateRange[0]?.toDate();
            inventoryUnavailabilityState.toDate = dateRange[1]?.toDate();

            // Clone the postRequest object to avoid mutating the original
            const updatedInventoryUnavailability = { ...inventoryUnavailabilityState };

            // Add 1 day to the toDate attribute
            updatedInventoryUnavailability.toDate = AddDaysToDate(updatedInventoryUnavailability.toDate, 1)
            // Remove UTC times
            updatedInventoryUnavailability.fromDate = DateShim(updatedInventoryUnavailability.fromDate);
            updatedInventoryUnavailability.toDate = DateShim(updatedInventoryUnavailability.toDate);

            submitted(updatedInventoryUnavailability);

        } catch (err: any) {
            setSubmitting(false)
            console.error(err);
        }
    }

    useEffect(() => {
        if (data) {

            const selectOptions = data.filter(x => x.isUserSelectable && !x.hasInventoryAllocation).map((inventoryType) => ({
                label: i18n.t(inventoryType.label),
                value: inventoryType.name
            }));

            setUnavailabilityTypes(selectOptions);
            if (data.length > 0) {
                setSelectedUnavailabilityType(selectOptions[0]);
                setInventoryUnavailabilityState({...inventoryUnavailabilityState, inventoryUnavailabilityTypeName : selectOptions[0].value})
            }

        }
    }, [data]);

    const handleDropdownChange = (value: SingleValue<InventoryUnavailabilityTypeSelectOption>) => {
        var selectedType = unavailabilityTypes.find(x => x.value === value?.value);
        setSelectedUnavailabilityType(selectedType!);
        setInventoryUnavailabilityState({...inventoryUnavailabilityState, inventoryUnavailabilityTypeName : selectedType!.value})
    };

    if (!selectedUnavailabilityTypes || !unavailabilityTypes || !inventoryUnavailabilityState) return <ApiStatus status={"loading"} />;


    return (
        <>
            <form onSubmit={onFormSubmit}>
                <LabelAndHelp mandatory={false} fullscreen={true} message='INVENTORY_LABEL_UNAVAILABILITYTYPE' ></LabelAndHelp>
                <div className="row d-flex justify-content-center mb-2">
                    <div className="justify-content-center">

                        <Select value={selectedUnavailabilityTypes} options={unavailabilityTypes} className="form-control fa-form fa-dropdown-container" classNamePrefix="fa-dropdown"
                            onChange={(e) => handleDropdownChange(e)}></Select>
                    </div>
                </div>

                <div className="row d-flex justify-content-center mt-4 mb-2">
                    <div className="justify-content-center">
                        <DateRangePicker  
                            className="fa-datepicker"   
                            value={dateRange}
                            onChange={(newValue) => setDateRange(newValue)}
                      />
                    </div>
                </div>

                <div className="row d-flex justify-content-center">
                <div className="justify-content-center mt-1">
                    <h5><LR localResource="INVENTORY_LABEL_UNAVAILABILITYREASON"></LR></h5>
                    <textarea rows={5} required className="form-control fa-form" 
                    value={inventoryUnavailabilityState.reason}
                    placeholder={i18n.t('INVENTORY_LABEL_UNAVAILABILITYREASONTEXT').toString()}
                    onChange={(e) => {setInventoryUnavailabilityState({...inventoryUnavailabilityState, reason : e.target.value});}}
                    >{inventoryUnavailabilityState.reason}</textarea>
                </div>
            </div>


                <div className="row d-flex justify-content-center mt-2">
                    <div className="col-6 justify-content-center">
                        <button className="btn btn-green btn-primary btn-block" disabled={submitting || !inventoryUnavailabilityState.reason} type="submit">
                            <LR localResource="COMMON_LABEL_SAVE"></LR>
                        </button>
                    </div>
                </div>
            </form>
        </>

    );
}


export { UnavailabilityForm }