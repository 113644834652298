import { ConfigurationDto } from "../types/Common/ConfigurationDto";

const locationGlobalConfigurations: ConfigurationDto[] = [
    {
        name: "LOCATION_LABEL_LOCATIONSUPLOAD",
        url: "locationsupload",
        groupName: "MENU_LABEL_GROUPUPLOAD"
    },
    {
        name: "LOCATION_LABEL_INVENTORYUPLOAD",
        url: "inventoryupload",
        groupName: "MENU_LABEL_GROUPUPLOAD"
    },
    {
        name: "LOCATION_LABEL_ORDERUPLOAD",
        url: "orderupload",
        groupName: "MENU_LABEL_GROUPUPLOAD"
    },
        {
        name: "LOCATION_LABEL_PRODUCTUPLOAD",
        url: "productupload",
        groupName: "MENU_LABEL_GROUPUPLOAD"
    }
]

export {
    locationGlobalConfigurations
}