import axios, { AxiosError, AxiosResponse } from "axios";
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { useNavigate } from "react-router-dom";
import Problem from "../../types/Problem";
import { DomainDto } from "../../types/Common/DomainDto";
import { HookConfig } from "../../config/HookConfig";
import { operatorDefaultHeaders } from "../../functions/api/api";
import { pontConstants } from "../../data/pontConstants";
import { isNil, omitBy } from "lodash";



const useManageMemberDomains = () => {
  const queryClient = useQueryClient();
  const nav = useNavigate();
  const manageDomains = async (
    [id, items]: [string, DomainDto[]]
  ): Promise<AxiosResponse<any, any>[]> => {
    const baseUrl = `${HookConfig.memberUrl}/${id}/domains`;
    const promises = items
      .map(async (item) => {
        const isEmptyGuid = item.id === pontConstants.emptyGuid;
        const url = isEmptyGuid ? baseUrl : `${baseUrl}/${item.id}`;
        const method = isEmptyGuid ? "post" : "put";

        let body: any = {
          value: item.value,
          memberId: id
        }
        if (!isEmptyGuid) {
          body.id = item.id;
        }

        const response = await axios[method](
          url, body,
          {
            withCredentials: true,
            headers: operatorDefaultHeaders(),
          }
        );
        return response;
      });
    const responses = await Promise.all(promises);
    return responses;
  };

  const mutation = useMutation<
    AxiosResponse<any, any>[],
    AxiosError<Problem>,
    [string, DomainDto[]]>(manageDomains);

  return mutation;
};

const useFetchMemberDomains = (id: string) => {
  return useQuery<DomainDto[], AxiosError>(["member-domains", id], () =>
    axios.get(`${HookConfig.memberUrl}/${id}/domains`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      })
      .then(
        (response) => {
          return response.data;
        }
      ));
};


const useDeleteDomain = () => {
  const queryClient = useQueryClient();
  return (domain: DomainDto) => {
    return axios
      .delete(`${HookConfig.memberUrl}/${domain.entityKey}/domains/${domain.id}`, {
        withCredentials: true,
        headers: operatorDefaultHeaders(),
      })
      .then(() => {
        console.log("Successfully deleted domain:", domain);
        queryClient.invalidateQueries(["member-domains", domain.entityKey]);
        return { success: true };
      })
      .catch((error) => {
        console.log("Error deleting domain:", error);
        return { success: false, error };
      });
  };
};

const useFetchMemberDomain = (id: string, params: { domain?: string }, options?: UseQueryOptions<DomainDto[], AxiosError>) => {
  const cleanedParams = omitBy(params, isNil);
  return useQuery<DomainDto[], AxiosError>(["member-domain", id, cleanedParams], () =>
    axios.get(`${HookConfig.memberUrl}/${id}/domains`,
      {
        params: cleanedParams,
        withCredentials: true,
        headers: operatorDefaultHeaders(),
      })
      .then(
        (response) => {
          return response.data;
        }
      ), {
    ...options
  });
}

export {
  useManageMemberDomains,
  useFetchMemberDomains,
  useDeleteDomain,
  useFetchMemberDomain
}
