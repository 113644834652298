import { createTheme, darken } from '@mui/material/styles';


export const colors = {
  primary: '#00be95',
  grey: {
    100: '#383841',
    200: '#656570',
    300: '#313139',
    400: '#2c2c2c',
    500: '#232327',
    600: "#484850"
  },
  red: {
    100: '#F44336',
  },
  orange: {
    100: '#ffa726'
  },
  white: '#fff'
}

const PontTheme = createTheme({
  typography: {
    fontFamily: '"Inter", sans-serif',
  },
  palette: {
    mode: 'dark',
    primary: {
      main: colors.grey[400]
    },
    secondary: {
      main: colors.primary,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        outlined: {
          color: '#fff'
        },
        contained: {
          backgroundColor: colors.primary,
          '&:hover': {
            backgroundColor: darken(colors.primary, 0.2)
          },
          '&.MuiButton-containedError': {
            backgroundColor: colors.red[100],
            color: colors.white,
            '&:hover': {
              backgroundColor: darken(colors.red[100], 0.2),
            },
          },
          '&.MuiButton-containedInfo': {
            backgroundColor: colors.grey[200],
            color: colors.white,
            '&:hover': {
              backgroundColor: darken(colors.grey[200], 0.2),
            },
          },
          '&.MuiButton-containedWarning': {
            backgroundColor: colors.orange[100],
            color: colors.white,
            '&:hover': {
              backgroundColor: darken(colors.orange[100], 0.2),
            },
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          border: 'solid 1px #fff',
          backgroundColor: '#dddddd',
          color: colors.grey[400]
        },

        // paper: {
        //   backgroundColor: colors.grey[400],
        // },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: colors.grey[400],
        },
      },
    },
    MuiAlert: {
      variants: [
        {
          props: { severity: 'error' },
          style: {
            backgroundColor: colors.red[100],
            color: colors.white,
            '& .MuiAlert-icon': {
              color: colors.white
            },
          },
        },
        {
          props: { severity: 'success' },
          style: {
            backgroundColor: colors.primary,
            color: colors.white,
            '& .MuiAlert-icon': {
              color: colors.white
            },
          },
        }
      ]
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-filterPanel': {
            backgroundColor: colors.grey[400],
          }
        },
      },
    },
  },
});

export { PontTheme };
