import { useEffect, useState } from "react";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { MemberResponseDto } from "../../types/Member/MemberResponseDto";
import axios from "axios";
import { useFetchMembers } from "../Member/member";
import { HookConfig } from "../../config/HookConfig";
import { operatorDefaultHeaders, operatorNoPartnertHeaders } from "../../functions/api/api";
import { IsWithin24Hours } from "../../functions/utils/helper";
import { PartnerResponseDto } from "../../types/Partner/PartnerResponseDto";
import { ApplicationConfig } from "../../config/ApplicationConfig";
import { LocationResponseDto } from "../../types/Location/LocationResponseDto";


const isUserLoggedV2 = async (): Promise<ApplicationUser> => {

    const returnedUser: ApplicationUser =
    {
        isDeclined: false,
        isLogged: false,
        doesNeedSetup: undefined,
        memberId: "",
        loginTime: new Date(),
        name: "",
        familyName: "",
        givenName: "",
        country: "",
        email: "",
        id: "",
        logoutUrl: "",
    }

    //first thing we call the API to see if the user is still logged in. 
    const response = await fetch("/bff/user", { headers: operatorDefaultHeaders() });

    if(!response.ok && response.status === 401)
    {
        returnedUser.isDeclined = true;
        return returnedUser;
    }


    const currentUser: ApplicationUser = JSON.parse(localStorage.getItem(ApplicationConfig.currentUserStorageKey)!) as ApplicationUser;

    // 1. user is authenticated
    // 2. user has done the login in the last 24h 
    // 3. user has a partner set
    if (currentUser && IsWithin24Hours(currentUser.loginTime) && currentUser.partner && currentUser.operator && currentUser.location && currentUser.operator.id === currentUser.location.operatorMemberId) {
        return currentUser;
    }

    if (response.ok && response.status === 200) {
        returnedUser.isLogged = true;
        const data = await response.json();
        returnedUser.id = data.find((claim: { type: string; }) => claim.type === "sub")?.value ?? "";

        if (returnedUser.id === "") {
            returnedUser.isLogged = false;
        }

        returnedUser.logoutUrl = data.find((claim: { type: string; }) => claim.type === "bff:logout_url")?.value ?? "/bff/logout";
        returnedUser.name = data.find((claim: { type: string; }) => claim.type === "name")?.value;
        returnedUser.givenName = data.find((claim: { type: string; }) => claim.type === "given_name")?.value;
        returnedUser.familyName = data.find((claim: { type: string; }) => claim.type === "family_name")?.value;
        returnedUser.email = data.find((claim: { type: string; }) => claim.type === "preferred_username")?.value;
        returnedUser.country = data.find((claim: { type: string; }) => claim.type === "locale")?.value;
        returnedUser.checkPartner = true;
        returnedUser.doesNeedSetup = false;
        localStorage.setItem(ApplicationConfig.currentUserStorageKey, JSON.stringify(returnedUser));

    } else {
        returnedUser.isLogged = false;
        returnedUser.isDeclined = true;
        returnedUser.doesNeedSetup = false;
        returnedUser.checkPartner = false;
    }
    return returnedUser;

};

const resetCurrentUser = (currentUser: ApplicationUser): ApplicationUser => {
    currentUser.partner = undefined;
    currentUser.operator = undefined;
    currentUser.location = undefined;
    localStorage.removeItem(currentUser.id + ApplicationConfig.currentLocationStorageKey);
    localStorage.removeItem(currentUser.id + ApplicationConfig.currentOperatorStorageKey);
    return currentUser;
}

const setCurrentPartner = async (partner: PartnerResponseDto): Promise<ApplicationUser> => {
    let currentUser: ApplicationUser = JSON.parse(localStorage.getItem(ApplicationConfig.currentUserStorageKey)!) as ApplicationUser;

    //1. User is authenticated (we have it in cache)
    //2. User logged more than 24h ago, we want him to reauthenticate. > edge case, but playing safe.
    if (currentUser && !IsWithin24Hours(currentUser.loginTime)) {
        currentUser = resetCurrentUser(currentUser);
        currentUser.isDeclined = true;
        currentUser.checkPartner = false;
        currentUser.doesNeedSetup = false;
        return currentUser;
    } else {

        //setting the partner
        currentUser.partner = partner;
        localStorage.setItem(ApplicationConfig.partnerStorageKey, partner?.partnerName!);
        currentUser.checkPartner = false;

        //fetching the member for the register user
        try {
            const member = await axios.get<MemberResponseDto>(`${HookConfig.memberUrl}/me`, {
                withCredentials: true,
                headers: operatorDefaultHeaders(),
            });

            currentUser.memberId = member.data.id;
        } catch (error: any) {
            currentUser = resetCurrentUser(currentUser);
            currentUser.doesNeedSetup = true;
            return currentUser;
        }

        //fetching now the operator from the cache
        const cachedOperator: MemberResponseDto = JSON.parse(localStorage.getItem(currentUser.id + ApplicationConfig.currentOperatorStorageKey)!) as MemberResponseDto;
        if (cachedOperator) {
            currentUser.operator = cachedOperator;
        } else {
            //no cache, fetching from the database
            let url = `${HookConfig.memberUrl}?memberTypeFlags=4&searchRelationShip=2`;

            try {
                const operators = await axios.get<MemberResponseDto[]>(url, {
                    withCredentials: true,
                    headers: operatorDefaultHeaders(),
                });

                if (operators.data && operators.data.length > 0) {
                    currentUser.operator = operators.data[0];
                    localStorage.setItem(currentUser.id + ApplicationConfig.currentOperatorStorageKey, JSON.stringify(operators.data[0]));
                } else {
                    currentUser = resetCurrentUser(currentUser);
                    currentUser.doesNeedSetup = true;
                    return currentUser;
                }

            } catch (error: any) {
                currentUser = resetCurrentUser(currentUser);
                currentUser.doesNeedSetup = true;
                return currentUser;
            }
        }

        //fetching now the selectedLocation from the cache
        const cachedLocation: LocationResponseDto = JSON.parse(localStorage.getItem(currentUser.id + ApplicationConfig.currentLocationStorageKey)!) as LocationResponseDto;
        if (cachedLocation && cachedLocation.operatorMemberId === currentUser.operator.id) {
            currentUser.location = cachedLocation;
        } else {
            const defaultLocation = await getDefaultLocationByOperator(currentUser.operator);
            if (!defaultLocation) {
                currentUser = resetCurrentUser(currentUser);
                currentUser.doesNeedSetup = true;
                return currentUser;
            } else {
                currentUser.location = defaultLocation;
                localStorage.setItem(currentUser.id + ApplicationConfig.currentLocationStorageKey, JSON.stringify(defaultLocation));
            }
        }

        localStorage.setItem(ApplicationConfig.currentUserStorageKey, JSON.stringify(currentUser));
        return currentUser;
    }
};


const getDefaultLocationByOperator = async (operator: MemberResponseDto): Promise<LocationResponseDto | null> => {
    //no cache, fetching from the database
    let url = `${HookConfig.locationUrl}?memberId=${operator.id}`;

    try {
        const locations = await axios.get<LocationResponseDto[]>(url, {
            withCredentials: true,
            headers: operatorDefaultHeaders(),
        });

        if (locations.data && locations.data.length > 0) {
            return locations.data[0];

        } else {
            return null;
        }

    } catch (error: any) {
        return null;
    }
}






export {
    isUserLoggedV2,
    setCurrentPartner,
    getDefaultLocationByOperator
} 