import axios, { AxiosError, AxiosResponse } from 'axios';
import { isNil, omitBy } from 'lodash';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { HookConfig } from '../../config/HookConfig';
import dummyCustomers from '../../data/customer/customer.json';
import { operatorDefaultHeaders } from '../../functions/api/api';
import { CompanyCreateRequestDto, ContactCreateRequestDto, Customer, CustomerEmailCompanyDto, IndividualCreateRequestDto } from '../../types/Customer/CustomerDto';
import { CustomerSearchRequestDto, CustomerSearchResponseDto } from '../../types/Customer/CustomerSearchResponseDto';


const useFetchCustomers = ({ locationId, searchRequirement, pageNumber, pageSize, memberTypeName }: CustomerSearchRequestDto) => {
  const payload = {
    locationId,
    searchRequirement,
    pageNumber,
    pageSize,
    memberTypeName
  }

  if (!memberTypeName) {
    delete payload.memberTypeName
  }

  return useQuery<CustomerSearchResponseDto, AxiosError>(
    ['customers', locationId, searchRequirement, pageNumber, pageSize, memberTypeName],
    () =>
      axios
        .post(
          `${HookConfig.memberMgmtUrl}/search/customers`,
          payload,
          {
            withCredentials: true,
            headers: operatorDefaultHeaders(),
          }
        )
        .then((response) => response.data),
    {
      enabled: !!locationId,
    }
  );
};


const useCustomers = (operatorId: string) => {
  return useQuery<Customer[], AxiosError>(['customers', operatorId], async () => {
    const filteredCustomers = dummyCustomers.filter(customer => customer.operatorId === operatorId);
    console.log("Filtered Customers:", filteredCustomers);
    return filteredCustomers;
  });
};




// Hook to fetch a single customer by ID from JSON file
const useFetchCustomerById = (customerId: string) => {
  return useQuery<Customer | undefined, AxiosError>(['customer', customerId], async () => {
    const customer = dummyCustomers.find(c => c.customerId === customerId);
    return customer;
  });
};

// Hook to create a customer
const useCreateCustomer = (callback: (customerId: string) => void) => {
  const queryClient = useQueryClient();
  return useMutation<{ data: Customer }, AxiosError, Customer>(
    (newCustomer) => {
      dummyCustomers.push(newCustomer);
      return Promise.resolve({ data: newCustomer });
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries('customers');
        callback(response.data.customerId);
      }
    }
  );
};

// Hook to update a customer
const useUpdateCustomer = (customerId: string, callback: () => void) => {
  const queryClient = useQueryClient();
  return useMutation<{ data: Customer }, AxiosError, Customer>(
    (updatedCustomer) => {
      const index = dummyCustomers.findIndex(customer => customer.customerId === customerId);
      if (index !== -1) {
        dummyCustomers[index] = updatedCustomer;
      }
      return Promise.resolve({ data: updatedCustomer });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['customers', customerId]);
        callback();
      }
    }
  );
};

// Hook to delete a customer
const useDeleteCustomer = (callback: () => void) => {
  const queryClient = useQueryClient();
  return useMutation<{ data: string }, AxiosError, string>(
    (customerId: string) => {
      const index = dummyCustomers.findIndex(c => c.customerId === customerId);
      if (index !== -1) {
        dummyCustomers.splice(index, 1);
      }
      return Promise.resolve({ data: customerId });
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries('customers');
        callback();
      }
    }
  );
};


// Create a company
const useCreateCompany = () => {
  return useMutation<{ id: string }, AxiosError, CompanyCreateRequestDto>(
    (companyPayload) => axios.post(`${HookConfig.memberMgmtUrl}/companies`, companyPayload,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data)
  );
};


const useCheckEmailAlreadyRegistered = (params: {
  email?: string,
  companyid?: string
}, options?: UseQueryOptions<CustomerEmailCompanyDto[], AxiosError>) => {
  const cleanedParams = omitBy(params, isNil);
  return useQuery<CustomerEmailCompanyDto[], AxiosError>(
    ['emailTaken', JSON.stringify(cleanedParams)],
    () =>
      axios.get(`${HookConfig.memberUrl}/emails`, {
        params: cleanedParams,
        withCredentials: true,
        headers: operatorDefaultHeaders(),
      })
        .then((response) => {
          return response.data;
        }),
    {
      ...options
    }
  );
};

// Create an individual
const useCreateIndividual = () => {
  return useMutation<{ id: string }, AxiosError, IndividualCreateRequestDto>(
    (individualPayload) => axios.post(`${HookConfig.memberMgmtUrl}/individuals`, individualPayload,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data)
  );
};

const useCreateContact = ({ companyId }: { companyId: string }) => {
  return useMutation<AxiosResponse, AxiosError, ContactCreateRequestDto>(
    (contactPayload) => axios.post(`${HookConfig.memberMgmtUrl}/companies/${companyId}/contacts`, contactPayload,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then(res => res.data)
  );
}

export {
  useCheckEmailAlreadyRegistered,
  useCreateCompany,
  useCreateCustomer,
  useCreateIndividual,
  useCustomers,
  useDeleteCustomer,
  useFetchCustomerById,
  useFetchCustomers,
  useUpdateCustomer,
  useCreateContact
};

