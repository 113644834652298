import { alpha, styled } from '@mui/material/styles';
import { DataGrid, DataGridProps, gridClasses } from '@mui/x-data-grid';
import { colors } from '../../../main/Theme';

interface CustomDataGridProps extends DataGridProps {
  // You can add more custom props here if needed
}

// Creating a styled version of DataGrid
const CustomDataGridStyle = styled(DataGrid)<DataGridProps>(({ theme }) => ({
  // Customize DataGrid root
  [`&.${gridClasses.root}`]: {
    border: 'none',
    borderRadius: '0px',
    color: theme.palette.text.primary,
    backgroundColor: 'transparent',
    '--DataGrid-overlayHeight': "0"
  },
  // Customize header
  [`& .${gridClasses.columnHeaderTitle}`]: {
    fontWeight: 'normal !important',
    color: alpha(colors.white, 0.5)
  },
  [`& .${gridClasses.columnHeaders}`]: {
    border: 'none',
    borderRadius: '0px !important',
  },
  [`& .${gridClasses.columnHeader}`]: {
    backgroundColor: 'transparent',
    color: '#fff',
    fontWeight: 'normal',
    borderRadius: '0 !important',

    '&:hover': {
      backgroundColor: 'transparent',
    },
    [`& .${gridClasses.columnSeparator}`]: {
      display: 'none'
    }
  },

  // Customize rows
  [`& .${gridClasses.row}`]: {
    backgroundColor: 'transparent !important',
    borderRadius: '0',
    border: 'none',
    padding: '5px 0',
    '&:hover, &.Mui-hovered': {
      backgroundColor: 'transparent',
    },
  },
  [`& .${gridClasses.cell}`]: {
    border: 'none',
    backgroundColor: colors.grey[100],
    outline: 'none !important',
    padding: '8px 16px',
    [`&:first-child`]: {
      borderRadius: '6px 0 0 6px',
    },
    [`&:last-child`]: {
      borderRadius: '0 6px 6px 0',
    }
  },
  [`& .${gridClasses.footerContainer}`]: {
    border: 'none !important'
  },
  [`& .${gridClasses.row}.Mui-selected`]: {
    backgroundColor: theme.palette.secondary.light,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
}));


export const CustomDataGrid = (props: CustomDataGridProps) => {
  const {
    columnHeaderHeight,
    rowHeight,
    disableColumnFilter = true,
    disableColumnSelector = true,
    disableColumnMenu = true,
    disableRowSelectionOnClick = true,
    checkboxSelection = false,
    autoHeight,
    ...rest
  } = props;
  return <CustomDataGridStyle
    columnHeaderHeight={columnHeaderHeight || 25}
    getRowHeight={() => 'auto'}
    disableColumnFilter={disableColumnFilter}
    disableColumnSelector={disableColumnSelector}
    disableColumnMenu={disableColumnMenu}
    disableRowSelectionOnClick={disableRowSelectionOnClick}
    checkboxSelection={checkboxSelection}
    autoHeight
    {...rest}
  />
}