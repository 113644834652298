import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { useNavigate } from "react-router";
import { SectionHead } from "../Common/Help/SectionHead";
import { LabelAndHelp } from "../Common/Help/LabelAndHelp";
import { LR } from "../Common/Help/LR";
import { SaveSnackBar } from "../Common/SaveSnackBar";
import { useDirtyState } from "../../hooks/Common/dirty";
import { ConfirmationChangesDialog } from '../Common/ConfirmationChangesDialog';
import { useCreateTicketPriority, useGetPriorityNameValidity } from "../../hooks/Ticket/ticketPriority";
import { TicketPriorityDto } from "../../types/Ticket/TicketPriorityDto";
import { Tooltip } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { GenerateExternalReference } from "../../functions/utils/helper";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import InfoIcon from '@mui/icons-material/Info';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { debounce } from 'lodash';

type Args =
  {
    userLogged: ApplicationUser
  }

const TicketPriorityAdd = ({ userLogged }: Args) => {
  const defaultTicketPriority: TicketPriorityDto = {
    id: 0,
    partnerMemberId: '',
    name: '',
    label: '',
    internalIdentifier: GenerateExternalReference('TICKET-PRIORITY')
  }
  const nav = useNavigate();
  const [ticketPriorityState, setTicketPriorityState] = useState(defaultTicketPriority);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [isDirty, setDirty, unsetDirty] = useDirtyState();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [targetUrl, setTargetUrl] = useState('');
  const [debouncedName, setDebouncedName] = useState<string>('');
  const { data: isNameValid, loading } = useGetPriorityNameValidity(debouncedName, isDirty);

  const createdCallback = (ticketPriorityId: string) => {
    setSnackbarOpen(true);
    nav(`/ticket-priorities/${ticketPriorityId}`);
  }

  const handleNameChange = useCallback(
    debounce((term) => {
      setDebouncedName(term);
    }, 500),
    []
  );

  useEffect(() => {
    handleNameChange(ticketPriorityState.name);
  }, [ticketPriorityState.name]);

  const homeCallBack = () => {
    const url = `/ticket-priorities`;
    if (isDirty) {
      setShowConfirmation(true);
      setTargetUrl(url);
    } else {
      nav(url);
    }
  };

  const dialogConfirm = () => {
    setShowConfirmation(false);
    unsetDirty();
    nav(targetUrl);
  };

  const dialogCancel = () => {
    setShowConfirmation(false);
  };

  const enableSave = () => {
    return isDirty && isNameValid && !loading && ticketPriorityState.name !== '' && ticketPriorityState.label !== '';
  }

  const handleSnackClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const { mutate } = useCreateTicketPriority(createdCallback);

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();

      if (isDirty) {
        mutate(ticketPriorityState);
        unsetDirty();
      }

    } catch (err: any) {
      console.error(err);
    }
  }

  return (
    <>
      <SectionHead linkCallBack={homeCallBack}
        ctaText={i18n.t('MENU_LABEL_TICKETPRIORITIES')!}
        name={`${ticketPriorityState?.name}`}
        title={i18n.t("MENU_LABEL_TICKETPRIORITIES")}
        description={i18n.t("COMMON_SUMMARY_TICKETMANAGEMENT")} />

      <ConfirmationChangesDialog
        handleConfirm={dialogConfirm}
        handleCancel={dialogCancel}
        showConfirmation={showConfirmation}
      />

      <form onSubmit={onFormSubmit} >

        <div className="row d-flex justify-content-center">
          <div className="col-md-5 col-11 justify-content-center">
            <LabelAndHelp mandatory={false} fullscreen={true} message='TICKET_LABEL_NAME' ></LabelAndHelp>
            <div className="row d-flex justify-content-center mb-2">
              <div className="col-md-11 col-6 justify-content-center">
                <input
                  type="text"
                  className="form-control fa-form"
                  placeholder={i18n.t("TICKET_LABEL_NAME").toString()}
                  value={ticketPriorityState.name}
                  onChange={e => { setTicketPriorityState({ ...ticketPriorityState, name: e.target.value }); setDirty(); }}></input>
              </div>
              <div className="col-md-1 col-6 d-flex align-items-center justify-content-center">
                {loading && (
                  <Tooltip title={i18n.t('COMMON_TOOLTIP_NOTVALIDATED')}>
                    <InfoIcon />
                  </Tooltip>
                )}
                {!isNameValid && !loading && (
                  <Tooltip title={i18n.t('COMMON_TOOLTIP_NOTVALID')}>
                    <ErrorRoundedIcon color="error" className='fa-paper-cta-rejected' />
                  </Tooltip>
                )}
                {isNameValid && !loading && (
                  <Tooltip title={i18n.t('COMMON_TOOLTIP_VALID')}>
                    <CheckCircleRoundedIcon className="fa-paper-cta-good" />
                  </Tooltip>
                )}
              </div>
            </div>

            <LabelAndHelp mandatory={false} fullscreen={true} message='TICKET_LABEL_LABEL' ></LabelAndHelp>
            <div className="row d-flex justify-content-center mb-2">
              <div className="justify-content-center">
                <input
                  type="text"
                  className="form-control fa-form"
                  placeholder={i18n.t("TICKET_LABEL_LABEL").toString()}
                  value={ticketPriorityState.label}
                  onChange={e => { setTicketPriorityState({ ...ticketPriorityState, label: e.target.value }); setDirty(); }}></input>
              </div>
            </div>

            <LabelAndHelp mandatory={false} fullscreen={true} message='TICKET_LABEL_INTERNALIDENTIFIER' ></LabelAndHelp>
            <div className="row d-flex justify-content-center mb-2 disabled-content">
              <div className="justify-content-center">
                <input
                  type="text"
                  className="form-control fa-form"
                  placeholder={i18n.t("TICKET_LABEL_INTERNALIDENTIFIER").toString()}
                  value={ticketPriorityState.internalIdentifier}
                  onChange={e => { setTicketPriorityState({ ...ticketPriorityState, internalIdentifier: e.target.value }); setDirty(); }}></input>
              </div>
            </div>


            <div className="row d-flex justify-content-center mt-3">
              <div className="col-md-4 col-6 justify-content-center">
                <Tooltip title={!enableSave() ? i18n.t('TICKET_REQUIRED_TOOLTIP') : ''}>
                  <span>
                    <button type="submit" disabled={!enableSave()} style={!enableSave() ? { pointerEvents: 'none' } : {}} className="btn btn-green btn-primary btn-block">
                      <LR localResource="COMMON_LABEL_SAVE"></LR>
                    </button>
                  </span>
                </Tooltip>
              </div>
            </div>
            <SaveSnackBar onClose={handleSnackClose} isOpen={snackbarOpen} />
          </div>
        </div>

      </form>
    </>
  );
};

export { TicketPriorityAdd }
