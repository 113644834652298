import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import CustomerTable from './CustomerTable';
import './Customers.css';
import { ApplicationUser } from '../../types/Authentication/ApplicationUser';
import { CustomerSearchItemResponseDto } from '../../types/Customer/CustomerSearchItemResponseDto';
import { useFetchCustomers } from '../../hooks/Customer/useCustomer';
import HeaderDisplay from '../Home/HeaderDisplay';
import { CustomerTypeName } from '../../types/Customer/CustomerSearchResponseDto';

type CustomerFeedProps = {
    userLogged: ApplicationUser;
    searchQuery: string;
    headers?: string[];
    handleArrowClick?: (customer: CustomerSearchItemResponseDto) => void;
    compact?: boolean;
    memberTypeName?: CustomerTypeName;
};

const CustomerFeed = ({ userLogged, searchQuery, compact, headers, handleArrowClick, memberTypeName }: CustomerFeedProps) => {
    const [page, setPage] = useState(1);
    const itemsPerPage = 5;
    const [allCustomers, setAllCustomers] = useState<CustomerSearchItemResponseDto[]>([]);
    // Only proceed with fetching customers if the search query length is 3 characters or more
    const shouldFetchCustomers = searchQuery.length >= 3;
    const effectiveSearchQuery = shouldFetchCustomers ? searchQuery.trim() : '';

    const { data: customersData, isLoading, isError } = useFetchCustomers({
        locationId: userLogged.location?.id!,
        searchRequirement: effectiveSearchQuery,
        pageNumber: 0, // Fetch all data at once
        pageSize: 0, // Pass 0 to fetch all items without pagination from the server
        memberTypeName: memberTypeName
    });

    useEffect(() => {
        if (customersData?.records) {
            setAllCustomers(customersData.records);
            setPage(1); // Reset to the first page after a new search
        }
    }, [customersData]);

    const navigate = useNavigate();

    const defaultHeaders = [
        'Account Reference',
        'Type',
        'Company',
        'First Name',
        'Last Name',
        'Relationship',
        'Orders',
        'Spend'
    ];

    const paginatedCustomers = useMemo(() => {
        const start = (page - 1) * itemsPerPage;
        return allCustomers.slice(start, start + itemsPerPage);
    }, [page, allCustomers]);

    const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
        setPage(newPage);
    };

    const defaultHandleArrowClick = (customer: CustomerSearchItemResponseDto) => {
        console.log(customer.memberTypeName)
        const memberType = customer.memberTypeName as CustomerTypeName;
        if (memberType) {
            const path = {
                Individual: `/individuals/${customer.memberId}/summary`,
                CompanyContact: `/companies/${customer.companyId}/contacts/${customer.memberId}/summary`,
                Company: `/companies/${customer.memberId}/summary`,
            }
            navigate(path[memberType], { state: { customer, userLogged } });
        }
    };

    if (isLoading) return <div>Loading...</div>;


    return (
        <div className="customer-feed">
            <HeaderDisplay headers={headers ?? defaultHeaders} />
            <CustomerTable
                customers={paginatedCustomers}
                headers={headers ?? defaultHeaders}
                onActionClick={handleArrowClick ?? defaultHandleArrowClick}
                userLogged={userLogged}
                compact={compact}
            />

            <Pagination
                count={Math.ceil(allCustomers.length / itemsPerPage)}
                page={page}
                onChange={handleChangePage}
                color="primary"
                className="pagination-bar-customer"
            />
        </div>
    );
};

export default CustomerFeed;
