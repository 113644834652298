import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../../types/Authentication/ApplicationUser";
import ApiStatus from "../../Common/Errors/ApiStatus";
import i18n from "../../../i18n";
import { useFetchLocationById } from "../../../hooks/Location/location";
import { InventoryVariantConfiguration } from "./InventoryVariantConfiguration";
import { InventoryBasePricing } from "./InventoryBasePricing";
import { SectionHead } from "../../Common/Help/SectionHead";
import { OperationalDetails } from "./OperationalDetails";

type Args =
    {
        userLogged: ApplicationUser
    }

const LocationConfigurationDetails = ({ userLogged }: Args) => {

    const nav = useNavigate();
    const { id, configurationName } = useParams();

    if (!id) throw Error("Location id needed");
    if (!configurationName) throw Error("configurationName id needed");

    const linkCallBack = () => {
        nav(`/locations/${id}/configuration/`);
    }

    const { data: location, status: locationStatus, isSuccess: locationIsSuccess } = useFetchLocationById(id!)



    if ((!locationIsSuccess)) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <ApiStatus status={locationStatus} />
                </div>
            </div>
        </>
    )

    if (!location) return <ApiStatus status={locationStatus} />;

    const label = `LOCATION_CONFIGURATION_${configurationName.toUpperCase()}`
    console.log(label)

    const componentToRender: Record<string, JSX.Element> = {
        variants: <InventoryVariantConfiguration locationId={id} />,
        'base-prices': <InventoryBasePricing countryIsoCode3={location.countryIso3Code} locationId={id} />,
        'operational-details': <OperationalDetails />
    }

    return (
        <>
            <SectionHead linkCallBack={linkCallBack} ctaText={i18n.t('LOCATION_LABEL_CONFIGURATION')!}
                name={`${location.name} (${location.externalReference})`}
                title={i18n.t(label)}
                description={i18n.t('COMMON_SUMMARY_LOCATIONMANAGEMENT')} />
            <div className="row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    {
                        componentToRender[configurationName] &&
                        componentToRender[configurationName]
                    }
                </div>
            </div>
        </>
    );
};


export { LocationConfigurationDetails }