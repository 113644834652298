import { GridExpandMoreIcon } from "@mui/x-data-grid"
import { CondensedAccordion, CondensedAccordionDetails, CondensedAccordionSummary } from "../../Common/UI/CondensedAccordion"
import { Grid, SxProps, Theme } from "@mui/material"
import { InvoicePartySummaryDto } from "../../../types/Invoice/InvoiceDto"
import { useFetchInvoicePartyById } from "../../../hooks/Invoice/useInvoiceHooks"
import { useState } from "react"
import { Loader } from "../../Common/UI/Loader"
import i18n from "../../../i18n"
import { CustomSnackbar } from "../../Common/UI/CustomSnackbar"
import { colors } from "../../../main/Theme"

export const InvoiceInvoicePartyDetail = ({ data, invoiceId, currencySymbol }: { data: InvoicePartySummaryDto, invoiceId: string, currencySymbol?: string }) => {
  const { invoicePartyTypeName, id } = data
  const [expand, setExpand] = useState(false)
  const [snackbarContent, setSnackbarContent] = useState<any>()
  const { data: partyDetail, isLoading } = useFetchInvoicePartyById({
    invoiceId: invoiceId,
    partyId: id,
    options: {
      enabled: expand,
      onError: (err) => {
        setSnackbarContent(err.response?.data)
      }
    }
  })

  const { address, memberId, email, externalReference, purchaseOrderNumber, registrationNumber, vatNumber } = partyDetail || {}


  let partyType = null
  if (invoicePartyTypeName === "BillTo") {
    partyType = i18n.t('INVOICE_LABEL_BILL_TO')
  }
  if (invoicePartyTypeName === "Supplier") {
    partyType = i18n.t('INVOICE_LABEL_SUPPLIER')
  }
  if (invoicePartyTypeName === "Location") {
    partyType = i18n.t('INVOICE_LABEL_LOCATION')
  }

  const addressSummary = address && `${address.line1}, ${address.city}, ${address.countryIso3Code}`

  const leftGridStyle: SxProps<Theme> = {
    backgroundColor: colors.grey[300],
    paddingY: 1.5,
    paddingX: 3,
  }
  const rightGridStyle: SxProps<Theme> = {
    paddingY: 1.5,
    paddingX: 3,
  }
  const containerGridStyle: SxProps<Theme> = {
    borderBottom: `1px solid ${colors.grey[100]}`,
    '&:last-child': {
      borderBottom: 'none'
    }
  }

  const itemsToShow = [{
    label: i18n.t('COMMON_TITLE_ADDRESS'),
    value: addressSummary
  },
  {
    label: i18n.t('INVOICE_LABEL_VAT_NUMBER'),
    value: vatNumber
  },
  {
    label: i18n.t('INVOICE_LABEL_PO_NUMBER'),
    value: purchaseOrderNumber
  }, {
    label: i18n.t('COMMON_LABEL_EMAIL'),
    value: email
  }]

  return (
    <CondensedAccordion
      expanded={expand}
      onChange={() => setExpand(!expand)}
    >
      <CondensedAccordionSummary
        expandIcon={<GridExpandMoreIcon />}
        sx={{ pl: 2 }}
      >
        <Grid container>
          <Grid item xs={3}>
            {partyType}
          </Grid>
          <Grid item xs={9} sx={{ pl: 1.5, color: colors.primary }}>
            {data.fullName ?? "-"}
          </Grid>
        </Grid>
      </CondensedAccordionSummary>
      <CondensedAccordionDetails sx={{ p: 0 }}>
        <CustomSnackbar snackbarContent={snackbarContent} setSnackbarContent={setSnackbarContent}></CustomSnackbar>
        {isLoading ? <Loader></Loader>
          : (
            <>
              {itemsToShow.map((item, index) => item.value && (
                <Grid container key={index} sx={containerGridStyle}>
                  <Grid item xs={3} sx={leftGridStyle}>
                    {item.label}
                  </Grid>
                  <Grid item xs={4} sx={rightGridStyle}>
                    {item.value}
                  </Grid>
                </Grid>
              ))}
            </>
          )}
      </CondensedAccordionDetails>
    </CondensedAccordion>
  )
}