import { useQuery } from "react-query";
import axios, { AxiosError } from "axios";
import { HookConfig } from "../../config/HookConfig";
import { operatorDefaultHeaders } from "../../functions/api/api";
import { ProductPortfolioResponseDto } from "../../types/Product/ProductPortfolioResponseDto";
import { useEffect, useState } from "react";
import { PriceDto } from "../../types/Price/PriceDto";

const useFetchPortfolios = (memberId: string) => {
  return useQuery<ProductPortfolioResponseDto[], AxiosError>(["portfolios", memberId], () =>
    axios.get(`${HookConfig.productUrl}/portfolios?operatorMemberId=${memberId}`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data)
  );
}


const useFetchPortfolioPrices = (portfolioId: string, productId: string) => {
  const [data, setData] = useState<PriceDto[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!portfolioId || !productId) return;

    const fetchPortfolioPriceData = async () => {
      setLoading(true);
      try {
        let url = `${HookConfig.productUrl}/portfolios/${portfolioId}/products/${productId}/prices`;
        const response = await axios.get<PriceDto[]>(url, {
          withCredentials: true,
          headers: operatorDefaultHeaders()
        });
        setData(response.data);
      } catch (err) {
        console.error('Error fetching data', err);
      } finally {
        setLoading(false);
      }
    };

    fetchPortfolioPriceData();
  }, [portfolioId, productId]);

  return { data, loading };
};

export {
  useFetchPortfolios,
  useFetchPortfolioPrices
}
