import axios, { AxiosError, AxiosResponse } from "axios";
import { operatorDefaultHeaders } from "../../functions/api/api";
import { HookConfig } from "../../config/HookConfig";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { TicketPriorityDto } from "../../types/Ticket/TicketPriorityDto";
import { useEffect, useState } from "react";

const useFetchTicketPriorities = () => {
  return useQuery<TicketPriorityDto[], AxiosError>(["ticket-priorities"], () =>
    axios.get(`${HookConfig.operatorMgmtUrl}/ticket-priorities`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data)
  );
}

const useFetchTicketPriorityById = (ticketPriorityId: string) => {
  return useQuery<TicketPriorityDto, AxiosError>(["ticket-priority", ticketPriorityId], () =>
    axios.get(`${HookConfig.operatorMgmtUrl}/ticket-priorities/${ticketPriorityId}`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data)
  );
}


const useCreateTicketPriority = (callback: (ticketPriorityId: string) => void) => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError, TicketPriorityDto>(
    (ticketPriorityResponse) => axios.post(`${HookConfig.operatorMgmtUrl}/ticket-priorities`, ticketPriorityResponse,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ),
    {
      onSuccess: (_, ticketPriority) => {
        queryClient.invalidateQueries(["ticket-priority", ticketPriority.id]);
        callback(_.data.id);
      },
      onError: (_, error) => {
        console.log(error);
      }
    }
  );
};

const useUpdateTicketPriority = (callback: (ticketPriorityId: string) => void) => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError, TicketPriorityDto>(
    (ticketPriorityResponse) => axios.put(`${HookConfig.operatorMgmtUrl}/ticket-priorities/${ticketPriorityResponse.id}`, ticketPriorityResponse,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ),
    {
      onSuccess: (_, ticketPriority) => {
        queryClient.invalidateQueries(["ticket-priority", ticketPriority.id]);
        callback(_.data.id);
      },
      onError: (_, error) => {
        console.log(error);
      }
    }
  );
};

const useGetPriorityNameValidity = (priorityName: string, isDirty: boolean) => {
  const [data, setData] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
      const fetchData = async () => {
          setLoading(true);
          try {
              const response = await axios.get(`${HookConfig.operatorMgmtUrl}/ticket-priorities/validity/${priorityName}`,
                {
                  withCredentials: true,
                  headers: operatorDefaultHeaders()
                }
              );
              setData(response.data.isValid);
          } catch (err) {
              console.error('Error fetching data', err);
          } finally {
              setLoading(false);
          }
      };
      if(isDirty && priorityName !== '') {
        fetchData();
      }
  }, [priorityName, isDirty]);

  return { data, loading };
};

export {
  useFetchTicketPriorities,
  useFetchTicketPriorityById,
  useCreateTicketPriority,
  useUpdateTicketPriority,
  useGetPriorityNameValidity
}