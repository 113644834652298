const ApplicationConfig = {
    currentUserStorageKey : "currentUser",
    currentOperatorStorageKey: "_currentOperator",
    currentLocationStorageKey: "_currentLocation",
    partnerStorageKey: "currentPartner",
}


export {
    ApplicationConfig
}