import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Snackbar, Switch, Typography } from "@mui/material";
import { isBoolean, isEmpty } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from 'yup';
import { useDeleteMerchantAccount, useFetchMerchantAccount, useFetchPaymentProvider, usePostMerchantAccount, useUpdateMerchantAccount } from "../../hooks/Order/merchantAccounts";
import i18n from "../../i18n";
import { colors } from "../../main/Theme";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { CustomSelect } from "../Common/formComponents/CustomSelect";
import { Input } from "../Common/formComponents/Input";
import { Label } from "../Common/formComponents/Label";
import { LR } from "../Common/Help/LR";
import { SectionHead } from "../Common/Help/SectionHead";
import { CustomButton } from "../Common/UI/CustomButton";
import { Loader } from "../Common/UI/Loader";
import { CustomSnackbar } from "../Common/UI/CustomSnackbar";

type Args =
    {
        userLogged: ApplicationUser
    }

const PaymentMethodForm = ({ userLogged }: Args) => {
    const nav = useNavigate()
    const { id } = useParams();
    const { data: paymentProviderData, isLoading: fetchingPaymentProvider } = useFetchPaymentProvider();
    const { data: merchantAccountDetail, isLoading: gettingMerchantAccountDetails } = useFetchMerchantAccount({
        id: id,
        options: {
            enabled: !!id,
        }
    });

    const [deleteConfirmation, setDeleteConfirmation] = useState(false)
    const [snackbarContent, setSnackbarContent] = useState<any>()

    const { mutate: postMerchantAccount, isLoading: postingMerchantAccount } = usePostMerchantAccount()
    const { mutate: updateMerchantAccount, isLoading: updatingMerchantAccount } = useUpdateMerchantAccount(id)
    const { mutate: deleteMerchantAccount, isLoading: deletingMerchantAccount } = useDeleteMerchantAccount(id)

    const schema = yup.object().shape({
        pspName: yup.object()
            .required(i18n.t('VALIDATION_PSPNAME_REQUIRED')!)
            .test('not-empty', i18n.t('VALIDATION_PSPNAME_REQUIRED')!, (value) => {
                return value && !isEmpty(value);
            }).shape({
                id: yup.string(),
                name: yup.string(),
                isEnrolmentEnabled: yup.boolean(),
                isPaymentSupported: yup.boolean(),
                label: yup.string(),
                value: yup.string(),
            }),
        pspAccountName: yup.string()
            .nullable()
            .when('pspName', {
                is: (value: any) => !!value?.isPaymentSupported,
                then: (schema) => schema.required(i18n.t('VALIDATION_PSPACCOUNTNAME_REQUIRED').toString()),
                otherwise: (schema) => schema.nullable(),
            }),
        pspAccountIdentifier: yup.string()
            .nullable()
            .when('pspName', {
                is: (value: any) => !!value?.isPaymentSupported,
                then: (schema) => schema.required(i18n.t('VALIDATION_PSPACCOUNTIDENTIFIER_REQUIRED').toString()),
                otherwise: (schema) => schema.nullable(),
            }),
        isRegisteredForTax: yup.boolean(),
        taxRegistrationNumber: yup.string().nullable().when('isRegisteredForTax', {
            is: (value: boolean) => value,
            then: (schema) => schema.required(i18n.t('VALIDATION_TAXREGISTRATIONNUMBER_REQUIRED').toString()),
            otherwise: (schema) => schema.nullable(),
        }),
        taxRate: yup.number().nullable().when('isRegisteredForTax', {
            is: (value: boolean) => value,
            then: (schema) => schema
                .required(i18n.t('VALIDATION_TAXRATE_REQUIRED').toString())
                .min(0, i18n.t('VALIDATION_TAXRATE_MIN').toString())
                .max(100, i18n.t('VALIDATION_TAXRATE_MAX').toString()),
            otherwise: (schema) => schema.nullable(),
        }),
        isDefault: yup.boolean(),
        isDisabled: yup.boolean(),
    });

    type FormType = yup.InferType<typeof schema>

    const { handleSubmit, watch, control, reset } = useForm<FormType>({
        resolver: yupResolver(schema),
        mode: 'onBlur',
        defaultValues: {
            isDefault: false,
            isDisabled: false,
            isRegisteredForTax: false,
        }
    });

    const paymentProvider = watch('pspName')
    const isRegisteredForTax = watch('isRegisteredForTax')

    const isEditMode = !!id;

    const goBack = () => {
        nav('/system/payment-methods')
    }

    const onSubmit = (data: FormType) => {
        if (isEditMode) {
            updateMerchantAccount({
                id: id,
                isDefault: isBoolean(data.isDefault) ? data.isDefault : false,
                isDisabled: isBoolean(data.isDisabled) ? data.isDisabled : false,
                isRegisteredForTax: isBoolean(data.isRegisteredForTax) ? data.isRegisteredForTax : false,
                pspAccountIdentifier: data.pspAccountIdentifier,
                pspAccountName: data.pspAccountName,
                taxRate: data.taxRate,
                taxRegistrationNumber: data.taxRegistrationNumber
            }, {
                onSuccess: () => {
                    goBack()
                },
                onError: (data) => {
                    setSnackbarContent(data.response?.data)
                }
            })
        } else {
            postMerchantAccount({
                pspName: data.pspName.name as string,
                isDefault: isBoolean(data.isDefault) ? data.isDefault : false,
                isDisabled: isBoolean(data.isDisabled) ? data.isDisabled : false,
                isRegisteredForTax: isBoolean(data.isRegisteredForTax) ? data.isRegisteredForTax : false,
                pspAccountIdentifier: data.pspAccountIdentifier,
                pspAccountName: data.pspAccountName,
                taxRate: data.taxRate,
                taxRegistrationNumber: data.taxRegistrationNumber
            }, {
                onSuccess: () => {
                    goBack()
                },
                onError: (data) => {
                    setSnackbarContent(data.response?.data)
                }
            })
        }
    }

    const handleDelete = () => {
        deleteMerchantAccount(id, {
            onSuccess: () => {
                goBack()
            },
            onError: (data) => {
                setSnackbarContent(data.response?.data)
            }
        })
    }

    const isLoading = fetchingPaymentProvider || gettingMerchantAccountDetails
    const isSubmitting = postingMerchantAccount || updatingMerchantAccount

    const formattedPaymentOptions = useMemo(() =>
        paymentProviderData?.map(item => ({
            label: item.name,
            value: item.id,
            ...item,
        })), [paymentProviderData]);

    useEffect(() => {
        if (merchantAccountDetail && formattedPaymentOptions && formattedPaymentOptions?.length > 0) {
            reset({
                isDefault: merchantAccountDetail.isDefault,
                isDisabled: merchantAccountDetail.isDisabled,
                isRegisteredForTax: merchantAccountDetail.isRegisteredForTax,
                pspAccountIdentifier: merchantAccountDetail.pspAccountIdentifier,
                pspAccountName: merchantAccountDetail.pspAccountName,
                pspName: formattedPaymentOptions.find(x => x.name === merchantAccountDetail.pspName),
                taxRate: merchantAccountDetail.taxRate,
                taxRegistrationNumber: merchantAccountDetail.taxRegistrationNumber
            })
        }
    }, [merchantAccountDetail, formattedPaymentOptions, reset])

    return (
        <>
            <CustomSnackbar
                snackbarContent={snackbarContent}
                setSnackbarContent={setSnackbarContent}
                severity="error"
            ></CustomSnackbar>
            <SectionHead linkCallBack={goBack}
                ctaText={i18n.t('COMMON_LABEL_PAYMENT_METHODS')!}
                name={''}
                title={i18n.t(isEditMode ? 'COMMON_LABEL_EDIT_PAYMENT_METHOD' : 'COMMON_LABEL_ADD_PAYMENT_METHOD')!}
                description={i18n.t('COMMON_SUMMARY_SYSTEMMANAGEMENT')} />
            <Dialog open={deleteConfirmation} onClose={() => setDeleteConfirmation(false)}>
                <Card>
                    <CardContent>
                        <DialogTitle><LR localResource='MERCHANTACCOUNT_DELETE_CONFIRMATION_TITLE' /></DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <Typography variant="body1" color="textSecondary">
                                    <LR localResource='MERCHANTACCOUNT_DELETE_CONFIRMATION' />
                                </Typography>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button className='btn-green' onClick={() => setDeleteConfirmation(false)}>
                                <LR localResource='COMMON_BUTTON_CANCEL' />
                            </Button>
                            <CustomButton loading={deletingMerchantAccount} onClick={handleDelete} color="error" autoFocus>
                                <LR localResource='COMMON_BUTTON_DELETE' />
                            </CustomButton>
                        </DialogActions>
                    </CardContent>
                </Card>
            </Dialog>
            <div className="row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    {
                        isLoading ? (
                            <Loader></Loader>
                        ) : (
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Controller
                                            control={control}
                                            name="pspName"
                                            render={({ field, fieldState }) => (
                                                <CustomSelect
                                                    options={formattedPaymentOptions}
                                                    labelProps={{
                                                        mandatory: true,
                                                        message: 'COMMON_LABEL_PSPNAME'
                                                    }}
                                                    isDisabled={isEditMode}
                                                    field={field}
                                                    validationError={fieldState.error}
                                                ></CustomSelect>

                                            )}
                                        />
                                    </Grid>
                                    {
                                        paymentProvider?.isPaymentSupported ? (
                                            <>
                                                <Grid item xs={12}>
                                                    <Controller
                                                        control={control}
                                                        name="pspAccountName"
                                                        render={({ field, fieldState }) => (
                                                            <Input
                                                                placeholder={i18n.t('COMMON_LABEL_PSPACCOUNTNAME').toString()}
                                                                labelProps={{ message: 'COMMON_LABEL_PSPACCOUNTNAME', mandatory: true }}
                                                                validationError={fieldState.error}
                                                                field={field}
                                                            ></Input>

                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Controller
                                                        control={control}
                                                        name="pspAccountIdentifier"
                                                        render={({ field, fieldState }) => (
                                                            <Input
                                                                placeholder={i18n.t('COMMON_LABEL_PSPACCOUNTIDENTIFIER').toString()}
                                                                labelProps={{ message: 'COMMON_LABEL_PSPACCOUNTIDENTIFIER', mandatory: true }}
                                                                validationError={fieldState.error}
                                                                field={field}
                                                            ></Input>

                                                        )}
                                                    />
                                                </Grid>
                                            </>
                                        ) : <></>
                                    }
                                    <Grid item xs={12}>
                                        <Label mandatory={false} message='COMMON_LABEL_ISDEFAULT'></Label>
                                        <Controller
                                            control={control}
                                            name="isDefault"
                                            render={({ field }) => (
                                                <Box sx={{ backgroundColor: colors.grey[100], padding: '3px' }}>
                                                    <Switch
                                                        color="secondary"
                                                        checked={field.value}
                                                        onChange={field.onChange}
                                                    />
                                                    {field.value ? i18n.t('COMMON_LABEL_YES').toString() : i18n.t('COMMON_LABEL_NO').toString()}
                                                </Box>
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Label mandatory={false} message='COMMON_LABEL_COMMISSIONS'></Label>
                                        <Controller
                                            control={control}
                                            name="isRegisteredForTax"
                                            render={({ field }) => (
                                                <Box sx={{ backgroundColor: colors.grey[100], padding: '3px' }}>
                                                    <Switch
                                                        color="secondary"
                                                        checked={field.value}
                                                        onChange={field.onChange}
                                                    />
                                                    {field.value ? i18n.t('COMMON_LABEL_YES').toString() : i18n.t('COMMON_LABEL_NO').toString()}
                                                </Box>
                                            )}
                                        />
                                    </Grid>
                                    {
                                        isRegisteredForTax ?
                                            <>
                                                <Grid item xs={12} md={6}>
                                                    <Controller
                                                        control={control}
                                                        name="taxRegistrationNumber"
                                                        render={({ field, fieldState }) => (
                                                            <Input
                                                                placeholder={i18n.t('COMMON_LABEL_TAXREGISTRATIONNUMBER').toString()}
                                                                labelProps={{ message: 'COMMON_LABEL_TAXREGISTRATIONNUMBER', mandatory: true }}
                                                                validationError={fieldState.error}
                                                                field={field}
                                                            ></Input>

                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Controller
                                                        control={control}
                                                        name="taxRate"
                                                        render={({ field, fieldState }) => (
                                                            <Input
                                                                placeholder={i18n.t('COMMON_LABEL_TAXRATE').toString()}
                                                                labelProps={{ message: 'COMMON_LABEL_TAXRATE', mandatory: true }}
                                                                validationError={fieldState.error}
                                                                field={field}
                                                            ></Input>

                                                        )}
                                                    />
                                                </Grid>
                                            </> :
                                            <></>
                                    }

                                    {
                                        isEditMode &&
                                        <Grid item xs={12}>
                                            <Label mandatory={false} message='COMMON_LABEL_ISDISABLED'></Label>
                                            <Controller
                                                control={control}
                                                name="isDisabled"
                                                render={({ field, fieldState }) => (
                                                    <Box sx={{ backgroundColor: colors.grey[100], padding: '3px'}}>
                                                        <Switch
                                                            color="secondary"
                                                            checked={field.value}
                                                            onChange={field.onChange}
                                                        />
                                                        {field.value ? i18n.t('COMMON_LABEL_YES').toString() : i18n.t('COMMON_LABEL_NO').toString()}
                                                    </Box>
                                                )}
                                            />
                                        </Grid>
                                    }
                                    <Grid container justifyContent={"center"} spacing={2}>
                                        {/* {isEditMode &&
                                            <Grid item xs={12} md={4} mt={3}>
                                                <CustomButton variant="contained" color={'error'} fullWidth type="button" onClick={() => setDeleteConfirmation(true)}>
                                                    {i18n.t('COMMON_BUTTON_DELETE')}
                                                </CustomButton>
                                            </Grid>
                                        } */}
                                        <Grid item xs={12} md={4} mt={3}>
                                            <CustomButton variant="contained" fullWidth type="submit" loading={isSubmitting}>
                                                {i18n.t('COMMON_LABEL_SAVE')}
                                            </CustomButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        )
                    }
                </div>
            </div>
        </>
    );
};


export { PaymentMethodForm };

