import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { useFetchMemberById, } from "../../hooks/Member/member";
import ApiStatus from "../Common/Errors/ApiStatus";
import { CommunicationTypeCollection } from "../Common/CommunicationTypeCollection";
import { CommunicationTypeDto } from "../../types/Common/CommunicationTypeDto";
import { useManageCommunication, useFetchCommsTypes } from "../../hooks/Common/communications";
import i18n from "../../i18n";
import { useDirtyState } from "../../hooks/Common/dirty";
import { useState } from "react";
import { ConfirmationChangesDialog } from "../Common/ConfirmationChangesDialog";
import { SectionHead } from "../Common/Help/SectionHead";
import { SourceEnum, CommonSummaryHeaderProps } from "../../types/Common/CommonSummaryHeaderProps";
import { useFetchLocationById } from "../../hooks/Location/location";
import { MemberResponseDto } from "../../types/Member/MemberResponseDto";
import { LocationResponseDto } from "../../types/Location/LocationResponseDto";
import { useSource } from "../../providers/SourceProvider";

type SourceType = SourceEnum.locations | SourceEnum.legalEntity | SourceEnum.operators | SourceEnum.companies

type Args = {
    userLogged: ApplicationUser,
}

const keyTypeList: Record<SourceType, string> = {
    locations: 'Location',
    "legal-entities": 'Member',
    operators: 'Member',
    companies: 'Member',
}
const baseUrlList: Record<SourceType, string> = {
    locations: '/locations',
    "legal-entities": '/legal-entities',
    operators: '/operators',
    companies: '/companies',
}

const HostCommunicationTypes = ({ userLogged }: Args) => {
    const {source:_source} = useSource()
    const source = _source as SourceType

    const keyType = keyTypeList[source]
    const baseUrl = baseUrlList[source]

    const nav = useNavigate();

    const { id, communicationType } = useParams();
    const { mutate } = useManageCommunication();
    const [isDirty, setDirty, unsetDirty] = useDirtyState();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [targetUrl, setTargetUrl] = useState('');

    if (!id) throw Error(`${source} id needed`);
    if (!communicationType) throw Error("Communication Type is needed");

    const handleSubmitData = (items: CommunicationTypeDto[]) => {
        mutate([id!, communicationType!, items, keyType],{
            onSuccess:() => {
                nav(`${baseUrl}/${id}/summary/communications`)
            }
        });
    }

    const handleDirty = () => {
        setDirty();
    };

    const dialogConfirm = () => {
        setShowConfirmation(false);
        unsetDirty();
        nav(targetUrl);
    }

    const dialogCancel = () => {
        setShowConfirmation(false);
    }

    const isMemberType = [SourceEnum.operators, SourceEnum.legalEntity, SourceEnum.companies].includes(source)
    const isLocationType = [SourceEnum.locations].includes(source)

    const {
        data: memberData,
        status: memberDataStatus,
        isSuccess: memberDataIsSuccess
    } = useFetchMemberById(id!, {
        enabled: isMemberType
    })

    const {
        data: locationData,
        status: locationDataStatus,
        isSuccess: locationDataIsSuccess
    } = useFetchLocationById(id!, {
        enabled: isLocationType
    })

    const { data, status, isSuccess } = useFetchCommsTypes(id!, communicationType!, keyType);

    let communicationData = null
    let communicationDataStatus = null
    let communicationDataIsSuccess = null
    let headerTitle = null

    if(isMemberType) {
        communicationData = memberData as MemberResponseDto
        communicationDataStatus = memberDataStatus
        communicationDataIsSuccess = memberDataIsSuccess
    }
    if(isLocationType) {
        communicationData = locationData as LocationResponseDto
        communicationDataStatus = locationDataStatus
        communicationDataIsSuccess = locationDataIsSuccess
    }

    if ((!isSuccess || !communicationDataIsSuccess) && (status !== 'success') && (communicationDataStatus !== 'success')) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <ApiStatus status={status} />
                </div>
            </div>
        </>
    )

    if (!data || !communicationData) return <ApiStatus status={status} />;

    let commTypeLabel = `COMMON_LABEL_${communicationType?.toUpperCase()}`

    const handleBackLinkCall = () => {
        let url = `${baseUrl}/${id}/summary/communications`;

        if (isDirty) {
            setShowConfirmation(true);
            setTargetUrl(url);
        }
        else {
            nav(url);
        }
    }

    if(isMemberType) {
        headerTitle = `${memberData?.fullName} (${communicationData.externalReference})`
    }
    if(isLocationType) {
        headerTitle = `${locationData?.name} (${communicationData.externalReference})`
    }

    let headerProps: Record<SourceType, CommonSummaryHeaderProps> = {
        operators: {
            description: i18n.t('COMMON_SUMMARY_OPERATORMANAGEMENT')!,
        },
        "legal-entities": {
            description: i18n.t('COMMON_SUMMARY_LEGALENTITYMANAGEMENT')!,
        },
        companies: {
            description: i18n.t('COMMON_SUMMARY_COMPANYMANAGEMENT')!,
        },
        locations: {
            description: i18n.t('COMMON_SUMMARY_LOCATIONMANAGEMENT')!,
        }
    }
    let headerData = headerProps[source]

    return (
        <>
            <ConfirmationChangesDialog
                handleConfirm={dialogConfirm}
                handleCancel={dialogCancel}
                showConfirmation={showConfirmation}
            />
            <SectionHead
                linkCallBack={handleBackLinkCall}
                ctaText={i18n.t('COMMON_SUMMARY_COMMUNICATIONS')!}
                name={headerTitle!}
                title={i18n.t(commTypeLabel!)!}
                description={headerData.description!}
            />
            <div className="row d-flex justify-content-center mt-1 mb-3">
                <div className="col-md-7 col-11">
                    <CommunicationTypeCollection
                        handleDirty={handleDirty}
                        items={data}
                        submitData={handleSubmitData}
                    />
                </div>
            </div>

        </>
    );
};


export { HostCommunicationTypes }

