import { useNavigate, useParams } from "react-router-dom";
import { getSummaryLabel } from "../../hooks/Common/labels";
import { useFetchLocationById, useFetchLocationSummaryById } from "../../hooks/Location/location";
import i18n from "../../i18n";
import { useSource } from "../../providers/SourceProvider";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { SummaryItemDto } from "../../types/Common/SummaryItemDto";
import ApiStatus from "../Common/Errors/ApiStatus";
import { SectionHead } from "../Common/Help/SectionHead";
import { SummaryItemCollection } from "../Common/SummaryItems/SummaryItemCollection";

type Args =
    {
        userLogged: ApplicationUser
    }

const LocationSummary = ({ userLogged }: Args) => {

    const { source } = useSource()
    const nav = useNavigate();
    const { id, summaryName } = useParams();

    if (!id) throw Error("Location id needed");
    const titleLabel = getSummaryLabel(summaryName!);

    const linkCallBackCollection = (item: SummaryItemDto) => {
        const navHandle: Record<string, string> = {
            "LocationSummary": `/${source}/${id}/locationdetails`,
            "DescriptionSummary": `/${source}/${id}/description`,
            "CommunicationSummary": `/${source}/${id}/summary/communications`,
            "FeatureSummary": `/${source}/${id}/features`,
            "DigitalAssetSummary": `/${source}/${id}/summary/digital-assets`,
            "FloorPlansDocumentsSummary": `/${source}/${id}/digital-assets/${item.uri.substring(item.uri.indexOf('group=') + 6)}`,
            "ImagesSummary": `/${source}/${id}/digital-assets/${item.uri.substring(item.uri.indexOf('group=') + 6)}`,
            "VideosVirtualToursSummary": `/${source}/${id}/digital-assets/${item.uri.substring(item.uri.indexOf('group=') + 6)}`,
            "PhoneSummary": `/${source}/${id}/communications/phone`,
            "LocationInventorySummary": `/${source}/${id}/inventories`,
            "EmailSummary": `/${source}/${id}/communications/email`,
            "SocialSummary": `/${source}/${id}/communications/social`,
            "WebSummary": `/${source}/${id}/communications/web`,
            "LocationConfigurationSummary": `/${source}/${id}/configuration`,
            "NearBySummary": `/${source}/${id}/near-by-amenities`,
        }
        const path = navHandle[item.name]
        if (path) {
            nav(path);
        } else {
            alert(`Item ${item.name} does not have a link yet`);
        }
    }

    const linkCallBack = () => {
        nav(`/${source}/${id}/summary`);
    }

    const { data: location, status: locationStatus, isSuccess: locationIsSuccess } = useFetchLocationById(id!)
    const { data, status, isSuccess } = useFetchLocationSummaryById(id!, summaryName!);

    if ((!isSuccess || !locationIsSuccess) && (status !== 'success') && (locationStatus !== 'success')) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <ApiStatus status={status} />
                </div>
            </div>
        </>
    )

    if (!data || !location) return <ApiStatus status={status} />;

    return (
        <>
            {!summaryName &&
                <SectionHead
                    linkCallBack={()=>nav('/admin')}
                    ctaText={i18n.t("COMMON_LABEL_ADMIN")!}
                    name={`${location.name} (${location.externalReference})`}
                    title={i18n.t("LOCATION_SUMMARY_LOCATIONMAINPAGE")!}
                    description={i18n.t("COMMON_SUMMARY_LOCATIONMANAGEMENT")}
                />
            }
            {summaryName &&
                <SectionHead
                    linkCallBack={linkCallBack}
                    ctaText={i18n.t("LOCATION_SUMMARY_LOCATIONMAINPAGE")!}
                    name={`${location.name} (${location.externalReference})`}
                    title={i18n.t(titleLabel!)}
                    description={i18n.t('COMMON_SUMMARY_LOCATIONMANAGEMENT')}
                />
            }
            <div className={`row d-flex justify-content-center `}>
                <div className="col-md-7 col-11 justify-content-center">
                    <SummaryItemCollection linkCallBack={linkCallBackCollection} items={data}></SummaryItemCollection>
                </div>
            </div>
        </>
    );
};


export { LocationSummary };
