import { IconButton, Input, InputAdornment } from "@mui/material";
import i18n from "../../i18n";
import { useEffect, useState } from "react";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import ApiStatus from "../Common/Errors/ApiStatus";
import MessageAlert from "../Common/Errors/MessageAlert";
import { DialogCustomerSelector } from "./DialogCustomerSelector";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { CustomerSearchItemResponseDto } from "../../types/Customer/CustomerSearchItemResponseDto";
import { useFetchMemberById } from "../../hooks/Member/member";

type Args =
  {
    userLogged: ApplicationUser,
    customerMemberId: string,
    placeholder?: string,
    handleConfirm: (customer: CustomerSearchItemResponseDto) => void
  }

const CustomerSelector = ({ userLogged, customerMemberId, placeholder, handleConfirm }: Args) => {
  const { data: member, status, isSuccess } = useFetchMemberById(customerMemberId);
  const [open, setOpen] = useState(false);
  const [customerName, setCustomerName] = useState('');

  useEffect(() => {
    if (customerMemberId && member) {
      setCustomerName(member.fullName);
    }
  }, [customerMemberId, member]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleValueSelection = (customer: CustomerSearchItemResponseDto) => {
    setCustomerName(customer.firstName + ' ' + customer.lastName);
    handleConfirm(customer);
    handleClose();
  };

  const getPlaceholder = () => {
    return i18n.t(placeholder ?? "LOCATION_LABEL_LEGALENTITY").toString();
  }

  if (!isSuccess) return (
    <>
      <div className="mt-5 row d-flex justify-content-center">
        <div className="col-md-5 col-11 justify-content-center">
          <ApiStatus status={status} />
        </div>
      </div>
    </>
  )

  if (!member) return <MessageAlert message="MEMBER_LABEL_MEMBERSNOTFOUND"></MessageAlert>;

  return (
    <>
      <DialogCustomerSelector open={open} userLogged={userLogged} handleCancel={handleClose} handleConfirm={handleValueSelection}></DialogCustomerSelector>
      <div>
        <Input
          style={{ opacity: 1 }}
          type="text"
          className="form-control fa-form"
          placeholder={getPlaceholder()}
          value={customerName}
          disabled
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={handleOpen}>
                <SearchRoundedIcon style={{ color: "white" }} />
              </IconButton>
            </InputAdornment>
          }
        />
      </div>
    </>
  );
};

export { CustomerSelector }