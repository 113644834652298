import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import OrderTable from './common/OrderTable';  
import './common/OrderTable.css'
import { ApplicationUser } from '../../types/Authentication/ApplicationUser';
import { OrderSearchV2ItemResponseDto } from '../../types/Order/OrderSearchV2ItemResponseDto';
import { getOrderData } from '../../hooks/Order/getOrderData';
interface OrderFeedProps {
  userLogged: ApplicationUser;
  searchQuery?: string;
  fromDate: string;
  toDate: string;
}

const OrderFeed: React.FC<OrderFeedProps> = ({ userLogged, searchQuery = "", fromDate, toDate }) => {
  const navigate = useNavigate();
  const itemsPerPage = 5;
  
  const [page, setPage] = useState(1);
  const [allOrders, setAllOrders] = useState<OrderSearchV2ItemResponseDto[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const shouldFetchOrders = searchQuery.length >= 3;
  const effectiveSearchQuery = shouldFetchOrders ? searchQuery.trim() : '';

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      setError(false);
      try {
        const params = {
          locationId: userLogged.location!.id,
          searchRequirement: effectiveSearchQuery,
          pageNumber: 0,
          pageSize: 0,  
          fromDate,
          toDate,
        };

        console.log('Fetching orders with params:', params);  

        const data = await getOrderData(params);

        if (data?.records) {
          setAllOrders(data.records);
          setPage(1); 
          setTotalRecords(data.totalRecords || 0); 
        } else {
          setAllOrders([]);
          setTotalRecords(0);
        }
      } catch (err) {
        console.error('Error fetching orders:', err);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [fromDate, toDate, effectiveSearchQuery, userLogged.location!.id]);

  const paginatedOrders = useMemo(() => {
    const start = (page - 1) * itemsPerPage;
    return allOrders.slice(start, start + itemsPerPage);
  }, [page, allOrders]);

  const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  const handleOrderClick = (order: OrderSearchV2ItemResponseDto) => {
    navigate(`/orders/${order.orderId}/view-order`);
  };

  const headers = [
    'Order Number',
    'Product',
    'Customer',
    'Company',
    'From Date',
    'To Date',
    'Space'
  ];

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading orders</div>;

  return (
    <div className="order-feed">
      {allOrders.length === 0 ? (
        <div>No bookings found</div> 
      ) : (
        <>
          <OrderTable 
            orders={paginatedOrders}  
            headers={headers}  
            onActionClick={handleOrderClick} 
            userLogged={userLogged} 
          />
          <Pagination
            count={Math.ceil(totalRecords / itemsPerPage)}
            page={page}
            onChange={handleChangePage}
            color="primary"
            className="pagination-bar-order"
          />
        </>
      )}
    </div>
  );
};

export { OrderFeed };


