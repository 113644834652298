import { Alert, Snackbar } from "@mui/material";
import { LR } from "./Help/LR";

type Args = {
    isOpen: boolean,
    onClose: () => void
};

export const SaveSnackBar = ({ isOpen, onClose }: Args) => (
    <Snackbar open={isOpen} autoHideDuration={3000} onClose={onClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={onClose} variant="filled" severity="success" sx={{ width: '100%' }}>
            <LR localResource='COMMON_LABEL_SAVE_SUCCESS'></LR>
        </Alert>
    </Snackbar>
)

