import { IconDisplayer } from "./IconDisplayer";
import { LR } from "./LR";

type Args = {
    label: string,
    fullscreen?: boolean
    hideIcon?:boolean

};

const TitleWithIcon = ({ label, fullscreen, hideIcon }: Args) => {
    return (
        <div className="row d-flex justify-content-center">
            <div className={`row d-flex justify-content-center align-items-center ${fullscreen ? "justify-content-center" : 'col-md-7 col-11 justify-content-center'}`}>
                <p className="lead">
                    { !hideIcon &&
                        <IconDisplayer className='iconTitle' type={label}></IconDisplayer>
                    }
                    <LR localResource={label} />
                </p>
            </div>
        </div>
    );
}

export {
    TitleWithIcon
}