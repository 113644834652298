import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { LR } from "../Common/Help/LR";
import i18n from "../../i18n";
import ApiStatus from '../Common/Errors/ApiStatus';
import MessageAlert from '../Common/Errors/MessageAlert';
import "../Common/react-draft-wysiwyg.css";
import { SectionHead } from '../Common/Help/SectionHead';
import { useFetchMembershipById, useUpdateMembershipDescription } from '../../hooks/Memberships/membership';
import { MembershipResponseDto } from '../../types/Membership/MembershipResponseDto';
import CustomQuillEditor from '../Common/Input/CustomQuillEditor';
import { SaveSnackBar } from '../Common/SaveSnackBar';
import { useDirtyState } from '../../hooks/Common/dirty';
import { ConfirmationChangesDialog } from '../Common/ConfirmationChangesDialog';
import 'react-quill/dist/quill.snow.css';
import './DescriptionHandler.css';
import '../../main/App.css'
import { pontConstants } from '../../data/pontConstants';
import { useSource } from '../../providers/SourceProvider';
import { SourceEnum } from '../../types/Common/CommonSummaryHeaderProps';
import { CustomButton } from '../Common/UI/CustomButton';


type Args = {
  userLogged: ApplicationUser,
  idToUse: string
}

export const DescriptionHandler = ({ userLogged, idToUse }: Args) => {
  const nav = useNavigate();
  const params = useParams();
  const { source } = useSource();

  const { id, inventoryId } = params

  const memberId = params[idToUse]

  if (!memberId) throw Error(`${idToUse} needed`);

  const emptyMembershipResponseDto: MembershipResponseDto = {
    id: memberId,
    name: '',
    inventoryTypeName: '',
    locationId: pontConstants.emptyGuid,
    inventoryVariantId: pontConstants.emptyGuid,
    quantity: 0,
    quantityLimitPerOrder: 0,
    externalReference: '',
    isDisabled: false,
    internalIdentifier: '',
    fromDate: new Date(),
    toDate: new Date(),
    prices: [],
    soldQuantity: 0,
    locationName: '',
    shortDescription: '',
    description: '',
  };

  const [descriptionState, setDescriptionState] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const { data: membership, status, isSuccess } = useFetchMembershipById(memberId)
  const [useMembership, setUseMembership] = useState(emptyMembershipResponseDto);

  const [isDirty, setDirty, unsetDirty] = useDirtyState();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [targetUrl, setTargetUrl] = useState('');

  useEffect(() => {
    if (isSuccess && membership) {
      setUseMembership(membership);
      setDescriptionState(membership.description);
    }
  }, [isSuccess, membership]);

  const handleDescriptionChange = (value: string) => {
    setDescriptionState(value);
    setUseMembership({ ...useMembership, description: value });
    setUnsavedChanges(true);
    setDirty()
  }

  const backLinkCollection: Partial<Record<SourceEnum, string>> = {
    [SourceEnum.locations]: `/${source}/${id}/inventories/${inventoryId}`,
  }

  const ctaCollection: Partial<Record<SourceEnum, string>> = {
    [SourceEnum.locations]: i18n.t("INVENTORY_SUMMARY").toString(),
  }

  const descriptionCollection: Partial<Record<SourceEnum, string>> = {
    [SourceEnum.locations]: i18n.t("COMMON_SUMMARY_LOCATIONMANAGEMENT").toString(),
  }

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await mutateAsync(useMembership, {
        onSuccess: () => {
          nav(backLinkCollection[source]!)
        }
      });
      setSnackbarOpen(true);
      unsetDirty();
      setUnsavedChanges(false);
      setShowConfirmation(false);
    } catch (err: any) {
      console.error(err);
    }
  };


  const linkCallBack = () => {
    let url = backLinkCollection[source];
    if (!url) {
      alert(`Back link not found for ${source}`)
      return
    }

    if (isDirty && unsavedChanges) {
      setShowConfirmation(true);
      setTargetUrl(url);
    } else {
      nav(url);
    }
  };

  const dialogConfirm = () => {
    setShowConfirmation(false);
    unsetDirty();
    nav(targetUrl);
  };

  const dialogCancel = () => {
    setShowConfirmation(false);
  };


  const handleSnackClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };


  const { mutateAsync, isLoading } = useUpdateMembershipDescription(linkCallBack);

  if (!isSuccess) return (
    <>
      <div className="mt-5 row d-flex justify-content-center">
        <div className="col-md-7 col-11 justify-content-center">
          <ApiStatus status={status} />
        </div>
      </div>
    </>
  )

  if (!membership) return <MessageAlert message="MEMBER_LABEL_MEMBERNOTFOUND" params={{ param0: memberId }}></MessageAlert>;

  return (
    <form onSubmit={onFormSubmit}>
      <SectionHead linkCallBack={linkCallBack} ctaText={ctaCollection[source]}
        name={`${membership.name} (${membership.externalReference})`}
        title={i18n.t('COMMON_SUMMARY_DESCRIPTIONS')}
        description={descriptionCollection[source]!} />

      {showConfirmation && (
        <ConfirmationChangesDialog
          handleConfirm={dialogConfirm}
          handleCancel={dialogCancel}
          showConfirmation={showConfirmation}
        />
      )}

      <CustomQuillEditor
        value={descriptionState}
        onChange={handleDescriptionChange}
        placeholder={i18n.t("MEMBERSHIP_LABEL_DESCRIPTION", { membershipName: membership.name })}
      />

      <div className="row d-flex justify-content-center mt-3">
        <div className="col-md-2 col-6 justify-content-center">
          <CustomButton type="submit" disabled={!isDirty} variant='contained' color='secondary' fullWidth loading={isLoading}>
            <LR localResource="COMMON_LABEL_SAVE"></LR>
          </CustomButton>
        </div>
      </div>
      {unsavedChanges && (
        <div className="alert alert-center" role="alert">
          <p className="warning-label">
            {i18n.t('WARNING_LABEL')}
          </p>
        </div>
      )}
      <SaveSnackBar onClose={handleSnackClose} isOpen={snackbarOpen} />
    </form>
  );
};
