import { useEffect, useState } from "react";
import i18n from "../../../i18n";
import { LR } from "../../Common/Help/LR";
import { LabelAndHelp } from "../../Common/Help/LabelAndHelp";
import {  InventoryTypeSelectOption } from "../../../types/Inventory/InventoryTypeDto";
import { useFetchInventoryTypes, useManageInventoryVariants } from "../../../hooks/Inventory/inventory";
import { InventoryVariantDto } from "../../../types/Inventory/InventoryVariantDto";
import { operatorDefaultHeaders } from "../../../functions/api/api";
import Select, { SingleValue } from "react-select";
import axios from "axios";
import { HookConfig } from "../../../config/HookConfig";
import ApiStatus from "../../Common/Errors/ApiStatus";
import React from "react";
import MessageAlert, { AlertStyle } from "../../Common/Errors/MessageAlert";
import { SaveSnackBar } from "../../Common/SaveSnackBar";

type Args = {
    locationId: string

};

const InventoryVariantConfiguration = ({ locationId, }: Args) => {

    const [submitting, setSubmitting] = useState(false);
    const [inventoryTypes, setInventoryTypes] = useState<InventoryTypeSelectOption[]>([]);
    const [inventoryVariants, setInventoryVariants] = useState<InventoryVariantDto[]>([]);
    const [selectedInventoryType, setSelectedInventoryType] = useState<InventoryTypeSelectOption>();
    const { data, status, isSuccess } = useFetchInventoryTypes();
    const [isFormValid, setIsFormValid] = useState(false);
    const variantsMutation = useManageInventoryVariants();
    const [snackbarOpen, setSnackbarOpen] = useState(false);


    const success = () => {
        setSnackbarOpen(true);
    }

    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            setSubmitting(true);
            variantsMutation.mutate([inventoryVariants, success])


        } catch (err: any) {
            setSubmitting(false)
            console.error(err);
        } finally {
            setSubmitting(false);
        }
    }

    useEffect(() => {
        if (data) {
            const selectOptions = data.map((inventoryType) => ({
                label: i18n.t(inventoryType.label),
                value: inventoryType.name,
                quantityVisible: inventoryType.quantityVisible,
                unitName: inventoryType.unitName
            }));

            setInventoryTypes(selectOptions);
            setSelectedInventoryType(selectOptions[0]);
        }
    }, [data]);




    useEffect(() => {
        const fetchData = async () => {
            try {
                if (selectedInventoryType) {
                    const response = await axios.get(`${HookConfig.inventoryUrl}/inventory-variants/${locationId}/${selectedInventoryType.value}`,
                        {
                            withCredentials: true,
                            headers: operatorDefaultHeaders()
                        }
                    );
                    const variants: InventoryVariantDto[] = response.data;
                    setInventoryVariants(variants);

                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [selectedInventoryType]);


    const handleDropdownChange = (value: SingleValue<InventoryTypeSelectOption>) => {
        var selectedType = inventoryTypes.find(x => x.value === value?.value);
        setSelectedInventoryType(selectedType);
    };

    const handleChangeVariant = (item: InventoryVariantDto, value: string) => {
        var selectedVariant = inventoryVariants.find(x => x.name === item.name);
        if (selectedVariant) {
            selectedVariant.value = value;
        }
        validateForm(selectedVariant!);

        const updatedVariants = inventoryVariants.map(variant =>
            variant.name === item.name ? { ...variant, ...selectedVariant } : variant
        );
        setInventoryVariants(updatedVariants);
    }

    const validateForm = (item: InventoryVariantDto): boolean => {

        let response = true;
        if (item.isMandatory) {
            response = Boolean(item.value)
        }

        setIsFormValid(response);
        return response;
    };

 //#region snackbar

 const handleSnackClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
        return;
    }

    setSnackbarOpen(false);
};
//#endregion snackbar




    if (!selectedInventoryType || !inventoryTypes || !inventoryVariants) return <ApiStatus status={"loading"} />;


    return (
        <>
            <form onSubmit={onFormSubmit}>
            <SaveSnackBar onClose={handleSnackClose} isOpen={snackbarOpen}></SaveSnackBar>
                <LabelAndHelp mandatory={false} fullscreen={true} message='INVENTORY_LABEL_TYPE' ></LabelAndHelp>
                <div className="row d-flex justify-content-center mb-2">
                    <div className="justify-content-center">

                        <Select value={selectedInventoryType} options={inventoryTypes} className="form-control fa-form fa-dropdown-container" classNamePrefix="fa-dropdown"
                            onChange={(e) => handleDropdownChange(e)} placeholder={i18n.t('INVENTORY_LABEL_TYPE')}></Select>
                    </div>
                </div>


                {inventoryVariants.map((item, index) => (
                    <React.Fragment key={index}>
                        <LabelAndHelp mandatory={item.isMandatory} fullscreen={true} message={item.label} ></LabelAndHelp>
                        <div className="row d-flex justify-content-center mt-1 mb-1">
                            <div className="justify-content-center">
                                <input type="text"
                                    value={item.value}
                                    id={item.name}
                                    className="form-control fa-form"
                                    onChange={(e) => { handleChangeVariant(item, e.target.value); }}
                                    placeholder={i18n.t(`${item.label}`).toString()}
                                    required={item.isMandatory}>
                                </input>
                            </div>
                        </div>

                    </React.Fragment>
                ))}
                <div className="row d-flex justify-content-center mt-2">
                    <div className="col-6 justify-content-center">
                        <button className="btn btn-green btn-primary btn-block" disabled={submitting || !isFormValid} type="submit">
                            <LR localResource="COMMON_LABEL_SAVE"></LR>
                        </button>
                    </div>
                </div>
                {(variantsMutation.isError) &&
                <div className="row d-flex justify-content-center">
                    <div className="col-md-4 col-11 justify-content-center">
                        <MessageAlert message={variantsMutation.error.message} variant="danger" style={AlertStyle.Tiny} additionalClassName="fa-alert-tiny"></MessageAlert>
                    </div>
                </div>
            }
            </form>
        </>

    );
}


export { InventoryVariantConfiguration }