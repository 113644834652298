import React from 'react';


type Args = {
  fileName: string;
  titleAccess?: string;
}


const FileIcon = ({ fileName, titleAccess }: Args) => {
  const getFileExtension = () => {
    const extension = fileName.substring(fileName.lastIndexOf('.') + 1);
    return extension.toLowerCase();
  };

  const renderFileIcon = () => {
    const fileExtension = getFileExtension();

    switch (fileExtension) {
      case 'txt':
      case 'csv':
        return <img src="/images/preview/csv.png" style={{ width: '80%' }} alt={titleAccess} />;
      case 'pdf':
        return <img src="/images/preview/pdf.png" style={{ width: '80%' }} alt={titleAccess} />;
      case 'mp4':
      case 'avi': 
      case 'm4v':
      case 'mov':
        return <img src="/images/preview/video.png" style={{ width: '80%' }} alt={titleAccess} />;
      case 'doc':
      case 'docx':
        return <img src="/images/preview/word.png" style={{ width: '80%' }} alt={titleAccess} />;
      case 'xls':
      case 'xlsx':
        return <img src="/images/preview/excel.png" style={{ width: '80%' }} alt={titleAccess} />;
      default:
        return <img src="/images/preview/folder.png" style={{ width: '80%' }} alt={titleAccess} />;
    }
  };

  return <span>{renderFileIcon()}</span>;
};

export default FileIcon;
