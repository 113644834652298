import { Box, Grid } from "@mui/material";
import { differenceWith, isEqual } from "lodash";
import React, { useState } from "react";
import { useManageMemberFiscalDetails } from "../../hooks/Member/fiscaldetails";
import i18n from "../../i18n";
import { FiscalDetailDto } from "../../types/Member/FiscalDetailDto";
import { Input } from "../Common/formComponents/Input";
import { CustomButton } from "../Common/UI/CustomButton";



type Args = {
  entityKey: string,
  entityName: string,
  items: FiscalDetailDto[],
  handleDirty: () => void,
  afterSave: () => void
};

const FiscalDetailsCollection = ({ entityKey, entityName, items, handleDirty, afterSave }: Args) => {


  const [isValueChanged, setIsValueChanged] = useState<boolean>(false);

  // const isFormValid = items.every((item) => item.value.trim() !== '');
  const [itemsState, setItemsState] = useState<FiscalDetailDto[]>(items);
  const [isFormValidState, setIsFormValidState] = useState<boolean>(true);
  const { mutate, isLoading } = useManageMemberFiscalDetails();

  const setItemValue = (item: FiscalDetailDto, value: string) => {
    let updatedItem = { ...item, value: value };
    updatedItem = validateForm(updatedItem);
    const updatedItemsState = itemsState.map((i) => (i === item ? updatedItem : i));

    setIsFormValidState(updatedItemsState.every((item) => !item.errorMessage));

    setItemsState(updatedItemsState);

    const isValueChanged = isEqual(
      items.map(({ errorMessage, ...rest }) => rest),
      updatedItemsState.map(({ errorMessage, ...rest }) => rest)
    );

    setIsValueChanged(!isValueChanged)
  };

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      if (isValueChanged && isFormValidState) {
        // Get values which are changed
        const valueToSubmit = differenceWith(
          itemsState.map(({ errorMessage, ...rest }) => rest),
          items.map(({ errorMessage, ...rest }) => rest),
          isEqual
        ) as FiscalDetailDto[];

        mutate([entityKey, valueToSubmit], {
          onSuccess: () => {
            afterSave()
          }
        });
      }
    } catch (err: any) {
      console.error(err);
    }
  }



  const validateForm = (item: FiscalDetailDto): FiscalDetailDto => {
    const ibantools = require('ibantools');
    switch (item.fiscalDetailTypeName.toUpperCase()) {
      case "IBAN":
        if (item.value === "") {
          item.errorMessage = ""
          return item
        }
        const iban = ibantools.electronicFormatIBAN(item.value);
        const validation = ibantools.validateIBAN(iban);
        if (!validation.valid) {
          item.errorMessage = "MEMBER_ERROR_IBANFORMATTING"
        } else {
          item.value = ibantools.friendlyFormatIBAN(iban);
          item.errorMessage = ""
        }
        return item;
      default:
        return item;
    }
  };


  return (
    <Box sx={{ width: '100%' }}>
      <form onSubmit={onFormSubmit}>
        <Grid container spacing={2}>
          {itemsState.map((item, index) => (
            <Grid item xs={12} key={index}>
              <Input
                type="text"
                value={item.value}
                onChange={(e) => { setItemValue(item, e.target.value); handleDirty() }}
                placeholder={i18n.t(item.label).toString()}
                labelProps={{
                  message: item.label,
                  mandatory: false
                }}
                validationError={item?.errorMessage ? { message: i18n.t(item?.errorMessage).toString() } : undefined}
              />
            </Grid>
          ))}
          <Grid item xs={12} md={4} mx={'auto'} mt={3}>
            <CustomButton
              variant="contained"
              fullWidth
              type="submit"
              loading={isLoading}
              disabled={!(isValueChanged && isFormValidState)}
            >
              {i18n.t('COMMON_LABEL_SAVE')}
            </CustomButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

export { FiscalDetailsCollection };

