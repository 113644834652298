import { alpha, ButtonPropsColorOverrides, Typography } from "@mui/material";
import { colors } from "../../../main/Theme";
import "./loader.css"
import i18n from "../../../i18n";
import { Box } from "@mui/system";
export const Loader = ({ message }: { message?: string }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
      <div className="sk-chase" style={{ '--loader-color': alpha(colors.primary, 0.6) } as React.CSSProperties}>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
      </div>
      <Typography variant="body1" mt={5}>{message ?? i18n.t('COMMON_LABEL_LOADING')}</Typography>
    </Box>
  );
}

export const ButtonLoader = ({color}:{color?: string}) => {
  let _color = colors.primary;

  if (color === 'error') {
    _color = colors.white;
  }

  return (
    <Box display={'flex'} alignItems={"center"} justifyContent={"center"} height={24} width={'100%'}>
      <div className="button-loader" style={{ '--loader-color': _color } as React.CSSProperties}></div>
    </Box>
  );
}