import { Navbar } from 'react-bootstrap';
import { LR } from '../Common/Help/LR';



const Footer = () => {

    return (

        <Navbar fixed="bottom" className="justify-content-center">
            <hr className="separator" />
            <div className='mb-2'>
                <div className="row d-flex justify-content-center">
                    <div className="col-8 d-flex justify-content-center">
                        <hr className="hr hr-blurry" />
                    </div>
                </div>
            </div>
        </Navbar>
    );
};

export default Footer;