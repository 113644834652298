import { Box } from "@mui/material";
import { colors } from "../../../main/Theme";

export const ValidationError = ({ message }: { message?: string }) => {
  if (!message) return null;
  return (
    <Box sx={{ color: colors.red[100], fontSize: '0.8rem', marginTop: '0.5rem' }}>
      <span>{message}</span>
    </Box>
  );
};