import React from 'react';

interface CancelTotalProps {
  label: string;
  info?: number;
  value?: number;
}

const CancelTotal: React.FC<CancelTotalProps> = ({ label, info, value }) => {
  const displayContent = (info !== undefined) ? info : value;

  return (
    <>
      <div className='order-status-main-wrapper'>
        {/* <div className='order-status-main'> */}
          <div className='order-comment-left'>{label}</div>
          <div className='order-comment-right cancel-total-right'>{displayContent}</div>
        {/* </div> */}
      </div>
    </>
  );
};

export { CancelTotal };
