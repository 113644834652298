import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import ApiStatus from "../Common/Errors/ApiStatus";
import i18n from "../../i18n";
import { useFetchLocationById } from "../../hooks/Location/location";
import { useFetchDigitalAssetsRequirements } from "../../hooks/Common/fileUpload";
import { getSummaryLabel } from "../../hooks/Common/labels";
import { FileuploadContainer } from "../Common/Upload/FileUploadContainer";
import { SectionHead } from "../Common/Help/SectionHead";
import { useDirtyState } from "../../hooks/Common/dirty";
import { ConfirmationChangesDialog } from '../../components/Common/ConfirmationChangesDialog';
import { useState } from "react";


type Args = {
    userLogged: ApplicationUser;
}

const LocationDigitalAssets = ({ userLogged }: Args) => {
    const [isDirty, setDirty, unsetDirty] = useDirtyState();
    console.log("🚀 ~ LocationDigitalAssets ~ isDirty:", isDirty)
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [targetUrl, setTargetUrl] = useState('');

    const nav = useNavigate();
    const queryStringParams = useParams();

    if (!queryStringParams.id) throw Error("Location id needed");
    if (!queryStringParams.groupName) throw Error("Digital Asset Type id needed");

    const titleLabel = getSummaryLabel(queryStringParams.groupName!);

    const dialogConfirm = () => {
        setShowConfirmation(false);
        unsetDirty();
        nav(targetUrl);
    };

    const dialogCancel = () => {
        setShowConfirmation(false);
    };

    const linkCallBack = () => {
        const url = `/locations/${queryStringParams.id}/summary/`;

        if (isDirty) {
            setTargetUrl(url);
            setShowConfirmation(true);
        } else {
            unsetDirty();
            nav(url);
        }
    };

    const { data: location, status: locationStatus, isSuccess: locationIsSuccess } = useFetchLocationById(queryStringParams.id!);
    const { data, status, isSuccess } = useFetchDigitalAssetsRequirements('location', queryStringParams.id!, queryStringParams.groupName!);

    if ((!isSuccess || !locationIsSuccess) && (status !== 'success') && (locationStatus !== 'success')) {
        return (
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <ApiStatus status={locationStatus} />
                </div>
            </div>
        );
    }

    if (!location || !data) return <ApiStatus status={status} />;

    return (
        <>
            <SectionHead
                linkCallBack={linkCallBack}
                ctaText={i18n.t("LOCATION_SUMMARY_LOCATIONMAINPAGE")!}
                name={`${location.name} (${location.externalReference})`}
                title={i18n.t(titleLabel!)}
                description={i18n.t('COMMON_SUMMARY_LOCATIONMANAGEMENT')}
            />

            <FileuploadContainer
                items={data}
                groupname={queryStringParams.groupName!}
                setDirty={setDirty}
                unsetDirty={unsetDirty}
            />

            <ConfirmationChangesDialog
                handleConfirm={dialogConfirm}
                handleCancel={dialogCancel}
                showConfirmation={showConfirmation}
            />
        </>
    );
};

export { LocationDigitalAssets };
