import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../../types/Authentication/ApplicationUser";
import { SummaryItemDto } from "../../../types/Common/SummaryItemDto";
import { useFetchPostById } from "../../../hooks/Posts/posts";
import ApiStatus from "../../Common/Errors/ApiStatus";
import { SectionHead } from "../../Common/Help/SectionHead";
import i18n from "../../../i18n";
import { SummaryItemCollection } from "../../Common/SummaryItems/SummaryItemCollection";
import { useFetchFeaturesByPostId } from "../../../hooks/Posts/postFeatures";

type Args =
    {
        userLogged: ApplicationUser
    }

const PostFeatures = ({ userLogged }: Args) => {

    const nav = useNavigate();

    const queryStringParams = useParams();
    if (!queryStringParams.id) throw Error("Application id needed");
    if (!queryStringParams.postId) throw Error("Post id needed");
    const applicationId = queryStringParams.id;
    const postId = queryStringParams.postId;



    const linkCallBackCollection = (item: SummaryItemDto) => {
        switch (item.name) {
            default:
                nav(`/application/${applicationId}/configuration/posts/${postId}/features/${item.uri.substring(item.uri.indexOf('group=') + 6)}`);
                break;
        }
    }

    const linkCallBack = () => {
        nav(`/application/${applicationId}/configuration/posts/${postId}`);
    }


    const { data: post, status: locationStatus, isSuccess: locationIsSuccess } = useFetchPostById(postId)
    const { data, status, isSuccess } = useFetchFeaturesByPostId(postId);

    if ((!isSuccess || !locationIsSuccess) && (status !== 'success') && (locationStatus !== 'success')) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <ApiStatus status={status} />
                </div>
            </div>
        </>
    )

    if (!data || !post) return <ApiStatus status={status} />;

    return (
        <>

            <SectionHead linkCallBack={linkCallBack} ctaText={i18n.t("COMMON_SUMMARY_POSTS")!}
                name={`${post.title}`}
                title={i18n.t('COMMON_SUMMARY_FEATURES')}
                description={i18n.t('COMMON_SUMMARY_POSTS')} />

            <div className="row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    {data &&
                        <SummaryItemCollection linkCallBack={linkCallBackCollection} items={data}></SummaryItemCollection>
                    }
                </div>
            </div>

        </>
    );
};


export { PostFeatures }