import React from 'react';
import './Invoice.css';

interface SingleRowProps {
  label: string;
  data: string;
}

const SingleRow: React.FC<SingleRowProps> = ({ label, data }) => {
  return (
    <div className='invoice-singlerow-wrapper'>
        <div className='invoice-singlerow-main'>
            <div className='invoice-left'>{label}</div>
            <div className='invoice-right'>{data}</div>
        </div>
    </div>
  );
};

export { SingleRow };
