import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { InvoiceLocation } from './Invoice/InvoiceLocation';
import { InvoiceCustomer } from './Invoice/InvoiceCustomer';
import { SingleRow } from './Invoice/SingleRow';
import { InvoiceLineDropDown } from './Invoice/InvoiceLineDropDown';

import './Invoice/Invoice.css';
import { ApplicationUser } from '../../types/Authentication/ApplicationUser';
import { useFetchInvoiceById } from '../../hooks/Invoice/useInvoiceHooks';
import { SectionHead } from '../Common/Help/SectionHead';
import i18n from '../../i18n';

interface ViewInvoiceProps {
  userLogged: ApplicationUser;
}

const ViewInvoice: React.FC<ViewInvoiceProps> = ({ userLogged }) => {
  const { id } = useParams<{ id?: string }>(); 
  const invoiceId = parseInt(id ?? '0'); 
  const { data: invoice, isLoading, isError } = useFetchInvoiceById(invoiceId);
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  if (isLoading) return <div>Loading...</div>;
  if (isError || !invoice) return <div>Error fetching invoice or invoice not found.</div>;

  return (
    <>  
      <SectionHead 
        linkCallBack={goBack}
        ctaText={i18n.t('Go Back') || 'Go Back'}
        name={`Invoice ID: ${invoiceId}`}
        title={i18n.t('Invoice') || 'Invoice'}
        description={i18n.t('Detailed view of the selected Invoice') || 'Detailed view of the selected Invoice'}
      />
      <div className='view-invoice-container'>
        <div className='view-invoice-inner'>
          <InvoiceLocation location='Location' address={invoice.location} />
          {invoice.customers.map((customer, index) => (
            <InvoiceCustomer key={index} location={customer.role} address={customer.address} additionalData={customer.additionalData} />
          ))}
          {invoice.locations.map((location, index) => (
            <SingleRow key={index} label={location.label} data={location.data} />
          ))}
          {invoice.lineItems.map((item, index) => (
            <InvoiceLineDropDown key={index} location={item.role} address={item.address} additionalData={item.additionalData} />
          ))}
        </div>
      </div>
    </>
  );
};

export { ViewInvoice };
