import axios, { AxiosError, AxiosResponse } from "axios";
import { useMutation, useQuery, UseQueryOptions } from "react-query"
import { operatorDefaultHeaders } from "../../functions/api/api";
import { HookConfig } from "../../config/HookConfig";
import { MerchantAccountCreateRequestDto, MerchantAccountGetByIdResponseDto, MerchantAccountUpdateRequestDto } from "../../types/Order/merchantAccount";

type PaymentProvider = {
  id: string
  name: string
  isEnrolmentEnabled: boolean
  isPaymentSupported: boolean
}

export const useFetchPaymentProvider = () => {
  return useQuery<PaymentProvider[], AxiosError>(["orderManagement-payment-providers"], () => {
    return axios.get(`${HookConfig.orderUrl}/payment-providers`, {
      withCredentials: true,
      headers: operatorDefaultHeaders()
    })
      .then((resp) => resp.data);
  });
}



export const useFetchMerchantAccount = ({
  id,
  options
}: {
  id?: string,
  options?: UseQueryOptions<MerchantAccountGetByIdResponseDto, AxiosError>
}) => {
  return useQuery<MerchantAccountGetByIdResponseDto, AxiosError>(["orderManagement-get-merchant-account", id], () => {
    return axios.get(`${HookConfig.orderUrl}/merchant-accounts/${id}`, {
      withCredentials: true,
      headers: operatorDefaultHeaders()
    })
      .then((resp) => resp.data);
  }, { ...options });
}

export const usePostMerchantAccount = () => {
  return useMutation<AxiosResponse, AxiosError, MerchantAccountCreateRequestDto>((payload) => {
    return axios.post(`${HookConfig.orderUrl}/merchant-accounts`, payload, {
      withCredentials: true,
      headers: operatorDefaultHeaders()
    });
  });
}

export const useUpdateMerchantAccount = (id?: string) => {
  return useMutation<AxiosResponse, AxiosError, MerchantAccountUpdateRequestDto>((payload) => {
    return axios.put(`${HookConfig.orderUrl}/merchant-accounts/${id}`, payload, {
      withCredentials: true,
      headers: operatorDefaultHeaders()
    });
  });
}

export const useDeleteMerchantAccount = (id?: string) => {
  return useMutation<AxiosResponse, AxiosError, any>((_) => {
    return axios.delete(`${HookConfig.orderUrl}/merchant-accounts/${id}`, {
      withCredentials: true,
      headers: operatorDefaultHeaders()
    });
  });
}