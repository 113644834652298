import Select, { SingleValue } from "react-select";
import { pontCountriesLabels } from "../../data/en/pontCountriesLabels";
import i18n from "../../i18n";
import { useEffect, useState } from "react";
import useFetchVisitor from "../../hooks/Common/visitor";
import { pontCountries } from "../../data/en/pontCountries";




type Args = {    
    inputCountry?: string | null,  
    valueChanged: (value: string) => void;
};

const CountryDropDown = ({ inputCountry, valueChanged }: Args) => {    
    const { data : visitor, status, isSuccess } = useFetchVisitor();  
    const [selectedCountry, setSelectedCountry] = useState<any>(null);

      useEffect(() => {
        if(!inputCountry || inputCountry === '')
        {
            const defaultCountry = pontCountriesLabels.find(i => i.value === visitor?.countryCode);   
            setSelectedCountry(defaultCountry);
            if(visitor?.countryCode !== undefined){
                const selectedValue =  pontCountries.find(i => i.Iso2Code === visitor?.countryCode)?.Iso3Code!
                valueChanged(selectedValue);
            }
        }
      }, [visitor?.countryCode]);


      useEffect(() => {
        if(inputCountry && inputCountry!== '')
        {
            const iso2Value = pontCountries.find(i => i.Iso3Code === inputCountry)?.Iso2Code!
            if(iso2Value)
            {
                const selectedCountry = pontCountriesLabels.find(i => i.value === iso2Value);   
                setSelectedCountry(selectedCountry);        
                valueChanged(inputCountry!);
            }
        }        
      }, [inputCountry]);



    const ChangeCountry = (value : SingleValue<{value:string, label: string|undefined}>) => {        
        setSelectedCountry({
            ...selectedCountry,
            value: (value?.value !== undefined ? value.value : ''),
            label: (value?.label !== undefined ? value.label : '')
        });
        
        const selectedValue =  pontCountries.find(i => i.Iso2Code === value?.value!)?.Iso3Code!
        valueChanged(selectedValue);
    }   
    
    if (!isSuccess) return <><span>{status}...</span></>;  

    return (
        <>           
            <Select options={pontCountriesLabels} className="form-control fa-form fa-dropdown-container"  classNamePrefix="fa-dropdown" 
            value={selectedCountry}            
             onChange={(e) => ChangeCountry(e)} placeholder={i18n.t('COMMON_LABEL_COUNTRY')}></Select>                                                   
        </>
    );
}

export default CountryDropDown;