import React, { useState } from 'react';
import { useNavigate } from "react-router";
import { IconButton, Tooltip } from "@mui/material";
import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { useFetchTickets } from "../../hooks/Ticket/ticket";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import ClassRoundedIcon from '@mui/icons-material/ClassRounded';
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
import { LR } from "../Common/Help/LR";
import { SearchInput } from '../Common/SearchInput';
import TicketTable from './TicketTable'; 
import './Ticket.css';

type Args = {
  userLogged: ApplicationUser;
};

const Tickets = ({ userLogged }: Args) => {
  const nav = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const { data: ticketResponse, status, isSuccess } = useFetchTickets();

  const handleSearchChange = (query: string) => {
    setSearchQuery(query);
  };

  const addNewItem = () => {
    nav(`/tickets/add`);
  };

  const categoryManagement = () => {
    nav(`/ticket-categories/`);
  };

  const priorityManagement = () => {
    nav(`/ticket-priorities/`);
  };

  if (!isSuccess)
    return <div>Loading...</div>; 

  if (!ticketResponse || !ticketResponse.records || ticketResponse.records?.length === 0)
    return <div>No tickets found.</div>; 

  return (
    <div className="mainpage-container-layout">
      <div className="col-md-10 col-11 mt-2 justify-content-center">
      <div className="row d-flex justify-content-center">
            <div className="col-md-10 col-4">
              <Tooltip title={i18n.t("TICKET_LABEL_NEWTICKET")}>
                <IconButton onClick={addNewItem} className="btn plusButton mb-1">
                  <AddCircleRoundedIcon fontSize="medium" sx={{ color: "#00be95" }} />
                </IconButton>
              </Tooltip>
              <span className="lead mb-0">
                <LR localResource="TICKET_LABEL_NEWTICKET" />
              </span>
              <Tooltip title={i18n.t("TICKET_LABEL_CATEGORYMANAGEMENT")}>
                <IconButton onClick={categoryManagement} className="btn plusButton mb-1">
                  <ClassRoundedIcon fontSize="medium" sx={{ color: "#00be95" }} />
                </IconButton>
              </Tooltip>
              <span className="lead mb-0">
                <LR localResource="TICKET_LABEL_CATEGORYMANAGEMENT" />
              </span>
              <Tooltip title={i18n.t("TICKET_LABEL_PRIORITYMANAGEMENT")}>
                <IconButton onClick={priorityManagement} className="btn plusButton mb-1">
                  <PriorityHighRoundedIcon fontSize="medium" sx={{ color: "#00be95" }} />
                </IconButton>
              </Tooltip>
              <span className="lead mb-0">
                <LR localResource="TICKET_LABEL_PRIORITYMANAGEMENT" />
              </span>
            </div>
            <div className="col-md-2"></div>
          </div>

        <div className="ticket-container-child">
          <div className="ticket-container-search-wrapper">
            <SearchInput onSearchChange={handleSearchChange} searchQuery={searchQuery} placeholder={'SEARCH_BY_TICKET'} />
          </div>
          <TicketTable tickets={ticketResponse.records} /> 
        </div>
      </div>
    </div>
  );
};

export { Tickets };
