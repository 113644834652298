import DangerousIcon from '@mui/icons-material/Dangerous';
import { Box, Card, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { colors } from "../../../main/Theme";
import { LR } from "../Help/LR";
import { CustomButton } from "./CustomButton";
import { CustomDialogStyle } from './CustomDialog';

type DeleteConfirmationDialogArgs = {
  open: boolean
  onClose: () => void
  isLoading: boolean
  confirm: () => void
  text?: string
}


export const DeleteConfirmationDialog = ({ open, onClose, isLoading, confirm, text }: DeleteConfirmationDialogArgs) => {
  return (
    <CustomDialogStyle open={open} onClose={onClose}>
      <Card sx={{
        background: colors.grey[500],
        padding: 3
      }}>
        <DialogTitle sx={{ p: 1 }}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            color: colors.red[100]
          }}>
            <DangerousIcon sx={{ height: 32, width: 32 }}></DangerousIcon>
            <Box sx={{
              fontWeight: 'bold',
              pl: 1
            }}><LR localResource='TITLE_DANGER' /></Box>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ p: 1 }}>
          <Typography variant="body1" color="textSecondary">
            {text}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: 1, pt: 4 }}>
          <CustomButton variant="text" onClick={onClose} color="secondary">
            <LR localResource='COMMON_BUTTON_CANCEL' />
          </CustomButton>
          <CustomButton loading={isLoading} onClick={confirm} variant="contained" color={"error"} autoFocus>
            <LR localResource='COMMON_BUTTON_DELETE' />
          </CustomButton>
        </DialogActions>
      </Card>
    </CustomDialogStyle>
  )
}