import React, { useState } from 'react';
import { IconDisplayer } from '../../Common/Help/IconDisplayer';


interface OrderLineProps {
  orderId: number;
  orderType: string;
  orderDate: string;
  price: number;
  room?: string;
  fromDate: string;
  toDate: string;
  quantity: number;
  listPrice: number;
  discountAmount?: number;
  discountReason?: string;
  totalPrice: number;
  options: {
    saveChanges: () => void;
    delete: () => void;
  };
}

const OrderLine: React.FC<OrderLineProps> = ({
  orderId, orderType, orderDate, price, room, fromDate, toDate, quantity,
  listPrice, discountAmount, discountReason, totalPrice, options
}) => {
  const [showMore, setShowMore] = useState(false);
  const toggleShowMore = () => setShowMore(!showMore);

  return (
    <>
      <div className='order-section-main-wrapper'>
        <div className='order-section-main'>
          <div className='order-comment-left'>{orderId}</div>
          <div className='order-section-group-right'>
            <div className='order-comment-right'>{orderType} - {orderDate}</div>
            <div className='order-section-arrow' onClick={toggleShowMore}>
            <IconDisplayer type={showMore ? 'COMMON_LABEL_UP_ARROW' : 'COMMON_LABEL_DOWN_ARROW'} />
            </div>
          </div>
        </div>
        {showMore && (
          <div className={`order-section-additional ${showMore ? 'show' : ''}`}>
            <div className='order-section-additional-inner'>
              <div className='order-section-additional-children'>
                <div className='order-aditional-inner-options'>Room</div>
                <div className='order-aditional-inner-data'>{room || '-'}</div>
              </div>
              <div className='order-section-additional-children'>
                <div className='order-aditional-inner-options'>From Date</div>
                <div className='order-aditional-inner-data'>{fromDate}</div>
              </div>
              <div className='order-section-additional-children'>
                <div className='order-aditional-inner-options'>To Date</div>
                <div className='order-aditional-inner-data'>{toDate}</div>
              </div>
              <div className='order-section-additional-children'>
                <div className='order-aditional-inner-options'>Quantity</div>
                <div className='order-aditional-inner-data'>{quantity}</div>
              </div>
              <div className='order-section-additional-children'>
                <div className='order-aditional-inner-options'>List Price</div>
                <div className='order-aditional-inner-data'>£{listPrice.toFixed(2)}</div>
              </div>
              <div className='order-section-additional-children'>
                <div className='order-aditional-inner-options'>Discount Amount</div>
                <div className='order-aditional-inner-data'>£{discountAmount ? discountAmount.toFixed(2) : '-'}</div>
              </div>
              <div className='order-section-additional-children'>
                <div className='order-aditional-inner-options'>Discount Reason</div>
                <div className='order-aditional-inner-data'>{discountReason || '-'}</div>
              </div>
              <div className='order-section-additional-children'>
                <div className='order-aditional-inner-options'>Total Price</div>
                <div className='order-aditional-inner-data'>£{totalPrice.toFixed(2)}</div>
              </div>
              <div className='order-section-additional-children'>
                <div className='children-options options'>Options</div>
                <div className='children-save save'>Save Changes</div>
                <div className='children-delete delete'>Delete</div>
            </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export { OrderLine };

