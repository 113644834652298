import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { useFetchMemberById } from "../../hooks/Member/member";
import ApiStatus from "../Common/Errors/ApiStatus";
import { SectionHead } from "../Common/Help/SectionHead";
import i18n from "../../i18n";

type Args =
    {
        userLogged: ApplicationUser
    }

const ApplicationNetworksConfiguration = ({ userLogged }: Args) => {

    const nav = useNavigate();
    const queryStringParams = useParams();
    if (!queryStringParams.configurationName) throw Error("ConfigurationName id needed");

    const label = `APPLICATION_LABEL_${queryStringParams.configurationName.toUpperCase()}`
    const linkCallBack = () => {
        nav(`/application/configuration/`);
    }

    const { data: operator, status: operatorStatus, isSuccess: operatorIsSuccess } = useFetchMemberById(userLogged.operator?.id!)

    if ((!operatorIsSuccess)) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <ApiStatus status={operatorStatus} />
                </div>
            </div>
        </>
    )

    if (!operator) return <ApiStatus status={operatorStatus} />;

    return (
        <>

        </>
    );
};


export { ApplicationNetworksConfiguration }