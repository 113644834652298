import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import {  Collapse, IconButton, List, Paper, Tooltip } from '@mui/material';
import ApiStatus from '../../Common/Errors/ApiStatus';
import MessageAlert from '../../Common/Errors/MessageAlert';
import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import { useFetchLocations } from '../../../hooks/Location/location';
import i18n from '../../../i18n';
import HelpIcon from '@mui/icons-material/Help';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import SettingsIcon from '@mui/icons-material/Settings';
import { ApplicationUser } from '../../../types/Authentication/ApplicationUser';
//import { GlobalStateContext } from '../Common/GlobalStateProvider';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { LR } from '../../Common/Help/LR';


type Args = {
  userLogged: ApplicationUser
  setHelp: (value: string) => void
}

const Orders = ({ userLogged, setHelp }: Args) => {

  // const globalState = React.useContext(GlobalStateContext);
  // if (!globalState) {
  //   throw new Error('Used GlobalStateContext outside of GlobalStateProvider');
  // }

  // const { globalLoggedUser, setGlobalLoggedUser } = globalState;



  const nav = useNavigate();

   // eslint-disable-next-line react-hooks/rules-of-hooks
   const [isListVisible, setIsListVisible] = useState(false);

   const handleToggleList = () => {
     setIsListVisible(!isListVisible);
   };

  const { data: locations, status, isSuccess } = useFetchLocations(userLogged.operator?.id!);//globalLoggedUser?.operator?.id!;

  const [locationIds, setLocationIds] = useState<string[]>([]);

  useEffect(() => {
    if (isSuccess && locations) {
      const ids = locations.map((item) => item.id);
      setLocationIds(ids);
    }
  }, [isSuccess, locations]);



  const editLocation = (locationId: string) => {
    nav(`/locations/${locationId}/summary`);
  }

  if (!isSuccess) return (
    <>
      <div className="mt-5 row d-flex justify-content-center">
        <div className="col-md-7 col-11 justify-content-center">
          <ApiStatus status={status} />
        </div>
      </div>
    </>
  )

  if (!locations) return <MessageAlert message="MEMBER_LABEL_MEMBERSNOTFOUND"></MessageAlert>;

  return (
    <>

<Box sx={{ minWidth: 300, maxWidth: 300, minHeight: 400, maxHeight: 400 }}>
      <Card className='card-container'>
        <React.Fragment>
          {/* Header with Title and Arrow */}
          <CardContent className='DashBoardCard' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography className='cardTitle' gutterBottom>
              <LR localResource='COMMON_LABEL_ORDERS'></LR>
            </Typography>
            <IconButton className="fa-arrow" onClick={handleToggleList}>
              {!isListVisible && <ArrowForwardIosRoundedIcon />}
              {isListVisible && <ArrowForwardIosRoundedIcon className='fa-arrow-down' />}
            </IconButton>
          </CardContent>

          <div className="list-container" >
            {/* Initial Display */}
            {!isListVisible && (
              <div className='cardCounter ordersCardCounter'>
                <Typography variant="h1"> 11</Typography>
              </div>
            )}

            {/* List Container */}
            <Collapse in={isListVisible}>
              <List component="nav" aria-label="main mailbox folders">
                {locations.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Paper key={index} elevation={3} className="fa-paper-card">
                        <div className="d-flex align-items-center">
                          <div className="fa-name flex-grow-1 fa-name-card">
                            {item.name}
                          </div>
                          <div className="fa-icons">
                            <IconButton onClick={(event) => editLocation(item.id)}>
                              <ArrowForwardIosRoundedIcon className='fa-arrow-small' />
                            </IconButton>
                          </div>
                        </div>
                      </Paper>
                    </React.Fragment>
                  );
                })}
              </List>

            </Collapse>
          </div>

          {/* Card Actions */}
          {/* <CardActions className='DashBoardCard'
            disableSpacing
            sx={{
              alignSelf: "stretch",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-start",
              p: 0,
            }}>
            <IconButton aria-label="share" className='btn plusButton mr-2' href="/locations/add">
              <Tooltip title={i18n.t('LOCATION_TITLE_NEWLOCATION')}>
                <AddCircleRoundedIcon  sx={{ color: '#00be95' }} />
              </Tooltip>
            </IconButton>
            <IconButton aria-label="share" href="/locations">
              <Tooltip title={i18n.t('LOCATION_TITLE_SEARCHLOCATIONS')}>
                <SearchRoundedIcon  />
              </Tooltip>
            </IconButton>
            <IconButton aria-label="share" href="/locations/configuration">
              <SettingsIcon />
            </IconButton>
            <IconButton aria-label="share" onClick={(e) => setHelp('LOCATION_MESSAGE_NEWLOCATION')}>
              <Tooltip title={i18n.t('COMMON_LABEL_HELP')}>
                <HelpIcon  />
              </Tooltip>
            </IconButton>
          </CardActions> */}
        </React.Fragment>
      </Card>
    </Box>
    </>
  );

}

export { Orders }
  