import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Drawer as MuiDrawer, List, ListItem, ListItemButton, ListItemIcon, Typography, Divider } from '@mui/material';
import { IconDisplayer } from '../Common/Help/IconDisplayer';
import { useNavigate, useLocation } from 'react-router-dom';
import i18n from '../../i18n';
import { ApplicationUser } from '../../types/Authentication/ApplicationUser';

const desktopDrawerWidth = 70;

const Drawer = styled(MuiDrawer)(({ theme }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  '& .MuiDrawer-paper': {
    backgroundColor: '#2c2c2c',
    marginTop: '5.6rem',
    width: desktopDrawerWidth,
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      top: 790,
      bottom: 0,
      left: 0,
      right: 0,
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '60px',
      zIndex: 1300,
    },
  },
}));

type Args = {
  userLogged: ApplicationUser;
};

const Sidebar = ({ userLogged }: Args) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeType, setActiveType] = useState<string>('');

  useEffect(() => {
    // Retrieve the last active item from localStorage and update state
    const lastActiveType = localStorage.getItem('lastActiveType');
    if (lastActiveType) {
      setActiveType(lastActiveType);
    }
  }, []);

  useEffect(() => {
    // Update localStorage and state when location changes
    const type = Object.keys(navigationMapping).find(key => navigationMapping[key] === location.pathname);
    if (type) {
      localStorage.setItem('lastActiveType', type);
      setActiveType(type);
    }
  }, [location.pathname]);

  const navigationMapping: { [key: string]: string } = {
    COMMON_LABEL_ACTIVITY: '/',
    COMMON_LABEL_CALENDAR: '/calendar-view',
    COMMON_LABEL_CUSTOMERS: '/customers',
    COMMON_LABEL_ORDERS: '/orders',
    COMMON_SUMMARY_LOCATION: `/locations/${userLogged.location?.id!}/summary`,
    COMMON_SUMMARY_INVENTORY: '/inventory',
    COMMON_SUMMARY_TICKETS: '/tickets',
    COMMON_LABEL_REPORTS: '/reports',
    COMMON_SUMMARY_MOBILEAPP: '/application/configuration',
    COMMON_LABEL_ADMIN: '/admin',
  };

  // Mapping to hold the display names for each type
  const displayNames: { [key: string]: string } = {
    COMMON_LABEL_ACTIVITY: 'COMMON_LABEL_ACTIVITY',
    COMMON_LABEL_CALENDAR: 'COMMON_LABEL_CALENDAR',
    COMMON_LABEL_CUSTOMERS: 'COMMON_LABEL_CUSTOMERS',
    COMMON_LABEL_ORDERS: 'COMMON_LABEL_ORDERS',
    COMMON_SUMMARY_LOCATION: 'COMMON_SUMMARY_LOCATION',
    COMMON_SUMMARY_INVENTORY: 'COMMON_SUMMARY_INVENTORY',
    COMMON_SUMMARY_TICKETS: 'COMMON_SUMMARY_TICKETS',
    COMMON_LABEL_REPORTS: 'COMMON_LABEL_REPORTS',
    COMMON_SUMMARY_MOBILEAPP: 'COMMON_SUMMARY_MOBILEAPP',
    COMMON_LABEL_ADMIN: 'COMMON_LABEL_ADMIN',
  };

  const getMenuText = (type: string) =>  {
    if(i18n.isInitialized) {
      const translated = i18n.t(type);
      return translated;
    }
  };


  const handleNavigation = (type: string) => {
    const path = navigationMapping[type] ?? '/';
    navigate(path);
    setActiveType(type); 
  };

  return (
    <Drawer variant="permanent">
      <Divider key={'divider'} />
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            margin: 0,
          },
        }}
      >
        {Object.entries(navigationMapping).map(([type, path]) => {
          const isActive = type === activeType;
          return (
            <div key={`${type}sidebar`} className='sideBar-menuItem'>
            <ListItem
              disablePadding
              key={type}
              sx={{
                textAlign: 'center',
                [theme.breakpoints.up('sm')]: { width: '100%' },
                [theme.breakpoints.down('sm')]: { flex: '1 1 auto', margin: theme.spacing(0.5) },
              }}
            >
              <ListItemButton
                onClick={() => handleNavigation(type)}
                key={`${type}button`}
                className='siteBar-menuItem-button'
                sx={{
                  flexDirection: 'column',
                  alignItems: 'center',
                  color: isActive ? '#00be95' : '#fff',
                  backgroundColor: isActive ? '#333' : 'inherit',
                  '&:hover': { backgroundColor: '#444' },
                  [theme.breakpoints.down('sm')]: { padding: theme.spacing(0.5) },
                }}
              >
                <ListItemIcon
                  key={`${type}icon`}
                  sx={{
                    minWidth: 0,
                    color: isActive ? '#00be95' : '#fff',
                    fontSize: '18px',
                  }}
                >
                  <IconDisplayer type={type} />
                </ListItemIcon>
                <Typography
                  key={`${type}typography`}
                  variant="caption"
                  sx={{
                    fontSize: '9px',
                    color: isActive ? '#00be95' : '#fff',
                    fontWeight: isActive ? 'bold' : 'normal',
                  }}
                >
                  {getMenuText(displayNames[type])}
                </Typography>
              </ListItemButton>
            </ListItem>
            </div>
          );
        })}
      </List>
    </Drawer>
  );
};

export default Sidebar;
