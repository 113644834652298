import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { InvoiceLineSummaryDto } from "../../../types/Invoice/InvoiceDto";
import { CondensedAccordion, CondensedAccordionDetails, CondensedAccordionSummary } from "../../Common/UI/CondensedAccordion";
import { Box, Grid, SxProps, Theme } from "@mui/material";
import { useFetchInvoiceSummaryLineById, useFetchInvoiceSummaryLineTaxLinesById } from "../../../hooks/Invoice/useInvoiceHooks";
import React, { useState } from "react";
import { Loader } from "../../Common/UI/Loader";
import { formatCurrency, readableDate } from "../../../functions/utils/helper";
import i18n from "../../../i18n";
import { CustomSnackbar } from "../../Common/UI/CustomSnackbar";
import { colors } from "../../../main/Theme";

export const InvoiceSummaryLine = ({ data, invoiceId, lineNumber, currencySymbol }: { data: InvoiceLineSummaryDto, invoiceId: string, lineNumber: number, currencySymbol?: string }) => {
  const { id, description, fromDate, toDate, totalPriceExTax } = data
  const [expand, setExpand] = useState(false)
  const [expandTax, setExpandTax] = useState(false)
  const [snackbarContent, setSnackbarContent] = useState<any>()
  const { data: invoiceLineDetail, isLoading } = useFetchInvoiceSummaryLineById({
    invoiceId,
    invoiceLineId: id,
    options: {
      enabled: expand,
      onError: (err) => {
        setSnackbarContent(err.response?.data)
      }
    }
  })
  const { data: invoiceTaxLineDetail } = useFetchInvoiceSummaryLineTaxLinesById({
    invoiceId,
    invoiceLineId: id,
    options: {
      enabled: expand,
      onError: (err) => {
        setSnackbarContent(err.response?.data)
      }
    }
  })

  const {
    orderId,
    quantity,
    unitName,
    unitPriceExTax,
    invoiceLineTaxes,
    isAdjustment,
    orderExternalReference,
    orderLineQuantity,
    totalTax,
    totalPriceIncTax
  } = invoiceLineDetail || {}

  const leftGridStyle: SxProps<Theme> = {
    backgroundColor: colors.grey[300],
    paddingY: 1.5,
    paddingX: 3,
  }
  const rightGridStyle: SxProps<Theme> = {
    paddingY: 1.5,
    paddingX: 3,
  }
  const containerGridStyle: SxProps<Theme> = {
    borderBottom: `1px solid ${colors.grey[100]}`,
    '&:last-child': {
      borderBottom: 'none'
    }
  }


  return (
    <CondensedAccordion
      expanded={expand}
      onChange={() => setExpand(!expand)}
    >
      <CondensedAccordionSummary
        expandIcon={<GridExpandMoreIcon />}
      >
        <Grid container>
          <Grid item xs={3}>
            {i18n.t('INVOICE_LABEL_LINE', {
              lineNumber: lineNumber + 1
            })}
          </Grid>
          <Grid item xs={9} sx={{ pl: 1.4 }}>
            {`${formatCurrency(totalPriceExTax, currencySymbol)} - ${description} - ${readableDate(fromDate, "MMM dd, yyyy HH:mm")} to ${readableDate(toDate, "MMM dd, yyyy")}`}
          </Grid>
        </Grid>
      </CondensedAccordionSummary>
      <CondensedAccordionDetails sx={{ p: 0 }}>
        <CustomSnackbar snackbarContent={snackbarContent} setSnackbarContent={setSnackbarContent}></CustomSnackbar>
        {isLoading ? <Loader></Loader> :
          <>
            <Grid container sx={containerGridStyle}>
              <Grid item xs={3} sx={leftGridStyle}>
                {i18n.t('INVOICE_LABEL_ORDER')}
              </Grid>
              <Grid item xs={4} sx={{ ...rightGridStyle, color: colors.primary }}>
                {orderExternalReference ?? "-"}
              </Grid>
            </Grid>

            <Grid container sx={containerGridStyle}>
              <Grid item xs={3} sx={leftGridStyle}>
                {i18n.t('INVOICE_LABEL_DESCRIPTION')}
              </Grid>
              <Grid item xs={4} sx={rightGridStyle}>
                {description ?? "-"}
              </Grid>
            </Grid>

            <Grid container sx={containerGridStyle}>
              <Grid item xs={3} sx={leftGridStyle}>
                {i18n.t('INVOICE_LABEL_FROM_DATE')}
              </Grid>
              <Grid item xs={4} sx={rightGridStyle}>
                {readableDate(fromDate, "MMM dd, yyyy HH:mm")}
              </Grid>
            </Grid>

            <Grid container sx={containerGridStyle}>
              <Grid item xs={3} sx={leftGridStyle}>
                {i18n.t('INVOICE_LABEL_TO_DATE')}
              </Grid>
              <Grid item xs={4} sx={rightGridStyle}>
                {readableDate(toDate, "MMM dd, yyyy HH:mm")}
              </Grid>
            </Grid>


            {
              !isAdjustment && (
                <Grid container sx={containerGridStyle}>
                  <Grid item xs={3} sx={leftGridStyle}>
                    {i18n.t('INVOICE_LABEL_TO_QUANTITY')}
                  </Grid>
                  <Grid item xs={4} sx={rightGridStyle}>
                    {quantity ?? "-"}
                  </Grid>
                </Grid>

              )
            }

            <Grid container sx={containerGridStyle}>
              <Grid item xs={3} sx={leftGridStyle}>
                {i18n.t('INVOICE_LABEL_TO_UNIT')}
              </Grid>
              <Grid item xs={4} sx={rightGridStyle}>
                {unitName ?? "-"}
              </Grid>
            </Grid>

            {
              !isAdjustment && (
                <Grid container sx={containerGridStyle}>
                  <Grid item xs={3} sx={leftGridStyle}>
                    {i18n.t('INVOICE_LABEL_TO_UNIT_PRICE')}
                  </Grid>
                  <Grid item xs={4} sx={{ ...rightGridStyle, textAlign: 'right' }}>
                    {formatCurrency(unitPriceExTax, currencySymbol)}
                  </Grid>
                </Grid>

              )
            }

            <Grid container sx={containerGridStyle}>
              <Grid item xs={3} sx={leftGridStyle}>
                {i18n.t('INVOICE_LABEL_PRICE_EX_TAX')}
              </Grid>
              <Grid item xs={4} sx={{ ...rightGridStyle, textAlign: 'right' }}>
                {formatCurrency(totalPriceExTax, currencySymbol)}
              </Grid>
            </Grid>

            <CondensedAccordion
              expanded={expandTax}
              onChange={() => setExpandTax(!expandTax)}
              sx={{
                backgroundColor: "transparent",
                margin:0,
                borderBottom: `1px solid ${colors.grey[100]}`,
              }}
            >
              <CondensedAccordionSummary
                expandIcon={<GridExpandMoreIcon />}
                sx={{
                  backgroundColor: "transparent",
                  p: 0,
                  '& .MuiAccordionSummary-content': {
                    margin: 0,
                    p: 0
                  }
                }}
              >
                <Grid container spacing={0}>
                  <Grid item xs={3} sx={leftGridStyle}>
                    {i18n.t('INVOICE_LABEL_TOTAL_TAX')}
                  </Grid>
                  <Grid item xs={4} sx={{ ...rightGridStyle, textAlign: 'right' }}>
                    {formatCurrency(totalTax, currencySymbol)}
                  </Grid>
                </Grid>
              </CondensedAccordionSummary>
              <CondensedAccordionDetails sx={{ p: 0, backgroundColor: "transparent", }}>
                {
                  invoiceTaxLineDetail?.map((taxLine, index) => (
                    <Grid container key={index} sx={{...containerGridStyle,
                      borderTop: `${index === 0 ? '1px' : '0px'} solid ${colors.grey[100]}`,
                    }}>
                      <Grid item xs={3} sx={leftGridStyle}>
                        Tax Line {index + 1}
                      </Grid>
                      <Grid item xs={4} sx={{ ...rightGridStyle, textAlign: 'right' }}>
                        {/* {formatCurrency(50150, currencySymbol)} */}
                        XXXXXXXXXXXXXXXXXXXXXXXXXXXXX
                      </Grid>
                      <Grid item xs={5} sx={{ ...rightGridStyle }}>
                        {taxLine.taxDescription} @ {taxLine.taxRate}%
                      </Grid>
                    </Grid>
                  ))
                }
              </CondensedAccordionDetails>
            </CondensedAccordion>

            <Grid container sx={containerGridStyle}>
              <Grid item xs={3} sx={leftGridStyle}>
                {i18n.t('INVOICE_LABEL_PRICE_INC_TAX')}
              </Grid>
              <Grid item xs={4} sx={{ ...rightGridStyle, textAlign: 'right' }}>
                {formatCurrency(totalPriceIncTax, currencySymbol)}
              </Grid>
            </Grid>
          </>
        }
      </CondensedAccordionDetails>
    </CondensedAccordion>
  )
}