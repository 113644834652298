import React, { useState } from 'react';
import { Card, Tooltip, IconButton } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import i18n from "../../i18n";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { useNavigate } from "react-router";
import DataRow from '../../components/Common/DataRow/DataRow';
import { TicketDto } from '../../types/Ticket/TicketDto';

type TicketTableProps = {
  tickets: any[];
};

const TicketTable = ({ tickets }: TicketTableProps) => {
  const nav = useNavigate();
  const [page, setPage] = useState(1);
  const itemsPerPage = 5; 

  const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  const paginatedTickets = tickets.slice((page - 1) * itemsPerPage, page * itemsPerPage);

  return (
    <>
      <Card variant="outlined">
        <div className="ticket-table-headers">
          <div className="col-md-3 header-subject">{i18n.t('TICKET_LABEL_SUBJECT')}</div>
          <div className="col-md-2 header-member-name">{i18n.t('TICKET_LABEL_CUSTOMERNAME')}</div>
          <div className="col-md-2 header-priority">{i18n.t('TICKET_LABEL_PRIORITY')}</div>
          <div className="col-md-2 header-company">{i18n.t('TICKET_LABEL_COMPANY')}</div>
          <div className="col-md-2 header-category">{i18n.t('TICKET_LABEL_CATEGORY')}</div>
          <div className="col-md-3 header-last-updated">{i18n.t('TICKET_LABEL_UPDATED')}</div>
        </div>

        {paginatedTickets.map((ticket: TicketDto, index) => {
          const dataToShow = [
            { content: ticket.subject, className: 'data-item-ticket' },
            { content: ticket.memberName },
            { content: ticket.priorityName },
            { content: ticket.companyName },
            { content: ticket.categoryName },
            { content: new Date(ticket.createDateUtc!).toLocaleString() }
          ];

          return (
            <DataRow
              key={index}
              data={dataToShow}
              actionIcon={
                <div>
                  <Tooltip title={i18n.t("COMMON_TOOLTIP_DETAILS")}>
                    <IconButton onClick={() => nav(`${ticket.id}`)}>
                      <EditRoundedIcon sx={{ color: "#00be95" }} />
                    </IconButton>
                  </Tooltip>
                </div>
              }
            />
          );
        })}
      </Card>

      <div className="pagination-bar-ticket">
        <Pagination
          count={Math.ceil(tickets.length / itemsPerPage)}
          page={page}
          onChange={handleChangePage}
          color="primary"
        />
      </div>
    </>
  );
};

export default TicketTable;
