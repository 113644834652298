// ConnectedAccountDto
// autogenerated
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import { HookConfig } from "../../config/HookConfig";
import { useNavigate } from "react-router-dom";
import { operatorDefaultHeaders } from "../../functions/api/api";
import { ConnectedAccountDto} from "../../types/Order/ConnectedAccount";

const useFetchConnectedAccountById = (id: string ) => {
    return useQuery<ConnectedAccountDto, AxiosError>(["connected-account", id], () =>
    axios.get(

        `${HookConfig.orderUrl}/connected-accounts/${id}`
      ,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      })
      .then(
        (response) => {
            var connectedAccount: ConnectedAccountDto = response.data;
            return connectedAccount;
        }
      ));
};


const useAddConnectedAccount = () => {
  const queryClient = useQueryClient();
  const nav = useNavigate();
  return useMutation<AxiosResponse, AxiosError, ConnectedAccountDto>(
    (connectedAccount) => axios.post(`${HookConfig.orderUrl}/connected-accounts`, connectedAccount,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders() 
      }
    ),
    {
      onSuccess: (_, connectedAccount) => {
        queryClient.invalidateQueries(["connected-account", _.data.id]);
        nav(`/connected-accounts/${_.data.id}/connected-account-details`);
      },
      onError: (_, error) => {
        console.log(error);
      }
    }
  );
};


const useDeleteConnectedAccountById  = (id: string ) => {
  const queryClient = useQueryClient();
  return (id: string) => {
    return axios
      .delete(`${HookConfig.orderUrl}/connected-accounts/${id}`, {
        withCredentials: true,
         headers: operatorDefaultHeaders() ,
      })
      .then(() => {
        queryClient.invalidateQueries(["connected-account", id]);
        return { success: true }; 
      })
      .catch((error) => {
        console.log("Error deleting connectedaccount:", error);
        return { success: false, error }; 
      });
  };
};


const useUpdateConnectedAccount = (callback : () => void) => {
  const queryClient = useQueryClient();
  const nav = useNavigate();
  return useMutation<AxiosResponse, AxiosError, ConnectedAccountDto>(
    ( connectedAccount) => axios.put(`${HookConfig.orderUrl}/connected-accounts/${connectedAccount.id}`, connectedAccount,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders() 
      }
    ),
    {
      onSuccess: (_, connectedaccount) => {
        
            queryClient.invalidateQueries(["connected-account", connectedaccount.id ]);
            callback();
          
        
      },
      onError: (_, error) => {
        console.log(error);
      }
    }
  );
};


export {
  useFetchConnectedAccountById,
  useAddConnectedAccount,
  useDeleteConnectedAccountById,
  useUpdateConnectedAccount,
   
}
