import { useMutation, useQuery, useQueryClient } from "react-query";
import { ProductBenefitDto } from "../../types/Product/ProductBenefitDto";
import axios, { AxiosError, AxiosResponse } from "axios";
import { HookConfig } from "../../config/HookConfig";
import { operatorDefaultHeaders } from "../../functions/api/api";
import { OperationResultDto } from "../../types/Common/OperationResultDto";

const useFetchBenefitById = (productId: string, benefitId: string, doFetch: boolean) => {
  return useQuery<ProductBenefitDto, AxiosError>(["product-benefits", productId], () =>
    axios.get(`${HookConfig.productUrl}/products/${productId}/benefits/${benefitId}`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data),
    { enabled: doFetch }
  );
}

const useManageProductBenefit = (productId: string, callback: (operationResult: OperationResultDto) => void) => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse, AxiosError, ProductBenefitDto>(
    (productBenefit) => axios[productBenefit.id === '' ? 'post' : 'put'](
      productBenefit.id === '' ? `${HookConfig.productUrl}/products/${productId}/benefits` : `${HookConfig.productUrl}/products/${productId}/benefits/${productBenefit.id}`,
      productBenefit,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ),
    {
      onSuccess: (_, productBenefit) => {

        queryClient.invalidateQueries(["product-benefits", productBenefit.id]);
        callback({ isSuccess: true, errorMessage: '' });
      },
      onError: (_, error) => {
        console.log(error);
        callback({ isSuccess: false, errorMessage: _.message });
      }
    }
  );
};

const useDeleteBenefit = () => {
  const queryClient = useQueryClient();
  return (productId: string, benefitId: string) => {
    return axios
      .delete(`${HookConfig.productUrl}/products/${productId}/benefits/${benefitId}`, {
        withCredentials: true,
        headers: operatorDefaultHeaders(),
      })
      .then(() => {
        queryClient.invalidateQueries(["product-benefits", productId]);
        return { success: true };
      })
      .catch((error) => {
        console.log("Error deleting benefit:", error);
        return { success: false, error };
      });
  };
};

export {
  useFetchBenefitById,
  useManageProductBenefit,
  useDeleteBenefit
}