import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { AddressDto } from "../../types/Common/AddressDto";
import CountryDropDown from "../Common/CountryDropDown";
import { LR } from "../Common/Help/LR";
import i18n from "../../i18n";
import { getLabelsByOperatorType, useFetchMemberById, useUpdateMember } from '../../hooks/Member/member';
import ApiStatus from '../Common/Errors/ApiStatus';
import MessageAlert, { AlertStyle } from '../Common/Errors/MessageAlert';
import { MemberResponseDto } from '../../types/Member/MemberResponseDto';
import { useDirtyState } from '../../hooks/Common/dirty';
import { ConfirmationChangesDialog } from '../Common/ConfirmationChangesDialog';
import AddressContainer from '../Common/Address/AddressContainer';
import { SaveSnackBar } from '../Common/SaveSnackBar';
import { LabelAndHelp } from '../Common/Help/LabelAndHelp';
import { Backdrop, CircularProgress } from '@mui/material';
import { SectionHead } from '../Common/Help/SectionHead';
import { useSource } from '../../providers/SourceProvider';

type Args = {
    userLogged: ApplicationUser
}

const CompanyDetails = ({ userLogged }: Args) => {
    const nav = useNavigate();
    const params = useParams();

    const {source} = useSource()

    if (!params.id) throw Error("Operator id needed");

    //#region Empty Entities
    const emptyMemberResponseDto: MemberResponseDto = {
        id: '',
        memberTypeFlags: 4,
        fullName: '',
        languageCode: '',
        countryIso3Code: '',
        person: null,
        summaries: [],
        contactDetails: null
    };

    const emptyAddressDTO: AddressDto = {

        line1: '',
        countryIso3Code: '',
        languageCode: i18n.language,
        addressTypeName: 'Business',
        entityKey: '',
        entityName: '',
        city: '',
        area: ''
    }

    let addressPassed: AddressDto = {
        line1: '',
        countryIso3Code: '',
        languageCode: i18n.language,
        addressTypeName: 'Business',
        entityKey: '',
        entityName: 'Member',
        city: '',
        area: '',
    };

    //#endregion

    const success = () => {
        setSnackbarOpen(true);
    }

    const { data: operator, status, isSuccess } = useFetchMemberById(params.id)
    const [useOperator, setUseOperator] = useState<MemberResponseDto>(emptyMemberResponseDto);
    const [useAddress, setUseAddress] = useState<AddressDto>(emptyAddressDTO);
    const [useCountry, setUseCountry] = useState<string>('');
    const { mutate } = useUpdateMember("Operator", undefined, success);
    const [isDirty, setDirty, unsetDirty] = useDirtyState();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [targetUrl, setTargetUrl] = useState('');
    const [isCollapseOpen, setIsCollapseOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    //#region snackbar

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };
    //#endregion snackbar

    useEffect(() => {
        if (isSuccess && operator) {
            if (!operator.address) {
                addressPassed.countryIso3Code = operator.countryIso3Code;
                addressPassed.entityKey = operator.id;
                setUseAddress(addressPassed);
                setIsCollapseOpen(true);
            } else {
                setUseAddress(operator.address);
            }

            setUseOperator(operator);
            setUseCountry(operator.countryIso3Code);
        }
    }, [isSuccess, operator]);

    const linkCallBack = () => {
        let url = `/${source}/${useOperator.id}/summary`;

        if (isDirty) {
            setTargetUrl(url);
            setShowConfirmation(true);
        }
        else {
            nav(url);
        }
    }
    //#endregion

    //#region changeState
    const setAddress = (value: AddressDto) => {
        if (value !== null && value !== undefined) {
            useAddress.id = value.id;
            useAddress.latitude = value.latitude;
            useAddress.longitude = value.longitude;
            useAddress.line1 = value.line1;
            useAddress.line2 = value.line2;
            useAddress.line3 = value.line3;
            useAddress.area = value.area;
            useAddress.city = value.city;
            useAddress.zip = value.zip;
            useAddress.countryIso3Code = value.countryIso3Code;
            setUseAddress({ ...useAddress, line1: useAddress.line1 });
        };
    }

    const changeCountry = (value: string) => {
        setUseCountry(value);
        setUseAddress({ ...useAddress, countryIso3Code: value });
        setUseOperator({ ...useOperator, countryIso3Code: value })

    }
    //#endregion

    //#region Dirty
    const handleDirty = () => {
        setDirty();
    };

    const dialogConfirm = () => {
        setShowConfirmation(false);
        unsetDirty();
        nav(targetUrl);
    }

    const dialogCancel = () => {
        setShowConfirmation(false);
    }
    //#endregion

    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            setError('')
            setSubmitting(true);
            setShowConfirmation(false);
            unsetDirty();
            useAddress.entityKey = useOperator.id;
            useAddress.entityName = "Member";
            useOperator.address = useAddress;
            if (useAddress.countryIso3Code !== useOperator.countryIso3Code) {
                useOperator.countryIso3Code = useAddress.countryIso3Code;
            }
            mutate(useOperator);
            e.preventDefault();
            setSubmitting(false)

        } catch (err: any) {
            setSubmitting(false);
            setError(err)
            console.error(err);
        }
    }


    if (!isSuccess) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <ApiStatus status={status} />
                </div>
            </div>
        </>
    )

    if (!operator) return <MessageAlert message="MEMBER_LABEL_MEMBERNOTFOUND" params={{ param0: params.id }}></MessageAlert>;


    const labels = getLabelsByOperatorType(operator);


    return (
        <form onSubmit={onFormSubmit}>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={submitting}>
                <CircularProgress color="inherit" />
            </Backdrop>



            <SaveSnackBar onClose={handleClose} isOpen={snackbarOpen}></SaveSnackBar>

            <ConfirmationChangesDialog handleConfirm={dialogConfirm} handleCancel={dialogCancel} showConfirmation={showConfirmation}></ConfirmationChangesDialog>


            <SectionHead linkCallBack={linkCallBack} ctaText={i18n.t(labels!.cta)!}
                name={`${operator.fullName} (${operator.externalReference})`}
                title={i18n.t('COMMON_SUMMARY_COMPANYDETAILS')}
                description={i18n.t(labels!.label)} />


            <LabelAndHelp mandatory={true} message="MEMBER_LABEL_COMPANYNAME" />
            <div className="row d-flex justify-content-center mb-3">
                <div className="col-md-7 col-11 justify-content-center">
                    <input type="text"
                        value={useOperator.fullName}
                        className="form-control fa-form"
                        onChange={(e) => {
                            setUseOperator({ ...useOperator, fullName: e.target.value });
                            handleDirty();
                        }}
                        placeholder={i18n.t("MEMBER_LABEL_COMPANYNAME").toString()}
                        required>
                    </input>
                </div>
            </div>

            <LabelAndHelp mandatory={true} message="COMMON_LABEL_COUNTRY" />


            <div className="row d-flex justify-content-center mb-3">
                <div className="col-md-7 col-11 justify-content-center">
                    <CountryDropDown valueChanged={changeCountry} inputCountry={useOperator.countryIso3Code}></CountryDropDown>
                </div>
            </div>


            <AddressContainer collapseStatus={isCollapseOpen} collapseCallback={() => setIsCollapseOpen(!isCollapseOpen)} address={useAddress} handleDirty={handleDirty} callback={setAddress} country={operator.countryIso3Code} originalAddress={operator.address!}></AddressContainer>


            <div className="row d-flex justify-content-center mt-3">
                <div className="col-md-2 col-6 justify-content-center">
                    <button type="submit" className="btn btn-green btn-primary btn-block"
                        disabled={
                            !useAddress.line1 ||
                            !useAddress.city ||
                            !useAddress.countryIso3Code ||
                            !useAddress.zip ||
                            useAddress.line1.length < 3 ||
                            useAddress.city.length < 3 ||
                            useAddress.countryIso3Code.length < 3 ||
                            useAddress.zip.length < 3 ||
                            useOperator.fullName.length < 3 ||
                            !useOperator.fullName ||
                            submitting
                        }
                    >
                        <LR localResource="COMMON_LABEL_SAVE"></LR>
                    </button>
                </div>
            </div>
            <div className="row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    {
                        error &&
                        <MessageAlert message={error} variant="danger" style={AlertStyle.Tiny} additionalClassName="fa-alert-tiny"></MessageAlert>
                    }


                </div>
            </div>
        </form>
    );
};


export { CompanyDetails }