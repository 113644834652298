import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Switch } from "@mui/material";
import dayjs from "dayjs";
import { useContext, useEffect, useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from "yup";
import { pontCurrencies } from '../../../data/en/pontCurrencies';
import { getFormatterLanguageOptions } from '../../../data/en/pontLanguages';
import { pontLocationStatus } from "../../../data/locationConfigurations";
import { useFetchOperationalDetails, useFetchOperatorOpeningTimeGroups, useUpdateOperationalDetails } from '../../../hooks/Location/location';
import { useFetchPortfolios } from '../../../hooks/Product/portfolios';
import i18n from "../../../i18n";
import { colors } from "../../../main/Theme";
import { CustomDatePicker } from "../../Common/formComponents/CustomDatepicker";
import { CustomSelect } from "../../Common/formComponents/CustomSelect";
import { Label } from "../../Common/formComponents/Label";
import { GlobalStateContext } from "../../Common/GlobalStateProvider";
import { CustomButton } from "../../Common/UI/CustomButton";
import { Loader } from '../../Common/UI/Loader';



const OperationalDetails = () => {
    const { id } = useParams();
    const nav = useNavigate();

    if (!id) throw Error("Location id needed");

    const globalState = useContext(GlobalStateContext);
    const operatorId = globalState?.globalLoggedUser?.location?.operatorMemberId

    if (!operatorId) throw Error("Operator id needed");

    const { data: operationalDetails, isLoading: operationalDetailIsLoading } = useFetchOperationalDetails(id);
    const operatorMemberId = operationalDetails?.operatorMemberId || operatorId;
    const { data: portfolios, isLoading: portfoliosIsLoading } = useFetchPortfolios(operatorMemberId);
    const { data: openingTimeGroups, isLoading: openingTimeGroupsIsLoading } = useFetchOperatorOpeningTimeGroups(operatorMemberId);
    const { mutate: updateOperationalDetails, isLoading: updateOperationalDetailsIsLoading, } = useUpdateOperationalDetails(id);

    const formattedOptions = useMemo(
        () =>
            pontLocationStatus.map((item) => ({
                label: i18n.t(item.label),
                value: item.name,
                name: item.name,
            })),
        [pontLocationStatus]
    );

    const formattedOpeningTimeGroups = useMemo(
        () =>
            openingTimeGroups?.map((item) => ({
                label: item.name,
                value: item.id,
            })),
        [openingTimeGroups]
    );

    const formattedPortfolioOptions = useMemo(
        () =>
            portfolios?.map((item) => ({
                label: item.name,
                value: item.id,
                description: item.description,
            })),
        [portfolios]
    );

    const formattedLanguageOptions = getFormatterLanguageOptions()

    const schema = yup.object().shape({
        languageCode: yup.object().shape({
            label: yup.string().required(),
            value: yup.string().required(),
            name: yup.string().required()
        }).required(),
        currency: yup.object().shape({
            label: yup.string().required(),
            value: yup.string().required(),
            countryCode: yup.string().required()
        }).required(),
        isPubliclyVisible: yup.boolean().required(),
        locationStatusName: yup.object().shape({
            label: yup.string().required(),
            value: yup.string().required(),
            name: yup.string().required()
        }).required(),
        fromDate: yup
            .string()
            .nullable()
            .test('is-valid-from-date', i18n.t('VALIDATION_FROM_DATE_MUST_BE_A_VALID_DATE') as string, value => {
                return value ? dayjs(value).isValid() : true;
            }),
        toDate: yup
            .string()
            .nullable()
            .test('is-valid-to-date', i18n.t('VALIDATION_TO_DATE_MUST_BE_A_VALID_DATE') as string, value => {
                return value ? dayjs(value).isValid() : true;
            })
            .test('is-to-date-later', i18n.t('VALIDATION_TO_DATE_CANNOT_BE_EARLIER_THAN_FROM_DATE') as string, function (value) {
                const { fromDate } = this.parent;
                if (!value || !fromDate) return true;
                return dayjs(value).isAfter(dayjs(fromDate));
            }),
        portfolioId: yup.object().shape({
            label: yup.string().required(),
            value: yup.string().required(),
            description: yup.string().required()
        }).required(),
        openingTimeGroups: yup.array().of(yup.object().shape({
            label: yup.string().required(),
            value: yup.string().required()
        })).required(),
    });

    type OperationalDetailsFormData = yup.InferType<typeof schema>;

    const { handleSubmit, formState: { errors }, watch, control, reset } = useForm<OperationalDetailsFormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            isPubliclyVisible: false,
            fromDate: null,
            toDate: null,
        }
    });

    const fromDate = watch('fromDate');
    const toDate = watch('toDate');

    const onSubmit: SubmitHandler<OperationalDetailsFormData> = (data) => {
        console.log(data.openingTimeGroups.map(i => i.value))
        updateOperationalDetails({
            id: id,
            languageCode: data.languageCode.value,
            currencyCode: data.currency.value,
            isPubliclyVisible: data.isPubliclyVisible,
            locationStatusName: data.locationStatusName.value,
            fromDate: data.fromDate ? dayjs(data.fromDate).toISOString() : null,
            toDate: data.toDate ? dayjs(data.toDate).toISOString() : null,
            portfolioId: data.portfolioId.value,
            openingTimeGroups: data.openingTimeGroups.map((x) => x.value)
        }, {
            onSuccess: () => {
                nav(`/locations/${id}/configuration`)
            }
        })
    }

    useEffect(() => {
        if (operationalDetails && formattedPortfolioOptions && formattedOpeningTimeGroups) {
            const { currencyCode, languageCode, fromDate, toDate, openingTimeGroups, portfolioId, locationStatusName, isPubliclyVisible } = operationalDetails;
            console.log(operationalDetails)
            reset({
                locationStatusName: formattedOptions.find(x => x.value === locationStatusName)!,
                currency: pontCurrencies.find(x => x.value === currencyCode)!,
                languageCode: formattedLanguageOptions.find(x => x.value === languageCode)!,
                fromDate: fromDate ? dayjs(fromDate).toISOString() : null,
                toDate: toDate ? dayjs(toDate).toISOString() : null,
                isPubliclyVisible: isPubliclyVisible,
                portfolioId: formattedPortfolioOptions?.find(x => x.value === portfolioId)!,
                openingTimeGroups: formattedOpeningTimeGroups.filter(x => openingTimeGroups?.includes(x.value))
            })

            console.log(formattedOpeningTimeGroups.filter(x => openingTimeGroups?.includes(x.value)))
            console.log(formattedOpeningTimeGroups)
            console.log(openingTimeGroups)
        }
    }, [operationalDetails, formattedPortfolioOptions, formattedOpeningTimeGroups])


    if (operationalDetailIsLoading || portfoliosIsLoading || openingTimeGroupsIsLoading) {
        return <Loader />
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Controller
                        name="locationStatusName"
                        control={control}
                        render={({ field }) => (
                            <CustomSelect
                                labelProps={{ message: 'OPERATIONAL_DETAIL_LABEL_STATUS', mandatory: true }}
                                options={formattedOptions}
                                placeholder={i18n.t('INVENTORY_LABEL_TYPE')}
                                field={field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Label mandatory={false} message='OPERATIONAL_DETAIL_LABEL_PUBLICLY_VISIBLE'></Label>
                    <Controller control={control} name="isPubliclyVisible" render={({ field }) => (
                        <Box sx={{ backgroundColor: colors.grey[100], padding: '3px' }}>
                            <Switch color="secondary" checked={field.value} onChange={field.onChange} />
                        </Box>
                    )} />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="currency"
                        control={control}
                        render={({ field }) => (
                            <CustomSelect
                                labelProps={{ message: 'OPERATIONAL_DETAIL_LABEL_CURRENCY' }}
                                options={pontCurrencies}
                                placeholder={i18n.t('INVENTORY_LABEL_TYPE')}
                                field={field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="languageCode"
                        control={control}
                        render={({ field }) => (
                            <CustomSelect
                                labelProps={{ message: 'OPERATIONAL_DETAIL_LABEL_LANGUAGE' }}
                                options={formattedLanguageOptions}
                                placeholder={i18n.t('INVENTORY_LABEL_TYPE')}
                                field={field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="portfolioId"
                        control={control}
                        render={({ field }) => (
                            <CustomSelect
                                labelProps={{ message: 'OPERATIONAL_DETAIL_LABEL_PORTFOLIO' }}
                                options={formattedPortfolioOptions}
                                placeholder={i18n.t('INVENTORY_LABEL_TYPE')}
                                field={field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        name="fromDate"
                        control={control}
                        render={({ field, ...rest }) => (
                            <CustomDatePicker
                                labelProps={{ message: 'OPERATIONAL_DETAIL_LABEL_OPENING_DATE' }}
                                value={dayjs(field.value)}
                                onChange={(e) => field.onChange(dayjs(e).toDate())}
                                maxDate={dayjs(toDate)}
                                validationError={errors.fromDate}
                                {...rest}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        name="toDate"
                        control={control}
                        render={({ field, ...rest }) => (
                            <CustomDatePicker
                                labelProps={{ message: 'OPERATIONAL_DETAIL_LABEL_CLOSING_DATE' }}
                                minDate={dayjs(fromDate)}
                                value={dayjs(field.value)}
                                onChange={(e) => field.onChange(dayjs(e).toDate())}
                                disabled={!fromDate}
                                validationError={errors.toDate}
                                {...rest}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="openingTimeGroups"
                        control={control}
                        render={({ field }) => (
                            <CustomSelect
                                isMulti
                                labelProps={{ message: 'OPERATIONAL_DETAIL_LABEL_OPENING_TIME' }}
                                options={formattedOpeningTimeGroups}
                                placeholder={i18n.t('INVENTORY_LABEL_TYPE')}
                                field={field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={4} mx={'auto'} mt={3}>
                    <CustomButton variant="contained" fullWidth type="submit" loading={updateOperationalDetailsIsLoading}>
                        {i18n.t('COMMON_LABEL_SAVE')}
                    </CustomButton>
                </Grid>
            </Grid>
        </form >
    );
}


export { OperationalDetails };

