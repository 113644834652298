const pontBenefitTypes = [
    { label: "BENEFITTYPE_LABEL_NOTE", value: "Note" },
    { label: "BENEFITTYPE_LABEL_CREDIT", value: "Credit" },
    { label: "BENEFITTYPE_LABEL_DISCOUNT", value: "Discount" },
    { label: "BENEFITTYPE_LABEL_DISCOUNTCREDIT", value: "Discount and Credit" },
]

const pontBenefitTypeConstants = {
    note: 'Note',
    credit: 'Credit',
    discount: 'Discount',
    discountAndCredit: 'Discount and Credit'
}

export { pontBenefitTypes, pontBenefitTypeConstants }