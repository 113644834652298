import { SingleValue } from "react-select";
import { useEffect, useState, useMemo } from "react";
import { pontCountries } from "../../../data/en/pontCountries";
import { pontCountriesLabels } from "../../../data/en/pontCountriesLabels";
import useFetchVisitor from "../../../hooks/Common/visitor";
import { CustomSelect } from "./CustomSelect";
import { LabelType } from "./Label";

type CountryOption = { value: string, label: string };

type Args = {
    inputCountry?: string | null;
    valueChanged: (value: string) => void;
    validationError?: { message?: string };
    labelProps?: LabelType;
    disabled?: boolean;
};

const CustomCountryDropDown = ({ inputCountry, valueChanged, labelProps, validationError, disabled }: Args) => {
    const { data: visitor, status, isSuccess } = useFetchVisitor();
    const [selectedCountry, setSelectedCountry] = useState<any>(null);

    useEffect(() => {
        if (!inputCountry || inputCountry === '') {
            const defaultCountry = pontCountriesLabels.find(i => i.value === visitor?.countryCode);
            setSelectedCountry(defaultCountry);
            if (visitor?.countryCode !== undefined) {
                const selectedValue = pontCountries.find(i => i.Iso2Code === visitor?.countryCode)?.Iso3Code!
                valueChanged(selectedValue);
            }
        }
    }, [visitor?.countryCode]);


    useEffect(() => {
        if (inputCountry && inputCountry !== '') {
            const iso2Value = pontCountries.find(i => i.Iso3Code === inputCountry)?.Iso2Code!
            if (iso2Value) {
                const selectedCountry = pontCountriesLabels.find(i => i.value === iso2Value);
                setSelectedCountry(selectedCountry);
                valueChanged(inputCountry!);
            }
        }
    }, [inputCountry]);



    const changeCountry = (value: SingleValue<{ value: string, label: string | undefined }>) => {
        setSelectedCountry({
            ...selectedCountry,
            value: (value?.value !== undefined ? value.value : ''),
            label: (value?.label !== undefined ? value.label : '')
        });

        const selectedValue = pontCountries.find(i => i.Iso2Code === value?.value!)?.Iso3Code!
        valueChanged(selectedValue);
    }

    if (!isSuccess) return <span>{status}...</span>;

    return (
        <CustomSelect
            options={pontCountriesLabels}
            value={selectedCountry}
            onChange={changeCountry}
            labelProps={labelProps}
            validationError={validationError}
            isDisabled={disabled}
        />
    );
};

export default CustomCountryDropDown;
