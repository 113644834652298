import axios, { AxiosError, AxiosResponse } from "axios"
import { useMutation, useQuery, UseQueryOptions } from "react-query"
import { HookConfig } from "../../config/HookConfig"
import { operatorDefaultHeaders } from "../../functions/api/api"
import { AmenitiesListDto, NearByGetByIdResponseDto, NearByPostRequestDto, NearByUpdateRequestDto } from "../../types/Location/NearByAmenitiesDto"

type FetchNearByAmenitiesByLocationId = {
  params: {
    locationId?: string,
  },
  options?: UseQueryOptions<any, AxiosError>
}
export const useFetchNearByAmenities = (args: FetchNearByAmenitiesByLocationId) => {
  const { locationId } = args.params;
  return useQuery<AmenitiesListDto[], AxiosError>(['nearby-amenities', locationId], () =>
    axios.get(`${HookConfig.locationUrl}/${locationId}/near-by-amenities`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then(
      (response) => {
        return response.data;
      }
    ), {
    ...args?.options
  });
};


export const useDeleteAmenity = (locationId?: string) => {
  return useMutation<any, AxiosError, any>((amenityId: string) =>
    axios.delete(`${HookConfig.locationUrl}/${locationId}/near-by-amenities/${amenityId}`, {
      withCredentials: true,
      headers: operatorDefaultHeaders()
    }).then(
      (response) => {
        return response.data;
      }
    )
  );
}

type FetchAmenityDetailsById = {
  params: {
    amenityId?: string,
    locationId?: string,
  },
  options?: UseQueryOptions<NearByGetByIdResponseDto, AxiosError>
}
export const useFetchAmenityDetailsById = (args: FetchAmenityDetailsById) => {
  const { amenityId, locationId } = args.params;
  return useQuery<NearByGetByIdResponseDto, AxiosError>(['amenity-detail', locationId, amenityId], () =>
    axios.get(`${HookConfig.locationUrl}/${locationId}/near-by-amenities/${amenityId}`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then(
      (response) => {
        return response.data;
      }
    ), {
    ...args?.options
  });
};


type PostAmenityArgs = {
  params: {
    locationId?: string,
  }
}

export const usePostAmenity = (args: PostAmenityArgs) => {
  const { locationId } = args.params;
  return useMutation<AxiosResponse, AxiosError, NearByPostRequestDto>((payload) => {
    return axios.post(`${HookConfig.locationUrl}/${locationId}/near-by-amenities`, payload, {
      withCredentials: true,
      headers: operatorDefaultHeaders()
    });
  });
}

type UpdateAmenityArgs = {
  params: {
    amenityId?: string,
    locationId?: string,
  }
}

export const useUpdateAmenity = (args: UpdateAmenityArgs) => {
  const { locationId, amenityId } = args.params;
  return useMutation<AxiosResponse, AxiosError, NearByUpdateRequestDto>((payload) => {
    return axios.put(`${HookConfig.locationUrl}/${locationId}/near-by-amenities/${amenityId}`, payload, {
      withCredentials: true,
      headers: operatorDefaultHeaders()
    });
  });
}