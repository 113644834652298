import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { LR } from "../Common/Help/LR";
import i18n from "../../i18n";
import MessageAlert, { AlertStyle } from '../Common/Errors/MessageAlert';
import { useDirtyState } from '../../hooks/Common/dirty';
import { ConfirmationChangesDialog } from '../Common/ConfirmationChangesDialog';
import { SaveSnackBar } from '../Common/SaveSnackBar';
import { LabelAndHelp } from '../Common/Help/LabelAndHelp';
import { useFetchProductById, useFetchRulesByProductId, useManageProduct, useManageProductPortfolioPrices } from '../../hooks/Product/product';
import { ProductResponseDto } from '../../types/Product/ProductResponseDto';
import { ProductRules } from './ProductRules';
import { OperationResultDto } from '../../types/Common/OperationResultDto';
import { SectionHead } from '../Common/Help/SectionHead';
import Select from 'react-select';
import { pontProductTypes } from '../../data/en/pontProductTypes';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useFetchMembers } from '../../hooks/Member/member';
import { SummaryItemCollection } from '../Common/SummaryItems/SummaryItemCollection';
import { SummaryItemDto } from '../../types/Common/SummaryItemDto';
import { useFetchNetworksByOperator } from '../../hooks/Location/networks';
import { useFetchPortfolioPrices, useFetchPortfolios } from '../../hooks/Product/portfolios';
import { ProductSubscriptionDto } from '../../types/Product/ProductSubscriptionDto';
import { pontDurations } from '../../data/en/pontDurations';
import { IconButton, Switch, Tooltip } from '@mui/material';
import { PricesCollection } from '../Common/Prices/PricesCollection';
import { PriceDto } from '../../types/Price/PriceDto';
import { ProductBenefits } from './ProductBenefits';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { pontConstants } from '../../data/pontConstants';

type Args =
    {
        userLogged: ApplicationUser,
        isEdit: boolean
    }

const ProductDetails = ({ userLogged, isEdit }: Args) => {
    const nav = useNavigate();
    const params = useParams();

    const defaultProductSubscription: ProductSubscriptionDto = {
        headline: '',
        supplierLegalEntityMemberId: '',
        isTrialPeriodEnabled: false,
        trialPeriodQuantity: 0,
        trialPeriodUnitName: '',
        soldByNetworkId: '',
        useAtNetworkId: ''
    }

    const defaultProduct: ProductResponseDto = {
        id: '',
        name: '',
        description: '',
        operatorMemberId: userLogged.operator!.id,
        productTypeName: 'Subscription',
        fromDate: new Date().toISOString(),
        toDate: new Date().toISOString(),
        externalReference: '',
        internalIdentifier: null,
        isInUse: true,
        isDisabled: false,
        productRuleCount: 0,
        inventoryVariantCount: 0,
        applicationGroupCount: 0,
        locationNetworkCount: 0,
        summaries: [],
        shortDescription: '',
        subscription: defaultProductSubscription,
        summarisedBenefits: []
    };

    const { data: product } = useFetchProductById(params.id || '', isEdit);
    const { data: rules } = useFetchRulesByProductId(params.id || '', isEdit);
    const [useProduct, setUseProduct] = useState<ProductResponseDto>(defaultProduct);
    const [isDirty, setDirty, unsetDirty] = useDirtyState();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [targetUrl, setTargetUrl] = useState('');
    const [isOperationInError, setOperationInError] = useState(false);
    const [error, setError] = useState('');
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [selectedProductType, setSelectedProductType] = useState(pontProductTypes[6]);
    const pontDurationOptions = pontDurations.map(x => ({ label: x.label, value: x.id.toString() }));
    const [selectedDuration, setSelectedDuration] = useState(pontDurationOptions[0]);
    const { data: legalEntities } = useFetchMembers(512, userLogged.operator!.id, 3);
    const [legalEntityOptions, setLegalEntityOptions] = useState<{ label: string, value: string }[]>();
    const [selectedLegalEntity, setSelectedLegalEntity] = useState<{ label: string, value: string }>();

    const { data: networks } = useFetchNetworksByOperator(userLogged.operator!.id, true);
    const [networkOptions, setNetworkOptions] = useState<{ label: string, value: string }[]>();
    const [soldFromNetworkOptions, setSoldFromNetworkOptions] = useState<{ label: string, value: string }[]>();
    const [selectedSaleNetwork, setSelectedSaleNetwork] = useState<{ label: string, value: string }>();
    const [selectedUseNetwork, setSelectedUseNetwork] = useState<{ label: string, value: string }>();

    const { data: portfolios } = useFetchPortfolios(userLogged.operator!.id);
    const [portfolioOptions, setPortfolioOptions] = useState<{ label: string, value: string }[]>();
    const [selectedPortfolio, setSelectedPortfolio] = useState<{ label: string, value: string }>();

    const { data: prices } = useFetchPortfolioPrices(selectedPortfolio?.value ?? '', useProduct.id ?? '');
    const [usePrices, setUsePrices] = useState<PriceDto[]>();
    const pricesMutation = useManageProductPortfolioPrices();

    const mutationCallBack = (operationResult: OperationResultDto) => {
        if (operationResult.isSuccess) {
            setSnackbarOpen(true);
            if (operationResult.data) {
                nav(`/products/${operationResult.data}`);
            }
        } else {
            setOperationInError(true);
            setError(operationResult.errorMessage);
        }
    }
    const updateMutation = useManageProduct(mutationCallBack);

    const pricesCallBack = (operationResult: OperationResultDto) => {

        if (!operationResult.isSuccess) {
            setOperationInError(true);
            setError(operationResult.errorMessage);
        }
    }

    //#region Effects
    useEffect(() => {
        if (product) {
            if (product.summarisedBenefits === null) {
                product.summarisedBenefits = [];
            }

            let productType = pontProductTypes.find(x => x.value === product.productTypeName);
            if (productType) {
                setSelectedProductType(productType);
            }
            if (product.productTypeName !== 'Subscription' && !product.shortDescription) {
                product.shortDescription = '';
            }
            setUseProduct(product);
        }
    }, [product]);

    useEffect(() => {
        if (prices) {
            setUsePrices(prices.filter(x => x.unitName === 'Month' || x.unitName === 'Month12'));
        }
    }, [prices])

    useEffect(() => {
        if (legalEntities && legalEntities.length > 0) {
            setLegalEntityOptions(legalEntities.map(x => ({ label: x.fullName, value: x.id })));
            let legalEntityIndex = 0;
            if (isEdit && product && product.subscription && product.subscription.supplierLegalEntityMemberId) {
                legalEntityIndex = legalEntities.findIndex(x => x.id === product.subscription.supplierLegalEntityMemberId);
            }

            setSelectedLegalEntity({ label: legalEntities[legalEntityIndex].fullName, value: legalEntities[legalEntityIndex].id });
            if(!product) {
                setUseProduct(currentUseProduct => ({ ...currentUseProduct, subscription: { ...currentUseProduct.subscription, supplierLegalEntityMemberId: legalEntities[legalEntityIndex].id } }));
            }
        }
    }, [legalEntities, product]);

    useEffect(() => {
        if (networks) {
            let soldByNetworkIndex = 0;
            let useNetworkIndex = 0;
            let mappedNetworkOptions = networks.map(x => ({ label: x.name, value: x.id }));
            let soldFromNetworkOptions = [{ label: 'None', value: pontConstants.emptyGuid }, ...mappedNetworkOptions];
            if (isEdit && product && product.subscription && product.subscription.useAtNetworkId && product.subscription.soldByNetworkId) {
                useNetworkIndex = mappedNetworkOptions.findIndex(x => x.value === product.subscription.useAtNetworkId);
                soldByNetworkIndex = soldFromNetworkOptions.findIndex(x => x.value === product.subscription.soldByNetworkId);
            }

            setNetworkOptions(mappedNetworkOptions);
            setSoldFromNetworkOptions(soldFromNetworkOptions);
            setSelectedUseNetwork(mappedNetworkOptions[useNetworkIndex]);
            setSelectedSaleNetwork(soldFromNetworkOptions[soldByNetworkIndex]);
            if(!product) {
                setUseProduct(currentUseProduct => ({ ...currentUseProduct, subscription: { ...currentUseProduct.subscription, useAtNetworkId: mappedNetworkOptions[useNetworkIndex].value, soldByNetworkId: soldFromNetworkOptions[soldByNetworkIndex].value } }));
            }
        }
    }, [networks, product]);

    useEffect(() => {
        if (portfolios) {
            setPortfolioOptions(portfolios.map(x => ({ label: x.name, value: x.id })));
            setSelectedPortfolio({ label: portfolios[0].name, value: portfolios[0].id })
        }
    }, [portfolios, product]);
    //#region Effects

    //#region Snackbar
    const handleSnackClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };
    //#endregion snackbar

    //#region SummaryLinks
    const linkCallBackCollection = (item: SummaryItemDto) => {
        switch (item.name) {
            case "DescriptionSummary":
                nav(`/products/${params.id}/description`);
                break;
            case "ImageSummary":
            case "DocumentSummary":
                nav(`/products/${params.id}/digital-assets/${item.uri.substring(item.uri.indexOf('group=') + 6)}`);
                break;
            default:
                alert(`Item ${item.name} does not have a link yet`);
                break;
        }
    }
    //#endregion SummaryLinks

    //#region Dirty
    const handleDirty = () => {
        setDirty();
    };

    const dialogConfirm = () => {
        setShowConfirmation(false);
        unsetDirty();
        nav(targetUrl);
    }

    const linkCallBack = () => {
        let url = `/products/`;

        if (isDirty) {
            setTargetUrl(url);
            setShowConfirmation(true);
        }
        else {
            nav(url);
        }
    };

    const dialogCancel = () => {
        setShowConfirmation(false);
    }

    const rulesCallBack = (result: OperationResultDto) => {
        if (result.isSuccess) {
            setSnackbarOpen(true);
        } else {
            setOperationInError(true);
            setError(result.errorMessage);
        }
    }
    //#endregion

    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            setShowConfirmation(false);
            unsetDirty();
            updateMutation.mutate(useProduct!);
            if (usePrices) {
                pricesMutation.mutate([usePrices!, pricesCallBack]);
            }

            e.preventDefault();

        } catch (err: any) {
            console.error(err);
        }
    }

    const handleProductTypeChange = (selectedOption: any) => {
        setSelectedProductType(selectedOption);
        setUseProduct({ ...useProduct!, productTypeName: selectedOption.value });
        handleDirty();
    }

    const handleLegalEntityChange = (selectedOption: any) => {
        setSelectedLegalEntity(selectedOption);
        setUseProduct(currentUseProduct => ({ ...currentUseProduct, subscription: { ...currentUseProduct.subscription, supplierLegalEntityMemberId: selectedOption.value } }));
        handleDirty();
    }

    const handleUseNetworkChange = (selectedOption: any) => {
        setSelectedUseNetwork(selectedOption);
        setUseProduct(currentUseProduct => ({ ...currentUseProduct, subscription: { ...currentUseProduct.subscription, useAtNetworkId: selectedOption.value } }));
        handleDirty();
    }

    const handleSaleNetworkChange = (selectedOption: any) => {
        setSelectedSaleNetwork(selectedOption);
        setUseProduct(currentUseProduct => ({ ...currentUseProduct, subscription: { ...currentUseProduct.subscription, soldByNetworkId: selectedOption.value } }));
        handleDirty();
    }

    const handlePortfolioChange = (selectedOption: any) => {
        setSelectedPortfolio(selectedOption);
        //setUseProduct({ ...useProduct!, legalEntity: selectedOption.value });
        handleDirty();
    }

    const handleToggleTrial = () => {
        setUseProduct(currentUseProduct => ({ ...currentUseProduct, subscription: { ...currentUseProduct.subscription, isTrialPeriodEnabled: !currentUseProduct.subscription.isTrialPeriodEnabled } }));
        handleDirty();
    }

    const handleDurationChange = (selectedOption: any) => {
        setSelectedDuration(selectedOption);
        setUseProduct(currentUseProduct => ({
            ...currentUseProduct, subscription:
            {
                ...currentUseProduct.subscription,
                trialPeriodQuantity: pontDurations[selectedOption.value].quantity,
                trialPeriodUnitName: pontDurations[selectedOption.value].unitName
            }
        }));
        handleDirty();
    }

    const addBenefit = () => {
        nav(`/products/${params.id}/benefits/add`);
    }

    const handlePrices = (value: PriceDto) => {
        if (usePrices) {
            var changedPrice = usePrices!.find(x => x.unitName === value.unitName);

            if (!changedPrice)
                throw Error("Price not found!");

            changedPrice.currencyCode = value.currencyCode;
            changedPrice.price = value.price;
            changedPrice.fromDate = new Date();
            changedPrice.toDate = new Date('2099-01-01');

            setUsePrices((usePrices) =>
                usePrices!.map((price) => {

                    if (price.unitName === changedPrice!.unitName) {
                        return changedPrice!;
                    }

                    return price;
                })
            );
            handleDirty();
        }
    };

    return (
        <form onSubmit={onFormSubmit}>
            <SaveSnackBar onClose={handleSnackClose} isOpen={snackbarOpen}></SaveSnackBar>
            <ConfirmationChangesDialog handleConfirm={dialogConfirm} handleCancel={dialogCancel} showConfirmation={showConfirmation}></ConfirmationChangesDialog>

            <SectionHead linkCallBack={linkCallBack} ctaText={i18n.t("PRODUCTS_SUMMARY_PAGE")!} name={`${useProduct.name}`} title={i18n.t('COMMON_SUMMARY_PRODUCTDETAILS')} description={i18n.t('COMMON_SUMMARY_PRODUCTMANAGEMENT')} />

            <LabelAndHelp mandatory={true} message='PRODUCT_LABEL_NAME'></LabelAndHelp>
            <div className="row d-flex justify-content-center mb-3">
                <div className="col-md-7 col-11 justify-content-center">
                    <input type="text"
                        value={useProduct!.name! || ''}
                        className="form-control fa-form"
                        onChange={(e) => {
                            setUseProduct({ ...useProduct!, name: e.target.value });
                            handleDirty();
                        }}
                        placeholder={i18n.t("PRODUCT_LABEL_NAME").toString()}
                        required>
                    </input>
                </div>
            </div>

            <div className="row d-flex justify-content-center mb-3">
                <div className="col-md-7 col-11 justify-content-center disabled-content">
                    <LabelAndHelp mandatory={true} fullscreen={true} message='PRODUCT_LABEL_PRODUCTTYPENAME'></LabelAndHelp>
                    <Select
                        value={selectedProductType} getOptionLabel={(option) => { return i18n.t(option.label) }}
                        className="form-control fa-form fa-dropdown-container" classNamePrefix="fa-dropdown"
                        options={pontProductTypes}
                        onChange={handleProductTypeChange}
                        placeholder={i18n.t("PRODUCT_LABEL_PRODUCTTYPENAME").toString()}
                    />
                </div>
            </div>
            {useProduct.productTypeName === 'Subscription' &&
                <>
                    <LabelAndHelp mandatory={true} message='PRODUCT_LABEL_SHORTDESCRIPTION'></LabelAndHelp>
                    <div className="row d-flex justify-content-center mb-3">
                        <div className="col-md-7 col-11 justify-content-center">
                            <input type="text"
                                value={useProduct!.shortDescription! || ''}
                                className="form-control fa-form"
                                onChange={(e) => {
                                    setUseProduct({ ...useProduct!, shortDescription: e.target.value });
                                    handleDirty();
                                }}
                                placeholder={i18n.t("PRODUCT_LABEL_SHORTDESCRIPTION").toString()}
                                required>
                            </input>
                        </div>
                    </div>

                    <div className="row d-flex justify-content-center">
                        <div className="col-md-7 col-11 justify-content-center">
                            <div className="row d-flex justify-content-center mt-4 mb-3">
                                <div className="col-md-6 col-11">
                                    <DatePicker
                                        label={i18n.t("PRODUCT_LABEL_FROMDATE").toString()}
                                        className="fa-datepicker"
                                        value={dayjs(useProduct.fromDate)}
                                        onChange={(newValue) => { setUseProduct({ ...useProduct!, fromDate: newValue?.toISOString()! }); handleDirty(); }}
                                    />
                                </div>
                                <div className="col-md-6 col-11">
                                    <DatePicker
                                        label={i18n.t("PRODUCT_LABEL_TODATE").toString()}
                                        className="fa-datepicker"
                                        value={dayjs(useProduct.toDate)}
                                        onChange={(newValue) => { setUseProduct({ ...useProduct, toDate: newValue?.toISOString()! }); handleDirty(); }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <>
                        <LabelAndHelp mandatory={true} message='PRODUCT_LABEL_HEADLINE'></LabelAndHelp>
                        <div className="row d-flex justify-content-center mb-3">
                            <div className="col-md-7 col-11 justify-content-center">
                                <input type="text"
                                    value={useProduct!.subscription.headline!}
                                    className="form-control fa-form"
                                    onChange={(e) => {
                                        setUseProduct(currentUseProduct => ({ ...currentUseProduct, subscription: { ...currentUseProduct.subscription, headline: e.target.value } }));
                                        handleDirty();
                                    }}
                                    placeholder={i18n.t("PRODUCT_LABEL_HEADLINE").toString()}
                                    required>
                                </input>
                            </div>
                        </div>
                    </>

                    <div className="row d-flex justify-content-center mb-3">
                        <div className="col-md-7 col-11 justify-content-center">
                            <LabelAndHelp mandatory={true} fullscreen={true} message='PRODUCT_LABEL_LEGALENTITIES'></LabelAndHelp>
                            <Select
                                value={selectedLegalEntity} getOptionLabel={(option) => { return i18n.t(option.label) }}
                                className="form-control fa-form fa-dropdown-container" classNamePrefix="fa-dropdown"
                                options={legalEntityOptions}
                                onChange={handleLegalEntityChange}
                                placeholder={i18n.t("PRODUCT_LABEL_LEGALENTITIES").toString()}
                            />
                        </div>
                    </div>

                    <div className="row d-flex justify-content-center mb-3">
                        <div className="col-md-7 col-11 justify-content-center">
                            <LabelAndHelp mandatory={true} fullscreen={true} message='PRODUCT_LABEL_USEDLOCATIONS'></LabelAndHelp>
                            <Select
                                value={selectedUseNetwork} getOptionLabel={(option) => { return i18n.t(option.label) }}
                                className="form-control fa-form fa-dropdown-container" classNamePrefix="fa-dropdown"
                                options={networkOptions}
                                onChange={handleUseNetworkChange}
                                placeholder={i18n.t("PRODUCT_LABEL_USEDLOCATIONS").toString()}
                            />
                        </div>
                    </div>

                    <div className="row d-flex justify-content-center mb-3">
                        <div className="col-md-7 col-11 justify-content-center">
                            <LabelAndHelp mandatory={true} fullscreen={true} message='PRODUCT_LABEL_SOLDLOCATIONS'></LabelAndHelp>
                            <Select
                                value={selectedSaleNetwork} getOptionLabel={(option) => { return i18n.t(option.label) }}
                                className="form-control fa-form fa-dropdown-container" classNamePrefix="fa-dropdown"
                                options={soldFromNetworkOptions}
                                onChange={handleSaleNetworkChange}
                                placeholder={i18n.t("PRODUCT_LABEL_SOLDLOCATIONS").toString()}
                            />
                        </div>
                    </div>

                    <div className="row d-flex justify-content-center">
                        <div className="col-md-7 col-11 justify-content-center">
                            <div className="row d-flex justify-content-center mt-4 mb-3">
                                <div className="col-md-2 col-11">
                                    <LabelAndHelp mandatory={true} fullscreen={true} message='PRODUCT_LABEL_TRIAL'></LabelAndHelp>
                                    <Switch key={'trial'}
                                        checked={useProduct.subscription.isTrialPeriodEnabled}
                                        onChange={() => handleToggleTrial()}
                                        color="primary"
                                    />
                                </div>
                                <div className={`col-md-10 col-11 ${useProduct.subscription.isTrialPeriodEnabled ? '' : 'disabled-content'}`}>
                                    <LabelAndHelp mandatory={true} fullscreen={true} message='PRODUCT_LABEL_DURATION'></LabelAndHelp>
                                    <Select
                                        value={selectedDuration} getOptionLabel={(option) => { return i18n.t(option.label) }}
                                        className="form-control fa-form fa-dropdown-container" classNamePrefix="fa-dropdown"
                                        options={pontDurationOptions}
                                        onChange={handleDurationChange}
                                        placeholder={i18n.t("PRODUCT_LABEL_DURATION").toString()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row d-flex justify-content-center mb-3">
                        <div className="col-md-7 col-11 justify-content-center">
                            <LabelAndHelp mandatory={true} fullscreen={true} message='PRODUCT_LABEL_PORTFOLIO'></LabelAndHelp>
                            <Select
                                value={selectedPortfolio} getOptionLabel={(option) => { return i18n.t(option.label) }}
                                className="form-control fa-form fa-dropdown-container" classNamePrefix="fa-dropdown"
                                options={portfolioOptions}
                                onChange={handlePortfolioChange}
                                placeholder={i18n.t("PRODUCT_LABEL_PORTFOLIO").toString()}
                            />
                        </div>
                    </div>
                </>
            }


            <LabelAndHelp mandatory={false} message='PRODUCT_LABEL_INTERNALREFERENCE' helpMessage='PRODUCT_LABEL_HELP_INTERNALREFERENCE'></LabelAndHelp>

            <div className="row d-flex justify-content-center mb-3">
                <div className="col-md-7 col-11 justify-content-center" style={{ display: 'flex', alignItems: 'center' }}>
                    <input type="text"
                        value={useProduct!.internalIdentifier! || ''}
                        className="form-control fa-form"
                        onChange={(e) => {
                            setUseProduct({ ...useProduct!, internalIdentifier: e.target.value });
                            handleDirty();
                        }}
                        placeholder={i18n.t("PRODUCT_LABEL_INTERNALREFERENCE").toString()}>
                    </input>

                </div>
            </div>

            {usePrices && useProduct.productTypeName === 'Subscription' &&
                <div className="row d-flex justify-content-center mb-3">
                    <div className="col-md-7 col-11 justify-content-center" >
                        <PricesCollection countryIsoCode3={'GBR'} label={''} itemsPerRow={1} handleDirty={() => handleDirty()} callBack={handlePrices} items={usePrices!}></PricesCollection>
                    </div>
                </div>
            }

            {
                useProduct.productTypeName === 'Subscription' &&
                <>
                    <div className="row d-flex justify-content-center mt-2">
                        <div className="col-md-7 col-11 justify-content-center" >
                            <ProductBenefits mutationCallBack={rulesCallBack} items={useProduct.summarisedBenefits!}></ProductBenefits>
                        </div>
                    </div>

                    <div className="mt-1 row d-flex justify-content-end">
                        <div className="col-md-7 col-11 d-flex align-items-center">
                            <Tooltip title={i18n.t('BENEFIT_LABEL_NEWBENEFIT')}>
                                <IconButton onClick={() => addBenefit()} className="btn plusButton mr-2">
                                    <AddCircleRoundedIcon fontSize="medium" sx={{ color: '#00be95' }} />
                                </IconButton>
                            </Tooltip>
                            <span className="lead mb-0">
                                <LR localResource="BENEFIT_LABEL_NEWBENEFIT" />
                            </span>
                        </div>
                    </div>
                </>
            }

            {
                rules && rules.length > 0 &&
                <>
                    <LabelAndHelp mandatory={false} message='PRODUCT_LABEL_RULESASSOCIATIONS'></LabelAndHelp>
                    <div className="row d-flex justify-content-center mt-2">
                        <div className="col-md-7 col-11 justify-content-center" >
                            <ProductRules mutationCallBack={rulesCallBack} items={rules!}></ProductRules>
                        </div>
                    </div>
                </>
            }

            <div className={`row d-flex justify-content-center`}>
                <div className="col-md-7 col-11 justify-content-center">
                    <SummaryItemCollection linkCallBack={linkCallBackCollection} items={useProduct.summaries}></SummaryItemCollection>
                </div>
            </div>

            <div className="row d-flex justify-content-center mt-3">
                <div className="col-md-2 col-6 justify-content-center">
                    <button type="submit" className="btn btn-green btn-primary btn-block"
                        disabled={
                            !isDirty ||
                            !useProduct.name ||
                            !useProduct.shortDescription ||
                            !useProduct.subscription.supplierLegalEntityMemberId ||
                            useProduct.toDate <= useProduct.fromDate
                        }>
                        <LR localResource="COMMON_LABEL_SAVE"></LR>
                    </button>
                </div>
            </div>

            {(isOperationInError) &&
                <div className="row d-flex justify-content-center">
                    <div className="col-md-4 col-11 justify-content-center">
                        <MessageAlert message={error} variant="danger" style={AlertStyle.Tiny} additionalClassName="fa-alert-tiny"></MessageAlert>
                    </div>
                </div>
            }

        </form>
    );
};


export { ProductDetails }