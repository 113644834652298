import { useMutation, useQuery, useQueryClient } from "react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import { HookConfig } from "../../config/HookConfig";
import { useNavigate } from "react-router-dom";
import { operatorDefaultHeaders } from "../../functions/api/api";
import { LookUpDto } from "../../types/Common/LookUpDto";

const useFetchMerchantAccountLookUp = () => {
    return useQuery<LookUpDto[], AxiosError>(["merchant-account-lookup",], () =>
        axios.get(

            `${HookConfig.orderUrl}/merchant-accounts/look-up`
            ,
            {
                withCredentials: true,
                headers: operatorDefaultHeaders()
            })
            .then(
                (response) => {
                    var lookUp: LookUpDto[] = response.data;
                    return lookUp;
                }
            ));
};


export {
    useFetchMerchantAccountLookUp
}