import { useQuery } from "react-query";
import axios, { AxiosError } from "axios";
import { HookConfig } from "../../config/HookConfig";
import { LocationResponseDto } from "../../types/Location/LocationResponseDto";
import { operatorDefaultHeaders } from "../../functions/api/api";


const useFetchNetworksByOperator = (memberId: string, excludeSharedNetworks : boolean) => {
  return useQuery<LocationResponseDto[], AxiosError>(["networks", memberId], () =>
      axios.get(`${HookConfig.locationBaseUrl}/members/${memberId}/networks?ExcludeSharedNetworks=${excludeSharedNetworks}`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data)
  );
}

export {
  useFetchNetworksByOperator
}