import { CreditUnitDto } from "../../types/Common/CreditUnitDto"

const pontCreditPeriodUnits: CreditUnitDto[] =
    [
        {
            "id": 0,
            "unitName": "Day",
            "label": "Per Day"
        },
        {
            "id": 1,
            "unitName": "Week",
            "label": "Per Week"
        },
        {
            "id": 2,
            "unitName": "Month",
            "label": "Per Month"
        },
        {
            "id": 3,
            "unitName": "Year",
            "label": "Per Year"
        }
    ]

export {
    pontCreditPeriodUnits
}