import React, { useState } from 'react';
import './Invoice.css';
import { IconDisplayer } from '../../Common/Help/IconDisplayer';

interface AdditionalData {
  label: string;
  value: string;
}

interface InvoiceLineDropDownProps {
  location: string;
  address: string;
  additionalData: AdditionalData[];
}

const InvoiceLineDropDown: React.FC<InvoiceLineDropDownProps> = ({ location, address, additionalData }) => {
  const [showMore, setShowMore] = useState(false);
  const toggleShowMore = () => setShowMore(!showMore);

  return (
    <div className='invoice-main-wrapper'>
      <div className='invoice-main'>
        <div className='invoice-left'>{location}</div>
        <div className='invoice-group-right'>
          <div className='invoice-right'>{address}</div>
          <div className='invoice-arrow' onClick={toggleShowMore}>
            <IconDisplayer type={showMore ? 'COMMON_LABEL_UP_ARROW' : 'COMMON_LABEL_DOWN_ARROW'} />
          </div>
        </div>
      </div>
      <div className={`invoice-additional ${showMore ? 'show' : ''}`}>
        <div className='invoice-additional-inner'>
          {additionalData.map((data, index) => (
            <div className='invoice-additional-children' key={index}>
              <div className='invoice-inner-options'>{data.label}</div>
              <div className='invoice-inner-data'>{data.value}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export { InvoiceLineDropDown };
