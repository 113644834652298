import { Button, IconButton, Paper, Typography } from "@mui/material";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { LR } from "../Common/Help/LR";
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { useFetchPartnersForRegisteredUsers } from "../../hooks/Member/member";
import { useEffect, useState } from "react";
import { PartnerResponseDto } from "../../types/Partner/PartnerResponseDto";
import { setCurrentPartner } from "../../hooks/Authentication/userinfoV2";

type Args =
    {
        userLogged: ApplicationUser,
        setupCallBack: (updatedUser: ApplicationUser) => void
    }

const PartnerSetup = ({ userLogged, setupCallBack }: Args) => {

    const [partners, setPartners] = useState<PartnerResponseDto[]>();
    const { data, status, isSuccess } = useFetchPartnersForRegisteredUsers();
    const [noPartners, setNoPartners] = useState<boolean>(false);

    const clickAndSetPartners = async (partner: PartnerResponseDto) => {
        const applicationUser = await setCurrentPartner(partner);
        setupCallBack(applicationUser);

    }

    useEffect(() => {
        
        if (isSuccess && data) {
            if (data.length === 0) {
                setNoPartners(true);

            } else {
                setPartners(data)
            }
        }

        if(status === "error")
            setNoPartners(true);
    }, [isSuccess, status, data]);

    return (
        <>

            {
                noPartners && 
                <>
                <div className="mt-5 row d-flex justify-content-center">
                        <div className="col-md-4 col-11 justify-content-center">
                            <Typography variant="h5"><LR localResource="MEMBER_TITLE_PARTNERSETUPNO"></LR></Typography>
                            <LR localResource="PARTNER_MESSAGE_SETUPNO"></LR>
                        </div>
                    </div>
                </>
            }

            {
                !noPartners &&
                <>
                    <div className="mt-5 row d-flex justify-content-center">
                        <div className="col-md-4 col-11 justify-content-center">
                            <Typography variant="h5"><LR localResource="MEMBER_TITLE_PARTNERSETUP"></LR></Typography>
                            <LR localResource="PARTNER_MESSAGE_SETUP"></LR>
                        </div>
                    </div>

                    {partners && partners.map((item, index) => (
                        <div className="row d-flex justify-content-center" key={index}>
                            <div className=" col-md-4 col-11 row d-flex justify-content-center">
                                <Paper key={index} elevation={3} className="fa-paper ">
                                    <div className="d-flex align-items-center">
                                        <div className="fa-name flex-grow-1">
                                            <Typography variant="h6" >
                                                <Button type="button" onClick={() => clickAndSetPartners(item)} className="linkbutton" key={index}>
                                                    {item.partnerName}
                                                </Button>
                                            </Typography>
                                        </div>
                                        <div className="fa-icons">
                                            <IconButton onClick={() => clickAndSetPartners(item)} className="fa-arrow">
                                                <ArrowForwardIosRoundedIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                </Paper>
                            </div>
                        </div>
                    ))}
                </>
            }






        </>
    );
};


export { PartnerSetup }