import { DurationDto } from "../../types/Common/DurationDto"

const pontDurations: DurationDto[] =
    [
        {
            "id": 0,
            "quantity": 1,
            "unitName": "Week",
            "label": "1 Week"
        },
        {
            "id": 1,
            "quantity": 1,
            "unitName": "Month",
            "label": "1 Month"
        },
        {
            "id": 2,
            "quantity": 3,
            "unitName": "Month",
            "label": "3 Months"
        },
        {
            "id": 3,
            "quantity": 1,
            "unitName": "Year",
            "label": "1 Year"
        }
    ]

export {
    pontDurations
}