import DeleteIcon from '@mui/icons-material/Delete';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { Box, IconButton, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useDeleteAmenity, useFetchNearByAmenities } from "../../hooks/Location/amenities";
import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { SectionHead } from "../Common/Help/SectionHead";
import { DeleteConfirmationDialog } from '../Common/UI/DeleteConfirmationDialog';
import { AmenitiesListDto } from '../../types/Location/NearByAmenitiesDto';
import { CustomButton } from '../Common/UI/CustomButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useFetchLocationById } from '../../hooks/Location/location';
import { LR } from '../Common/Help/LR';
type Args = {
    userLogged: ApplicationUser
}

export const NearByAmenities = ({ userLogged }: Args) => {
    const { id } = useParams()
    const nav = useNavigate()

    const [itemToDelete, setItemToDelete] = useState<AmenitiesListDto | null>()

    const { data: amenityData, refetch: refetchAmenityData } = useFetchNearByAmenities({
        params: {
            locationId: id
        },
        options: {
            enabled: !!id
        }
    })

    const { data: location } = useFetchLocationById(id)

    const { mutate: deleteAmenity, isLoading: deletingAmenity } = useDeleteAmenity(id)

    const handleBackLink = () => {
        nav(`/locations/${id}/summary`)
    }

    const handleDelete = () => {
        if (!itemToDelete) {
            return
        }

        deleteAmenity(itemToDelete.id, {
            onSuccess: () => {
                refetchAmenityData()
                setItemToDelete(null)
            }
        })
    }

    const locationName = () => {
        if (!location) return ''
        return `${location?.name} (${location?.externalReference})`
    }

    return (
        <>
            <SectionHead linkCallBack={handleBackLink}
                ctaText={i18n.t('TITLE_LOCATION_SUMMARY')!}
                name={locationName()}
                title={i18n.t('TITLE_NEARBY_AMENITIES')}
                description={i18n.t('COMMON_SUMMARY_LOCATIONMANAGEMENT')} />

            <DeleteConfirmationDialog
                confirm={handleDelete}
                isLoading={deletingAmenity}
                onClose={() => setItemToDelete(null)}
                open={!!itemToDelete}
                text={i18n.t('COMMON_BUTTON_DELETETEXT', { name: itemToDelete?.name }).toString()}
            ></DeleteConfirmationDialog>

            <div className="row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <Box sx={{ mb: 2 }}>
                        <CustomButton color='secondary' onClick={() => nav(`/locations/${id}/near-by-amenities/add`)} sx={{
                            textTransform: "none"
                        }}>
                            <AddCircleIcon /> <LR localResource='TITLE_AMENITIES_ADD'></LR>
                        </CustomButton>
                    </Box>
                    <DataGrid
                        rows={amenityData || []}
                        columns={[
                            { field: 'name', headerName: i18n.t('LOCATION_LABEL_NAMESHORT')!, flex: 1 },
                            {
                                field: 'id', headerName: '', flex: 0.1, align: "center", sortable: false,
                                renderCell: (params) => (
                                    <>
                                        <Tooltip title={i18n.t('COMMON_TOOLTIP_DETAILS')}>
                                            <IconButton color='secondary' onClick={() => nav(`/locations/${params.row.locationId}/near-by-amenities/${params.row.id}`)}>
                                                <EditRoundedIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={i18n.t('COMMON_TOOLTIP_DELETE', { param0: params.row.name })}>
                                            <IconButton onClick={() => setItemToDelete(params.row)} color='error'>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                ),
                            }
                        ]}

                        checkboxSelection={false}
                        disableColumnSelector={true}
                        disableDensitySelector={true}
                        disableColumnFilter={true}
                        disableColumnMenu={true}
                        disableRowSelectionOnClick={true}
                    />
                </div>
            </div>
        </>
    );
};

