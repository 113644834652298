import { isEmpty } from 'lodash';
import * as yup from 'yup';

declare module 'yup' {
  interface ObjectSchema<
    TIn extends yup.Maybe<yup.AnyObject>,
    TContext = yup.AnyObject,
    TDefault = any,
    TFlags extends yup.Flags = ""
  > {
    isEmptyObject(message: string): this;
  }

  interface NumberSchema<
    TType extends yup.Maybe<number> = number | undefined,
    TContext = yup.AnyObject,
    TDefault = undefined,
    TFlags extends yup.Flags = ""> {
    emptyNumberToNull(): this;
  }
}

// Adding the custom method
yup.addMethod(yup.object, 'isEmptyObject', function (message: string) {
  return this.test('is-empty-object', message, function (value) {
    const { createError } = this;

    if (isEmpty(value)) {
      return createError({ message });
    }

    return true;
  });
});


yup.addMethod(yup.number, 'emptyNumberToNull', function () {
  return this.transform((value, originalValue) =>
    originalValue === '' ? null : value
  );
});


export default yup;
