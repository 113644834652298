import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { getLabelsByOperatorType, useFetchMemberById } from "../../hooks/Member/member";
import ApiStatus from "../Common/Errors/ApiStatus";
import i18n from "../../i18n";
import { useFetchDigitalAssetsRequirements } from "../../hooks/Common/fileUpload";
import { FileuploadContainer } from "../Common/Upload/FileUploadContainer";
import { SectionHead } from "../Common/Help/SectionHead";
import { useSource } from "../../providers/SourceProvider";

type Args = {
    userLogged: ApplicationUser
}

const KycDetails = ({ userLogged }: Args) => {
    const { source } = useSource()
    const nav = useNavigate();
    const { id, country } = useParams();
    if (!id) throw Error("Operator id needed");
    if (!country) throw Error("Country id needed");

    const linkCallBack = () => {
        nav(`/${source}/${id}/summary/`);
    }



    const { data: operator, status: operatorStatus, isSuccess: operatorIsSuccess } = useFetchMemberById(id!)
    const { data, status, isSuccess } = useFetchDigitalAssetsRequirements('kyc', id!, country!);

    if ((!isSuccess || !operatorIsSuccess) && (status !== 'success') && (operatorStatus !== 'success')) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <ApiStatus status={status} />
                </div>
            </div>
        </>
    )

    if (!data || !operator) return <ApiStatus status={status} />;

    const labels = getLabelsByOperatorType(operator);

    return (
        <>
            <SectionHead
                linkCallBack={linkCallBack}
                ctaText={i18n.t(labels!.cta)!}
                name={`${operator.fullName} (${operator.externalReference})`}
                title={i18n.t('COMMON_SUMMARY_KYC-DETAILS')}
                description={i18n.t(labels!.label)} />

            <FileuploadContainer
                items={data}
                groupname={country!}
            />
        </>
    );
};


export { KycDetails }