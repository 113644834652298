import React from 'react';
import './DataRow.css';
import { Box } from '@mui/material';

type DataRowProps = {
    data: Array<{ content: React.ReactNode, className?: string }>;
    actionIcon?: React.ReactNode;
    onActionClick?: () => void;
    className?: string;
    isExpanded?: boolean;
    compact?: boolean;
};

const DataRow: React.FC<DataRowProps> = ({ data, actionIcon, onActionClick, className, isExpanded, compact }) => {
    return (
        <Box
            className={`data-row ${compact ? '' : 'full-width'} ${isExpanded ? 'expanded' : ''} ${className || ''}`}
            onClick={onActionClick}
            sx={{cursor:"pointer"}}
        >
            {data.map((item, index) => (
                <div key={index} className={`data-item ${item.className || ''}`}>
                    {item.content}
                </div>
            ))}
            {actionIcon && (
                <div className="data-action">
                    {actionIcon}
                </div>
            )}
        </Box>
    );
};

export default DataRow;
