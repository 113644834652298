import { useCallback, useState } from "react";
import { UseConfirmEmail, UseRegisterEmail } from "../../hooks/Login/login";
import Header from "../Navigation/HeaderLogin";
import './Login.css'
import { UserRegistrationRequest } from "../../types/Login/UserRegistrationRequest";
import { useParams } from "react-router-dom";
import { UserRegistrationResponse } from "../../types/Login/UserRegistrationResponse";
import Otp from "../Common/Login/Otp";
import MessageAlert from "../Common/Errors/MessageAlert";
import i18n from "../../i18n";
import { LR } from "../Common/Help/LR";
import CountryDropDown from "../Common/CountryDropDown";
import { RegistrationCompleted } from "./RegistrationCompleted";
import { isMobile } from 'react-device-detect';
import { RegisterLinks } from "./subsections/RegisterLinks";

function Register() {
    const emptyUserRegistration: UserRegistrationRequest =
    {
        firstName: "",
        lastName: "",
        confirmPassword: "",
        email: "",
        password: "",
        countryISOCode: ""
    }

    const emptyUserRegistrationResponse: UserRegistrationResponse =
    {
        id: "",
        code: "",
        error: "",
        name: "",
        countryISOCode: "",
        email: "",
        confirmed: false
    }

    const { email } = useParams();
    let flagExistingEmail = false;




    if (email) {
        flagExistingEmail = true;
        emptyUserRegistration.email = email;
    }

    const [submitting, setSubmitting] = useState(false);
    const [existingEmail, setExistingEmail] = useState(flagExistingEmail);
    const [userRegistrationState, setUserRegistrationState] = useState<UserRegistrationRequest>({ ...emptyUserRegistration });
    const [userRegistrationResponseState, setUserRegistrationResponseState] = useState<UserRegistrationResponse>({ ...emptyUserRegistrationResponse });

    const OTPCodeChange = (value: string) => {
        setUserRegistrationResponseState({ ...userRegistrationResponseState, code: value });
    }


    const ChangeCountry = (value: string) => {
        setUserRegistrationState({ ...userRegistrationState, countryISOCode: value })
    }


    const onFormSubmit = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            setSubmitting(true);
            setExistingEmail(false);
            setUserRegistrationResponseState({ ...userRegistrationResponseState, error: "" });

            if (userRegistrationResponseState.code) {
                UseConfirmEmail(userRegistrationResponseState, function (response: UserRegistrationResponse) {


                    //createMemberMutation.mutate(memberRequest);

                    setUserRegistrationResponseState({
                        ...userRegistrationResponseState,
                        code: "",
                        confirmed: response.confirmed,
                        error: response.error
                    });

                    setSubmitting(false);
                });
            } else {
                UseRegisterEmail(userRegistrationState, function (response: UserRegistrationResponse) {
                    setUserRegistrationResponseState({
                        ...userRegistrationResponseState,
                        id: response.id,
                        name: response.name,
                        error: response.error,
                        confirmed: false,
                        email: response.email
                    });

                    setSubmitting(false);
                });
            }
        } catch (err: any) {
            console.error(err)
        }
    }, [userRegistrationResponseState, userRegistrationState]);



    return (

        <div className='container'>
            <div className="row d-flex justify-content-center">
                <div className={isMobile ? "col-12 justify-content-center logincontainer" : "col-4 justify-content-center center-block border border-white logincontainer"}>
                    <Header />
                    {!userRegistrationResponseState.confirmed &&
                        <form onSubmit={onFormSubmit}>
                            {existingEmail &&
                                <MessageAlert message="LOGIN_LABEL_NOTEXISTINGEMAIL" params={{ email: userRegistrationState.email }} timeout={5000} variant="warning"></MessageAlert>
                            }

                            <div className="row d-flex justify-content-center mb-2">
                                <div className="col-10 d-flex justify-content-center">
                                    <input
                                        type="email"
                                        className="form-control fa-form"
                                        placeholder={i18n.t("COMMON_LABEL_EMAIL").toString()}
                                        value={userRegistrationState.email}
                                        disabled={Boolean(userRegistrationResponseState.id)}
                                        onChange={(e) =>
                                            setUserRegistrationState({ ...userRegistrationState, email: e.target.value })
                                        }
                                        required
                                    ></input>
                                </div>
                            </div>

                            <div className="row d-flex justify-content-center mb-2">
                                <div className="col-10 d-flex justify-content-center">
                                    <input
                                        type="text"
                                        className="form-control fa-form"
                                        placeholder={i18n.t("COMMON_LABEL_FIRSTNAME").toString()}
                                        disabled={Boolean(userRegistrationResponseState.id)}
                                        value={userRegistrationState.firstName}
                                        onChange={(e) =>
                                            setUserRegistrationState({ ...userRegistrationState, firstName: e.target.value })
                                        }
                                        required
                                    ></input>
                                </div>
                            </div>

                            <div className="row d-flex justify-content-center mb-2">
                                <div className="col-10 d-flex justify-content-center">
                                    <input
                                        type="text"
                                        className="form-control fa-form"
                                        placeholder={i18n.t("COMMON_LABEL_LASTNAME").toString()}
                                        disabled={Boolean(userRegistrationResponseState.id)}
                                        value={userRegistrationState.lastName}
                                        onChange={(e) =>
                                            setUserRegistrationState({ ...userRegistrationState, lastName: e.target.value })
                                        }
                                        required
                                    ></input>
                                </div>
                            </div>

                            <div className="row d-flex justify-content-center mb-2">
                                <div className="col-10 d-flex justify-content-center">
                                    <CountryDropDown valueChanged={ChangeCountry}></CountryDropDown>
                                </div>
                            </div>



                            {!userRegistrationResponseState.id &&
                                <div className="row d-flex justify-content-center mb-2">
                                    <div className="col-10 d-flex justify-content-center">
                                        <input
                                            type="password"
                                            className="form-control fa-form"
                                            placeholder={i18n.t("SECURITY_LABEL_PASSWORD").toString()}
                                            value={userRegistrationState.password}
                                            onChange={(e) =>
                                                setUserRegistrationState({ ...userRegistrationState, password: e.target.value })
                                            }
                                            required
                                        ></input>
                                    </div>
                                </div>
                            }
                            {!userRegistrationResponseState.id &&
                                <div className="row d-flex justify-content-center mb-2">
                                    <div className="col-10 d-flex justify-content-center">
                                        <input
                                            type="password"
                                            className="form-control fa-form"
                                            placeholder={i18n.t("SECURITY_LABEL_CONFIRMPASSWORD").toString()}
                                            value={userRegistrationState.confirmPassword}
                                            onChange={(e) =>
                                                setUserRegistrationState({ ...userRegistrationState, confirmPassword: e.target.value })
                                            }
                                            required
                                        ></input>
                                    </div>
                                </div>
                            }

                            {userRegistrationResponseState.id &&
                                <Otp code={userRegistrationResponseState.code} id={userRegistrationResponseState.id} valueChanged={OTPCodeChange} ></Otp>
                            }

                            <MessageAlert message={userRegistrationResponseState.error} timeout={5000} variant="danger"></MessageAlert>

                            <div className="row d-flex justify-content-center mb-2">
                                <div className="col-10 d-flex justify-content-center">

                                    {userRegistrationResponseState.id &&
                                        <button className="btn btn-green btn-primary btn-block" disabled={submitting || !userRegistrationResponseState.code} type="submit">
                                            <LR localResource="COMMON_BUTTON_CONFIRM"></LR>
                                        </button>
                                    }


                                    {!userRegistrationResponseState.id &&
                                        <button className="btn btn-green btn-primary btn-block" disabled={submitting} type="submit">
                                            <LR localResource="LOGIN_BUTTON_REGISTER"></LR>
                                        </button>
                                    }

                                </div>
                            </div>
                            <RegisterLinks></RegisterLinks>
                        </form>

                    }
                    {
                        userRegistrationResponseState.confirmed &&
                        <RegistrationCompleted value={userRegistrationResponseState.name} type="REGISTER" />
                    }
                </div>
            </div>
        </div>
    );
}

export default Register;
