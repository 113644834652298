import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { LR } from "../Common/Help/LR";
import i18n from "../../i18n";
import ApiStatus from '../Common/Errors/ApiStatus';
import MessageAlert from '../Common/Errors/MessageAlert';
import "../Common/react-draft-wysiwyg.css";
import { SectionHead } from '../Common/Help/SectionHead';
import 'react-quill/dist/quill.snow.css';
import { LocationResponseDto } from '../../types/Location/LocationResponseDto';
import { useFetchLocationById, useUpdateLocationDescription } from '../../hooks/Location/location';
import CustomQuillEditor from '../Common/Input/CustomQuillEditor';
import { SaveSnackBar } from '../Common/SaveSnackBar';
import { useDirtyState } from '../../hooks/Common/dirty';
import { ConfirmationChangesDialog } from '../Common/ConfirmationChangesDialog';
import '../../main/App.css'

type Args = {   
    userLogged: ApplicationUser;
};

const LocationDescription = ({ userLogged }: Args) => {
    const nav = useNavigate();  
    const params = useParams();
    const locationId = params.id;

    if (!locationId) {
        throw new Error("Location ID is missing from the URL!");
    }

    const emptyLocationResponseDto: LocationResponseDto = {
        id: '',
        name: '',
        internalIdentifier: '',
        externalReference: '',
        operatorMemberId: '',
        legalEntityMemberId: '',
        longDescription: '',
        countryIso3Code: '',
        shortDescription: '',
        summaries: [],
        brandName: ''
    };

    const [descriptionState, setDescriptionState] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const { data: location, status, isSuccess } = useFetchLocationById(locationId);
    const [useLocation, setUseLocation] = useState(emptyLocationResponseDto);

    const [isDirty, setDirty, unsetDirty] = useDirtyState();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [targetUrl, setTargetUrl] = useState('');


    useEffect(() => {
        if (isSuccess && location) {
            setUseLocation(location); 
            setDescriptionState(location.longDescription); 
        }
    }, [isSuccess, location]);
    

    const handleDescriptionChange = (value: string) => {
        setDescriptionState(value);
        setUseLocation(prevState => ({ 
            ...prevState, 
            longDescription: value 
        })); 
        setDirty(); 
    };

    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault(); 
        
        try {
            await updateMutation.mutateAsync(useLocation); 
            setSnackbarOpen(true); 
            unsetDirty(); 
            setShowConfirmation(false);  
            setUnsavedChanges(false);  
        } catch (err: any) {
            console.error(err);
        }
    };
    
    const linkCallBack = () => {
        if (isDirty) {
            setShowConfirmation(true);  
        } else {
            nav(`/locations/${locationId}/summary`);  
        }
    };
    
    
    const dialogConfirm = () => {
        setShowConfirmation(false);
        unsetDirty();  
    };
    
    const dialogCancel = () => {
        setShowConfirmation(false); 
    };
    
    

    const handleSnackClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const updateMutation = useUpdateLocationDescription(linkCallBack);

    if (!isSuccess) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <ApiStatus status={status} />
                </div>
            </div>
        </>
    );

    if (!location) return <MessageAlert message="MEMBER_LABEL_MEMBERNOTFOUND" params={{ param0: locationId }}></MessageAlert>;

    return (
        <form onSubmit={onFormSubmit}>
            {showConfirmation && (
                <ConfirmationChangesDialog
                    handleConfirm={dialogConfirm}
                    handleCancel={dialogCancel}
                    showConfirmation={showConfirmation}
                />
            )}

            <SectionHead linkCallBack={linkCallBack} ctaText={i18n.t("LOCATION_SUMMARY_LOCATIONMAINPAGE")!}
                name={`${location.name} (${location.externalReference})`}
                title={i18n.t('COMMON_SUMMARY_DESCRIPTIONS')}
                description={i18n.t('COMMON_SUMMARY_LOCATIONMANAGEMENT')} />

            <CustomQuillEditor
                value={descriptionState}
                onChange={(value) => {
                    setDescriptionState(value); 
                    setUseLocation(prevState => ({ 
                        ...prevState, 
                        longDescription: value 
                    }));
                    setDirty();  
                }}
                placeholder={i18n.t("LOCATION_LABEL_FEATUREDESCRIPTION", { locationDescription: location.name }).toString()}
            />

            <div className="row d-flex justify-content-center mt-3">
                <div className="col-md-2 col-6 justify-content-center">
                    <button type="submit" className="btn btn-green btn-primary btn-block"
                        disabled={!isDirty}>
                        <LR localResource="COMMON_LABEL_SAVE"></LR>
                    </button>
                </div>
            </div>
            {isDirty && (
                    <div className="alert alert-center" role="alert">
                        <p className="warning-label">
                            {i18n.t('WARNING_LABEL')}
                        </p>
                    </div>
             )}

            <SaveSnackBar onClose={handleSnackClose} isOpen={snackbarOpen} />
        </form>
    );
};

export { LocationDescription };
