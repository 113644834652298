import React, { useState, useEffect, useRef } from 'react';
import { format, } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import {
  ScheduleComponent, TimelineViews, TimelineMonth, EventSettingsModel, ViewsDirective,
  ViewDirective, Inject, ResourcesDirective, ResourceDirective,
  PopupOpenEventArgs, ActionEventArgs, NavigatingEventArgs,
  EventRenderedArgs} from '@syncfusion/ej2-react-schedule';
import { Select, MenuItem, FormControl, InputLabel, SelectChangeEvent } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { registerLicense } from '@syncfusion/ej2-base';
import './Calendar.css';
import { ApplicationUser } from '../../types/Authentication/ApplicationUser';
import { CalendarSearchResponseDto } from '../../types/Calendar/CalendarSearchResponseDto';
import { CalendarSearchRequestDto } from '../../types/Calendar/CalendarSearchRequestDto';
import { DateShim } from '../../functions/utils/helper';
import { getCalendarData } from '../../hooks/Calendar/calendar';
import { CalendarSearchDetailResponseDto } from '../../types/Calendar/CalendarSearchDetailResponseDto';

registerLicense("ORg4AjUWIQA/Gnt2U1hhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5bd0djX35ec3ZQR2Fd");

type CalendarFeedProps = {
  userLogged: ApplicationUser;
};

const CalendarFeed = ({ userLogged }: CalendarFeedProps) => {
  const scheduleObj = useRef<ScheduleComponent>(null);  
  const [selectedInventoryId, setSelectedInventoryId] = useState<string>('');
  const [events, setEvents] = useState<EventSettingsModel>({ dataSource: [] });
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedDateFrom, setselectedDateFrom] = useState<Date>(new Date());
  const [calendarData, setCalendarData] = useState<CalendarSearchResponseDto[]>();
  const [ownerData, setOwnerData] = useState<any>([]);


  const calculateDates = (fromDate: Date): {from: Date, to : Date} => {    
    const firstDayOfMonth = new Date(fromDate.getFullYear(), fromDate.getMonth(), 1);
    const lastDayOfMonth = new Date(fromDate.getFullYear(), fromDate.getMonth() + 1, 0);
    return {from: firstDayOfMonth, to :lastDayOfMonth};

};


  useEffect(() => {
    const fetchCalendarData = async () => {
      const dates = calculateDates(selectedDateFrom);
      console.log("🚀 ~ CalendarFeed Fetching Data ~  selectedDate:", selectedDateFrom)
      console.log("🚀 ~ CalendarFeed Fetching Data ~ :", dates)
      const params: CalendarSearchRequestDto = {
        fromDate: DateShim(dates.from).toISOString().split('T')[0],
        toDate: DateShim(dates.to).toISOString().split('T')[0],
        operatorMemberId: userLogged.operator?.id!,
        locationId: userLogged.location!.id
      }

      const data = await getCalendarData(params)
      if (data)
        setCalendarData(data);
    }

    fetchCalendarData();
  }, [selectedDateFrom]);

  useEffect(() => {
    if (calendarData) { 
      console.log("🧭 ~ CalendarFeed mapping Data :", selectedDateFrom)
      if (selectedInventoryId) {
        setOwnerData(calendarData?.filter(item => item.sellableItemId === selectedInventoryId).map((data: CalendarSearchResponseDto) => ({
          text: data.sellableItemName,
          id: data.sellableItemId,
          color: '#ff0',
          type: 'Conference'
        })));
      } else {
        setOwnerData(calendarData?.map((data: CalendarSearchResponseDto) => ({
          text: data.sellableItemName,
          id: data.sellableItemId,
          color: '#ff0',
        })));
      }

      const filteredData = selectedInventoryId ? calendarData.filter(item => item.sellableItemId === selectedInventoryId) : calendarData;

      const timeZone = 'Europe/London'; 
const newEvents = filteredData.flatMap((data: CalendarSearchResponseDto) =>
  data.unavailabilites?.map((unavailability: CalendarSearchDetailResponseDto) => {
    // Parse the date string without the "Z"
    const startDateLocalString = new Date(unavailability.fromDate.replace("Z", "+01:00")); //TODO=> remove the hardcoded for UK locations.
    const endDateLocalString = new Date(unavailability.toDate.replace("Z", "+01:00"));

    // Convert to UTC first, then to Europe/London time zone
    const startDateLocal = formatInTimeZone(startDateLocalString, timeZone,"yyyy-MM-dd HH:mm:ssXXX" );
    const endDateLocal = formatInTimeZone(endDateLocalString, timeZone,"yyyy-MM-dd HH:mm:ssXXX" )

    return {
      Id: unavailability.orderId || uuidv4(),
      Subject: `${unavailability.memberFullName ? `${unavailability.memberFullName}, ` : ''} ${unavailability.unavailabilityTypeName}${unavailability.orderExternalReference ? ` n° ${unavailability.orderExternalReference}` : ''}`,
      StartTime: startDateLocal,
      Reason: unavailability.reason,
      EndTime: endDateLocal,
      ExternalReference: unavailability.orderExternalReference,
      OrderId: unavailability.orderId,
      MemberName : unavailability.memberFullName,      
      Description: unavailability.unavailabilityTypeName || '--',
      RoomId: data.sellableItemId,
      CustomStyle: unavailability.orderExternalReference ? 'custom-event-color' : 'custom-event-unavailable',
    };
  }) || []
      );

      setEvents({ dataSource: newEvents });
      setLoading(false);
    }
  }, [calendarData, selectedInventoryId]);

  const handleInventoryChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setSelectedInventoryId(value === "all" ? '' : value);
  };

  const handleNavigating = (args: NavigatingEventArgs): void => {
    
  };


  const onActionComplete = (args: ActionEventArgs): void => {
    console.log("onActionComplete :", args.requestType)

    if (args.requestType === 'dateNavigate' && scheduleObj.current) {
      const newDate = scheduleObj.current.getCurrentViewDates()[0]; // Get the new date selected      
      if (newDate.getTime() !== selectedDateFrom.getTime()) {
        console.log('on action complete: change of date',  newDate)
        setselectedDateFrom(newDate); // Update the state with the new date        
      }
    }
  };

  const onEventRendered = (args: EventRenderedArgs): void => {
    args.element.classList.add(args.data.CustomStyle);
  }



  const resourceHeaderTemplate = (props: any) => {
    return (
      <div className="resource-header-template">
        <div className="resource-column">
          <div className="resource-room">{props.resourceData.text}</div>
        </div>
        <div className="resource-column">
          <div className="resource-type">{props.resourceData.type}</div>
        </div>
      </div>
    );
  };

  const onActionBegin = (args: ActionEventArgs): void => {
    if (args.requestType === 'eventCreate' || args.requestType === 'eventChange') {
      let data = args.data instanceof Array ? args.data[0] : args.data;
      if (data) {
        args.cancel = !scheduleObj.current!.isSlotAvailable(data);
      }
    }
  };


  const onPopupOpen = (args: PopupOpenEventArgs): void => {
    args.cancel = true;
  };

  const headerTemplate = (props: { [key: string]: string }): JSX.Element => {
    return (
      <>
        {props.Subject &&
          <div className="quick-info-header calendar-popup-header">
             <div className="e-header-icon-wrapper">
             {/* <button className="e-edit e-control e-btn e-lib e-flat e-round e-small e-icon-btn" title="Edit"><span className="e-btn-icon e-icons e-edit-icon"></span>
             </button> */}
                </div>
            <div className="quick-info-header-content">
                <div className="quick-info-title"><h3>{props.MemberName  ? props.MemberName : props.Description}</h3></div>
                <div className="duration-text"><p>Start: <strong>{format(props.StartTime, 'dd/MM/yyyy HH:mm')}</strong>  End <strong>{format(props.EndTime, 'dd/MM/yyyy HH:mm')}</strong></p></div>         
            </div>
          </div>
        }
      </>
    );
  }

  const contentTemplate = (props: { [key: string]: string }): JSX.Element => {
    return (
      <>
        
          <div className="quick-info-content calendar-popup-content">
            <div className="event-content">
              
            {
                props.ExternalReference && 
                <div className="meeting-subject-wrap">                
                <span>Booking n° {props.ExternalReference}</span>
              </div>
              }

              {
                props.Reason && 
                <div className="meeting-subject-wrap">                
                <span>{props.Reason}</span>
              </div>
              }
              
            </div>
          </div>
      </>
    );
  }

  const footerTemplate = (props: { [key: string]: Object }): JSX.Element => {
    return (
      <></>
    );
  }



  const quickInfoTemplates = {
    header: headerTemplate,
    content: contentTemplate,
    footer: footerTemplate
  };


  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='schedule-control-section'>
      <div className='col-lg-12 control-section'>
        <div className='control-wrapper'>
          <FormControl fullWidth style={{ marginBottom: 20 }}>
            <InputLabel id="inventory-select-label">Select Inventory</InputLabel>
            <Select
              labelId="inventory-select-label"
              value={selectedInventoryId || "all"}
              onChange={handleInventoryChange}
              displayEmpty
            >
              <MenuItem value="all">All Inventories</MenuItem>
              {calendarData?.map(inventory => (
                <MenuItem key={inventory.sellableItemId} value={inventory.sellableItemId}>
                  {inventory.sellableItemName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <ScheduleComponent
            cssClass='timeline-resource'
            ref={scheduleObj}
            key={selectedDateFrom.toString()}
            width='100%'
            height='750px'
            selectedDate={selectedDateFrom}
            navigating={handleNavigating}
            timezone={"Europe/London"}
            eventRendered={onEventRendered}
            actionComplete={onActionComplete}
            resourceHeaderTemplate={resourceHeaderTemplate}
            eventSettings={events}
            quickInfoTemplates={quickInfoTemplates} // Replacing all templates with custom one
            actionBegin={onActionBegin}
            eventDoubleClick={onPopupOpen}
            group={{ enableCompactView: false,  resources: ['MeetingRoom'] }}
          >
            <ResourcesDirective>
              <ResourceDirective
                field='RoomId'
                title='Room Type'
                name='MeetingRoom'
                allowMultiple={true}
                dataSource={ownerData}
                textField='text'
                idField='id'
                colorField='color'
              />
            </ResourcesDirective>
            <ViewsDirective>
              <ViewDirective option='TimelineDay' startHour='07:00' endHour='20:00' />
              <ViewDirective option='TimelineWeek' startHour='07:00' endHour='20:00' firstDayOfWeek={1} />
              <ViewDirective option='TimelineMonth' startHour='07:00' endHour='20:00' />
            </ViewsDirective>
            <Inject services={[TimelineViews, TimelineMonth]} />
          </ScheduleComponent>
        </div>
      </div>
    </div>
  );
};


export{
  CalendarFeed
}


