import {  Button, Collapse, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { IconDisplayer } from '../Help/IconDisplayer';
import { LR } from '../Help/LR';
import { AddressDto } from '../../../types/Common/AddressDto';
import AddressForm from './AddressForm';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import InfoIcon from '@mui/icons-material/Info';
import i18n from '../../../i18n';
import ContentPasteGoRoundedIcon from '@mui/icons-material/ContentPasteGoRounded';

type Args = {
    address: AddressDto, 
    originalAddress: AddressDto,
    country:string, 
    collapseStatus : boolean,
    source? : string,
    copyaddressCallback? : ()=> void
    collapseCallback : () => void    
    callback: (address: AddressDto) => void
    handleDirty: () => void
};

const AddressContainer = ({ address, collapseStatus, originalAddress, country, source, collapseCallback, callback, handleDirty, copyaddressCallback }: Args) => {
    return(
        <>
          <div className="row d-flex justify-content-center ">
                <div className="col-md-7 col-11 justify-content-center ">
                    <Paper elevation={3} className="fa-paper ">
                        <div className="d-flex align-items-center">
                            <div className="fa-name flex-grow-1">
                                <IconDisplayer type='COMMON_TITLE_ADDRESS'></IconDisplayer>
                                <Typography variant="h6" >
                                    <Button type="button" className="linkbutton">
                                        <LR localResource='COMMON_TITLE_ADDRESS'></LR>
                                    </Button>
                                </Typography>
                                {
                                    (source === 'location') && 
                                    <Tooltip title={i18n.t('COMMON_DESCRIPTION_COPYFROMOPERATOR')}>
                                    <IconButton className="fa-info" onClick={copyaddressCallback}>
                                    <ContentPasteGoRoundedIcon></ContentPasteGoRoundedIcon>
                                    </IconButton>
                                    </Tooltip>
                                }
                                
                            </div>
                            <div className="fa-paper-cta-description d-flex align-items-center">

                            {!address.line1 && (
                                <>
                                <Typography variant="body2" style={{ fontSize: '12px' }}>
                                    <LR localResource="COMMON_SUMMARY_ACTIONREQUIRED"></LR>
                                </Typography>
                                
                                <Tooltip title={i18n.t('COMMON_DESCRIPTION_ADDRESS')}>
                                <IconButton className="fa-info">
                                  <InfoIcon />
                                </IconButton>
                                </Tooltip>
                                </>
                              
                            )}
                                {address.line1 && (
                                    <Tooltip title={i18n.t('COMMON_TOOLTIP_GREEN')}>
                                        <CheckCircleRoundedIcon className="fa-paper-cta-good" />
                                    </Tooltip>
                                )}
                            </div>
                            <div className="fa-icons">
                                <IconButton className="fa-arrow" onClick={collapseCallback}>
                                    {!collapseStatus && <ArrowForwardIosRoundedIcon />}
                                    {collapseStatus && <ArrowForwardIosRoundedIcon className='fa-arrow-down' />}
                                </IconButton>
                            </div>
                        </div>
                    </Paper>
                </div>
            </div>

            <Collapse in={collapseStatus}>
                <div className="row d-flex justify-content-center">
                    <div className="col-md-7 col-11 justify-content-center">
                        <AddressForm token="fa5ac-e04c1-3f576-0918f" handleDirty={handleDirty} callback={callback} country={country} originalAddress={originalAddress}></AddressForm>
                    </div>
                </div>
            </Collapse>
        </>
    )
};

export default AddressContainer;
