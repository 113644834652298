import { useEffect, useState } from "react";
import i18n from "../../../i18n";
import { LR } from "../../Common/Help/LR";
import { LabelAndHelp } from "../../Common/Help/LabelAndHelp";
import { InventoryTypeSelectOption } from "../../../types/Inventory/InventoryTypeDto";
import { useFetchInventoryTypes, useManageInventoryAllocationPrices, useManageInventoryVariants } from "../../../hooks/Inventory/inventory";
import { InventoryVariantDto, InventoryVariantSelectOption } from "../../../types/Inventory/InventoryVariantDto";
import { operatorDefaultHeaders } from "../../../functions/api/api";
import Select, { SingleValue } from "react-select";
import axios from "axios";
import { HookConfig } from "../../../config/HookConfig";
import ApiStatus from "../../Common/Errors/ApiStatus";
import { PriceDto } from "../../../types/Price/PriceDto";
import { PricesCollection } from "../../Common/Prices/PricesCollection";
import React from "react";
import { SaveSnackBar } from "../../Common/SaveSnackBar";
import { pontConstants } from "../../../data/pontConstants";

type Args = {
    locationId: string,
    countryIsoCode3: string

};

const InventoryBasePricing = ({ locationId, countryIsoCode3 }: Args) => {

    const [submitting, setSubmitting] = useState(false);
    const manageInventoryAllocationPricesMutation = useManageInventoryAllocationPrices();
    const [inventoryTypes, setInventoryTypes] = useState<InventoryTypeSelectOption[]>([]);
    const [inventoryVariants, setInventoryVariants] = useState<InventoryVariantSelectOption[]>([]);
    const [selectedVariant, setSelectedVariant] = useState<InventoryVariantSelectOption>();
    const [selectedInventoryType, setSelectedInventoryType] = useState<InventoryTypeSelectOption>();
    const { data, status, isSuccess } = useFetchInventoryTypes();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [inventoryPrices, setInventoryPrices] = useState<PriceDto[]>([]);
    const [isFormValid, setIsFormValid] = useState(false);
    const variantsMutation = useManageInventoryVariants()


    const success = () => {
        alert('success');
    }

    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            setSubmitting(true);

            manageInventoryAllocationPricesMutation.mutate(['base', inventoryPrices!, successPrices]);

        } catch (err: any) {
            setSubmitting(false)
            console.error(err);
        } finally {
            setSubmitting(false);
        }
    }


    //#region snackbar

    const handleSnackClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };
    //#endregion snackbar

    const successPrices = () => {
        setSnackbarOpen(true);
    }

    useEffect(() => {
        if (data) {
            const selectOptions = data.map((inventoryType) => ({
                label: i18n.t(inventoryType.label),
                value: inventoryType.name,
                quantityVisible: inventoryType.quantityVisible,
                unitName: inventoryType.unitName
            }));

            setInventoryTypes(selectOptions);
            setSelectedInventoryType(selectOptions[0]);
        }
    }, [data]);




    useEffect(() => {
        const fetchData = async () => {
            try {
                if (selectedInventoryType) {
                    const response = await axios.get(`${HookConfig.inventoryUrl}/inventory-variants/${locationId}/${selectedInventoryType.value}`,
                        {
                            withCredentials: true,
                            headers: operatorDefaultHeaders()
                        }
                    );
                    const variants: InventoryVariantDto[] = response.data;

                    const selectOptions = variants.filter(x => x.id !== pontConstants.emptyGuid).map((variant) => ({
                        label: variant.value,
                        value: variant.id
                    }));
                    setInventoryVariants(selectOptions);
                    setSelectedVariant(selectOptions[0]);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [selectedInventoryType]);



    useEffect(() => {
        const fetchData = async () => {
            try {
                if (selectedVariant) {
                    const response = await axios.get(`${HookConfig.inventoryUrl}/base-prices/${locationId}/${selectedInventoryType?.value}/${selectedVariant.value}`,
                        {
                            withCredentials: true,
                            headers: operatorDefaultHeaders()
                        }
                    );
                    const prices: PriceDto[] = response.data;

                    setInventoryPrices(prices);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [selectedVariant]);


    const handleDropdownChange = (value: SingleValue<InventoryTypeSelectOption>) => {
        var selectedType = inventoryTypes.find(x => x.value === value?.value);
        setSelectedInventoryType(selectedType);
    };


    const handlePrices = (value: PriceDto) => {
        if (inventoryPrices) {
            var changedPrice = inventoryPrices!.find(x => x.unitName === value.unitName);

            if (!changedPrice)
                throw Error("Price not found!");

            changedPrice.currencyCode = value.currencyCode;
            changedPrice.price = value.price;
            changedPrice.fromDate = new Date();
            changedPrice.toDate = new Date('2099-01-01');

            setInventoryPrices((prevPrices) =>
                prevPrices.map((price) => {

                    if (price.unitName === changedPrice!.unitName) {
                        return changedPrice!;
                    }

                    return price;
                })
            );
        }
    };



    const handleDropdownChangeVariant = (value: SingleValue<{ value: string, label: string | undefined }>) => {
        const selectedVariant = inventoryVariants.find(x => x.value === value?.value);

        if (selectedVariant) {
            setSelectedVariant(selectedVariant);
        }
    };

    if (!selectedInventoryType || !inventoryTypes || !inventoryVariants) return <ApiStatus status={"loading"} />;


    return (
        <>
            <form onSubmit={onFormSubmit}>
                <SaveSnackBar onClose={handleSnackClose} isOpen={snackbarOpen}></SaveSnackBar>
                <LabelAndHelp mandatory={false} fullscreen={true} message='INVENTORY_LABEL_TYPE' ></LabelAndHelp>
                <div className="row d-flex justify-content-center mb-2">
                    <div className="justify-content-center">

                        <Select value={selectedInventoryType} options={inventoryTypes} className="form-control fa-form fa-dropdown-container" classNamePrefix="fa-dropdown"
                            onChange={(e) => handleDropdownChange(e)} placeholder={i18n.t('INVENTORY_LABEL_TYPE')}></Select>
                    </div>
                </div>

                <LabelAndHelp mandatory={false} fullscreen={true} message='INVENTORY_LABEL_VARIANT' ></LabelAndHelp>
                <div className="row d-flex justify-content-center mb-2">
                    <div className="justify-content-center">
                        <Select value={selectedVariant} options={inventoryVariants} className="form-control fa-form fa-dropdown-container" classNamePrefix="fa-dropdown"
                            onChange={(e) => handleDropdownChangeVariant(e)} placeholder={i18n.t('INVENTORY_LABEL_VARIANT')}></Select>
                    </div>
                </div>

                {(inventoryPrices.length > 0) &&
                    <>
                        <div className="row d-flex justify-content-center mb-3">
                            <div className="justify-content-center">
                                <PricesCollection countryIsoCode3={countryIsoCode3} label={i18n.t('PRICES_TITLE_BASEPRICES', {param0: selectedVariant?.label})} handleDirty={() => { }} callBack={handlePrices} items={inventoryPrices!}></PricesCollection>
                            </div>
                        </div>
                    </>
                }

                <div className="row d-flex justify-content-center mt-2">
                    <div className="col-6 justify-content-center">
                        <button className="btn btn-green btn-primary btn-block" disabled={submitting} type="submit">
                            <LR localResource="COMMON_LABEL_SAVE"></LR>
                        </button>
                    </div>
                </div>

            </form>
        </>

    );
}


export { InventoryBasePricing }