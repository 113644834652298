import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { SummaryItemCollection } from "../Common/SummaryItems/SummaryItemCollection";
import { SummaryItemDto } from "../../types/Common/SummaryItemDto";
import i18n from "../../i18n";
import { SectionHead } from "../Common/Help/SectionHead";
import { useCreateEvent, useFetchEventSummaryById, useUpdateEvent, useFetchEventById } from "../../hooks/Events/event";
import { LabelAndHelp } from "../Common/Help/LabelAndHelp";
import { ChangeEvent, useEffect, useState } from "react";
import { GenerateExternalReference } from "../../functions/utils/helper";
import { ParentMemberSelector } from "../Member/ParentMemberSelector";
import { MemberResponseDto } from "../../types/Member/MemberResponseDto";
import Select from "react-select";
import { LR } from "../Common/Help/LR";
import { pontCurrencies } from "../../data/en/pontCurrencies";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import FormattedNumberInput from "../Common/Prices/FormatNumberInput";
import { EventPriceDto } from "../../types/Event/EventPriceDto";
import { PriceDto } from "../../types/Price/PriceDto";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { LocationSelector } from "../Location/LocationSelector";
import { LocationResponseDto } from "../../types/Location/LocationResponseDto";
import { pontTimezones } from "../../data/en/pontTimezones";
import { EventResponseDto } from "../../types/Event/EventResponseDto";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Tooltip from "@mui/material/Tooltip/Tooltip";
import { SaveSnackBar } from '../Common/SaveSnackBar';
import { useDirtyState } from "../../hooks/Common/dirty";
import { ConfirmationChangesDialog } from "../Common/ConfirmationChangesDialog";


type Args =
    {
        userLogged: ApplicationUser,
        isEdit: boolean
    }

const EventSummary = ({ userLogged, isEdit }: Args) => {
    // force to use of UTC in order to keep tz standardized
    // since timezone is handled separate from date object, it just needs to be consistent
    dayjs.extend(utc);
    dayjs.extend(timezone);

    const nav = useNavigate();
    let summaryName: string = '';
    const queryStringParams = useParams();
    if (isEdit && !queryStringParams.id) throw Error("Location id needed");

    if (queryStringParams.summaryName)
        summaryName = queryStringParams.summaryName;

    const linkCallBackCollection = (item: SummaryItemDto) => {
        switch (item.name) {
            case "EventFeaturesSummary":
                nav(`/events/${queryStringParams.id}/features`);
                break;
            case "EventDescriptionSummary":
                nav(`/events/${queryStringParams.id}/description`);
                break;
            case "EventCheckOutNoteSummary":
                nav(`/events/${queryStringParams.id}/checkoutnote`);
                break;
            case "FeatureSummary":
                nav(`/events/${queryStringParams.id}/features`);
                break;
            case "EventImagesSummary":
            case "EventDocsSummary":
                nav(`/events/${queryStringParams.id}/digital-assets/${item.uri.substring(item.uri.indexOf('group=') + 6)}`);
                break;
            default:
                alert(`Item ${item.name} does not have a link yet`);
                break;
        }
    }

    const dialogConfirm = () => {
        setShowConfirmation(false);
        unsetDirty();
        nav(targetUrl);
    };

    const dialogCancel = () => {
        setShowConfirmation(false);
    };


    const updatedCallback = () => {
        nav(`/events/${queryStringParams.id}/summary`);
        success();
    }

    const createdCallback = (eventId: string) => {
        nav(`/events/${eventId}/summary`);
        success();
    }

    // const homeCallBack = () => {
    //     nav(`/`);
    // }

    const homeCallBack = () => {
        const url = `/inventory`;
        if (isDirty) {
            setTargetUrl(url);
            setShowConfirmation(true);
        } else {
            nav(url);
        }
    };


    const createPriceItem = (eventPriceDto: EventPriceDto, eventId: string) => {
        let priceDto: PriceDto = {
            id: eventPriceDto?.id ?? 0,
            entityName: 'Event',
            entityKey: eventId ?? '',
            unitName: 'Ticket',
            fromDate: eventPriceDto?.fromDate ?? Date,
            toDate: eventPriceDto?.toDate ?? Date,
            currencyCode: eventPriceDto?.currencyCode ?? 'GBP',
            price: eventPriceDto?.price ?? 0
        }
        return priceDto;
    }

    const defaultEvent: EventResponseDto = {
        id: '',
        name: '',
        isOnlineEvent: true,
        locationId: 'DD8952D2-3D72-47FB-BD8E-32039FDA4A0B',
        timeZoneName: '',
        ownerMemberId: '',
        legalEntityMemberId: '',
        quantity: 0,
        retainedQuantity: 0,
        quantityLimitPerOrder: 1,
        externalReference: '',
        isDisabled: false,
        internalIdentifier: '',
        fromDate: new Date(),
        toDate: new Date(),
        prices: [],
        ticketsSold: 0,
        saleCount: 0,
        ownerMemberFullName: '',
        legalEntityMemberFullName: '',
        locationName: '',
        shortDescription: '',
        description: '',
        checkOutNote: '',
    };

    const defaultSummaries: SummaryItemDto[] = [];
    const { data: event, status: eventStatus, isSuccess: eventIsSuccess } = useFetchEventById(queryStringParams.id!, isEdit);
    const { data, status, isSuccess } = useFetchEventSummaryById(queryStringParams.id!, summaryName!, isEdit);
    const [eventState, setEventState] = useState(defaultEvent);
    const [summariesState, setEventSummaries] = useState(defaultSummaries);
    const [selectedPrice, setSelectedPrice] = useState(0);
    const [selectedCurrency, setSelectedCurrency] = useState<{ value: string, label: string, countryCode: string }>({ value: 'GBP', label: 'British Pound Sterling - £', countryCode: 'GBR' });
    const [selectedTimezone, setSelectedTimezone] = useState<{ value: string, label: string }>({ label: "Europe/London", value: "Europe/London" });
    const [priceItemState, setPriceItemState] = useState<PriceDto>(createPriceItem(null!, null!));
    const [isOnline, setIsOnline] = useState<boolean>(true);
    const [legalEntityIdState, setLegalEntityId] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const { mutate } = useUpdateEvent(updatedCallback);
    const create = useCreateEvent(createdCallback);

    const [isDirty, setDirty, unsetDirty] = useDirtyState();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [targetUrl, setTargetUrl] = useState('');


    useEffect(() => {
        if (isEdit) {
            if (eventIsSuccess && event) {
                event.fromDate = new Date(event.fromDate);
                event.toDate = new Date(event.toDate);

                if (!event.quantityLimitPerOrder || event.quantityLimitPerOrder === 0) {
                    event.quantityLimitPerOrder = 1;
                  }


                setEventState(event);
                setSelectedPrice(event?.prices[0]?.price ?? 0);
                var priceCurrency = pontCurrencies.find(x => x.value === event?.prices[0]?.currencyCode || 'GBP')
                setSelectedCurrency(priceCurrency!);
                var defaultZone = pontTimezones.find(x => x.label === event?.timeZoneName || 'Europe/London')
                setSelectedTimezone(defaultZone!);
                setIsOnline(event.isOnlineEvent);
                setLegalEntityId(event.legalEntityMemberId);
                setPriceItemState(createPriceItem(event?.prices[0], event.id));
                if (data) {
                    setEventSummaries(data)
                }
            }
        }
    }, [status, eventStatus]);

    const handleSnackClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
         }
        setSnackbarOpen(false);
        };

    const success = () => {
        setSnackbarOpen(true);
    };

    // const handleCurrencyChange = (selectedOption: any) => {
    //     setSelectedCurrency(selectedOption);
    //     setPriceItemState({ ...priceItemState!, currencyCode: selectedOption });
    // }

    const handleCurrencyChange = (selectedOption: any) => {
        setSelectedCurrency(selectedOption); // Setting the new currency
        setPriceItemState(prevState => ({ ...prevState, currencyCode: selectedOption.value }));
    };

    const handleZoneChange = (selectedOption: any) => {
        setSelectedTimezone(selectedOption);
        setEventState({ ...eventState, timeZoneName: selectedOption.value });
    }

    const generateUniqueCode = () => {
        if(eventState.externalReference === '' || eventState.externalReference === null){
            const formattedCode = GenerateExternalReference(eventState.name ?? '');
            setEventState({ ...eventState, externalReference: formattedCode });
        }
    };

    const confirmLegalEntity = (operator: MemberResponseDto) => {
        setEventState({ ...eventState, legalEntityMemberId: operator.id });
        setLegalEntityId(operator.id);
    }

    const confirmOrganizer = (operator: MemberResponseDto) => {
        setEventState({ ...eventState, ownerMemberId: operator.id, legalEntityMemberId: legalEntityIdState });
    }

    const confirmLocation = (location: LocationResponseDto) => {
        setEventState({ ...eventState, locationId: location.id });
    }

    const handleLocationRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsOnline(event.target.value === 'online');
        if (isOnline) {
            eventState.locationId = 'DD8952D2-3D72-47FB-BD8E-32039FDA4A0B';
        }
        setEventState({ ...eventState, isOnlineEvent: isOnline });
    };

    const onPriceItemChange = (value: string) => {
        setPriceItemState({ ...priceItemState!, price: Number(value) });
    }

    const handleTimeChange = (currentDateTime: Date, newTime: Date): Date => {
        // Update only the time component
        let convertedDateTime = new Date(currentDateTime);
        convertedDateTime.setHours(newTime.getHours(), newTime.getMinutes());
        return convertedDateTime;
    };

    const addEventPrice = () => {
        let price: EventPriceDto = {
            id: 0,
            unitName: 'Ticket',
            fromDate: eventState.fromDate,
            toDate: eventState.toDate,
            currencyCode: priceItemState.currencyCode,
            price: priceItemState.price
        }
        eventState.prices.push(price);
    }

    const requiredPopulated = () => {
        return !!eventState.name &&
                !!eventState.shortDescription &&
                !!legalEntityIdState &&
                !!eventState.ownerMemberId &&
                (eventState.isOnlineEvent || !!eventState.locationId)  &&
                eventState.toDate > eventState.fromDate;
    }

    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
          e.preventDefault();

          const quantityLimit = eventState.quantityLimitPerOrder;

          const updatedEventState = {
            ...eventState,
            quantityLimitPerOrder:
              quantityLimit == null || isNaN(quantityLimit) || quantityLimit === 0
                ? 1
                : quantityLimit,
          };

          let ticketPrice = eventState.prices?.find((x) => x.unitName === 'Ticket');
          if (ticketPrice) {
            ticketPrice.price = priceItemState.price;
            ticketPrice.currencyCode = priceItemState.currencyCode;
            ticketPrice.fromDate = new Date();
            ticketPrice.toDate = eventState.toDate;
          } else {
            addEventPrice();
          }

          await setEventState({ ...eventState, legalEntityMemberId: legalEntityIdState });

          if (isEdit) {
            mutate(updatedEventState);
          } else {
            create.mutate(updatedEventState);
          }

          unsetDirty();
          setSnackbarOpen(true);
        } catch (err: any) {
          console.error(err);
        }
    };


    return (
        <>
            <form onSubmit={onFormSubmit}>
                <ConfirmationChangesDialog
                    handleConfirm={dialogConfirm}
                    handleCancel={dialogCancel}
                    showConfirmation={showConfirmation}
                />

                {!queryStringParams.summaryName && (
                    <SectionHead
                        linkCallBack={homeCallBack}
                        ctaText={i18n.t('COMMON_LABEL_INVENTORY')!}
                        name={isEdit ? `${eventState.name} (${eventState.externalReference})` : ''}
                        title={i18n.t(!isEdit ? 'EVENT_SUMMARY_EVENTMAINPAGEADD' : 'EVENT_SUMMARY_EVENTMAINPAGEEDIT'!)}
                        description={i18n.t('COMMON_SUMMARY_EVENTMANAGEMENT')}
                    />
                )}

                <div className="row d-flex justify-content-center">
                    <div className="col-md-7 col-11 justify-content-center">
                        <LabelAndHelp mandatory={true} fullscreen={true} message="EVENT_LABEL_NAME" />
                        <div className="row d-flex justify-content-center mb-2">
                            <div className="justify-content-center">
                                <input
                                    type="text"
                                    className="form-control fa-form"
                                    placeholder={i18n.t('EVENT_LABEL_NAME').toString()}
                                    value={eventState.name}
                                    onChange={(e) => {
                                        setEventState({ ...eventState, name: e.target.value });
                                        setDirty();
                                    }}
                                    onBlur={() => generateUniqueCode()}
                                    required
                                />
                            </div>
                        </div>

                        <LabelAndHelp mandatory={true} fullscreen={true} message="EVENT_LABEL_DESCRIPTION" />
                        <div className="row d-flex justify-content-center mb-2">
                            <div className="justify-content-center">
                                <input
                                    type="text"
                                    className="form-control fa-form"
                                    placeholder={i18n.t('EVENT_LABEL_DESCRIPTION').toString()}
                                    value={eventState.shortDescription}
                                    onChange={(e) => {
                                        setEventState({ ...eventState, shortDescription: e.target.value });
                                        setDirty();
                                    }}
                                    required
                                />
                            </div>
                        </div>

                        <LabelAndHelp mandatory={true} fullscreen={true} message="EVENT_LABEL_IDENTIFIER" />
                        <div className="row d-flex justify-content-center mb-2">
                            <div className="justify-content-center">
                                <input
                                    type="text"
                                    className="form-control fa-form"
                                    placeholder={i18n.t('EVENT_LABEL_IDENTIFIER').toString()}
                                    value={eventState.externalReference}
                                    onChange={(e) => {
                                        setEventState({ ...eventState, externalReference: e.target.value });
                                        setDirty();
                                    }}
                                    required
                                />
                            </div>
                        </div>

                        <LabelAndHelp mandatory={true} fullscreen={true} message="EVENT_LABEL_LEGALENTITY" />
                        <div className="row d-flex justify-content-center mb-2">
                            <div className="justify-content-center">
                                <ParentMemberSelector
                                    key={'legalEntityIdState'}
                                    legalEntityMemberId={legalEntityIdState!}
                                    placeholder="EVENT_LABEL_LEGALENTITY"
                                    flagType={512}
                                    operatorId={userLogged.operator?.id!}
                                    handleConfirm={confirmLegalEntity}
                                />
                            </div>
                        </div>

                        <LabelAndHelp mandatory={true} fullscreen={true} message="EVENT_LABEL_OWNER" />
                        <div className="row d-flex justify-content-center mb-2">
                            <div className="justify-content-center">
                                <ParentMemberSelector
                                    key={'ownerMemberId'}
                                    legalEntityMemberId={eventState.ownerMemberId!}
                                    placeholder="EVENT_LABEL_OWNER"
                                    flagType={512}
                                    operatorId={userLogged.operator?.id!}
                                    handleConfirm={confirmOrganizer}
                                />
                            </div>
                        </div>

                        <div className="row d-flex justify-content-center mt-4 mb-3">
                            <div className="col-md-3 col-11">
                                <RadioGroup name="use-radio-group" value={isOnline ? "online" : "live"} onChange={handleLocationRadioChange}>
                                    <FormControlLabel value="online" label="Online" control={<Radio color="secondary" />} />
                                    <FormControlLabel value="live" label="In-Person" control={<Radio color="secondary" />} />
                                </RadioGroup>
                            </div>
                            <div className="col-md-9">
                                {isOnline && (
                                    <Select
                                        value={selectedTimezone}
                                        className="form-control fa-form fa-dropdown-container"
                                        classNamePrefix="fa-dropdown"
                                        options={pontTimezones}
                                        onChange={handleZoneChange}
                                        placeholder={i18n.t('COMMON_LABEL_SELECTTIMEZONE').toString()}
                                    />
                                )}
                                {!isOnline && (
                                    <LocationSelector
                                        locationId={eventState.locationId!}
                                        placeholder="EVENT_LABEL_LOCATION"
                                        operatorId={userLogged.operator?.id!}
                                        handleConfirm={confirmLocation}
                                    />
                                )}
                            </div>
                        </div>

                        <div className="row d-flex justify-content-center mt-4 mb-3">
                            <div className="col-md-6 col-11">
                                <DatePicker
                                    label={i18n.t('EVENT_LABEL_FROMDATE').toString()}
                                    className="fa-datepicker"
                                    value={dayjs(eventState.fromDate)}
                                    timezone="UTC"
                                    onChange={(newValue) => {
                                        setEventState({ ...eventState, fromDate: newValue?.toDate()! });
                                        setDirty();
                                    }}
                                />
                            </div>
                            <div className="col-md-6 col-11">
                                <TimePicker
                                    label={i18n.t('EVENT_LABEL_FROMTIME').toString()}
                                    className="fa-datepicker"
                                    value={dayjs(eventState.fromDate)}
                                    onChange={(newValue) => {
                                        const newDateTimeValue = handleTimeChange(eventState.fromDate, newValue?.toDate()!);
                                        setEventState({ ...eventState, fromDate: newDateTimeValue });
                                        setDirty();
                                    }}
                                />
                            </div>
                        </div>

                        <div className="row d-flex justify-content-center mt-4 mb-3">
                            <div className="col-md-6 col-11">
                                <DatePicker
                                    label={i18n.t('EVENT_LABEL_TODATE').toString()}
                                    className="fa-datepicker"
                                    value={dayjs(eventState.toDate)}
                                    timezone="UTC"
                                    onChange={(newValue) => {
                                        setEventState({ ...eventState, toDate: newValue?.toDate()! });
                                        setDirty();
                                    }}
                                />
                            </div>
                            <div className="col-md-6 col-11">
                                <TimePicker
                                    label={i18n.t('EVENT_LABEL_TOTIME').toString()}
                                    className="fa-datepicker"
                                    value={dayjs(eventState.toDate)}
                                    onChange={(newValue) => {
                                        const newDateTimeValue = handleTimeChange(eventState.toDate, newValue?.toDate()!);
                                        setEventState({ ...eventState, toDate: newDateTimeValue });
                                        setDirty();
                                    }}
                                />
                            </div>
                        </div>

                        <div className="row d-flex justify-content-center mb-2">
                            <div className="col-md-3">
                                <LabelAndHelp mandatory={true} fullscreen={true} message="EVENT_LABEL_QUANTITY" />
                                <input
                                    type="number"
                                    id="quantity"
                                    min={0}
                                    value={eventState.quantity}
                                    placeholder={i18n.t('EVENT_LABEL_QUANTITY')!}
                                    className="form-control fa-form"
                                    onChange={(e) => {
                                        setEventState({ ...eventState, quantity: Number(e.target.value) });
                                        setDirty();
                                    }}
                                />
                            </div>
                            <div className="col-md-3">
                                <LabelAndHelp mandatory={true} fullscreen={true} message="EVENT_LABEL_RETAINED" />
                                <input
                                    type="number"
                                    id="retained"
                                    min={0}
                                    max={eventState.quantity}
                                    value={eventState.retainedQuantity}
                                    placeholder={i18n.t('EVENT_LABEL_RETAINED')!}
                                    className="form-control fa-form"
                                    onChange={(e) => {
                                        setEventState({ ...eventState, retainedQuantity: Number(e.target.value) });
                                        setDirty();
                                    }}
                                />
                            </div>
                            <div className="col-md-3">
                         <LabelAndHelp mandatory={true} fullscreen={true} message="EVENT_LABEL_LIMIT" />
                                <input
                                    type="number"
                                    id="limit"
                                    min={1}
                                    value={eventState.quantityLimitPerOrder ?? 1}
                                    placeholder={i18n.t('EVENT_LABEL_LIMIT')!}
                                    className="form-control fa-form"
                                    onChange={(e) => {
                                        const value = Number(e.target.value);
                                        setEventState({
                                        ...eventState,
                                        quantityLimitPerOrder: isNaN(value) || value < 0 ? 1 : value,
                                        });
                                        setDirty();
                                    }}
                                />
                            </div>

                            <div className="col-md-3">
                                <LabelAndHelp mandatory={true} fullscreen={true} message="EVENT_LABEL_SOLD" />
                                <input
                                    type="number"
                                    id="sold"
                                    disabled
                                    min={0}
                                    value={eventState.ticketsSold}
                                    placeholder={i18n.t('EVENT_LABEL_SOLD')!}
                                    className="form-control fa-form"
                                />
                            </div>
                        </div>

                        <div className="row d-flex justify-content-center mt-4 mb-3">
                            <div className="col-md-6">
                                <LabelAndHelp mandatory={true} fullscreen={true} message="COMMON_LABEL_TICKETPRICE" />
                                <FormattedNumberInput
                                    allowEmpty={false}
                                    value={selectedPrice}
                                    onChange={(item, value) => onPriceItemChange(value)}
                                    currencyValue={selectedCurrency!.value}
                                    item={priceItemState!}
                                />
                            </div>

                            <div className="col-md-6">
                                <LabelAndHelp mandatory={true} fullscreen={true} message="COMMON_LABEL_CURRENCY" />
                                <Select
                                    key={JSON.stringify(selectedCurrency)}
                                    value={selectedCurrency}
                                    className="form-control fa-form fa-dropdown-container"
                                    classNamePrefix="fa-dropdown"
                                    onChange={handleCurrencyChange}
                                    options={pontCurrencies}
                                    getOptionLabel={(option) => option.label}
                                    getOptionValue={(option) => option.value}
                                    placeholder={i18n.t("COMMON_LABEL_SELECTCURRENCY").toString()}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row d-flex justify-content-center">
                    <div className="col-md-7 col-11 justify-content-center">
                        <SummaryItemCollection linkCallBack={linkCallBackCollection} items={summariesState} />
                    </div>
                </div>

                <div className="row d-flex justify-content-center mt-3">
                    <div className="col-md-2 col-6 justify-content-center">
                        <Tooltip title={!requiredPopulated() ? i18n.t('EVENT_REQUIRED_TOOLTIP') : ''}>
                            <span>
                                <button
                                    type="submit"
                                    disabled={!requiredPopulated()}
                                    style={!requiredPopulated() ? { pointerEvents: 'none' } : {}}
                                    className="btn btn-green btn-primary btn-block"
                                >
                                    <LR localResource="COMMON_LABEL_SAVE" />
                                </button>
                            </span>
                        </Tooltip>
                    </div>
                </div>

                <SaveSnackBar onClose={handleSnackClose} isOpen={snackbarOpen} />
            </form>
        </>
    );
};

export { EventSummary }