

const ErrorInterpolationParser = function (parameters: Array<string>) {

    var message = "{";
    for (var i = 0; i < parameters.length; i++) {
        if (i > 0) {
            message += ",";
        }
        message += '"param' + i + '": "' + parameters[i].replaceAll("'", "") + '"';
    }
    message += "}"
    return JSON.parse(message);
}


export {
    ErrorInterpolationParser
}

