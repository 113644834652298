import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Card, CardContent, Typography } from '@mui/material';
import { LR } from './LR';

type Args = {
  showHelp: boolean,
  contentReference: string
  handleConfirm: () => void,
  handleClose: () => void
}

const HelpDialog = ({ showHelp, handleConfirm, contentReference, handleClose }: Args) => {
  return (
    <>
      <Dialog open={showHelp} onClose={handleClose}>
        <Card>
          {/* <CardMedia component="img" height="70" image='/images/help/help.png' /> */}
          <CardContent>
            <DialogTitle><LR localResource='COMMON_TITLE_HELPDIALOG'></LR></DialogTitle>
            <DialogContent>
              <DialogContentText>
                <div className="helpDialogContent">
                  <Typography variant="subtitle2" color="textSecondary">
                    <LR localResource={contentReference}></LR>
                  </Typography>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant={'contained'} onClick={handleClose}><LR localResource='COMMON_BUTTON_CLOSE'></LR></Button>
            </DialogActions>
          </CardContent>
        </Card>
      </Dialog>
    </>
  );
};

export { HelpDialog }
