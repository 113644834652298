import axios, { AxiosError } from "axios";
import { useQuery } from "react-query";
import { DataInterchangeDto } from "../../types/Data-Interchange/DataInterchangeDto";
import { operatorDefaultHeaders } from "../../functions/api/api";

const useFetchDataInterchanges = (type:string, operatorId:string) => {

    const rootUrl = type === 'location' ? '/location-management/' : type === 'inventory' ? '/inventory-management/' : type === 'member' ? '/member-management/' : type === 'product' ? '/product-management/' : '/order-management/'

    return useQuery<DataInterchangeDto[], AxiosError>(["data-interchanges", type, operatorId], () =>
      axios.get(`${rootUrl}data-interchanges?operatormemberid=${operatorId}`,
        {
          withCredentials: true,
           headers: operatorDefaultHeaders() 
        }
      ).then((resp) => resp.data)
    );
  }


  export {useFetchDataInterchanges}