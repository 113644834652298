import React, { useRef, useState, useCallback, useEffect } from "react";  
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./CustomQuillEditor.css";
import Modal from 'react-bootstrap/Modal';
import EditorToolbar from './EditorToolbar';

interface CustomQuillEditorProps {
    value: string;
    onChange: (value: string) => void;
    placeholder: string;
    onSaveChanges?: (html: string) => void;
}

const CustomQuillEditor: React.FC<CustomQuillEditorProps> = ({ value, onChange, placeholder, onSaveChanges }) => {
    const quillRef = useRef<ReactQuill>(null);
    const [showSourceModal, setShowSourceModal] = useState(false);
    const [editorHtml, setEditorHtml] = useState(value);
    const [modalHtml, setModalHtml] = useState(value);  
    const [originalHtml, setOriginalHtml] = useState(value); 

    
    useEffect(() => {
        setEditorHtml(value);
        setOriginalHtml(value);  
    }, [value]);

    
    const handleEditorChange = (content: string) => {
        setEditorHtml(content); 
        onChange(content); 
    };

    const insertHtmlDirectly = (html: string) => {
        const editor = quillRef.current?.getEditor();
        if (editor) {
            const delta = editor.clipboard.convert(html);  
            editor.setContents(delta, 'silent');  
        }
    };

    
    const handleViewSource = useCallback(() => {
        const editor = quillRef.current?.getEditor();
        if (editor) {
            const currentHtml = editor.root.innerHTML;  
            setModalHtml(currentHtml);  
            setOriginalHtml(currentHtml);  
            setShowSourceModal(true);
        }
    }, []);

    
    const handleTextAreaChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setModalHtml(e.target.value);  
    }, []);

    
    const handleSaveChanges = useCallback(() => {
        insertHtmlDirectly(modalHtml);  
        onChange(modalHtml);  
        if (onSaveChanges) {
            onSaveChanges(modalHtml);
        }
        setShowSourceModal(false);  
    }, [modalHtml, onChange, onSaveChanges]);

    
    const handleClose = useCallback(() => {
        setModalHtml(originalHtml);  
        setShowSourceModal(false);   
    }, [originalHtml]);

    return (
        <div className="row d-flex justify-content-center mt-2">
            <div className="col-md-5 col-10 justify-content-center mt-1">
                <EditorToolbar onViewSourceClick={handleViewSource} />
                <ReactQuill
                    ref={quillRef}
                    theme="snow"
                    value={editorHtml}
                    onChange={handleEditorChange} 
                    placeholder={placeholder}
                    modules={{ toolbar: '#toolbar' }}
                    formats={[
                        'bold', 'italic', 'underline', 'link', 'list', 'bullet', 
                        'header', 'blockquote', 'code-block', 'script', 'indent',
                        'direction', 'align', 'color', 'background', 'font', 'size'
                    ]}
                />
            </div>
            <Modal className="custom-modal-position" show={showSourceModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className='modal-title'>View Source</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <textarea 
                        className="form-control"
                        rows={10}
                        value={modalHtml}  
                        onChange={handleTextAreaChange}  
                    />
                </Modal.Body>
                <Modal.Footer>
                    <button className="primary-button" onClick={handleSaveChanges}>Save Changes</button>
                    <button className="secondary-button" onClick={handleClose}>Close</button> 
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default CustomQuillEditor;
