import axios, { AxiosError, AxiosResponse } from "axios";
import { HookConfig } from "../../config/HookConfig";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { operatorDefaultHeaders } from "../../functions/api/api";
import { SearchRequestDto } from "../../types/Common/SearchRequestDto";
import { PostResponseDto } from "../../types/Post/PostResponseDto";
import { useEffect, useState } from "react";
import { PostSearchResponseDto } from "../../types/Post/PostSearchResponseDto";
import { PostRequestDto } from "../../types/Post/PostRequestDto";
import { AddDaysToDate, DateShim } from "../../functions/utils/helper";
import { SummaryItemDto } from "../../types/Common/SummaryItemDto";


const useSearchPosts = (applicationId: string, searchQuery: string, pageCount: number, pageSize: number) => {
    const [data, setData] = useState<PostSearchResponseDto>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let searchParams: SearchRequestDto = {
            applicationId: applicationId,
            requirement: searchQuery,
            pageNumber: pageCount,
            pageSize: pageSize
        }

        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.post(`${HookConfig.postUrl}/posts/search`,
                    searchParams,
                    {
                        withCredentials: true,
                        headers: operatorDefaultHeaders()
                    });
                setData(response.data);
            } catch (err) {
                console.error('Error fetching data', err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [applicationId, searchQuery, pageCount, pageSize]);

    return { data, loading };
};

const deletePostById = (id: string) => {
    return axios
        .delete(`${HookConfig.postUrl}/posts/${id}`, {
            withCredentials: true,
            headers: operatorDefaultHeaders(),
        })
        .then(() => {
            return { success: true };
        })
        .catch((error) => {
            console.log("Error deleting post:", error);
            return { success: false, error };
        });
};

const useFetchPostSummariesById = (id: string, summaryName: string, doFetch: boolean = true) => {
    return useQuery<SummaryItemDto[], AxiosError>(["post-summary", id, summaryName], () =>
      axios.get(
        (summaryName ?
          `${HookConfig.postUrl}/posts/${id}/${summaryName}/summary` :
          `${HookConfig.postUrl}/posts/${id}/summary`),
        {
          withCredentials: true,
          headers: operatorDefaultHeaders()
        })
        .then(
          (response) => {
            return response.data;
          }
        ), {enabled: doFetch});
  };
  

const useFetchPostById = (id: string, doFetch: boolean = true) => {
    return useQuery<PostResponseDto, AxiosError>(["post", id], () =>
        axios.get(`${HookConfig.postUrl}/posts/${id}`,
            {
                withCredentials: true,
                headers: operatorDefaultHeaders()
            })
            .then(
                (response) => {
                    var post: PostResponseDto = response.data;
                    //Take 1 day off the toDate
                    post.toDate = AddDaysToDate(post.toDate, -1);

                    return post;
                }
            ), { enabled: doFetch });
};


const useCreatePost = (callback: (postId: string) => void) => {
    const queryClient = useQueryClient();
    return useMutation<AxiosResponse, AxiosError, PostRequestDto>(
        (postRequest) => {
            // Clone the postRequest object to avoid mutating the original
            const updatedPostRequest = { ...postRequest };

            // Add 1 day to the toDate attribute
            updatedPostRequest.toDate = AddDaysToDate(updatedPostRequest.toDate, 1)
            // Remove UTC times
            updatedPostRequest.fromDate = DateShim(updatedPostRequest.fromDate);
            updatedPostRequest.toDate = DateShim(updatedPostRequest.toDate);

            return axios.post(`${HookConfig.postUrl}/posts`, updatedPostRequest,
                {
                    withCredentials: true,
                    headers: operatorDefaultHeaders()
                }
            );
        },
        {
            onSuccess: (_, post) => {
                queryClient.invalidateQueries(["post", post.id]);
                callback(_.data.id);
            },
            onError: (_, error) => {
                console.log(error);
            }
        }
    );
};

const useUpdatePost = (callback: () => void) => {
    const queryClient = useQueryClient();

    return useMutation<AxiosResponse, AxiosError, PostRequestDto>(
        (postRequest) => {
            // Clone the postRequest object to avoid mutating the original
            const updatedPostRequest = { ...postRequest };

            // Add 1 day to the toDate attribute
            updatedPostRequest.toDate = AddDaysToDate(updatedPostRequest.toDate, 1)
            // Remove UTC times
            updatedPostRequest.fromDate = DateShim(updatedPostRequest.fromDate);
            updatedPostRequest.toDate = DateShim(updatedPostRequest.toDate);

            // Send the updated postRequest object to the server
            return axios.put(`${HookConfig.postUrl}/posts/${postRequest.id}`, updatedPostRequest,
                {
                    withCredentials: true,
                    headers: operatorDefaultHeaders(),
                }
            );
        },
        {
            onSuccess: (_, post) => {
                queryClient.invalidateQueries(["post", post.id]);
                callback();
            },
            onError: (_, error) => {
                console.log(error);
            },
        }
    );
};


const useUpdatePostContent = (callback: () => void) => {
    const queryClient = useQueryClient();
    return useMutation<AxiosResponse, AxiosError, PostResponseDto>(
      (post) => axios.put(`${HookConfig.postUrl}/posts/${post.id}/content`,
        {
          id: post.id,
          content: post.content
        },
        {
          withCredentials: true,
          headers: operatorDefaultHeaders()
        }
      ),
      {
        onSuccess: (_, event) => {
          queryClient.invalidateQueries(["event", event.id]);
          callback();
        },
        onError: (_, error) => {
          console.log(error);
        }
      }
    );
  };


export {
    useSearchPosts,
    useFetchPostById,
    useCreatePost,
    useUpdatePost,
    deletePostById,
    useFetchPostSummariesById,
    useUpdatePostContent
};