import axios from "axios";
import { Visitor } from "../../types/Visitor";
import { useEffect, useState } from "react";
  
const useFetchVisitor = () => {
  const [data, setData] = useState<Visitor>();
  const [status, setStatus] = useState('idle');
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    const fetchVisitor = async () => {
      setStatus('loading');
      const cachedVisitor = JSON.parse(localStorage.getItem('visitor')!);
      const oneWeek = 7 * 24 * 60 * 60 * 1000; // milliseconds in one week
      const now = new Date().getTime();

      if (cachedVisitor && now - cachedVisitor.time < oneWeek) {
        setData(cachedVisitor.data);
        setStatus('success');
        setIsSuccess(true);
      } else {
        try {
          const response = await axios.get(`https://api.visitorapi.com/api/?pid=efNjQ0EBjpFm3FMS7wHW`,
          {
            withCredentials : false,
          });
          localStorage.setItem('visitor', JSON.stringify({
            time: now, // add timestamp
            data: response.data.data // store data
          }));
          setData(response.data.data);
          setStatus('success');
          setIsSuccess(true);
        } catch (error) {
          setStatus('error');
        }
      }
    };

    fetchVisitor();
  }, []);

  return { data, status, isSuccess };
};



export default useFetchVisitor;


