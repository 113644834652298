import { ConfigurationDto } from "../types/Common/ConfigurationDto";

export const locationConfigurations: ConfigurationDto[] = [
  {
    name: "INVENTORY_LABEL_VARIANTS",
    url: "variants",
    groupName: "MENU_LABEL_INVENTORY"
  },
  {
    name: "INVENTORY_LABEL_BASEPRICES",
    url: "base-prices",
    groupName: "MENU_LABEL_INVENTORY"
  },
  {
    name: "LOCATION_LABEL_OPERATIONALDETAILS",
    url: "operational-details",
    groupName: "MENU_LABEL_INVENTORY"
  },
]

export const pontLocationStatus = [
  {
    name: "Pending",
    label: "LABEL_LOCATIONSTATUS_PENDING"
  },
  {
    name: "Live",
    label: "LABEL_LOCATIONSTATUS_LIVE"
  },
  {
    name: "Closed",
    label: "LABEL_LOCATIONSTATUS_CLOSED"
  },
  {
    name: "Deactivated",
    label: "LABEL_LOCATIONSTATUS_DEACTIVATED"
  }
]


export const pontNearByTypes = [
  { label: "LOCATION_NEARBY_AIRPORT", value: "Airport" },
  { label: "LOCATION_NEARBY_BUS", value: "BusStop" },
  { label: "LOCATION_NEARBY_CAFE", value: "Cafe" },
  { label: "LOCATION_NEARBY_GROCERY", value: "Grocery" },
  { label: "LOCATION_NEARBY_GYM", value: "Gym" },
  { label: "LOCATION_NEARBY_PARK", value: "Park" },
  { label: "LOCATION_NEARBY_RESTAURANT", value: "Restaurant" },
  { label: "LOCATION_NEARBY_SUBWAY", value: "SubwayStation" },
  { label: "LOCATION_NEARBY_TRAIN", value: "TrainStation" },
]