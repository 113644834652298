import { useState } from "react";
import './Login.css'
import { UserPasswordReset } from "../../types/Login/UserPasswordReset";
import { UserPasswordResetResponse } from "../../types/Login/UserPasswordResetResponse";
import { UseRequestPasswordResetById, UseResetPassword } from "../../hooks/Login/reset";
import Otp from "../Common/Login/Otp";
import { ErrorLabel } from "../Common/Errors/ErrorLabel";
import i18n from "../../i18n";
import { LR } from "../Common/Help/LR";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";

type Args =
    {
        userLogged: ApplicationUser
    }

function ResetPasswordAlreadyRegistered({userLogged} : Args) {

    const emptyUserPasswordReset: UserPasswordReset =
    {
        id: "",
        confirmationCode: "",
        confirmPassword: "",
        password: "",
        passwordToken: "",
        error: ""
    }

    const emptyUserPasswordResetResponse: UserPasswordResetResponse =
    {
        id: "",
        email: "",
        isPasswordChanged: false,
        error: ""
    }

    const [submitting, setSubmitting] = useState(false);
    const [userPasswordResetState, setuserPasswordResetState] = useState<UserPasswordReset>({ ...emptyUserPasswordReset });
    const [userPasswordResetResponseState, setuserPasswordResetResponseState] = useState<UserPasswordResetResponse>({ ...emptyUserPasswordResetResponse });

    const OTPCodeChange = (value: string) => {        
        setuserPasswordResetState({ ...userPasswordResetState,  confirmationCode : value });
    }

    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            setSubmitting(true);
            setuserPasswordResetState({ ...userPasswordResetState, error: "" });

            if (userPasswordResetState.confirmationCode) {
                UseResetPassword(userPasswordResetState, function (response: UserPasswordResetResponse) {
                    if (!response.isPasswordChanged) {
                        setuserPasswordResetState({
                            ...userPasswordResetState,
                            error: response.error
                        });

                    } else {
                        setuserPasswordResetResponseState({
                            ...userPasswordResetResponseState,
                            id: response.id,
                            isPasswordChanged: response.isPasswordChanged,
                            email: response.email
                        });
                    }

                    setSubmitting(false);
                });
            } else {

                UseRequestPasswordResetById(userLogged.id, function (response: UserPasswordReset) {
                    setuserPasswordResetState({
                        ...userPasswordResetState,
                        id: response.id,
                        error: response.error,
                        passwordToken: response.passwordToken
                    });

                    setSubmitting(false);
                });

            }
        } catch (err: any) {
            console.error(err)
        } 
    }

    return (
        <div className="mt-5 row d-flex justify-content-center">
        <div className="col-md-4 mt-5  col-12 justify-content-center">
            {!Boolean(userPasswordResetResponseState.isPasswordChanged) &&
                <form onSubmit={onFormSubmit}>

                        <div className="row d-flex justify-content-center mb-2">
                            <div className="col-10  justify-content-center">
                            <p><LR localResource="COMMON_LABEL_ACCOUNT_RESETPASSWORDINTRO" params={{name : userLogged.name}}></LR></p>
                            <p><LR localResource="COMMON_LABEL_ACCOUNT_RESETPASSWORDCHANGEMESSAGE"/></p>
                            <p></p>
                            <p><LR localResource="COMMON_LABEL_ACCOUNT_RESETPASSWORDCREATIONGUIDELINES"/></p>
                            <ul>
                                <li><LR localResource="SECURITY_ERROR_PASSWORDRULES.LENGTH"/></li>
                                <li><LR localResource="SECURITY_ERROR_PASSWORDRULES.UPPERCASELOWERCASE"/></li>
                                <li><LR localResource="SECURITY_ERROR_PASSWORDRULES.CHARACTERS"/></li>
                            </ul>
                            </div>
                        </div>    

                    {Boolean(userPasswordResetState.id) &&
                        <div className="row d-flex justify-content-center mb-2">
                            <div className="col-10  d-flex justify-content-center">
                                <input
                                    type="password"
                                    className="form-control fa-form"
                                    placeholder={i18n.t("SECURITY_LABEL_PASSWORD").toString()}
                                    value={userPasswordResetState.password}
                                    onChange={(e) =>
                                        setuserPasswordResetState({ ...userPasswordResetState, password: e.target.value })
                                    }
                                    required
                                ></input>
                            </div>
                        </div>
                    }
                    {Boolean(userPasswordResetState.id) &&
                        <div className="row d-flex justify-content-center mb-2">
                            <div className="col-10 d-flex justify-content-center">
                                <input
                                    type="password"
                                    className="form-control fa-form"
                                    placeholder={i18n.t("SECURITY_LABEL_CONFIRMPASSWORD").toString()}
                                    value={userPasswordResetState.confirmPassword}
                                    onChange={(e) =>
                                        setuserPasswordResetState({ ...userPasswordResetState, confirmPassword: e.target.value })
                                    }
                                    required
                                ></input>
                            </div>
                        </div>
                    }

                    {Boolean(userPasswordResetState.id) &&
                        <Otp code={userPasswordResetState.confirmationCode} id={userPasswordResetState.id} valueChanged={OTPCodeChange} ></Otp>
                    }

                    <ErrorLabel message={userPasswordResetState.error} />

                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-md-4 col-6 d-flex justify-content-center">
                            <button className="btn btn-green btn-primary btn-block" disabled={submitting || !userLogged.id} type="submit">
                                {Boolean(userPasswordResetState.id) && <LR localResource="SECURITY_BUTTON_CHANGEPASSWORD"/>}
                                {!Boolean(userPasswordResetState.id) && <LR localResource="SECURITY_BUTTON_RESETPASSWORD"/>}
                            </button>
                        </div>
                    </div>
                </form>
            }
            {
                Boolean(userPasswordResetResponseState.isPasswordChanged) && 
                <LR localResource="COMMON_LABEL_ACCOUNT_MEMBERRESETPASSWORD" params={{name : userLogged.name}}></LR>
            }
           
       </div>
       </div>
    );
}

export default ResetPasswordAlreadyRegistered;
