import { IconButton, Input, InputAdornment } from "@mui/material";
import i18n from "../../i18n";
import { MemberResponseDto } from "../../types/Member/MemberResponseDto";
import { useEffect, useState } from "react";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { DialogOperatorSelector } from "./DialogOperatorSelector";
import { useFetchMembers } from "../../hooks/Member/member";
import ApiStatus from "../Common/Errors/ApiStatus";
import MessageAlert from "../Common/Errors/MessageAlert";
import { pontConstants } from "../../data/pontConstants";

type Args =
  {
    operatorId: string,
    legalEntityMemberId: string,
    placeholder?: string,
    displayMemberType?: string,
    handleConfirm: (operator: MemberResponseDto) => void
    flagType: number;
  }

const ParentMemberSelector = ({ operatorId, legalEntityMemberId, placeholder, handleConfirm, flagType, displayMemberType }: Args) => {
  const { data: legalentities, status, isSuccess } = useFetchMembers(flagType, operatorId, 3);
  const [selectedRow, setSelectedRow] = useState<MemberResponseDto | null>(null);
  const [open, setOpen] = useState(false);
  const [operatorName, setOperatorName] = useState('');


  useEffect(() => {
    if (operatorId && legalentities) {
      if (legalEntityMemberId !== pontConstants.emptyGuid && legalEntityMemberId !== null) {
        const selectedLegalEntity = legalentities.find(x => x.id === legalEntityMemberId);
        if (selectedLegalEntity) {
          setSelectedRow(selectedLegalEntity);
          handleValueSelection(selectedLegalEntity);
        }
      }

    }

  }, [operatorId, legalEntityMemberId, legalentities]);


  useEffect(() => {
    if (legalentities) {
      if (legalentities.length === 1) //only 1 operator. we'll select it automatically
      {
        setSelectedRow(legalentities[0]);
        handleValueSelection(legalentities[0]);
      }
    }

  }, [legalentities]);


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleValueSelection = (operator: MemberResponseDto) => {
    setOperatorName(operator.fullName)
    handleConfirm(operator);
    handleClose();
  };

  const getPlaceholder = () => {
    return i18n.t(placeholder ?? "LOCATION_LABEL_LEGALENTITY").toString();
  }


  if (!isSuccess) return (
    <>
      <div className="mt-5 row d-flex justify-content-center">
        <div className="col-md-7 col-11 justify-content-center">
          <ApiStatus status={status} />
        </div>
      </div>
    </>
  )

  if (!legalentities) return <MessageAlert message="MEMBER_LABEL_MEMBERSNOTFOUND"></MessageAlert>;



  return (
    <>
      <DialogOperatorSelector type={displayMemberType ?? 'LegalEntity'} operators={legalentities} open={open} handleCancel={handleClose} handleConfirm={handleValueSelection}></DialogOperatorSelector>
      <div>
        <Input
          style={{ opacity: 1 }}
          type="text"
          className="form-control fa-form"
          placeholder={getPlaceholder()}
          value={operatorName}
          disabled
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={handleOpen}>
                <SearchRoundedIcon style={{ color: "white" }} />
              </IconButton>
            </InputAdornment>
          }
        />

      </div>
    </>
  );
};


export { ParentMemberSelector }