import axios, { AxiosError, AxiosResponse } from "axios";
import { operatorDefaultHeaders } from "../../functions/api/api";
import { HookConfig } from "../../config/HookConfig";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { EventBaseDto } from "../../types/Event/EventBaseDto";
import { SummaryItemDto } from "../../types/Common/SummaryItemDto";
import { EventResponseDto } from "../../types/Event/EventResponseDto";

const useFetchEvents = (memberId: string) => {
  return useQuery<EventBaseDto[], AxiosError>(["events-summary", memberId], () =>
    axios.get(`${HookConfig.inventoryUrl}/events?operatormemberid=${memberId}`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data)
  );
}


const useFetchEventSummaryById = (id: string, summaryName: string, doFetch: boolean = true) => {
  return useQuery<SummaryItemDto[], AxiosError>(["event-summary", id, summaryName], () =>
    axios.get(
      (summaryName ?
        `${HookConfig.inventoryUrl}/events/${id}/${summaryName}/summary` :
        `${HookConfig.inventoryUrl}/events/${id}/summary`),
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      })
      .then(
        (response) => {
          return response.data;
        }
      ), {enabled: doFetch});
};

const useCreateEvent = (callback: (eventId: string) => void) => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError, EventResponseDto>(
    (eventResponse) => axios.post(`${HookConfig.inventoryUrl}/events`, eventResponse,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ),
    {
      onSuccess: (_, event) => {
        queryClient.invalidateQueries(["event", event.id]);
        callback(_.data.id);
      },
      onError: (_, error) => {
        console.log(error);
      }
    }
  );
};


const useUpdateEvent = (callback: () => void) => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError, EventResponseDto>(
    (eventResponse) => axios.put(`${HookConfig.inventoryUrl}/events/${eventResponse.id}`, eventResponse,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ),
    {
      onSuccess: (_, event) => {
        queryClient.invalidateQueries(["event", event.id]);
        callback();
      },
      onError: (_, error) => {
        console.log(error);
      }
    }
  );
};

const useFetchEventById = (id: string, doFetch: boolean = true) => {
  return useQuery<EventResponseDto, AxiosError>(["event", id], () =>
    axios.get(`${HookConfig.inventoryUrl}/events/${id}`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      })
      .then(
        (response) => {
          var event: EventResponseDto = response.data;
          return event;
        }
      ), { enabled: doFetch });
};


const useUpdateEventDescription = (callback: () => void, descriptiveFieldName: string) => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError, EventResponseDto>(
    (event) => axios.put(`${HookConfig.inventoryUrl}/events/${event.id}/descriptions/${descriptiveFieldName}`,
      {
        id: event.id,
        descriptivefieldname: descriptiveFieldName,
        description: descriptiveFieldName === 'Full' ? event.description : event.checkOutNote
      },
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ),
    {
      onSuccess: (_, event) => {
        queryClient.invalidateQueries(["event", event.id]);
        callback();
      },
      onError: (_, error) => {
        console.log(error);
      }
    }
  );
};

export {
  useFetchEvents,
  useFetchEventById,
  useFetchEventSummaryById,
  useUpdateEventDescription,
  useUpdateEvent,
  useCreateEvent
};