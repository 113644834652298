import { Card, Dialog, dialogClasses, Slide, styled } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import { colors } from "../../../main/Theme";

type CustomDialogArgs = {
  open: boolean
  onClose: () => void
  children?: React.ReactNode
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export const CustomDialogStyle = styled(Dialog)(({ theme }) => ({
  [`& .${dialogClasses.paper}`]: {
    border: 'none',
    borderRadius: 10,
    backgroundColor: 'transparent'
  },
  [`& .${dialogClasses.container}`]: {
    backdropFilter: 'blur(5px)'
  },
}));

export const CustomDialog = ({ open, onClose, children }: CustomDialogArgs) => {
  return (
    <CustomDialogStyle
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <Card sx={{
        background: colors.grey[500],
        padding: 3
      }}>
        {children}
      </Card>
    </CustomDialogStyle>
  )
}