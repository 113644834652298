import { useEffect, useState, useCallback, useRef } from 'react';

interface UseDirtyStateV2 {
  isDirty: boolean;
  setDirty: () => void;
  unsetDirty: () => void;
  showConfirmation: boolean;
  handleDirtyAction: (cb: () => void) => void;
  confirmDirtyAction: () => void;
  cancelDirtyAction: () => void;
}

export const useDirtyStateV2 = (): UseDirtyStateV2 => {
  const [isDirty, setIsDirty] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const cbRef = useRef<() => void>(() => {});

  // Handle the "beforeunload" event.
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isDirty) {
        e.preventDefault();
        e.returnValue = ''; // Required for certain browsers.
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [isDirty]);

  // Mark the state as dirty.
  const setDirty = useCallback(() => setIsDirty(true), []);

  // Reset the dirty state.
  const unsetDirty = useCallback(() => setIsDirty(false), []);

  // Cancel and hide the confirmation dialog.
  const cancelDirtyAction = useCallback(() => {
    setShowConfirmation(false);
    cbRef.current = () => {};
  }, []);

  // Confirm and proceed with the dirty action.
  const confirmDirtyAction = useCallback(() => {
    cbRef.current();
    unsetDirty();
    setShowConfirmation(false);
  }, [unsetDirty]);

  // Handle a dirty action by showing confirmation or executing directly.
  const handleDirtyAction = useCallback(
    (cb: () => void) => {
      if (isDirty) {
        setShowConfirmation(true);
        cbRef.current = cb;
      } else {
        cb();
      }
    },
    [isDirty]
  );

  return {
    isDirty,
    setDirty,
    unsetDirty,
    showConfirmation,
    handleDirtyAction,
    confirmDirtyAction,
    cancelDirtyAction,
  };
};
