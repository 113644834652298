import axios, { AxiosError, AxiosResponse } from "axios";
import { operatorDefaultHeaders } from "../../functions/api/api";
import { TicketCategoryDto } from "../../types/Ticket/TicketCategoryDto";
import { HookConfig } from "../../config/HookConfig";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useEffect, useState } from "react";

const useFetchTicketCategories = () => {
  return useQuery<TicketCategoryDto[], AxiosError>(["ticket-categories"], () =>
    axios.get(`${HookConfig.operatorMgmtUrl}/ticket-categories`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data)
  );
}

const useFetchTicketCategoryById = (ticketCategoryId: string) => {
  return useQuery<TicketCategoryDto, AxiosError>(["ticket-category", ticketCategoryId], () =>
    axios.get(`${HookConfig.operatorMgmtUrl}/ticket-categories/${ticketCategoryId}`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data)
  );
}


const useCreateTicketCategory = (callback: (ticketCategoryId: string) => void) => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError, TicketCategoryDto>(
    (ticketCategoryResponse) => axios.post(`${HookConfig.operatorMgmtUrl}/ticket-categories`, ticketCategoryResponse,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ),
    {
      onSuccess: (_, ticketCategory) => {
        queryClient.invalidateQueries(["ticket-category", ticketCategory.id]);
        callback(_.data.id);
      },
      onError: (_, error) => {
        console.log(error);
      }
    }
  );
};

const useUpdateTicketCategory = (callback: (ticketCategoryId: string) => void) => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError, TicketCategoryDto>(
    (ticketCategoryResponse) => axios.put(`${HookConfig.operatorMgmtUrl}/ticket-categories/${ticketCategoryResponse.id}`, ticketCategoryResponse,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ),
    {
      onSuccess: (_, ticketCategory) => {
        queryClient.invalidateQueries(["ticket-category", ticketCategory.id]);
        callback(_.data.id);
      },
      onError: (_, error) => {
        console.log(error);
      }
    }
  );
};


const useGetCategoryNameValidity = (categoryName: string, isDirty: boolean) => {
  const [data, setData] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
      const fetchData = async () => {
          setLoading(true);
          try {
              const response = await axios.get(`${HookConfig.operatorMgmtUrl}/ticket-categories/validity/${categoryName}`,
                {
                  withCredentials: true,
                  headers: operatorDefaultHeaders()
                }
              );
              setData(response.data.isValid);
          } catch (err) {
              console.error('Error fetching data', err);
          } finally {
              setLoading(false);
          }
      };
      if(isDirty && categoryName !== '') {
        fetchData();
      }
  }, [categoryName, isDirty]);

  return { data, loading };
};


export {
  useFetchTicketCategories,
  useFetchTicketCategoryById,
  useCreateTicketCategory,
  useUpdateTicketCategory,
  useGetCategoryNameValidity
}