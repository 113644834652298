import { ConfigurationDto } from "../types/Common/ConfigurationDto";

const productConfigurations: ConfigurationDto[] = [
  {
    name: "PRODUCT_LABEL_PRODUCTRULES",
    url: "productrules",
    groupName: "MENU_LABEL_PRODUCTS"
  },
  {
    name: "PRODUCT_LABEL_PRICINGPORTFOLIO",
    url: "pricingportfolio",
    groupName: "MENU_LABEL_PRODUCTS"
  },
]

export {
  productConfigurations
}