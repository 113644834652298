import axios, { AxiosError, AxiosResponse } from "axios";
import { HookConfig } from "../../config/HookConfig";
import { operatorDefaultHeaders } from "../../functions/api/api";
import { useEffect, useState } from "react";
import { SearchRequestDto } from "../../types/Common/SearchRequestDto";
import { NotificationRequestSearachResponseDto } from "../../types/Notification/NotificationRequestSearachResponseDto";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { NotificationRequestDto } from "../../types/Notification/NotificationRequestDto";
import { NotificationRequestStatusDto } from "../../types/Notification/NotificationRequestStatusDto";
import { NotificationRequestSearchRequestDto } from "../../types/Notification/NotificationRequestSearchRequestDto";

const useFetchNotificationRequests = (applicationId: string, searchQuery: string, pageCount: number, pageSize: number, statusId?: number) => {
  const [data, setData] = useState<NotificationRequestSearachResponseDto>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let searchParams: NotificationRequestSearchRequestDto = {
      applicationId: applicationId,
      requirement: searchQuery,
      pageNumber: pageCount,
      pageSize: pageSize,
      notificationRequestStatusId: statusId
    }

    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${HookConfig.notificationUrl}/notification-requests`,
          {
            params: searchParams,
            withCredentials: true,
            headers: operatorDefaultHeaders()
          });
        setData(response.data);
      } catch (err) {
        console.error('Error fetching data', err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [applicationId, searchQuery, pageCount, pageSize, statusId]);

  return { data, loading };
};

const useFetchNotificationRequestById = (id: string, doFetch: boolean = true) => {
  return useQuery<NotificationRequestDto, AxiosError>(["notification-request", id], () =>
    axios.get(`${HookConfig.notificationUrl}/notification-requests/${id}`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      })
      .then(
        (response) => {
          var notificationRequest: NotificationRequestDto = response.data;
          return notificationRequest;
        }
      ), { enabled: doFetch });
};

const useFetchNotificationRequestStatuses = () => {
  return useQuery<NotificationRequestStatusDto[], AxiosError>(["notification-request-statuses"], () =>
    axios.get(`${HookConfig.notificationUrl}/notification-request-statuses`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      })
      .then(
        (response) => {
          var notificationRequest: NotificationRequestStatusDto[] = response.data;
          return notificationRequest;
        }
      ));
};


const useCreateNotificationRequest = (callback: (eventId: string) => void) => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError, NotificationRequestDto>(
    (notificationRequest) => axios.post(`${HookConfig.notificationUrl}/notification-requests`, notificationRequest,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ),
    {
      onSuccess: (_, notificationRequestResponse) => {
        queryClient.invalidateQueries(["notification-request", notificationRequestResponse.id]);
        callback(_.data.id);
      },
      onError: (_, error) => {
        console.log(error);
      }
    }
  );
};

export {
  useFetchNotificationRequests,
  useCreateNotificationRequest,
  useFetchNotificationRequestById,
  useFetchNotificationRequestStatuses
}