import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../../types/Authentication/ApplicationUser";
import ApiStatus from "../../Common/Errors/ApiStatus";
import i18n from "../../../i18n";
import { useFetchMemberById } from "../../../hooks/Member/member";
import { ProductRulesConfigurationSummary } from "./ProductRulesConfigurationSummary";
import { PricingCatalogueConfigurationSummary } from "./PricingCatalogueConfigurationSummary";
import { SectionHead } from "../../Common/Help/SectionHead";

type Args =
    {
        userLogged: ApplicationUser
    }

const ProductConfigurationDetails = ({ userLogged }: Args) => {

    const nav = useNavigate();
    const queryStringParams = useParams();
    if (!queryStringParams.configurationName) throw Error("configurationName id needed");

    const label = `PRODUCT_LABEL_${queryStringParams.configurationName.toUpperCase()}`
    const linkCallBack = () => {
        nav(`/products/configuration/`);
    }

    const { data: operator, status: operatorStatus, isSuccess: operatorIsSuccess } = useFetchMemberById(userLogged.operator?.id!)

    if ((!operatorIsSuccess)) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <ApiStatus status={operatorStatus} />
                </div>
            </div>
        </>
    )

    if (!operator) return <ApiStatus status={operatorStatus} />;

    return (
        <>
            <SectionHead linkCallBack={linkCallBack} ctaText={i18n.t("PRODUCTS_SUMMARY_CONFIGURATION")!} name='' title={i18n.t(label)} description={i18n.t('COMMON_SUMMARY_PRODUCTMANAGEMENT')} />
            {
                queryStringParams.configurationName === 'productrules' &&
                <ProductRulesConfigurationSummary userLogged={userLogged} />
            }
            {
                queryStringParams.configurationName === 'pricingportfolio' &&
                <PricingCatalogueConfigurationSummary userLogged={userLogged} />
            }
        </>
    );
};


export { ProductConfigurationDetails }