import React, { useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { OrderStatus } from './common/OrderStatus';
import { CustomerInfo } from './common/CustomerInfo';
import { LocationLine } from './common/LocationLine';
import { CancelTotal } from './Delete/CancelTotal';
import { OrderLine } from './common/OrderLine';
import './Cancel/CancelOrder.css';
import { ApplicationUser } from '../../types/Authentication/ApplicationUser';
import { useFetchOrderById } from '../../hooks/Order/useOrder';
import { ExtendedOrderSearchResponseDto } from '../../types/Order/ExtendedOrderSearchResponseDto';
import { OrderLineDto } from '../../types/Order/OrderLineDto';
import { SectionHead } from '../Common/Help/SectionHead';
import i18n from '../../i18n';

const CancelOrder = ({ userLogged }: { userLogged: ApplicationUser }) => {
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const { data: orderDetails, isLoading, isError, error } = useFetchOrderById(id || '');

  useEffect(() => {
    if (!id) {
      console.error("Order ID is required for CancelOrder component.");
    } else {
      console.log("Fetching order details for ID:", id);
    }
  }, [id]);

  // Use type assertion here to treat orderDetails as ExtendedOrderSearchResponseDto
  const details = orderDetails as ExtendedOrderSearchResponseDto;
  console.log("🚀 ~ CancelOrder ~ details:", details)

  const { totalCanceled, totalRefund } = useMemo(() => {
    if (!details || !details.orderLines) {
      return { totalCanceled: 0, totalRefund: 0 };
    }

    const totalCanceled = details.orderLines.reduce((acc: number, line: OrderLineDto) => acc + (line.isCanceled ? 1 : 0), 0);
    const totalRefund = details.orderLines.reduce((acc: number, line: OrderLineDto) => acc + (line.isCanceled ? line.totalPrice : 0), 0);

    return { totalCanceled, totalRefund };
  }, [details]);

  const goBack = () => {
    navigate(-1);
  };

  if (isLoading) return <div>Loading order details...</div>;
  if (isError || !details) return <div>Error: {error ? (error as Error).message : 'Unknown error'}</div>;

  return (
    <>
      <SectionHead 
        linkCallBack={goBack}
        ctaText={i18n.t('Go Back') || 'Go Back'}
        name={`Order ID: ${id}`}
        title={i18n.t('Cancel Order') || 'Cancel Order'}
        description={i18n.t('Detailed view of the selected order') || 'Detailed view of the selected order'}
      />

      <div className='order-summary-container'>
        <div className='summary-inner-container'>
          <OrderStatus label='Order Status' info={details.orderStatusLabel ?? 'Unknown'} />
          <CustomerInfo label='Customer' company={details.memberFullName ?? 'Unknown'} />
          <LocationLine location='Location' address={details.locationId ?? 'Unknown'} />
          <CancelTotal label='Total Lines Canceled' info={totalCanceled} />
          <CancelTotal label='Total Refund' value={totalRefund} />
          {details.orderLines?.map((line: OrderLineDto) => (
            <OrderLine 
              key={line.orderId.toString()} 
              {...line}
              options={{
                saveChanges: () => console.log("Saving changes for", line.orderId),
                delete: () => console.log("Deleting", line.orderId)
              }}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export { CancelOrder };
