import {useQuery} from "react-query";
import axios from "axios";
import { MenuItem } from "../../types/Navigation/MenuItem";
import { operatorDefaultHeaders } from "../../functions/api/api";

function fetchMenu () : Promise<MenuItem[]> {
    return axios.get(`/menu-management/menuItems`,
    {
      withCredentials : true,
      headers: operatorDefaultHeaders() ,
    }).then((resp) => resp.data)
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        // Redirect to a specific URL when a 401 error occurs
        /*window.location.href = "/logout" */;
      }
      throw error;
    });
  }

const useFetchMenu  = ()  => {  
    return useQuery(["menuItems"], fetchMenu, {cacheTime: 3.6e+6, staleTime: 3.6e+6});
  };


export default useFetchMenu;