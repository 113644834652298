import axios, { AxiosError, AxiosResponse } from "axios";
import { operatorDefaultHeaders } from "../../functions/api/api";
import { HookConfig } from "../../config/HookConfig";
import { SummaryItemDto } from "../../types/Common/SummaryItemDto";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { MembershipBaseDto } from "../../types/Membership/MembershipBaseDto";
import { MembershipResponseDto } from "../../types/Membership/MembershipResponseDto";
import { InventoryUnavailabilityDto } from "../../types/Inventory/InventoryUnavailabilityDto";


const useFetchMemberships = (memberId: string) => {
  return useQuery<MembershipBaseDto[], AxiosError>(["memberships-summary", memberId], () =>
    axios.get(`${HookConfig.inventoryUrl}/memberships?operatormemberid=${memberId}`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data)
  );
}


const useFetchMembershipSummaryById = (id: string, summaryName: string, doFetch: boolean = true) => {
  return useQuery<SummaryItemDto[], AxiosError>(["membership-summary", id, summaryName], () =>
    axios.get(
      (summaryName ?
        `${HookConfig.inventoryUrl}/memberships/${id}/${summaryName}/summary` :
        `${HookConfig.inventoryUrl}/memberships/${id}/summary`),
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      })
      .then(
        (response) => {
          return response.data;
        }
      ), { enabled: doFetch });
};

const useCreateMembership = () => {
  return useMutation<{ id: string }, AxiosError, MembershipResponseDto>(
    (membershipResponse) => axios.post(`${HookConfig.inventoryUrl}/memberships`, membershipResponse, {
      withCredentials: true,
      headers: operatorDefaultHeaders()
    }).then(res => res.data),
  );
};


const useUpdateMembership = () => {
  return useMutation<{ id: string }, AxiosError, MembershipResponseDto>(
    (membershipResponse) => axios.put(`${HookConfig.inventoryUrl}/memberships/${membershipResponse.id}`, membershipResponse,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then(res => res.data));
};

const useFetchMembershipById = (id: string, doFetch: boolean = true) => {
  return useQuery<MembershipResponseDto, AxiosError>(["membership", id], () =>
    axios.get(`${HookConfig.inventoryUrl}/memberships/${id}`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      })
      .then(
        (response) => {
          var membership: MembershipResponseDto = response.data;
          return membership;
        }
      ), { enabled: doFetch });
};

const useFetchMembershipUnavailabilities = (id: string, doFetch: boolean = true) => {
  return useQuery<InventoryUnavailabilityDto[], AxiosError>(["membership_unavailabilities", id], () =>
    axios.get(`${HookConfig.inventoryUrl}/inventory/${id}/unavailabilities`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      })
      .then(
        (response) => {
          var unavailabilities: InventoryUnavailabilityDto[] = response.data;
          return unavailabilities;
        }
      ), { enabled: doFetch });
};

const useUpdateMembershipDescription = (callback: () => void) => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError, MembershipResponseDto>(
    (membership) => axios.put(`${HookConfig.inventoryUrl}/memberships/${membership.id}/descriptions/Full`,
      {
        id: membership.id,
        descriptivefieldname: 'Full',
        description: membership.description
      },
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ),
    {
      onSuccess: (_, membership) => {
        queryClient.invalidateQueries(["membership", membership.id]);
        callback();
      },
      onError: (_, error) => {
        console.log(error);
      }
    }
  );
};

export {
  useFetchMemberships,
  useFetchMembershipById,
  useFetchMembershipSummaryById,
  useUpdateMembershipDescription,
  useUpdateMembership,
  useCreateMembership,
  useFetchMembershipUnavailabilities
};