import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { useNavigate } from "react-router";
import { SectionHead } from "../Common/Help/SectionHead";
import { SaveSnackBar } from "../Common/SaveSnackBar";
import { LR } from "../Common/Help/LR";
import { TextareaAutosize, Tooltip } from "@mui/material";
import { LabelAndHelp } from "../Common/Help/LabelAndHelp";
import { TicketDto } from "../../types/Ticket/TicketDto";
import { useEffect, useState } from "react";
import { useCreateTicket, useFetchTicketConfiguration } from "../../hooks/Ticket/ticket";
import Select, { SingleValue } from "react-select";
import { LocationSelector } from "../Location/LocationSelector";
import { LocationResponseDto } from "../../types/Location/LocationResponseDto";
import { LookUpIdDto } from "../../types/Common/LookUpIdDto";
import { GenerateExternalReference } from "../../functions/utils/helper";
import { CustomerSelector } from "../Member/CustomerSelector";
import { CustomerSearchItemResponseDto } from "../../types/Customer/CustomerSearchItemResponseDto";
import { useDirtyState } from '../../hooks/Common/dirty';
import { ConfirmationChangesDialog } from '../Common/ConfirmationChangesDialog';


type Args =
  {
    userLogged: ApplicationUser
  }

const TicketAdd = ({ userLogged }: Args) => {
  let defaultTicket: TicketDto = {
    memberId: userLogged.memberId,
    companyMemberId: userLogged.operator?.id,
    companyName: userLogged.operator?.fullName,
    locationId: userLogged.location!.id,
    partnerMemberId: '00000000-0000-0000-0000-000000000001',
    statusId: 1,
    subject: '',
    description: '',
    categoryId: 1,
    priorityId: 1,
    internalIdentifier: GenerateExternalReference('TICKET'),
    updateDateUtc: new Date(),
    createDateUtc: new Date(),
    memberName: userLogged.name
  }
  const nav = useNavigate();
  const [ticketState, setTicketState] = useState(defaultTicket);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { data: configuration } = useFetchTicketConfiguration();

  const [priorities, setPriorities] = useState<LookUpIdDto[]>([]);
  const [selectedPriority, setSelectedPriority] = useState<LookUpIdDto>();

  const [categories, setCategories] = useState<LookUpIdDto[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<LookUpIdDto>();

  const [statuses, setStatuses] = useState<LookUpIdDto[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<LookUpIdDto>();

  const [isDirty, setDirty, unsetDirty] = useDirtyState();  
  const [showConfirmation, setShowConfirmation] = useState(false);  
  const [targetUrl, setTargetUrl] = useState('');  



  useEffect(() => {
    if (configuration) {
      setPriorities(configuration!.priorities.map(x => ({ label: x.label, value: x.id })));
      setStatuses(configuration!.statuses.map(x => ({ label: x.label, value: x.id })));
      setCategories(configuration!.categories.map(x => ({ label: x.label, value: x.id })));
    }
  }, [configuration]);

  useEffect(() => {
    if (priorities) {
      setSelectedPriority(priorities[0]);
    }
  }, [priorities]);

  useEffect(() => {
    if (statuses) {
      setSelectedStatus(statuses[0]);
    }
  }, [statuses]);

  useEffect(() => {
    if (categories) {
      setSelectedCategory(categories[0]);
    }
  }, [categories]);

  const handleDropdownChangePriority = (newValue: SingleValue<{ value: number, label: string | undefined }>) => {
    const selectedOption = priorities.find(x => x.value === newValue?.value);

    if (selectedOption) {
      setSelectedPriority(selectedOption);
      setTicketState({ ...ticketState!, priorityId: selectedOption.value });
    }
  };

  const handleDropdownChangeCategory = (newValue: SingleValue<{ value: number, label: string | undefined }>) => {
    const selectedOption = categories.find(x => x.value === newValue?.value);

    if (selectedOption) {
      setSelectedCategory(selectedOption);
      setTicketState({ ...ticketState!, categoryId: selectedOption.value });
    }
  };

  const handleDropdownChangeStatus = (newValue: SingleValue<{ value: number, label: string | undefined }>) => {
    const selectedOption = statuses.find(x => x.value === newValue?.value);

    if (selectedOption) {
      setSelectedStatus(selectedOption);
      setTicketState({ ...ticketState!, statusId: selectedOption.value });
    }
  };

  const confirmLocation = (location: LocationResponseDto) => {
    setTicketState({ ...ticketState!, locationId: location.id });
  }

  const confirmCustomer = (customer: CustomerSearchItemResponseDto) => {
    setTicketState({ ...ticketState, memberId: customer.memberId, memberName: `${customer.firstName} ${customer.lastName}`, companyMemberId: customer.companyId!, companyName: customer.companyName! });
  }

  const createdCallback = (ticketId: string) => {
    setSnackbarOpen(true);
    nav(`/tickets/${ticketId}`);
  }
  const create = useCreateTicket(createdCallback);

  const homeCallBack = () => {
    let url = `/tickets`;
    if (isDirty) {
      setShowConfirmation(true);  
      setTargetUrl(url);  
    } else {
      nav(url);  
    }
  };
  

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();

      await create.mutate(ticketState);

    } catch (err: any) {
      console.error(err);
    }
  }

  const requiredPopulated = () => {
    return true;
  }

  const handleSnackClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <> 
    
    <SectionHead linkCallBack={homeCallBack}
      ctaText={i18n.t('MENU_LABEL_TICKETS')!}
      name={`${ticketState.updateDateUtc?.toDateString()} - ${ticketState.updateDateUtc?.toLocaleTimeString()}`}
      title={i18n.t("MENU_LABEL_TICKETS")}
      description={i18n.t("COMMON_SUMMARY_TICKETMANAGEMENT")} />

      <ConfirmationChangesDialog
            handleConfirm={() => {
              setShowConfirmation(false);
              unsetDirty();  
              nav(targetUrl);
            }}
            handleCancel={() => setShowConfirmation(false)} 
            showConfirmation={showConfirmation}  
          />

      <form onSubmit={onFormSubmit} >

        <div className="row d-flex justify-content-center">
          <div className="col-md-7 col-11 justify-content-center">
            <div className="row d-flex justify-content-center mb-2">
              <div className="col-md-3 col-6 justify-content-center">
                <LabelAndHelp mandatory={false} fullscreen={true} message='TICKET_LABEL_INTERNALIDENTIFIER' ></LabelAndHelp>
                <input
                  type="text"
                  disabled
                  className="form-control fa-form"
                  placeholder={i18n.t("TICKET_LABEL_INTERNALIDENTIFIER").toString()}
                  defaultValue={ticketState.internalIdentifier} />
              </div>
              <div className="col-md-9 col-6 justify-content-center">
                <LabelAndHelp mandatory={true} fullscreen={true} message='TICKET_LABEL_SUBJECT' ></LabelAndHelp>
                <input
                  type="text"
                  className="form-control fa-form"
                  placeholder={i18n.t("TICKET_LABEL_SUBJECT").toString()}
                  value={ticketState.subject}
                  onChange={e => { setTicketState({ ...ticketState, subject: e.target.value });
                  setDirty();  
                  }}></input>
              </div>
            </div>

            <div className="row d-flex mb-2">
              <div className="col-md-7 col-6 justify-content-center">
                <LabelAndHelp mandatory={false} fullscreen={true} message='TICKET_LABEL_CATEGORY' ></LabelAndHelp>
                <Select 
                value={selectedCategory} 
                options={categories} 
                className="form-control fa-form fa-dropdown-container" 
                classNamePrefix="fa-dropdown"
                onChange={(e) => {
                  handleDropdownChangeCategory(e);
                  setDirty();  
                }} 
                placeholder={i18n.t('TICKET_LABEL_CATEGORY')}
              />
              </div>
              <div className="col-md-3 col-6 justify-content-center">
                <LabelAndHelp mandatory={false} fullscreen={true} message='TICKET_LABEL_PRIORITY' ></LabelAndHelp>
                <Select 
                value={selectedPriority}
                options={priorities}
                className="form-control fa-form fa-dropdown-container"
                classNamePrefix="fa-dropdown"
                  onChange={(e) => {
                    handleDropdownChangePriority(e);
                    setDirty(); 
                  }} 
                  placeholder={i18n.t('TICKET_LABEL_PRIORITY')}
                  ></Select>
              </div>
              <div className="col-md-2 col-6 justify-content-center">
                <LabelAndHelp mandatory={false} fullscreen={true} message='TICKET_LABEL_STATUS' ></LabelAndHelp>
                <Select
                value={selectedStatus}
                options={statuses}
                className="form-control fa-form fa-dropdown-container"
                classNamePrefix="fa-dropdown"
                onChange={(e) => {
                  handleDropdownChangeStatus(e);
                  setDirty(); 
                }} 
                placeholder={i18n.t('TICKET_LABEL_STATUS')}
                ></Select>
              </div>
            </div>

            <div className="row d-flex mb-2">
              <LabelAndHelp mandatory={false} fullscreen={true} message='TICKET_LABEL_LOCATION'></LabelAndHelp>
              <LocationSelector locationId={ticketState.locationId!}
                placeholder="MEMBERSHIP_LABEL_LOCATION"
                operatorId={userLogged.operator?.id!}
                handleConfirm={confirmLocation} />
            </div>

            <div className="row d-flex mb-2">

              <LabelAndHelp mandatory={false} fullscreen={true} message='TICKET_LABEL_MEMBER'></LabelAndHelp>
              <CustomerSelector userLogged={userLogged} customerMemberId={ticketState.memberId!} handleConfirm={confirmCustomer} ></CustomerSelector>
            </div>
            <div className="row d-flex mb-2">
            <div className="col-md-12 col-11 justify-content-center">
              <LabelAndHelp mandatory={true} fullscreen={true} message='TICKET_LABEL_DESCRIPTION' ></LabelAndHelp>
              <textarea
                className="form-control fa-form"
                style={{ resize: 'none', width: '100%' }}
                aria-label={i18n.t("TICKET_LABEL_DESCRIPTION").toString()}
                rows={5}
                defaultValue={ticketState.description}
                onChange={(value) => {
                  setTicketState(({ ...ticketState, description: value.target.value }));
                  setDirty();  
                }}
                placeholder={i18n.t("TICKET_LABEL_DESCRIPTION").toString()}
              />
                </div>
            </div>
            <div className="row d-flex justify-content-center mb-2">
              <div className="col-md-4 col-6 justify-content-center">
                <Tooltip title={!requiredPopulated() ? i18n.t('TICKET_REQUIRED_TOOLTIP') : ''}>
                  <span>
                    <button 
                    type="submit"
                    disabled={!requiredPopulated() || !isDirty}  
                    style={!requiredPopulated() ? { pointerEvents: 'none' } : {}}
                    className="btn btn-green btn-primary btn-block"
                    >
                      <LR localResource="COMMON_LABEL_SAVE"></LR>
                    </button>
                  </span>
                </Tooltip>
              </div>
            </div>
            <SaveSnackBar onClose={handleSnackClose} isOpen={snackbarOpen} />
          </div>
        </div>

      </form>
    </>
  );
};

export { TicketAdd }