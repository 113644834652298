import React from 'react';

interface LocationLineProps {
  location: string;
  address: string;
}

const LocationLine:React.FC<LocationLineProps> = ({ location, address}) => {
  return (
    <div className='invoice-line-main'>
      <div className='status-section-left'>{location}</div>
      <div className='status-section-right'>{address}</div>
    </div>
  );
};

export { LocationLine };
