import "../../Common/SummaryItems/SummaryItem.css"
import {  useFetchCataloguePrices } from "../../../hooks/Product/product";
import ApiStatus from "../../Common/Errors/ApiStatus";
import { PricesCollection } from "../../Common/Prices/PricesCollection";
import { PriceDto } from "../../../types/Price/PriceDto";

type Args = {
  catalogueId: string,
  currencyCode: string,
  productId: string
  callBack: (item: PriceDto) => void,
  handleDirty: () => void
};

const ProductPortfolioPricingContainer = ({ catalogueId, currencyCode, callBack, handleDirty, productId }: Args) => {
  const { data: prices, status: pricesStatus, isSuccess: pricesIsSuccess } = useFetchCataloguePrices(catalogueId, productId)
  if (!prices) return <ApiStatus status={"loading"} />;

  return (
    <>

      <div className="row d-flex justify-content-center mb-3">
        <div className="justify-content-center">
          <PricesCollection currencyCode={currencyCode} label='' handleDirty={handleDirty} callBack={callBack} items={prices!}></PricesCollection>
        </div>
      </div>

    </>
  );
}

export {
  ProductPortfolioPricingContainer
}