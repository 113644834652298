import { useMutation, useQuery, useQueryClient } from "react-query";
import { SummaryItemDto } from "../../types/Common/SummaryItemDto";
import axios, { AxiosError, AxiosResponse } from "axios";
import { HookConfig } from "../../config/HookConfig";
import { useNavigate } from "react-router";
import Problem from "../../types/Problem";
import { operatorDefaultHeaders } from "../../functions/api/api";


const useFetchFeaturesByLocationId = (locationId : string) => {
    return useQuery<SummaryItemDto[], AxiosError>(["locationFeatures", locationId], () =>
      axios.get(`${HookConfig.locationUrl}/${locationId}/features/summary`,
        {
          withCredentials: true,
           headers: operatorDefaultHeaders() 
        }
      ).then((resp) => resp.data)
    );
  }

  const useFetchLocationFeatureByGroupName = (locationId : string, groupName : string) => {
    return useQuery<any, AxiosError>(["locationFeatures", locationId, groupName], () =>
      axios.get(`${HookConfig.locationUrl}/${locationId}/feature-heirarchies?group=${groupName}`,
        {
          withCredentials: true,
           headers: operatorDefaultHeaders() 
        }
      ).then((resp) => resp.data)
    );
  }


  const   useManageLocationFeatures = () => {
    const queryClient = useQueryClient();
    const nav = useNavigate();
    const manageFeatures = async ([id, groupName, items]: [string, string, any[]]): Promise<AxiosResponse<any, any>> => {
        
        const url =  `${HookConfig.locationUrl}/${id}/features`;
        const body = {locationId: id, group: groupName, features: items}

        const response = await axios["put"](
            url, body,
            {
              withCredentials: true,
               headers: operatorDefaultHeaders() ,
            }
          );
        
        return response;
        
      };
  
    const mutation = useMutation<
      AxiosResponse<any, any>, AxiosError<Problem>,[string, string, any[]]>(manageFeatures, {
        onSuccess: (response, [id, groupName ] ) => {
            queryClient.invalidateQueries(["locationFeatures", id, groupName]);
            nav(`/locations/${id}/features`);
          },
    });
  
    return mutation;
  };



  export
  {
    useFetchFeaturesByLocationId,
    useFetchLocationFeatureByGroupName,
    useManageLocationFeatures
  }