import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import ApiStatus from "../Common/Errors/ApiStatus";
import i18n from "../../i18n";
import { useFetchDigitalAssetsRequirements } from "../../hooks/Common/fileUpload";
import { getSummaryLabel } from "../../hooks/Common/labels";
import { FileuploadContainer } from "../Common/Upload/FileUploadContainer";
import { SectionHead } from "../Common/Help/SectionHead";
import { useFetchProductById } from "../../hooks/Product/product";

type Args =
    {
        userLogged: ApplicationUser
    }

const ProductDigitalAssets = ({ userLogged }: Args) => {

    const nav = useNavigate();
    const queryStringParams = useParams();
    if (!queryStringParams.id) throw Error("Product id needed");
    if (!queryStringParams.groupName) throw Error("Digital Asset Type id needed");

    const titleLabel = getSummaryLabel(queryStringParams.groupName!);

    const linkCallBack = () => {
        nav(`/products/${queryStringParams.id}`);
    }

    const { data: product, status: productStatus, isSuccess: productIsSuccess } = useFetchProductById(queryStringParams.id!, true);
    const { data, status, isSuccess } = useFetchDigitalAssetsRequirements('product', queryStringParams.id!, queryStringParams.groupName!);

    if ((!isSuccess || !productIsSuccess) && (status !== 'success') && (productStatus !== 'success')) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <ApiStatus status={productStatus} />
                </div>
            </div>
        </>
    )

    if (!product || !data) return <ApiStatus status={status} />;

    return (
        <>
            <SectionHead linkCallBack={linkCallBack} ctaText={i18n.t("PRODUCT_SUMMARY_PRODUCTMAINPAGE")!}
                name={`${product.name}`}
                title={i18n.t(titleLabel!)}
                description={i18n.t('COMMON_SUMMARY_PRODUCTMANAGEMENT')} />

            <FileuploadContainer items={data} groupname={queryStringParams.groupName!}></FileuploadContainer>
        </>
    );
};


export { ProductDigitalAssets }