import React, { useState } from 'react';
import { IconDisplayer } from '../../Common/Help/IconDisplayer';


const Invoice = () => {
  const [showMore, setShowMore] = useState(false);
  const toggleShowMore = () => setShowMore(!showMore);

  return (
    <>
      <div className='order-section-main-wrapper'>
        <div className='order-section-main'>
          <div className='order-section-left'>Invoice 1</div>
          <div className='order-section-group-right'>
            <div className='order-section-right'>INV-123-1232</div>
            <div className='order-section-arrow' onClick={toggleShowMore}>
              <IconDisplayer type={'COMMON_LABEL_DOWN_ARROW'} />
            </div>
          </div>
        </div>
        {showMore && (
          <div className={`order-section-additional ${showMore ? 'show' : ''}`}>
            <div className='order-section-additional-inner'>
              <div className='order-section-additional-children'>
                <div className='order-aditional-inner-options'>Status</div>
                <div className='order-aditional-inner-data'>Paid</div>
              </div>
              <div className='order-section-additional-children'>
                <div className='order-aditional-inner-options'>Total Ex Tax</div>
                <div className='order-aditional-inner-data'>45$</div>
              </div>
              <div className='order-section-additional-children'>
                <div className='order-aditional-inner-options'>Total Inc Tax</div>
                <div className='order-aditional-inner-data'>$54</div>
              </div>
              <div className='order-section-additional-children'>
                <div className='order-aditional-inner-options'>Options</div>
                <div className='order-aditional-inner-data view-pdf'>View PDF</div>
              </div>    
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export { Invoice };
