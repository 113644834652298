import { Button, Card, CardContent, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, Tooltip, Typography, colors } from "@mui/material";
import { useState } from "react";
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ".././Common/SummaryItems/SummaryItem.css"
import { LR } from "../Common/Help/LR";
import { useNavigate } from "react-router";
import { useDeleteAllocation } from "../../hooks/Inventory/inventory";
import MessageAlert, { AlertStyle } from "../Common/Errors/MessageAlert";
import { ProductRuleSummaryResponseDto } from "../../types/Product/ProductRuleSummaryResponseDto";
import Switch from '@mui/material/Switch';
import { useManageProductsRules } from "../../hooks/Product/product";
import { OperationResultDto } from "../../types/Common/OperationResultDto";
import { pontConstants } from "../../data/pontConstants";

type Args = {
  items: ProductRuleSummaryResponseDto[],
  mutationCallBack : (operationResult : OperationResultDto) => void
};

const ProductRules = ({ items, mutationCallBack }: Args) => {


  const internalMutationCallback = (productRuleId : string, responseId : string, operationResult : OperationResultDto) => {

    if(operationResult.isSuccess)
    {
      const updatedItems = rulesItems.map(obj =>
        obj.productRuleId === productRuleId ? { ...obj, id : responseId } : obj
      );
      setRulesItems(updatedItems);
    }
    mutationCallBack(operationResult);
  };


  const nav = useNavigate();
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const deleteallocation = useDeleteAllocation();

  const [rulesItems, setRulesItems] = useState<ProductRuleSummaryResponseDto[]>(items);
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
  const [deleteName, setDeleteName] = useState<string>('');
  const [deleteId, setDeleteId] = useState<string>('');
  const [deleteRuleId, setdeleteRuleId] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [toggleStates, setToggleStates] = useState<boolean[]>(items.map((x) => x.id !== pontConstants.emptyGuid ));
  const mutationUpdate = useManageProductsRules(internalMutationCallback);

  const handleDelete = (name: string, id: string, inventoryId: string) => {
    setError('');
    setDeleteName(name);
    setDeleteId(id);
    setdeleteRuleId(inventoryId);
    setDeleteConfirmation(true);
  }

  const confirmDeleteItem = async (id: string, inventoryId: string) => {
    const resultDelete = await deleteallocation(id, inventoryId);
    if (resultDelete.success) {
      setRulesItems(rulesItems.filter(x => x.id !== id))
    } else {
      setError("INVENTORY_ERROR_ALLOCATIONDELETION");
    }
    setDeleteConfirmation(false);
  }


  // Handle toggle change for a specific item based on its index
  const handleToggleChange = (index: number, item : ProductRuleSummaryResponseDto) => {
    setToggleStates((prevStates) => {
      const updatedStates = [...prevStates];
      updatedStates[index] = !updatedStates[index];
      return updatedStates;
    });

    mutationUpdate.mutate({isDelete : item.id !== pontConstants.emptyGuid, productId : item.productId, productRuleId : item.productRuleId } );

  };

  return (
    <>

      <Paper elevation={3} className="fa-paper-light">
        <div className="d-flex align-items-center">
          <div className="fa-name flex-grow-1">

            <Typography variant="h6" className="linkbutton">
              <LR localResource="PRODUCT_LABEL_PRODUCTRULES" /> ({rulesItems.length})
            </Typography>
          </div>
          <div className="fa-icons">
            {
              rulesItems.length > 0 &&  <IconButton className="fa-arrow" onClick={() => setIsCollapseOpen(!isCollapseOpen)}>
              {!isCollapseOpen && <ArrowForwardIosRoundedIcon />}
              {isCollapseOpen && <ArrowForwardIosRoundedIcon className='fa-arrow-down' />}
            </IconButton>
            }

          </div>
        </div>
      </Paper>

      <Collapse in={isCollapseOpen}>
        <div>
          <div className="row d-flex justify-content-center">
            <div className="justify-content-center">
              {rulesItems.map((item, index) => (
                <Paper key={item.id} elevation={24} className="fa-paper-grid">
                  <div className="d-flex align-items-center">
                    <div className="fa-name flex-grow-1">
                      {item.productRuleName}
                    </div>

                    <div className="col-1 d-flex justify-content-end" style={{ flex: 1 }}>
                      <Switch
                        checked={toggleStates[index]}
                        onChange={() => handleToggleChange(index, item)}
                        color="primary"
                      />
                    </div>
                  </div>
                </Paper>
              ))}

            </div>
          </div>
          {
            error && <div className="row d-flex justify-content-center">
              <div className="justify-content-center">
                <MessageAlert message={error} variant="danger" style={AlertStyle.Tiny} additionalClassName="fa-alert-tiny"></MessageAlert>
              </div>
            </div>
          }


        </div>
      </Collapse>

      <Dialog open={deleteConfirmation} onClose={() => setDeleteConfirmation(false)}>
        <Card>
          <CardContent>
            <DialogTitle><LR localResource='COMMON_CONTACT_DELETECONFIRMATIONTITLE' /></DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Typography variant="body1" color="textSecondary">
                  <LR localResource='COMMON_BUTTON_DELETETEXT' params={{ name: deleteName }} />
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button className='btn-green' onClick={() => setDeleteConfirmation(false)}>
                <LR localResource='COMMON_BUTTON_CANCEL' />
              </Button>
              <Button className='btn-danger' type="button" onClick={() => { confirmDeleteItem(deleteId, deleteRuleId); }} color="error" autoFocus>
                <LR localResource='COMMON_BUTTON_DELETE' />
              </Button>
            </DialogActions>
          </CardContent>
        </Card>
      </Dialog>

    </>
  );
}

export {
  ProductRules
}