import React from 'react';
import { TextField, InputAdornment, Autocomplete } from '@mui/material';
import { IconDisplayer } from './Help/IconDisplayer';
import i18n from '../../i18n';

type SearchInputProps = {
  onSearchChange: (query: string) => void;
  searchQuery: string;
  suggestions?: string[];  
  placeholder?: string;
};

const SearchInput = ({ onSearchChange, searchQuery, suggestions = [], placeholder = '' }: SearchInputProps) => {  
  return (
    <div className="search-container">
      <Autocomplete
        freeSolo
        inputValue={searchQuery}
        onInputChange={(event, newInputValue) => onSearchChange(newInputValue)}
        options={suggestions}
        renderInput={(params) => (
          <TextField
            {...params}
            id="outlined-basic"
            label={i18n.t(placeholder)}  
            variant="outlined"
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end" className='order-container-highlight'>
                  <IconDisplayer type={'COMMON_LABEL_SEARCH'} />
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              style: { color: '#00be95' }, 
            }}
          />
        )}
      />
    </div>
  );
};


export { SearchInput };
