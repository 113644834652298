import { CreditUnitDto } from "../../types/Common/CreditUnitDto"

const pontCreditUnits: CreditUnitDto[] =
    [
        {
            "id": 0,
            "unitName": "Hour",
            "label": "Hours"
        },
        {
            "id": 1,
            "unitName": "Day",
            "label": "Days"
        },
        {
            "id": 2,
            "unitName": "Week",
            "label": "Weeks"
        },
        {
            "id": 3,
            "unitName": "Month",
            "label": "Months"
        },
        {
            "id": 4,
            "unitName": "Money",
            "label": "local currency"
        }
    ]

export {
    pontCreditUnits
}