import { useState, useEffect } from 'react'
import { LR } from '../Help/LR';

export enum AlertStyle {
    Default,
    Tiny
  }

type Args = {
    message: string
    variant?: string
    timeout?: number
    additionalClassName?: string,
    params?: any
    style? : AlertStyle 
};

const MessageAlert = ({ message , variant, timeout, additionalClassName, params = undefined, style = AlertStyle.Default } : Args ) => {
    

    switch(style)
    {
        case AlertStyle.Tiny:
            return (
                <>
                {Boolean(message) && 
                            <div className={"alert alert-" + variant +" "+ additionalClassName} role="alert">                        
                                <LR localResource={message} params={params}></LR>
                                </div>
                      
                }
                </>
            )
        default: 
        return (
            <>
            {Boolean(message) && 
                <div className="row d-flex justify-content-center mb-2">
                    <div className="col-10 d-flex justify-content-center small">
                        <div className={"alert alert-" + variant +" "+ additionalClassName} role="alert">                        
                            <LR localResource={message} params={params}></LR>
                            </div>
                    </div>
                </div>
            }
            </>
        );
    }


    
}

MessageAlert.defaultPros  = {
    variant: 'info',
    timeout: 3000,
    additionalClassName : ''
  }

export default MessageAlert;