import axios, { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import Problem from "../../types/Problem";
import { UserRegistrationRequest } from "../../types/Login/UserRegistrationRequest";
import { UserRegistrationResponse } from "../../types/Login/UserRegistrationResponse";
import i18n from "../../i18n";
import { ErrorInterpolationParser } from "../../functions/error-management/errorParse";
import { useAddMember } from "../Member/member";
import { MemberRequestDto } from "../../types/Member/MemberRequestDto";
import { operatorDefaultHeaders } from "../../functions/api/api";
import { HookConfig } from "../../config/HookConfig";

const useCheckEmail = () => {
    const nav = useNavigate();
    return useMutation<AxiosResponse, AxiosError<Problem>, string>(
        (email) =>
            axios.post(`/identity-management/management/checkemail`, email, {
                headers: operatorDefaultHeaders()
            }),
        {
            onSuccess: (_) => {
                window.location.href = `/bff/login`;
            },
            onError: (err) => {
                if (err.response?.status === 404) {
                    nav(`/account/register/` + err.config?.data.slice(1, -1));
                }
            }
        }
    );
};


const UseRegisterDelegatedEmail = async function (userRegistration: UserRegistrationRequest, callback:any) {

    const emptyUserRegistrationResponse: UserRegistrationResponse =
    {
        id: "",
        code:"",
        error: "",
        countryISOCode : "",
        name :"",
        confirmed:true,
        email: "",
    }

    axios.post(`/identity-management/register/delegated`, userRegistration, {
        withCredentials: true,
        headers: operatorDefaultHeaders()
    }).then(response => {
        emptyUserRegistrationResponse.email = response.data.email;
        emptyUserRegistrationResponse.countryISOCode = response.data.countryISOCode
        emptyUserRegistrationResponse.name = response.data.name;
        emptyUserRegistrationResponse.id = response.data.id;
        callback(emptyUserRegistrationResponse);
    }).catch(err => {
        emptyUserRegistrationResponse.error = i18n.t(err.response.data.error,
                ErrorInterpolationParser(err.response.data.parameters)
            ).toString();
        callback(emptyUserRegistrationResponse);
    })
};



const UseRegisterEmail = async function (userRegistration: UserRegistrationRequest, callback:any) {

    const emptyUserRegistrationResponse: UserRegistrationResponse =
    {
        id: "",
        code:"",
        error: "",
        countryISOCode : "",
        name :"",
        confirmed:false,
        email: "",
    }

    axios.post(`/identity-management/register`, userRegistration, {
        headers: operatorDefaultHeaders()
    }).then(response => {
        emptyUserRegistrationResponse.email = response.data.email;
        emptyUserRegistrationResponse.countryISOCode = response.data.countryISOCode
        emptyUserRegistrationResponse.name = response.data.name;
        emptyUserRegistrationResponse.id = response.data.id;
        callback(emptyUserRegistrationResponse);
    }).catch(err => {
        emptyUserRegistrationResponse.error = i18n.t(err.response.data.error,
                ErrorInterpolationParser(err.response.data.parameters)
            ).toString();
        callback(emptyUserRegistrationResponse);
    })
};

const UseConfirmEmail = async function (userRegistrationResponse: UserRegistrationResponse, callback:any) {
    axios.put(`/identity-management/register/`+ userRegistrationResponse.id +`/` + userRegistrationResponse.code, userRegistrationResponse, {
        headers: operatorDefaultHeaders()
    }).then(response => {
        userRegistrationResponse.code = "";
        userRegistrationResponse.confirmed = true;
        userRegistrationResponse.countryISOCode = response.data.countryISOCode;



        callback(userRegistrationResponse);
    }).catch(err => {
        userRegistrationResponse.confirmed = false;
        userRegistrationResponse.error = (
            i18n.t(err.response.data.error,
                ErrorInterpolationParser(err.response.data.parameters)
            ).toString()
        );
        callback(userRegistrationResponse);
    })
};

const useRegisterDelegatedEmailV2 = () => {
    return useMutation<AxiosResponse, AxiosError, UserRegistrationRequest>(
        (userRegistration) => axios.post(`${HookConfig.identityUrl}/register/delegated`, userRegistration,
            {
                withCredentials: true,
                headers: operatorDefaultHeaders()
            }
        )
    );
}

export {
    useCheckEmail,
    UseRegisterDelegatedEmail,
    UseRegisterEmail,
    UseConfirmEmail,
    useRegisterDelegatedEmailV2
}