import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { SearchInput } from '../Common/SearchInput';
import DateNavigation from '../Common/DateNavigation/DateNavigation';
import { OrderFeed } from './OrderFeed';
import { IconButton, Tooltip } from "@mui/material";
import { LR } from "../Common/Help/LR";
import i18n from "../../i18n";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';

const calculateDates = (fromDate: Date): { from: Date, to: Date } => {
  const firstDayOfMonth = new Date(fromDate.getFullYear(), fromDate.getMonth(), 1);
  const lastDayOfMonth = new Date(fromDate.getFullYear(), fromDate.getMonth() + 1, 0);
  return { from: firstDayOfMonth, to: lastDayOfMonth };
};

const formatDateToLocalString = (date: Date): string => {
  const year = date.getFullYear();
  const month = (`0${date.getMonth() + 1}`).slice(-2);
  const day = (`0${date.getDate()}`).slice(-2);
  return `${year}-${month}-${day}`;
};

type Args = {
  userLogged: ApplicationUser;
};

const Orders = ({ userLogged }: Args) => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');

  const now = new Date();
  const defaultDates = calculateDates(now);
  const defaultFromDate = formatDateToLocalString(defaultDates.from);
  const defaultToDate = formatDateToLocalString(defaultDates.to);

  const [fromDate, setFromDate] = useState<string>(defaultFromDate);
  const [toDate, setToDate] = useState<string>(defaultToDate);

  const handleAddNewOrder = () => {
    navigate('/orders/new-order');
  };

  const handleSearchChange = (query: string) => {
    setSearchQuery(query);
  };


  const handleDateRangeChange = (startDate: Date, endDate: Date) => {
    setFromDate(formatDateToLocalString(startDate));
    setToDate(formatDateToLocalString(endDate));
  };


  return (
    <div className="mainpage-container-layout">
      <div className='col-md-10 col-11 justify-content-center'>
        <div className='order-button'>
            <Tooltip title={i18n.t('ACTIVITY_LABEL_NEWORDER')}>
                <IconButton onClick={handleAddNewOrder} className="btn plusButton mb-1">
                  <AddCircleRoundedIcon fontSize="medium" sx={{ color: '#00be95' }} />
                </IconButton>
            </Tooltip>
            <span className="lead mb-0">
              <LR localResource="ACTIVITY_LABEL_NEWORDER" />
            </span>
        </div>
        <div className="orders-container-parent">
          <div className="activity-container-date">
            <DateNavigation onDateSelect={handleDateRangeChange} fromDate={new Date(fromDate)} toDate={new Date(toDate)} />
          </div>
          <div className="activity-container-search">
            <SearchInput onSearchChange={handleSearchChange} searchQuery={searchQuery} placeholder={'SEARCH_BY_ORDER'}/>
          </div>         
        </div>
        <div className="activity-container-feed">
          <OrderFeed
            userLogged={userLogged}
            searchQuery={searchQuery}
            fromDate={fromDate}
            toDate={toDate}
          />
        </div>
      </div>
    </div>
  );
};

export { Orders };
