import React from 'react';


interface PoNumberProps {
  label: string;
  info: string;
  comment: string;
}

const PoNumber: React.FC<PoNumberProps> = ({ label, info, comment }) => {
  
  return (
    <>
      <div className='order-comment-main-wrapper'>
        <div className='order-comment-main'>
            <div className='order-comment-left'>{label}</div>
            <div className='order-comment-mid'>{info}</div>
            <div className='order-comment-right'>{comment}</div>
        </div>
      </div>
    </>
  );
};

export { PoNumber };
