import { useNavigate, useParams } from "react-router-dom";
import { locationConfigurations } from "../../../data/locationConfigurations";
import { useFetchLocationById } from "../../../hooks/Location/location";
import i18n from "../../../i18n";
import { ApplicationUser } from "../../../types/Authentication/ApplicationUser";
import ApiStatus from "../../Common/Errors/ApiStatus";
import { SectionHead } from "../../Common/Help/SectionHead";
import { ConfigurationCollection } from "../../Common/SummaryItems/ConfigurationCollection";

type Args =
    {
        userLogged: ApplicationUser
    }

const LocationConfiguration = ({ userLogged }: Args) => {

    const nav = useNavigate();
    const queryStringParams = useParams();
    if (!queryStringParams.id) throw Error("Location id needed");

    const linkCallBack = () => {
        nav(`/locations/${queryStringParams.id}/summary/`);
    }

    const { data: location, status: locationStatus, isSuccess: locationIsSuccess } = useFetchLocationById(queryStringParams.id!)


    if ((!locationIsSuccess)) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <ApiStatus status={locationStatus} />
                </div>
            </div>
        </>
    )

    if (!location) return <ApiStatus status={locationStatus} />;

    return (
        <>
            <SectionHead linkCallBack={linkCallBack} ctaText={i18n.t('LOCATION_SUMMARY_LOCATIONMAINPAGE')!}
                name={`${location.name} (${location.externalReference})`}
                title={i18n.t('LOCATION_LABEL_CONFIGURATION')}
                description={i18n.t('COMMON_SUMMARY_LOCATIONMANAGEMENT')} />
            <div className="row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <ConfigurationCollection showGroup={true} items={locationConfigurations}></ConfigurationCollection>
                </div>
            </div>
        </>
    );
};


export { LocationConfiguration };
